import { useQuery } from "urql";

import {
  ClikFootprintFilter,
  ClikFootprintOrderBy,
  GetClikFootprintsDocument,
} from "../../../graphql/generated";
import { getApiPaginationData } from "../../../utils/api.ts";
import { ApiHookProps } from "../types.ts";

export const useClikFootprints = ({
  filter,
  orderBy,
  page,
}: ApiHookProps<ClikFootprintFilter, ClikFootprintOrderBy>) => {
  const pagination = getApiPaginationData(page);
  const variables = {
    filter,
    orderBy,
    skip: pagination?.skip,
    take: pagination?.take,
  };
  return useQuery({ query: GetClikFootprintsDocument, variables });
};
