import { useMemo } from "react";
import { Link } from "react-router-dom";

import MultiFieldCard from "../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import { TableEmptyValue } from "../../components/Table/constants.tsx";
import { AppRoute } from "../../constants.ts";
import { ClikResultStatus } from "../../graphql/generated.ts";
import { LimitRequest } from "../../types/limitRequest.ts";
import { toLocalDate } from "../../utils/date.ts";
import { toPrice } from "../../utils/number.ts";
import { toHumanReadable } from "../../utils/string.ts";

interface Props {
  limitRequest: Partial<LimitRequest> | null | undefined;
  loading: boolean;
}

export default function LimitRequestInfoCard({ limitRequest, loading }: Props) {
  const limitRequestInfo = useMemo(() => {
    return [
      {
        label: "Limit Requested Date",
        content: toLocalDate(limitRequest?.createdAt),
      },
      {
        label: "Requested Amount",
        content: toPrice(limitRequest?.limitRequestAmount),
      },
      {
        label: "Requested Tenor",
        content: limitRequest?.tenorRequest,
      },
      {
        label: "Limit Type",
        content: toHumanReadable(limitRequest?.limit?.limitType),
      },
      {
        label: "TOP Product Category",
        content: toHumanReadable(limitRequest?.topProductCategory),
      },
      {
        label: "Limit Status",
        content: toHumanReadable(limitRequest?.limit?.status),
      },
      {
        label: "Surat Pengajuan Limit",
        content: limitRequest?.suratPengajuanLimit?.url && (
          <Link to={limitRequest?.suratPengajuanLimit.url} target="_blank">
            Download
          </Link>
        ),
      },
      {
        label: "Form LOI",
        content: limitRequest?.formLOI?.url && (
          <Link to={limitRequest?.formLOI.url} target="_blank">
            Download
          </Link>
        ),
      },
      {
        label: "CLIK Result Date",
        content: toLocalDate(limitRequest?.lastClikResult?.createdAt),
      },
      {
        label: "CLIK Result",
        content:
          limitRequest?.lastClikResult &&
          limitRequest.lastClikResult.clikStatus ===
            ClikResultStatus.Success ? (
            <Link
              to={`${AppRoute.ClikResult}/${limitRequest.lastClikResult?.id}`}
            >
              View
            </Link>
          ) : (
            TableEmptyValue
          ),
      },
    ];
  }, [limitRequest]);

  return (
    <MultiFieldCard
      label="Limit Request"
      variant="blue"
      loading={loading}
      items={limitRequestInfo}
    />
  );
}
