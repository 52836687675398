import { Flex } from "@mantine/core";
import { DataTableSortStatus } from "mantine-datatable";
import { useCallback, useState } from "react";

import SectionTitle from "../../../../../components/Section/SectionTitle/SectionTitle.tsx";
import {
  ClikGrantedLetterOfCreditOrderBy,
  ContractType,
  ContractTypeFilterOptions,
  OrderBy,
} from "../../../../../graphql/generated.ts";
import { useClikGrantedDetailsOfLettersOfCredit } from "../../../../../hooks/api/clikResult/clikContract/useClikGrantedDetailsOfLettersOfCredit.ts";
import { useClikGrantedLettersOfCredit } from "../../../../../hooks/api/clikResult/clikContract/useClikGrantedLettersOfCredit.ts";
import { useClikListOfGrantedLettersOfCredit } from "../../../../../hooks/api/clikResult/clikContract/useClikListOfGrantedLettersOfCredit.ts";
import {
  ClikGrantedContract,
  ClikGrantedContractDetails,
} from "../../../../../types/clikContract.ts";
import ClikResultDetailsOfContract from "../ClikResultDetailsOfContract/ClikResultDetailsOfContract.tsx";
import ClikGrantedContracts from "../ClikResultGrantedContracts/ClikGrantedContracts.tsx";
import ClikResultNotGrantedContracts from "../ClikResultNotGrantedContracts/ClikResultNotGrantedContracts.tsx";
import { getContractDetailsOptions } from "../contants.ts";
import { getGrantedContractsApiSort } from "../utils.ts";

interface ClikResultBondContractsProps {
  clikResultId: number;
}

const ClikResultLetterOfCreditContracts = ({
  clikResultId,
}: ClikResultBondContractsProps) => {
  const [page, setPage] = useState<number>(1);
  const [orderBy, setOrderBy] = useState<ClikGrantedLetterOfCreditOrderBy>({
    id: OrderBy.Asc,
  });
  const [selectedContractId, setSelectedContractId] = useState<number | null>();

  const [
    {
      data: grantedLettersOfCreditResponse,
      fetching: grantedLettersOfCreditFetching,
    },
  ] = useClikGrantedLettersOfCredit({
    filter: { clikResultId: { equals: clikResultId } },
    orderBy,
    page,
  });

  const [
    {
      data: listOfGrantedLettersOfCreditResponse,
      fetching: listOfGrantedLettersOfCreditFetching,
    },
  ] = useClikListOfGrantedLettersOfCredit({ clikResultId });

  const [
    {
      data: grantedDetailsOfLetterOfCreditRespose,
      fetching: grantedDetailsOfLetterOfCreditFetching,
    },
  ] = useClikGrantedDetailsOfLettersOfCredit({ id: selectedContractId });

  const grantedLettersOfCreditData = grantedLettersOfCreditResponse
    ?.clikGrantedLettersOfCredit?.data as ClikGrantedContract[];
  const grantedLettersOfCreditCount =
    grantedLettersOfCreditResponse?.clikGrantedLettersOfCredit?.total ?? 0;

  const listOfGrantedLettersOfCreditData =
    listOfGrantedLettersOfCreditResponse?.clikGrantedLettersOfCredit?.data;

  const grantedDetailsOfLetterOfCreditData =
    grantedDetailsOfLetterOfCreditRespose?.clikGrantedLetterOfCreditDetails;

  const handleOnSortChange = useCallback(
    (sort: DataTableSortStatus) => {
      setOrderBy(getGrantedContractsApiSort(sort));
    },
    [setOrderBy]
  );

  return (
    <Flex gap={12} direction="column">
      <SectionTitle variant="bronze">
        Contract Details - Irrevocable LC
      </SectionTitle>
      <Flex gap={20} direction="column">
        <ClikResultNotGrantedContracts
          clikResultId={clikResultId}
          type={ContractTypeFilterOptions.LetterOfCredit}
        />
        <ClikGrantedContracts
          contracts={grantedLettersOfCreditData}
          total={grantedLettersOfCreditCount}
          loading={grantedLettersOfCreditFetching}
          type={ContractType.LetterOfCredit}
          page={page}
          onSetPage={setPage}
          onSortChange={handleOnSortChange}
        />
        <ClikResultDetailsOfContract
          type={ContractType.LetterOfCredit}
          data={
            grantedDetailsOfLetterOfCreditData as ClikGrantedContractDetails
          }
          loading={
            grantedDetailsOfLetterOfCreditFetching ||
            listOfGrantedLettersOfCreditFetching
          }
          clikContractProfileFilter={
            selectedContractId
              ? {
                  grantedLetterOfCreditId: { equals: selectedContractId },
                }
              : undefined
          }
          detailsOptions={getContractDetailsOptions(
            listOfGrantedLettersOfCreditData,
            ContractType.LetterOfCredit
          )}
          selectedContractId={selectedContractId}
          onSetSelectedContractId={setSelectedContractId}
        />
      </Flex>
    </Flex>
  );
};

export default ClikResultLetterOfCreditContracts;
