import {
  Button,
  Checkbox,
  Flex,
  Input,
  Select,
  Textarea,
  TextInput,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { isNotEmpty, useForm } from "@mantine/form";
import { isEmpty } from "lodash";
import { useCallback } from "react";

import Dropzone from "../../../../components/Dropzone/Dropzone.tsx";
import SvgCalendar from "../../../../components/Icons/Calendar.tsx";
import {
  FileResult,
  UpdateLimitRequestConditionPrecedentInput,
} from "../../../../graphql/generated.ts";
import { LimitRequestConditionPrecedent } from "../../../../types/limitRequestConditionPrecedent.ts";
import { useStyles } from "../TransactionHistory.styles.ts";
import { typeOptions } from "./utils.ts";

interface Props {
  value: LimitRequestConditionPrecedent | null | undefined;
  onClose: () => void;
  onSubmit: (
    value: UpdateLimitRequestConditionPrecedentInput,
    id?: number
  ) => void;
}

const ConditionPrecedentsForm = ({ value, onClose, onSubmit }: Props) => {
  const form = useForm({
    initialValues: {
      completed: value?.completed,
      name: value?.name,
      type: value?.type,
      dueDate: value?.dueDate ? new Date(value?.dueDate) : undefined,
      documents: value?.documents,
      notes: value?.notes,
    },
    validate: {
      name: isNotEmpty("Name is required"),
      type: isNotEmpty("Type is required"),
      dueDate: isNotEmpty("Due Date is required"),
    },
    validateInputOnBlur: true,
  });

  const { classes } = useStyles();

  const handleClose = useCallback(() => {
    form.reset();
    onClose();
  }, [form, onClose]);

  const handleFileUploaded = (files: FileResult[]) => {
    form.setValues({
      documents: form.values.documents
        ? [...form.values.documents, ...files]
        : files,
    });
  };

  const handleRemoveFile = (value: string) => {
    const filteredFiles = form.values.documents?.filter(
      (doc) => doc.filePath !== value
    );
    form.setFieldValue("documents", filteredFiles);
  };

  const handleSubmit = useCallback(() => {
    if (!form.isValid()) {
      return;
    }
    onSubmit(
      {
        ...form.values,
        documents: form.values.documents?.map((d) => d.filePath),
      } as UpdateLimitRequestConditionPrecedentInput,
      value?.id
    );
    handleClose();
  }, [form, value, handleClose, onSubmit]);

  return (
    <Flex direction="column" gap={12} className={classes.form}>
      <Checkbox
        label="Completed"
        size="md"
        variant="golden"
        checked={form.values.completed}
        {...form.getInputProps("completed")}
      />
      <TextInput
        required
        label="Name"
        placeholder="Enter Name"
        size="m"
        maxLength={50}
        {...form.getInputProps("name")}
      />
      <Select
        withinPortal
        dropdownPosition="top"
        required
        data={typeOptions}
        label="Type"
        placeholder="Select Type"
        size="m"
        {...form.getInputProps("type")}
      />
      <DatePickerInput
        label="Due date"
        placeholder="Select Due date"
        icon={<SvgCalendar />}
        firstDayOfWeek={0}
        size="m"
        valueFormat="YYYY MMM DD"
        popoverProps={{ withinPortal: true, zIndex: 1000 }}
        clearable
        required
        {...form.getInputProps("dueDate")}
      />
      <Input.Wrapper label="Document Submission">
        <Dropzone
          value={
            isEmpty(form?.values?.documents) ? null : form.values.documents
          }
          maxWidth="100%"
          onUploadSuccess={(files) => handleFileUploaded(files)}
          onRemove={(value) => handleRemoveFile(value)}
        />
      </Input.Wrapper>
      <Textarea
        label="Notes"
        placeholder="Enter Notes"
        autosize
        size="m"
        minRows={3}
        maxRows={3}
        {...form.getInputProps("notes")}
      />
      <Flex gap={20} justify="end">
        <Button variant="outlineBlue" uppercase onClick={handleClose}>
          cancel
        </Button>
        <Button
          variant="filledBlue"
          uppercase
          disabled={!form.isValid()}
          onClick={handleSubmit}
        >
          {value ? "edit" : "save"}
        </Button>
      </Flex>
    </Flex>
  );
};

export default ConditionPrecedentsForm;
