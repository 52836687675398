import { useQuery } from "urql";

import { GetLimitRequestDocument } from "../../../graphql/generated.ts";

interface Props {
  id: number | string;
}

export function useLimitRequest({ id }: Props) {
  return useQuery({
    query: GetLimitRequestDocument,
    variables: { id: Number(id) },
  });
}
