import type { SVGProps } from "react";
const SvgUploadFile = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 -960 960 960"
    fill="currentColor"
    {...props}
  >
    <path d="M460-249.23h40V-437l84 84 28.308-28.538L480-513.846 347.692-381.538l28.539 28.308L460-437v187.77ZM264.615-120Q237-120 218.5-138.5 200-157 200-184.615v-590.77Q200-803 218.5-821.5 237-840 264.615-840H580l180 180v475.385Q760-157 741.5-138.5 723-120 695.385-120h-430.77ZM560-640v-160H264.615q-9.23 0-16.923 7.692Q240-784.615 240-775.385v590.77q0 9.23 7.692 16.923Q255.385-160 264.615-160h430.77q9.23 0 16.923-7.692Q720-175.385 720-184.615V-640H560ZM240-800v160-160 640-640Z" />
  </svg>
);
export default SvgUploadFile;
