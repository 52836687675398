import { notifications } from "@mantine/notifications";
import { AnyVariables, OperationContext, OperationResult } from "@urql/core";
import {
  UseMutationExecute,
  UseMutationResponse,
  UseMutationState,
} from "urql";

import SvgSuccess from "../components/Icons/Success.tsx";
import SvgWarning from "../components/Icons/Warning.tsx";
import { formatApiMessage } from "../utils/api.ts";

interface Config {
  show?: boolean;
  title?: string;
  message?: string;
}

interface NotificationConfig {
  loading?: Config;
  success?: Config;
  error?: Config;
}

const defaultConfig = {
  loading: {
    show: true,
    message: "Please wait...",
  },
  success: {
    show: true,
  },
  error: {
    show: true,
  },
};

export function useMutationNotificationWrapper<
  Data = unknown,
  Variables extends AnyVariables = AnyVariables
>(
  mutation: UseMutationResponse<Data, Variables>,
  customConfig: NotificationConfig = {}
): [UseMutationState<Data, Variables>, UseMutationExecute<Data, Variables>] {
  const [state, execute] = mutation;
  const config: NotificationConfig = {
    loading: { ...defaultConfig.loading, ...customConfig.loading },
    success: { ...defaultConfig.success, ...customConfig.success },
    error: { ...defaultConfig.error, ...customConfig.error },
  };
  const executeWrapper: UseMutationExecute<Data, Variables> = (
    variables: Variables,
    context?: Partial<OperationContext>
  ): Promise<OperationResult<Data, Variables>> => {
    return new Promise((resolve, reject) => {
      config.loading?.show &&
        notifications.show({
          id: "load-data",
          loading: true,
          title: config.loading.title,
          message: config.loading.message,
          autoClose: false,
        });
      execute(variables, context)
        .then((result) => {
          notifications.clean();
          if (!result.error) {
            config.success?.show &&
              notifications.show({
                title: config.success.title,
                message: config.success.message,
                icon: <SvgSuccess />,
              });
          } else {
            const message =
              config.error?.message ?? formatApiMessage(result.error);
            config.error?.show &&
              notifications.show({
                title: config.error.title,
                message: message,
                icon: <SvgWarning />,
              });
          }
          resolve(result);
        })
        .catch(reject);
    });
  };
  return [state, executeWrapper];
}
