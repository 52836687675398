import { ActionIcon, Flex } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { useNavigate, useParams } from "react-router-dom";

import SvgChevronLeft from "../../components/Icons/ChevronLeft.tsx";
import SvgWarning from "../../components/Icons/Warning.tsx";
import Layout from "../../components/Layout/Layout.tsx";
import { AppRoute } from "../../constants.ts";
import ClikResultDetails from "./ClikResultDetails/ClikResultDetails.tsx";

export default function ClikResultPage() {
  const navigate = useNavigate();
  const { id } = useParams() as { id: string };
  const clikResultId = Number(id);

  const handleError = () => {
    notifications.show({
      message: "Something went wrong while trying to fetch data.",
      icon: <SvgWarning />,
    });
    navigate(AppRoute.Home);
  };

  const handleBack = () => navigate(-1);

  return (
    <Layout>
      <Flex mb={20}>
        <ActionIcon size="m" variant="outlineGolden" onClick={handleBack}>
          <SvgChevronLeft />
        </ActionIcon>
      </Flex>
      <ClikResultDetails clikResultId={clikResultId} onError={handleError} />
    </Layout>
  );
}
