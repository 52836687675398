import { createStyles, rem } from "@mantine/core";

export const useStyles = createStyles(() => ({
  wrapper: {
    height: "fit-content",
    textDecoration: "none",
  },
  nestedLink: {
    height: rem(44),
    whiteSpace: "nowrap",
  },
}));
