import { Badge, Flex } from "@mantine/core";
import { ReactElement, useCallback, useMemo } from "react";
import { Link } from "react-router-dom";

import ActionIcon from "../../../../../components/ActionIcon/ActionIcon.tsx";
import MultiFieldCard from "../../../../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import SvgDelete from "../../../../../components/Icons/Delete.tsx";
import SvgEdit from "../../../../../components/Icons/Edit.tsx";
import Spoiler from "../../../../../components/Spoiler/Spoiler.tsx";
import TableFilesDisplay from "../../../../../components/TableFilesDisplay/TableFilesDisplay.tsx";
import { LimitRequestException } from "../../../../../types/limitRequestException.ts";
import { useStyles } from "../../Exceptions.styles.ts";

interface Props {
  data: LimitRequestException[] | null | undefined;
  onEdit: (value: LimitRequestException) => void;
  onDelete: (id: number) => void;
}

export const useExceptionList = ({
  data,
  onEdit,
  onDelete,
}: Props): ReactElement | null => {
  const { classes } = useStyles();

  const renderItemCardFields = useCallback(
    (item: LimitRequestException) => {
      return {
        cardControl: (
          <Flex gap={8}>
            <ActionIcon spaces={false} onClick={() => onEdit(item)}>
              <SvgEdit />
            </ActionIcon>
            <ActionIcon
              spaces={false}
              variant="error"
              onClick={() => onDelete(item.id)}
            >
              <SvgDelete />
            </ActionIcon>
          </Flex>
        ),
        label: item.name,
        items: [
          {
            label: "Status",
            content: (
              <Badge size="s" variant={item?.completed ? "active" : "inactive"}>
                {item?.completed ? "Complete" : "Not Complete"}
              </Badge>
            ),
          },
          {
            label: "Document Submission",
            content:
              item.documents &&
              (item.documents.length > 1 ? (
                <TableFilesDisplay files={item.documents} />
              ) : (
                <Link
                  to={item.documents[0].url}
                  target="_blank"
                  className={classes.linkLabel}
                >
                  Download
                </Link>
              )),
          },
          {
            label: "Notes",
            content: item?.notes && <Spoiler text={item.notes} />,
          },
        ],
      };
    },
    [classes, onDelete, onEdit]
  );

  return useMemo(() => {
    if (!data) {
      return null;
    }

    const renderedCards = data?.map((item, index) => {
      const cardFields = renderItemCardFields(item);
      return <MultiFieldCard key={index} {...cardFields} />;
    });

    return <>{renderedCards}</>;
  }, [data, renderItemCardFields]);
};
