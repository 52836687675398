import {
  Button,
  Flex,
  Input,
  Modal,
  ScrollArea,
  Select,
  TextInput,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { isNotEmpty, useForm } from "@mantine/form";
import { useCallback } from "react";

import Dropzone from "../../../components/Dropzone/Dropzone.tsx";
import SvgCalendar from "../../../components/Icons/Calendar.tsx";
import {
  FileResult,
  UpdateOfferLetterInput,
} from "../../../graphql/generated.ts";
import { OfferLetter } from "../../../types/offerLetter.ts";
import { offerLetterStatusOptions } from "./utils.ts";

interface Props {
  value: OfferLetter | null | undefined;
  opened: boolean;
  onClose: () => void;
  onSubmit: (value: UpdateOfferLetterInput) => void;
}

const OfferingLetterModal = ({ value, opened, onClose, onSubmit }: Props) => {
  const form = useForm({
    initialValues: {
      olNo: value?.olNo,
      offerLetter: value?.offerLetter,
      olSignedDate: value?.olSignedDate
        ? new Date(value.olSignedDate)
        : undefined,
      olSigned: value?.olSigned,
      olExpiredDate: value?.olExpiredDate
        ? new Date(value.olExpiredDate)
        : undefined,
      olStatus: value?.olStatus,
    },
    validate: {
      olNo: isNotEmpty("Ol No is required"),
      offerLetter: isNotEmpty("Offer Letter is required"),
      olSignedDate: isNotEmpty("Ol Signed Date is required"),
      olSigned: isNotEmpty("Ol Signed is required"),
      olExpiredDate: isNotEmpty("Ol Expired Date is required"),
      olStatus: isNotEmpty("Ol Status is required"),
    },
    validateInputOnBlur: true,
  });

  const handleClose = useCallback(() => {
    form.reset();
    onClose();
  }, [form, onClose]);

  const handleSubmit = useCallback(() => {
    if (!form.isValid()) {
      return;
    }
    onSubmit({
      ...form.values,
      offerLetter: form.values.offerLetter?.filePath,
      olSigned: form.values.olSigned?.filePath,
    } as UpdateOfferLetterInput);
    handleClose();
  }, [form, handleClose, onSubmit]);

  const handleFileUploaded = (name: keyof OfferLetter, files: FileResult[]) => {
    if (name === "offerLetter") {
      form.setValues({ offerLetter: files[0] });
    }
    if (name === "olSigned") {
      form.setValues({ olSigned: files[0] });
    }
  };

  const handleRemoveFile = (name: keyof OfferLetter) => {
    if (name === "offerLetter") {
      form.setValues({ offerLetter: undefined });
    }
    if (name === "olSigned") {
      form.setValues({ olSigned: undefined });
    }
  };

  return (
    <>
      <Modal
        opened={opened}
        title={value ? "Edit Offering Letter" : "Add Offering Letter"}
        closeButtonProps={{
          size: 24,
          iconSize: 24,
        }}
        size="lg"
        scrollAreaComponent={ScrollArea.Autosize}
        onClose={handleClose}
      >
        <Flex direction="column" gap={20}>
          <Flex gap={12} direction="column">
            <TextInput
              required
              label="Ol No"
              placeholder="Enter Ol No"
              size="m"
              {...form.getInputProps("olNo")}
            />
            <Input.Wrapper required label="OL file" size="m" w="100%">
              <Dropzone
                maxFiles={1}
                maxWidth="unset"
                value={
                  form.values.offerLetter ? [form.values.offerLetter] : null
                }
                onUploadSuccess={(value) =>
                  handleFileUploaded("offerLetter", value)
                }
                onRemove={() => handleRemoveFile("offerLetter")}
              />
            </Input.Wrapper>
            <DatePickerInput
              label="OL Signing Date"
              placeholder="Select OL Signing Date"
              icon={<SvgCalendar />}
              firstDayOfWeek={0}
              size="m"
              valueFormat="YYYY MMM DD"
              popoverProps={{ zIndex: 1000, withinPortal: true }}
              maxDate={new Date()}
              clearable
              required
              {...form.getInputProps("olSignedDate")}
            />
            <Input.Wrapper required label="Signed OL" size="m" w="100%">
              <Dropzone
                maxFiles={1}
                maxWidth="unset"
                value={form.values.olSigned ? [form.values.olSigned] : null}
                onUploadSuccess={(value) =>
                  handleFileUploaded("olSigned", value)
                }
                onRemove={() => handleRemoveFile("olSigned")}
              />
            </Input.Wrapper>
            <DatePickerInput
              label="OL Expired Date"
              placeholder="Select OL Expired Date"
              icon={<SvgCalendar />}
              firstDayOfWeek={0}
              popoverProps={{ zIndex: 1000, withinPortal: true }}
              size="m"
              valueFormat="YYYY MMM DD"
              clearable
              required
              {...form.getInputProps("olExpiredDate")}
            />
            <Select
              size="m"
              withinPortal
              searchable
              required
              clearable
              label="OL Status"
              placeholder="Select OL Approval Status"
              nothingFound="No results found"
              data={offerLetterStatusOptions}
              {...form.getInputProps("olStatus")}
            />
          </Flex>
          <Flex gap={20} justify="end">
            <Button
              variant="outlineBlue"
              uppercase
              type="reset"
              onClick={onClose}
            >
              cancel
            </Button>
            <Button
              variant="filledBlue"
              uppercase
              type="submit"
              disabled={!form.isValid()}
              onClick={handleSubmit}
            >
              {value ? "edit" : "add"}
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
};

export default OfferingLetterModal;
