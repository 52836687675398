import { Badge, Flex, Text } from "@mantine/core";

import { toHumanReadable } from "../../utils/string.ts";
import { useStyles } from "./CPExceptionTableCard.styles.ts";
import { TransactionHistoryTableCardItemProps } from "./types.ts";

const CPExceptionTableCardItem = ({
  completed,
  type,
  name,
}: TransactionHistoryTableCardItemProps) => {
  const { classes } = useStyles();

  return (
    <Flex direction="column" className={classes.item}>
      <Flex gap={4}>
        <Text className={classes.label}>Name:</Text>
        <Text className={classes.name}>{name}</Text>
      </Flex>
      {type && (
        <Flex gap={4}>
          <Text className={classes.label}>Type:</Text>
          <Text>{toHumanReadable(type)}</Text>
        </Flex>
      )}
      <Flex gap={4}>
        <Text className={classes.label}>Status:</Text>
        <Badge
          variant={completed ? "active" : "inactive"}
          className={classes.badge}
        >
          {completed ? "Completed" : "Not Complete"}
        </Badge>
      </Flex>
    </Flex>
  );
};

export default CPExceptionTableCardItem;
