import { createStyles, MantineTheme, rem } from "@mantine/core";

export const useStyles = createStyles((theme: MantineTheme) => ({
  wrapper: {
    display: "flex",
    flexFlow: "row",
    alignItems: "center",
    justifyContent: "space-between",
    gap: rem(12),
    borderRadius: rem(4),
    padding: rem(8),
    background: theme.colors.grayIce[4],
  },
  nameWrapper: {
    flexGrow: 1,
    height: 20,
    position: "relative",
  },
  name: {
    fontSize: rem(12),
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    color: theme.colors.black[0],
    position: "absolute",
    width: "100%",
  },
  actions: {
    display: "flex",
    gap: rem(6),
  },
}));
