import { Flex } from "@mantine/core";

import { useCurrentUserContext } from "../../components/Auth/CurrentUserProvider.tsx";
import Layout from "../../components/Layout/Layout";
import Title from "../../components/Title/Title";
import { RolePermission } from "../../graphql/generated.ts";
import { hasPermission } from "../../utils/user.ts";
import Page404 from "../404/Page404.tsx";
import NewCURecommendationTable from "./NewCURecommendation/NewCURecommendationTable.tsx";
import OngoingRRTable from "./OngoingRR/OngoingRRTable.tsx";

const RiskReviewPage = () => {
  const { user } = useCurrentUserContext();

  const hasAccess = hasPermission(user, RolePermission.CanViewRiskReview);

  if (!hasAccess) {
    return <Page404 />;
  }

  return (
    <Layout>
      <Flex direction="column" gap={{ base: 16, sm: 24 }}>
        <Title>Risk Review</Title>
        <NewCURecommendationTable />
        <OngoingRRTable />
      </Flex>
    </Layout>
  );
};

export default RiskReviewPage;
