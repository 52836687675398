import { createStyles, rem } from "@mantine/core";

import { themeGradientBackground } from "../../../utils/mantine/themeGradientBackground.ts";

export const useStyles = createStyles((theme) => ({
  form: {
    borderRadius: rem(4),
    background: theme.colors.grayIce[6],
    padding: rem(20),
  },
  downloadLink: {
    flexShrink: 0,
  },
  pin: {
    width: rem(12),
    height: rem(12),
    flexShrink: 0,
    ...themeGradientBackground(theme, "bronze"),
  },
  label: {
    fontSize: rem(14),
    lineHeight: 1.5,
    textTransform: "capitalize",
    fontWeight: 600,
    color: theme.colors.midnightBlue[0],
    maxWidth: rem(200),
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));
