import { Button, Flex, Input, Modal, Textarea } from "@mantine/core";
import { isNotEmpty, useForm } from "@mantine/form";
import { useCallback } from "react";

import Dropzone from "../../../../../components/Dropzone/Dropzone.tsx";
import {
  FileResult,
  UpdateCollectionActivityInput,
} from "../../../../../graphql/generated";
import { CollectionActivity } from "../../../../../types/collectionActivity.ts";

interface EditCollectionActivityModalProps {
  value: Partial<CollectionActivity>;
  opened?: boolean;
  onClose: () => void;
  onSubmit: (value: UpdateCollectionActivityInput) => void;
}

const EditCollectionActivityModal = ({
  value,
  opened = false,
  onClose,
  onSubmit,
}: EditCollectionActivityModalProps) => {
  const form = useForm({
    initialValues: {
      resultDescription: value.resultDescription,
      attachment: value.attachment,
    },
    validate: {
      resultDescription: isNotEmpty("Result Description is required"),
    },
    validateInputOnBlur: true,
  });

  const handleClose = useCallback(() => {
    form.reset();
    onClose();
  }, [form, onClose]);

  const handleSubmit = useCallback(() => {
    onSubmit({
      ...form.values,
      attachment: form.values.attachment?.filePath,
    } as UpdateCollectionActivityInput);
    handleClose();
  }, [form.values, handleClose, onSubmit]);

  const handleFileUploaded = (files: FileResult[]) => {
    form.setValues({ attachment: files[0] });
  };

  const handleRemoveFile = () => {
    form.setValues({ attachment: undefined });
  };

  return (
    <>
      <Modal
        opened={opened}
        title="Edit Collection Activity"
        closeButtonProps={{
          size: 24,
          iconSize: 24,
        }}
        onClose={handleClose}
      >
        <Flex direction="column" gap={20}>
          <Flex gap={12} direction="column">
            <Textarea
              required
              label="Result Description"
              placeholder="Enter Result Description"
              size="m"
              minRows={3}
              maxRows={3}
              autosize
              {...form.getInputProps("resultDescription")}
            />
            <Input.Wrapper label="Attachment" size="m">
              <Dropzone
                value={form.values.attachment ? [form.values.attachment] : null}
                onUploadSuccess={handleFileUploaded}
                onRemove={handleRemoveFile}
              />
            </Input.Wrapper>
          </Flex>
          <Flex gap={20} justify="end">
            <Button
              variant="outlineBlue"
              uppercase
              type="reset"
              onClick={onClose}
            >
              cancel
            </Button>
            <Button
              variant="filledBlue"
              uppercase
              type="submit"
              disabled={!form.isValid()}
              onClick={handleSubmit}
            >
              edit
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
};

export default EditCollectionActivityModal;
