import { DataTableColumn } from "mantine-datatable";
import { useMemo } from "react";

import { TableData, TableRow } from "../../../../components/Table/types.ts";
import { NextPaymentDue } from "../../../../types/dashboard.ts";
import { toLocalNumber } from "../../../../utils/number.ts";
import { DueMonthOptions } from "./constants.ts";

interface Props {
  data: NextPaymentDue[] | null | undefined;
}

export const useNextPaymentDueMonthTableData = ({ data }: Props): TableData => {
  const columns: DataTableColumn<TableRow>[] = [
    {
      accessor: "dueMonth",
      title: "Due Month",
    },
    {
      accessor: "amount",
      title: "Amount",
    },
  ];

  const rows = useMemo(() => {
    return data?.map((item) => {
      return {
        dueMonth: DueMonthOptions[item.dueMonth],
        amount: toLocalNumber(item.amount),
      };
    });
  }, [data]);

  return [columns, rows];
};
