import { LimitRequestStatus } from "../../graphql/generated.ts";
import { toHumanReadable } from "../../utils/string.ts";

export const CUStatusOptions = [
  {
    value: LimitRequestStatus.OngoingCuManagerReview,
    label: toHumanReadable(LimitRequestStatus.OngoingCuManagerReview),
  },
  {
    value: LimitRequestStatus.OngoingCu,
    label: toHumanReadable(LimitRequestStatus.OngoingCu),
  },
];
