import { MultiFieldCardProps } from "../../../../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import { ClikNegativeEvent } from "../../../../../types/clikResult.ts";
import { toLocalDate } from "../../../../../utils/date.ts";

interface Props {
  data: ClikNegativeEvent[] | null | undefined;
}

export const useCRNegativeEventCardInfo = ({
  data,
}: Props): MultiFieldCardProps[] | null => {
  if (!data) {
    return null;
  }

  return data?.map((event, index) => ({
    label: `Negative Event ${index + 1}`,
    variant: "bronze",
    items: [
      {
        label: "Event Code",
        content: event?.eventCode,
      },
      {
        label: "Event Detail",
        content: event?.eventDetail,
      },
      {
        label: "Event Date",
        content: event?.eventDate && toLocalDate(event.eventDate),
      },
      {
        label: "Event Expiry Date",
        content: event?.eventExpiryDate && toLocalDate(event.eventExpiryDate),
      },
      {
        label: "Provider Type",
        content: event?.providerType,
      },
      {
        label: "Provider",
        content: event?.provider,
      },
      {
        label: "Reference Date",
        content: event?.referenceDate && toLocalDate(event.referenceDate),
      },
    ],
  }));
};
