import isNil from "lodash/isNil";
import { useQuery } from "urql";

import { GetOlNumbersListDocument } from "../../../graphql/generated.ts";

interface Props {
  customerId: number | null | undefined;
}

export function useGetOlNumbersList({ customerId }: Props) {
  return useQuery({
    query: GetOlNumbersListDocument,
    variables: {
      filter: { limitRequest: { companyId: { equals: Number(customerId) } } },
    },
    pause: isNil(customerId),
  });
}
