import { Button, Flex, Select, SimpleGrid } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { DatesRangeValue } from "@mantine/dates/lib/types/DatePickerValue";

import SvgCalendar from "../../../../../components/Icons/Calendar.tsx";
import SvgPlus from "../../../../../components/Icons/Plus.tsx";
import { giroStatusOptions, giroTypeOptions } from "../constants.ts";

interface GiroManagementToolbarProps {
  canEdit: boolean;
  onModalOpen: () => void;
  onFilterChange: (key: string, value: string | DatesRangeValue | null) => void;
}

const GiroManagementToolbar = ({
  canEdit,
  onModalOpen,
  onFilterChange,
}: GiroManagementToolbarProps) => {
  return (
    <Flex direction="column" gap={16} pb={4}>
      {canEdit && (
        <Button
          uppercase
          variant="filledGolden"
          leftIcon={<SvgPlus />}
          onClick={onModalOpen}
        >
          Add
        </Button>
      )}
      <SimpleGrid
        cols={3}
        breakpoints={[
          { maxWidth: "sm", cols: 1 },
          { maxWidth: "lg", cols: 2 },
        ]}
        spacing={20}
        verticalSpacing={8}
      >
        <Select
          size="m"
          clearable
          label="Giro Type"
          placeholder="Select Giro Type"
          data={giroTypeOptions}
          onChange={(value: string) => onFilterChange("type", value)}
        />
        <Select
          size="m"
          clearable
          label="Giro Status"
          placeholder="Select Giro Status"
          data={giroStatusOptions}
          onChange={(value: string) => onFilterChange("status", value)}
        />
        <DatePickerInput
          type="range"
          label="Giro Due Date Period"
          placeholder="Select Giro Due Date Period"
          icon={<SvgCalendar />}
          firstDayOfWeek={0}
          size="m"
          clearable
          onChange={(value: DatesRangeValue) =>
            onFilterChange("dueDate", value)
          }
        />
      </SimpleGrid>
    </Flex>
  );
};

export default GiroManagementToolbar;
