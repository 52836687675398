import { useQuery } from "urql";

import {
  DashboardCustomersByLongestDpdDocument,
  QueryDashboardCustomersByLongestDpdArgs,
} from "../../../graphql/generated";

interface Props {
  salesTeamName: QueryDashboardCustomersByLongestDpdArgs["salesTeamName"];
}

export const useDashboardCustomersByLongestDpd = ({ salesTeamName }: Props) => {
  return useQuery({
    query: DashboardCustomersByLongestDpdDocument,
    variables: { salesTeamName },
  });
};
