import { MantineTheme, rem } from "@mantine/styles";

import { ThemeComponent } from "./helpers.ts";

const defaultInputWrapper = (theme: MantineTheme) => ({
  description: {
    color: theme.colors.grayIce[0],
    fontSize: rem(14),
    lineHeight: 1.5,
  },
  error: {
    color: theme.colors.error[0],
    fontSize: rem(14),
    lineHeight: 1.5,
  },
  label: {
    color: theme.colors.midnightBlue[0],
    fontWeight: 500,
    lineHeight: 1.5,
    fontSize: rem(16),
  },
  wrapper: {
    "input, textarea": {
      background: theme.colors.white[0],
      borderRadius: rem(4),
      border: `1px solid ${theme.colors.grayIce[0]}`,
      lineHeight: 1.5,
      fontWeight: 500,
      "&::placeholder": {
        color: theme.colors.grayIce[0],
        fontWeight: 500,
      },
      "&:hover": {
        border: `1px solid ${theme.colors.dusk[0]}`,
      },
      "&:focus": {
        border: `1px solid ${theme.colors.dusk[0]}`,
      },
      "&:disabled": {
        background: "transparent",
      },
      "&[data-invalid]": {
        "&::placeholder": {
          color: theme.colors.error[0],
          fontWeight: 500,
        },
        color: theme.colors.error[0],
        border: `1px solid ${theme.colors.error[0]}`,
      },
    },
  },
});

export default function inputWrapperTheme(): ThemeComponent {
  return {
    styles: (theme) => defaultInputWrapper(theme),
    sizes: {
      s: () => ({
        input: {
          height: rem(32),
          padding: `0 ${rem(4)} 0 ${rem(12)}`,
          fontSize: rem(14),
        },
      }),
      m: () => ({
        input: {
          height: rem(44),
          padding: `0 ${rem(12)}`,
          fontSize: rem(16),
        },
      }),
    },
  };
}
