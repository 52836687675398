import { createStyles, rem } from "@mantine/core";

export const useStyles = createStyles((theme) => ({
  clikResult: {
    fontSize: rem(12),
    lineHeight: rem(18),
    fontWeight: 500,
    color: theme.colors.dusk[0],
  },
}));
