import { useMemo } from "react";

import { MultiFieldCardItem } from "../../../../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import { ClikResult } from "../../../../../types/clikResult.ts";
import { toLocalDate } from "../../../../../utils/date.ts";
import { toCurrencyPrice } from "../../../../../utils/number.ts";
import { toHumanReadable } from "../../../../../utils/string.ts";

interface Props {
  clikResult: ClikResult;
}

export interface CRCompanyInfo {
  subject: MultiFieldCardItem[];
  establishmentData: MultiFieldCardItem[];
  applicationSummary: MultiFieldCardItem[];
}

export const useCompanyResultInfoCards = ({
  clikResult,
}: Props): CRCompanyInfo => {
  const subject = useMemo(() => {
    return [
      {
        label: "CB Subject Code",
        content: clikResult.cbScore,
      },
      {
        label: "Provider Subject No",
        content: clikResult.companySubject?.providerSubjectNumber,
      },
      {
        label: "Last Update Date",
        content: toLocalDate(clikResult.companySubject?.lastUpdatedDate),
      },
      {
        label: "Go Public",
        content:
          clikResult?.companySubject?.goPublic &&
          toHumanReadable(clikResult.companySubject.goPublic),
      },
      {
        label: "Trade Name",
        content: clikResult.companySubject?.tradeName,
      },
      {
        label: "Company Category",
        content: clikResult.companySubject?.companyCategory,
      },
      {
        label: "Legal Form",
        content: clikResult?.companySubject?.legalForm,
      },
      {
        label: "Economic Sector",
        content: clikResult?.companySubject?.economicSector,
      },
      {
        label: "Rating",
        content: clikResult?.companySubject?.rating,
      },
      {
        label: "Rating Institution",
        content: clikResult?.companySubject?.ratingInstitution,
      },
      {
        label: "Rating Date",
        content:
          clikResult?.companySubject?.ratingDate &&
          toLocalDate(clikResult.companySubject.ratingDate),
      },
      {
        label: "Shareholders Counter",
        content: clikResult?.companySubject?.shareholdersCount,
      },
    ];
  }, [clikResult]);

  const establishmentData = useMemo(() => {
    return [
      {
        label: "Place of Establishment",
        content:
          clikResult?.companySubject?.establishment?.placeOfEstablishment,
      },
      {
        label: "Deed Number of Establishment",
        content:
          clikResult?.companySubject?.establishment?.deedNumberOfEstablishment,
      },
      {
        label: "Deed Date of Establishment",
        content:
          clikResult?.companySubject?.establishment?.deedDateOfEstablishment,
      },
      {
        label: "Recently Amendment Deed Number",
        content:
          clikResult?.companySubject?.establishment
            ?.recentlyAmendmentDeedNumber,
      },
      {
        label: "Date of Final Amendment Deed",
        content:
          clikResult?.companySubject?.establishment
            ?.dateofFinalAmendmentDeedNumber &&
          toLocalDate(
            clikResult.companySubject.establishment
              .dateofFinalAmendmentDeedNumber
          ),
      },
    ];
  }, [clikResult]);

  const applicationSummary = useMemo(() => {
    return [
      {
        label: "CB Contract Code",
        content: clikResult?.applicationSummary?.cbContractCode,
      },
      {
        label: "Contract Type",
        content:
          clikResult?.applicationSummary?.contractType &&
          toHumanReadable(clikResult.applicationSummary.contractType),
      },
      {
        label: "Contract Phase",
        content:
          clikResult?.applicationSummary?.contractPhase &&
          toHumanReadable(clikResult.applicationSummary.contractPhase),
      },
      {
        label: "Request Date",
        content:
          clikResult?.applicationSummary?.requestDate &&
          toLocalDate(clikResult.applicationSummary.requestDate),
      },
      {
        label: "Application Amount",
        content:
          clikResult?.applicationSummary?.amount &&
          toCurrencyPrice(clikResult.applicationSummary.amount),
      },
      {
        label: "Due Date",
        content:
          clikResult?.applicationSummary?.dueDate &&
          toLocalDate(clikResult.applicationSummary.dueDate),
      },
      {
        label: "Role",
        content: clikResult?.applicationSummary?.role,
      },
      {
        label: "Company Role",
        content: clikResult?.applicationSummary?.companyRole,
      },
      {
        label: "Account Number",
        content: clikResult?.applicationSummary?.accountNumber,
      },
      {
        label: "Original Agreement Number",
        content: clikResult?.applicationSummary?.originalAgreementNumber,
      },
      {
        label: "Original Agreement Date",
        content:
          clikResult?.applicationSummary?.originalAgreementDate &&
          toLocalDate(clikResult.applicationSummary.originalAgreementDate),
      },
      {
        label: "Currency",
        content: clikResult?.applicationSummary?.currency,
      },
    ];
  }, [clikResult]);

  return { subject, establishmentData, applicationSummary };
};
