import { TabProps } from "@mantine/core";

import CollectionActivityTab from "./CollectionActivityTab/CollectionActivityTab.tsx";
import CollectionDocumentsTab from "./CollectionDocumentsTab/CollectionDocumentsTab.tsx";
import ActivityManagementInvoicesTab from "./InvoicesTab/InvoicesTab.tsx";
import OdooContactDataTab from "./OdooContactDataTab/OdooContactDataTab.tsx";
import RestructuresTab from "./RestructuresTab/RestructuresTab.tsx";
import WaivesTab from "./WaivesTab/WaivesTab.tsx";

interface Tab extends TabProps {
  label: string;
}

export const tabs: Tab[] = [
  {
    value: "odooContactData",
    label: "Odoo Contact Data",
    children: <OdooContactDataTab />,
  },
  {
    value: "invoices",
    label: "Invoices",
    children: <ActivityManagementInvoicesTab />,
  },
  {
    value: "collectionActivity",
    label: "Collection activity",
    children: <CollectionActivityTab />,
  },
  {
    value: "collectionDocument",
    label: "Collection document",
    children: <CollectionDocumentsTab />,
  },
  {
    value: "restructure",
    label: "Restructure",
    children: <RestructuresTab />,
  },
  {
    value: "waive",
    label: "Waive",
    children: <WaivesTab />,
  },
];
