import {
  ActionIcon as MantineActionIcon,
  Button,
  Flex,
  MediaQuery,
  Title,
} from "@mantine/core";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import { useCurrentUserContext } from "../../components/Auth/CurrentUserProvider.tsx";
import SvgChevronLeft from "../../components/Icons/ChevronLeft.tsx";
import Layout from "../../components/Layout/Layout.tsx";
import Preloader from "../../components/Preloader/Preloader.tsx";
import { AppRoute } from "../../constants.ts";
import { RolePermission } from "../../graphql/generated.ts";
import { useClikCities } from "../../hooks/api/clikData/useClikCities.ts";
import { useClikCountries } from "../../hooks/api/clikData/useClikCountries.ts";
import { useCreateEmployeeCandidateRequest } from "../../hooks/api/employeeCandidateRequest/useCreateEmployeeCandidateRequest.ts";
import { useMutationNotificationWrapper } from "../../hooks/useMutationNotificationWrapper.tsx";
import { RawEmployeeCandidateRequest } from "../../types/employeeCandidateRequest.ts";
import { hasPermission } from "../../utils/user.ts";
import Page404 from "../404/Page404.tsx";
import EmployeeCandidateRequestForm from "./components/EmployeeCandidateRequestForm/EmployeeCandidateRequestForm.tsx";
import { formatEmployeeCandidateRequestToCreateInput } from "./components/EmployeeCandidateRequestForm/utils.ts";
import { useStyles } from "./EmployeeCandidateRequestCreate.styles.ts";

const EmployeeCandidateRequestCreate = () => {
  const { classes } = useStyles();

  const navigate = useNavigate();

  const [value, setValue] = useState<RawEmployeeCandidateRequest | null>(null);

  const [valid, setValid] = useState(false);

  const [{ data: clikCitiesData, fetching: clikCitiesFetching }] =
    useClikCities();

  const [{ data: clikCountriesData, fetching: clikCountriesFetching }] =
    useClikCountries();

  const { user } = useCurrentUserContext();

  const [, createRequest] = useMutationNotificationWrapper(
    useCreateEmployeeCandidateRequest(),
    {
      success: {
        message: "Employee Candidate Request has been added successfully.",
      },
    }
  );

  const hasAccess = hasPermission(
    user,
    RolePermission.CanEditEmployeeCandidateClik
  );

  const handleValueChange = (
    value: RawEmployeeCandidateRequest,
    valid: boolean
  ) => {
    setValue(value);
    setValid(valid);
  };

  const handleBack = () => navigate(-1);

  const handleSubmit = () => {
    if (!value) {
      return;
    }

    const input = formatEmployeeCandidateRequestToCreateInput(value);
    createRequest({ input }).then(() => {
      navigate(AppRoute.EmployeeCandidate);
    });
  };

  const handleCancel = () => {
    setValue(null);
    handleBack();
  };

  const isFetching = useMemo(() => {
    return clikCitiesFetching || clikCountriesFetching;
  }, [clikCitiesFetching, clikCountriesFetching]);

  if (!hasAccess) {
    return <Page404 />;
  }

  return (
    <>
      <Layout>
        <Preloader loading={isFetching} />
        <Flex direction="column" className={classes.wrapper}>
          <Flex
            justify="space-between"
            align="center"
            pb={{ base: 16, sm: 24 }}
          >
            <Flex gap={20} align="center">
              <MantineActionIcon
                size="m"
                variant="outlineGolden"
                onClick={handleBack}
              >
                <SvgChevronLeft />
              </MantineActionIcon>
              <Title className={classes.titleWrapper}>
                Add Employee Candidate Request
              </Title>
            </Flex>
            <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
              <Flex gap={20}>
                <Button
                  variant="outlineGolden"
                  type="reset"
                  uppercase
                  onClick={handleCancel}
                >
                  cancel
                </Button>
                <Button
                  variant="filledGolden"
                  size="m"
                  type="submit"
                  disabled={!valid}
                  uppercase
                  onClick={handleSubmit}
                >
                  submit
                </Button>
              </Flex>
            </MediaQuery>
          </Flex>
          <Flex direction="column" gap={{ base: 16, sm: 20 }}>
            <EmployeeCandidateRequestForm
              value={value}
              cities={clikCitiesData?.clikCities}
              countries={clikCountriesData?.clikCountries}
              onValueChange={handleValueChange}
            />
          </Flex>
        </Flex>
        <MediaQuery largerThan="sm" styles={{ display: "none" }}>
          <Flex gap={20} className={classes.buttonsWrapper}>
            <Button
              variant="outlineGolden"
              type="reset"
              uppercase
              w="100%"
              onClick={handleCancel}
            >
              cancel
            </Button>
            <Button
              variant="filledGolden"
              size="m"
              type="submit"
              uppercase
              w="100%"
              disabled={!valid}
              onClick={handleSubmit}
            >
              submit
            </Button>
          </Flex>
        </MediaQuery>
      </Layout>
    </>
  );
};

export default EmployeeCandidateRequestCreate;
