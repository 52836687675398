import { useCallback, useState } from "react";

import { UpdateLimitRequestExceptionInput } from "../../../graphql/generated.ts";
import { useCreateLimitRequestException } from "../../../hooks/api/limitRequestException/useCreateLimitRequestException.ts";
import { useDeleteLimitRequestException } from "../../../hooks/api/limitRequestException/useDeleteLimitRequestException.ts";
import { useUpdateLimitRequestException } from "../../../hooks/api/limitRequestException/useUpdateLimitRequestException.ts";
import { useMutationNotificationWrapper } from "../../../hooks/useMutationNotificationWrapper.tsx";
import { LimitRequestException } from "../../../types/limitRequestException.ts";
import ExceptionForm from "./ExceptionForm/ExceptionForm.tsx";
import ExceptionList from "./ExceptionsList/ExceptionList.tsx";

interface Props {
  limitRequestId: number;
  exceptions: LimitRequestException[] | null | undefined;
  onRefreshExceptions: () => void;
}

const Exceptions = ({
  limitRequestId,
  exceptions,
  onRefreshExceptions,
}: Props) => {
  const [selectedItem, setSelectedItem] =
    useState<LimitRequestException | null>(null);
  const [editMode, setEditMode] = useState<boolean>(false);

  const [, createException] = useMutationNotificationWrapper(
    useCreateLimitRequestException(),
    {
      success: { message: "Exception was successfully created." },
    }
  );

  const [, updateException] = useMutationNotificationWrapper(
    useUpdateLimitRequestException(),
    {
      success: { message: "Exception was successfully updated." },
    }
  );

  const [, deleteException] = useMutationNotificationWrapper(
    useDeleteLimitRequestException(),
    {
      success: { message: "Exception was successfully deleted." },
    }
  );
  const handleSetEditMode = useCallback((editMode: boolean) => {
    if (editMode) {
      setEditMode(true);
      return;
    }
    setEditMode(false);
    setSelectedItem(null);
  }, []);

  const handleSubmitForm = useCallback(
    (input: UpdateLimitRequestExceptionInput) => {
      if (selectedItem) {
        updateException({ id: selectedItem.id, input }).then(() => {
          handleSetEditMode(false);
          onRefreshExceptions();
        });
        return;
      }
      createException({
        input: { ...input, limitRequestId: limitRequestId },
      }).then(() => {
        handleSetEditMode(false);
        onRefreshExceptions();
      });
    },
    [
      selectedItem,
      createException,
      limitRequestId,
      updateException,
      handleSetEditMode,
      onRefreshExceptions,
    ]
  );

  const handleDelete = useCallback(
    (id: number) => {
      deleteException({ id }).then(onRefreshExceptions);
    },
    [deleteException, onRefreshExceptions]
  );

  const handleEditException = (item: LimitRequestException) => {
    handleSetEditMode(true);
    setSelectedItem(item);
  };

  return (
    <>
      {editMode ? (
        <ExceptionForm
          value={selectedItem}
          onClose={() => handleSetEditMode(false)}
          onSubmit={handleSubmitForm}
        />
      ) : (
        <ExceptionList
          exceptions={exceptions}
          onAdd={() => handleSetEditMode(true)}
          onEdit={handleEditException}
          onDelete={handleDelete}
        />
      )}
    </>
  );
};

export default Exceptions;
