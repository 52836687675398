import { CSSObject, MantineTheme, rem } from "@mantine/core";

import { ThemeComponent } from "./helpers";

const table = (theme: MantineTheme) => ({
  root: {
    minWidth: rem(500),
    "&>thead>tr": {
      background: theme.colors.grayIce[2],
    },
    "&>thead>tr>th>div>div": {
      textOverflow: "initial",
      whiteSpace: "initial" as CSSObject["whiteSpace"],
      svg: {
        color: theme.colors.grayIce[0],
      },
    },
    "&>tbody>tr": {
      fontSize: rem(14),
      lineHeight: 1.5,
      cursor: "pointer",
    },
  },
});

export default function tableTheme(): ThemeComponent {
  return {
    styles: (theme) => ({
      root: table(theme),
    }),
  };
}
