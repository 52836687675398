import { MantineTheme, rem } from "@mantine/styles";

import { ThemeComponent } from "./helpers.ts";

const defaultDatePickerInput = (theme: MantineTheme) => ({
  error: {
    color: theme.colors.error[0],
    fontSize: rem(14),
    lineHeight: 1.5,
  },
  label: {
    color: theme.colors.midnightBlue[0],
    fontWeight: 500,
    lineHeight: 1.5,
    fontSize: rem(16),
  },
  input: {
    background: theme.colors.white[0],
    borderRadius: rem(4),
    border: `1px solid ${theme.colors.grayIce[0]}`,
    lineHeight: 1.5,
    fontWeight: 500,
    "&::placeholder": {
      color: theme.colors.grayIce[0],
      fontWeight: 500,
    },
    "&:hover": {
      border: `1px solid ${theme.colors.dusk[0]}`,
    },
    "&:focus": {
      border: `1px solid ${theme.colors.dusk[0]}`,
    },
    "&:focus-within": {
      border: `1px solid ${theme.colors.dusk[0]}`,
    },
    "&:disabled": {
      background: theme.colors.grayIce[3],
    },
    "&[data-with-icon]": {
      paddingLeft: rem(44),
    },
    "&[data-invalid]": {
      "&::placeholder": {
        color: theme.colors.error[0],
        fontWeight: 500,
      },
      color: theme.colors.error[0],
      border: `1px solid ${theme.colors.error[0]}`,
    },
  },
  icon: {
    color: theme.colors.grayIce[0],
    justifyContent: "end",
    svg: {
      height: rem(24),
      width: rem(24),
    },
  },
  calendarHeader: {
    marginBottom: rem(20),
    maxWidth: "unset",
  },
  calendarHeaderLevel: {
    fontSize: rem(16),
    fontWeight: 600,
    lineHeight: 1.55,
  },
  calendarHeaderControlIcon: {
    color: theme.colors.golden[0],
  },
  weekday: {
    paddingBottom: rem(8),
    width: rem(40),
    height: rem(24),
    color: theme.colors.grayIce[0],
  },
  day: {
    width: rem(40),
    height: rem(40),
    color: theme.colors.black[0],
    fontSize: rem(14),
    lineHeight: 1.55,

    "&:hover": {
      background: theme.colors.gray[0],
    },

    "&[data-selected]": {
      color: theme.colors.black[0],
      background: theme.colors.golden[4],

      "&:hover": {
        background: theme.colors.golden[6],
      },
    },

    "&[data-in-range]": {
      background: theme.colors.golden[4],

      "&:hover": {
        background: theme.colors.golden[6],
      },
    },

    "&[data-weekend]": {
      color: theme.colors.black[0],
    },
  },
  pickerControl: {
    width: rem(85.5),
    height: rem(36),
  },
});

function defaultDatePickerInputTheme() {
  return {
    styles: (theme: MantineTheme) => ({
      ...defaultDatePickerInput(theme),
    }),
  };
}

export default function datePickerInputTheme(): ThemeComponent {
  return {
    ...defaultDatePickerInputTheme(),
    sizes: {
      s: () => ({
        input: {
          height: rem(32),
          padding: `0 ${rem(12)}`,
          fontSize: rem(14),
          display: "flex",
          alignItems: "center",
          "&[data-with-icon]": {
            paddingLeft: rem(44),
          },
        },
        icon: {
          width: rem(36),
          justifyContent: "end",
          svg: {
            height: rem(24),
            width: rem(24),
          },
        },
      }),
      m: () => ({
        input: {
          height: rem(44),
          padding: `0 ${rem(12)}`,
          fontSize: rem(16),
          display: "flex",
          alignItems: "center",
          "&[data-with-icon]": {
            paddingLeft: rem(44),
          },
        },
        icon: {
          width: rem(36),
          justifyContent: "end",
          svg: {
            height: rem(24),
            width: rem(24),
          },
        },
        rightSection: {
          width: rem(36),
          svg: {
            width: rem(20),
            height: rem(20),
          },
        },
      }),
      l: () => ({
        root: {
          width: "100%",
        },
        input: {
          height: rem(44),
          padding: `0 ${rem(12)}`,
          fontSize: rem(16),
          display: "flex",
          alignItems: "center",
          "&[data-with-icon]": {
            paddingLeft: rem(44),
          },
        },
        icon: {
          width: rem(36),
          justifyContent: "end",
          svg: {
            height: rem(24),
            width: rem(24),
          },
        },
        rightSection: {
          width: rem(32),
          svg: {
            width: rem(24),
            height: rem(24),
          },
          paddingRight: rem(8),
        },
      }),
    },
  };
}
