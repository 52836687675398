import { DataTableColumn } from "mantine-datatable";
import { useMemo } from "react";

import { TableData, TableRow } from "../../../../components/Table/types.ts";
import { OutstandingPrincipalAccumulative } from "../../../../types/dashboard.ts";
import { toLocalNumber, toPercent } from "../../../../utils/number.ts";
import { DpdBucketAccumulativeOptions } from "./constants.ts";

interface Props {
  data: OutstandingPrincipalAccumulative[] | null | undefined;
}

export const useOutstandingPrincipalAccumulative = ({
  data,
}: Props): TableData => {
  const columns: DataTableColumn<TableRow>[] = [
    {
      accessor: "bucket",
      title: "Collection Bucket",
    },
    {
      accessor: "outstandingPrincipal",
      title: "Outstanding Principal",
    },
    {
      accessor: "outstandingPrincipalAmount",
      title: "% Outstanding Principal Amount",
    },
    {
      accessor: "transactionNumber",
      title: "Number of Transactions",
    },
    {
      accessor: "outstandingAccount",
      title: "% Outstanding Account",
    },
  ];

  const rows = useMemo(() => {
    return data?.map((item) => {
      return {
        bucket: DpdBucketAccumulativeOptions[item.bucket],
        outstandingPrincipal: toLocalNumber(item.outstandingPrincipal),
        outstandingPrincipalAmount: toPercent(item.outstandingPrincipalAmount),
        transactionNumber: item.transactionNumber,
        outstandingAccount: toPercent(item.outstandingAccount),
      };
    });
  }, [data]);

  return [columns, rows];
};
