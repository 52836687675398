import { omit } from "lodash";

import { UpdateLimitRequestInput } from "../../../graphql/generated.ts";
import { RawLimitRequest } from "../../../types/limitRequest.ts";
import { toClikPhoneFormat } from "../../../utils/phone.ts";

export const formatLimitRequestToUpdateLimitRequestInput = (
  limitRequest: RawLimitRequest
): UpdateLimitRequestInput | null => {
  const res = {
    ...limitRequest,
    cityId: Number(limitRequest.city.id),
    countryId: Number(limitRequest.country.id),
    phone: toClikPhoneFormat(limitRequest.phone),
    postalCode: limitRequest.postalCode.toString(),
    formLOI: limitRequest.formLOI?.filePath,
    npwpCompany: limitRequest.npwpCompany?.filePath,
    suratPengajuanLimit: limitRequest.suratPengajuanLimit?.filePath,
  };
  return omit(res, ["city", "country", "company"]) as UpdateLimitRequestInput;
};
