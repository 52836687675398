import { createStyles, rem } from "@mantine/core";

export const useStyles = createStyles(
  (_, { conversationRateBgColor }: { conversationRateBgColor: string }) => ({
    conversationRate: {
      background: conversationRateBgColor,
      width: rem(20),
      height: rem(20),
      borderRadius: rem(2),
    },
  })
);
