import { createStyles, rem } from "@mantine/core";

export const useStyles = createStyles(() => ({
  labelUpdated: {
    textTransform: "unset",
    fontWeight: 400,
    fontSize: rem(12),
    lineHeight: rem(15.6),
  },
  nameUpdated: {
    textTransform: "unset",
    fontWeight: 500,
    fontSize: rem(12),
    lineHeight: rem(15.6),
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    "@media (max-width: 76rem)": {
      maxWidth: rem(48),
    },
  },
}));
