import { Flex } from "@mantine/core";
import { DataTableSortStatus } from "mantine-datatable";
import { useCallback, useState } from "react";

import SectionTitle from "../../../../../components/Section/SectionTitle/SectionTitle.tsx";
import {
  ClikGrantedBondOrderBy,
  ContractType,
  ContractTypeFilterOptions,
  OrderBy,
} from "../../../../../graphql/generated.ts";
import { useClikGrantedBonds } from "../../../../../hooks/api/clikResult/clikContract/useClikGrantedBonds.ts";
import { useClikGrantedDetailsOfBonds } from "../../../../../hooks/api/clikResult/clikContract/useClikGrantedDetailsOfBonds.ts";
import { useClikListOfGrantedBonds } from "../../../../../hooks/api/clikResult/clikContract/useClikListOfGrantedBonds.ts";
import {
  ClikGrantedContract,
  ClikGrantedContractDetails,
} from "../../../../../types/clikContract.ts";
import ClikResultDetailsOfContract from "../ClikResultDetailsOfContract/ClikResultDetailsOfContract.tsx";
import ClikGrantedContracts from "../ClikResultGrantedContracts/ClikGrantedContracts.tsx";
import ClikResultNotGrantedContracts from "../ClikResultNotGrantedContracts/ClikResultNotGrantedContracts.tsx";
import { getContractDetailsOptions } from "../contants.ts";
import { getGrantedContractsApiSort } from "../utils.ts";

interface ClikResultBondContractsProps {
  clikResultId: number;
}

const ClikResultBondContracts = ({
  clikResultId,
}: ClikResultBondContractsProps) => {
  const [page, setPage] = useState<number>(1);
  const [orderBy, setOrderBy] = useState<ClikGrantedBondOrderBy>({
    id: OrderBy.Asc,
  });
  const [selectedContractId, setSelectedContractId] = useState<number | null>();

  const [{ data: grantedBondsResponse, fetching: grantedBondsFetching }] =
    useClikGrantedBonds({
      filter: { clikResultId: { equals: clikResultId } },
      orderBy,
      page,
    });

  const [
    { data: listOfGrantedBondsResponse, fetching: listOfGrantedBondsFetching },
  ] = useClikListOfGrantedBonds({
    clikResultId,
  });

  const [
    {
      data: grantedDetailsOfBondResponse,
      fetching: grantedDetailsOfBondFetching,
    },
  ] = useClikGrantedDetailsOfBonds({ id: selectedContractId });

  const grantedBondsData = grantedBondsResponse?.clikGrantedBonds
    ?.data as ClikGrantedContract[];
  const grantedBondsCount = grantedBondsResponse?.clikGrantedBonds?.total ?? 0;

  const listOfGrantedBondsData =
    listOfGrantedBondsResponse?.clikGrantedBonds?.data;

  const grantedDetailsOfBondData =
    grantedDetailsOfBondResponse?.clikGrantedBondDetails;

  const handleOnSortChange = useCallback(
    (sort: DataTableSortStatus) => {
      setOrderBy(getGrantedContractsApiSort(sort));
    },
    [setOrderBy]
  );

  return (
    <Flex gap={12} direction="column">
      <SectionTitle variant="bronze">
        Contract Details - Bond / Securities
      </SectionTitle>
      <Flex gap={20} direction="column">
        <ClikResultNotGrantedContracts
          clikResultId={clikResultId}
          type={ContractTypeFilterOptions.Bond}
        />
        <ClikGrantedContracts
          contracts={grantedBondsData}
          loading={grantedBondsFetching}
          type={ContractType.Bond}
          total={grantedBondsCount}
          page={page}
          onSetPage={setPage}
          onSortChange={handleOnSortChange}
        />
        <ClikResultDetailsOfContract
          type={ContractType.Bond}
          data={grantedDetailsOfBondData as ClikGrantedContractDetails}
          loading={grantedDetailsOfBondFetching || listOfGrantedBondsFetching}
          clikContractProfileFilter={
            selectedContractId
              ? {
                  grantedBondId: { equals: selectedContractId },
                }
              : undefined
          }
          detailsOptions={getContractDetailsOptions(
            listOfGrantedBondsData,
            ContractType.Bond
          )}
          selectedContractId={selectedContractId}
          onSetSelectedContractId={setSelectedContractId}
        />
      </Flex>
    </Flex>
  );
};

export default ClikResultBondContracts;
