import { useCallback } from "react";

import { useCurrentUserContext } from "../../Auth/CurrentUserProvider.tsx";
import SidebarItem from "../Sidebar/SidebarItem/SidebarItem.tsx";
import { NavigationLink, NavigationNestedLink } from "../types.ts";

interface Props {
  links: NavigationLink[];
  isOpen?: boolean;
  onClick?: () => void;
}

export function useNavigationLinks({ links, isOpen = true, onClick }: Props) {
  const { user } = useCurrentUserContext();

  const getNestedLinks = useCallback(
    (links: NavigationNestedLink[] | undefined) => {
      if (!links) {
        return [];
      }
      return links.map((item) => ({
        label: item.label,
        url: item.url,
        show: item.show ? item.show(user) : true,
      }));
    },
    [user]
  );

  const getRenderedLink = useCallback(
    (link: NavigationLink, index: number) => {
      const visible = link.show ? link.show(user) : true;
      if (!visible) {
        return null;
      }

      return (
        <SidebarItem
          key={index}
          nestedLinks={getNestedLinks(link.nestedLinks)}
          label={link.label}
          icon={link.icon}
          isOpen={isOpen}
          onClick={onClick}
        />
      );
    },
    [getNestedLinks, isOpen, onClick, user]
  );

  return <>{links.map(getRenderedLink)}</>;
}
