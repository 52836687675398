import { useQuery } from "urql";

import {
  GetGiroManagementListDocument,
  GiroManagementFilter,
  GiroManagementOrderBy,
} from "../../../graphql/generated.ts";
import { getApiPaginationData } from "../../../utils/api";
import { ApiHookProps } from "../types.ts";

export const useGiroManagementList = ({
  filter,
  orderBy,
  pause,
  page,
}: ApiHookProps<GiroManagementFilter, GiroManagementOrderBy>) => {
  const pagination = getApiPaginationData(page);
  const variables = {
    filter,
    orderBy,
    skip: pagination?.skip,
    take: pagination?.take,
  };
  return useQuery({ query: GetGiroManagementListDocument, variables, pause });
};
