import { createStyles, rem } from "@mantine/core";

export const useStyles = createStyles((theme) => ({
  item: {
    fontSize: rem(14),
    lineHeight: rem(21),
  },
  label: {
    color: theme.colors.grayIce[0],
  },
  showMore: {
    color: theme.colors.dusk[0],
    fontWeight: 500,
    fontSize: rem(12),
    lineHeight: rem(18),
    textDecoration: "underline",
    cursor: "pointer",
  },
  badge: {
    textTransform: "unset",
  },
  name: {
    maxWidth: "190px",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));
