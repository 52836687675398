import {
  Button,
  Checkbox,
  Flex,
  Input,
  Text,
  Textarea,
  TextInput,
} from "@mantine/core";
import { isNotEmpty, useForm } from "@mantine/form";
import { isEmpty } from "lodash";
import { useCallback } from "react";

import Dropzone from "../../../../components/Dropzone/Dropzone.tsx";
import {
  FileResult,
  UpdateLimitRequestExceptionInput,
} from "../../../../graphql/generated.ts";
import { LimitRequestException } from "../../../../types/limitRequestException.ts";
import { useStyles } from "../Exceptions.styles.ts";

interface Props {
  value: LimitRequestException | null | undefined;
  onClose: () => void;
  onSubmit: (value: UpdateLimitRequestExceptionInput) => void;
}

const ExceptionForm = ({ value, onClose, onSubmit }: Props) => {
  const { classes } = useStyles();

  const form = useForm({
    initialValues: {
      completed: value?.completed ?? false,
      name: value?.name,
      documents: value?.documents,
      notes: value?.notes,
    },
    validate: {
      name: isNotEmpty("Name is required"),
    },
    validateInputOnBlur: true,
  });

  const handleClose = useCallback(() => {
    form.reset();
    onClose();
  }, [form, onClose]);

  const handleFileUploaded = (files: FileResult[]) => {
    form.setValues({
      documents: form.values.documents
        ? [...form.values.documents, ...files]
        : files,
    });
  };

  const handleRemoveFile = (value: string) => {
    const filteredFiles = form.values.documents?.filter(
      (doc) => doc.filePath !== value
    );
    form.setFieldValue("documents", filteredFiles);
  };

  const handleSubmit = useCallback(() => {
    if (!form.isValid()) {
      return;
    }
    onSubmit({
      ...form.values,
      documents: form.values.documents?.map((d) => d.filePath),
    } as UpdateLimitRequestExceptionInput);
    handleClose();
  }, [form, handleClose, onSubmit]);

  return (
    <Flex direction="column" gap={4}>
      <Flex gap={12} className={classes.label}>
        <Text>Exception</Text>
        <Text className={classes.status}>(Editing)</Text>
      </Flex>
      <Flex direction="column" p={20} gap={12} className={classes.form}>
        {value && (
          <Checkbox
            required
            label="Completed"
            size="md"
            variant="golden"
            checked={form.values.completed}
            {...form.getInputProps("completed")}
          />
        )}
        <TextInput
          required
          label="Name"
          placeholder="Enter Name"
          size="m"
          {...form.getInputProps("name")}
        />
        <Input.Wrapper label="Document Submission">
          <Dropzone
            value={
              isEmpty(form?.values?.documents) ? null : form.values.documents
            }
            maxWidth="unset"
            onUploadSuccess={(files) => handleFileUploaded(files)}
            onRemove={(value) => handleRemoveFile(value)}
          />
        </Input.Wrapper>
        <Textarea
          label="Notes"
          placeholder="Enter Notes"
          autosize
          size="m"
          minRows={3}
          maxRows={3}
          {...form.getInputProps("notes")}
        />
        <Flex gap={20}>
          <Button variant="outlineBlue" uppercase onClick={handleClose}>
            cancel
          </Button>
          <Button
            variant="filledBlue"
            uppercase
            disabled={!form.isValid()}
            onClick={handleSubmit}
          >
            {value ? "edit" : "add"}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ExceptionForm;
