import { MantineTheme, rem } from "@mantine/styles";

import { ThemeComponent } from "./helpers.ts";

const yellowSwitch = (theme: MantineTheme) => ({
  root: {
    "input:checked+.mantine-Switch-track": {
      background: `linear-gradient(180deg, ${theme.colors.golden[0]} 12.5%, ${theme.colors.bronze[0]} 220.45%)`,
      borderColor: "transparent",
    },
  },
  thumb: {
    cursor: "pointer",
  },
  track: {
    cursor: "pointer",
    height: rem(16),
    width: rem(38),
  },
});

export default function switchTheme(): ThemeComponent {
  return {
    variants: {
      yellow: yellowSwitch,
    },
  };
}
