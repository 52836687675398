import { createStyles, rem } from "@mantine/core";

export const useStyles = createStyles((theme) => ({
  mobileButtonBlockWrapper: {
    position: "fixed",
    bottom: 0,
    left: 0,
    padding: rem(20),
    background: theme.colors.white[0],
  },
}));
