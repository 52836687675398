import {
  Flex,
  Grid,
  Input,
  Select,
  SimpleGrid,
  TextInput,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { isNotEmpty, matches, useForm } from "@mantine/form";
import { useEffect } from "react";

import Dropzone from "../../../../components/Dropzone/Dropzone.tsx";
import Calendar from "../../../../components/Icons/Calendar.tsx";
import SectionTitle from "../../../../components/Section/SectionTitle/SectionTitle.tsx";
import { genderOptions } from "../../../../constants.ts";
import {
  ClikCity,
  ClikCountry,
  FileResult,
} from "../../../../graphql/generated.ts";
import { RawEmployeeCandidateRequest } from "../../../../types/employeeCandidateRequest.ts";
import {
  getClickCity,
  getClikCityOptions,
  getClikCountry,
  getClikCountryOptions,
} from "../../../../utils/clikData.ts";
import { phoneMatcher } from "../../../../utils/phone.ts";

interface Props {
  value: RawEmployeeCandidateRequest | null;
  cities: ClikCity[] | null | undefined;
  countries: ClikCountry[] | null | undefined;
  onValueChange: (value: RawEmployeeCandidateRequest, valid: boolean) => void;
}

const EmployeeCandidateRequestForm = ({
  value,
  cities,
  countries,
  onValueChange,
}: Props) => {
  const form = useForm({
    initialValues: {
      candidateName: value?.candidateName,
      position: value?.position,
      gender: value?.gender,
      birthDate: value?.birthDate,
      phone: value?.phone,
      ktpNumber: value?.ktpNumber,
      ktpFile: value?.ktpFile,
      consentForm: value?.consentForm,
      ktpAddress: value?.ktpAddress,
      subDistrict: value?.subDistrict,
      district: value?.district,
      city: value?.city,
      postalCode: value?.postalCode,
      country: value?.country,
    },
    validate: {
      candidateName: matches(
        /^[A-Za-z\s]+$/,
        "Candidate Name must contain only letters"
      ),
      position: isNotEmpty("Position is required"),
      gender: isNotEmpty("Gender is required"),
      birthDate: isNotEmpty("Birth date is required"),
      phone: phoneMatcher,
      ktpNumber: isNotEmpty("Ktp number is required"),
      ktpFile: isNotEmpty("Ktp file is required"),
      consentForm: isNotEmpty("Consent form is required"),
      ktpAddress: isNotEmpty("Ktp address is required"),
      subDistrict: isNotEmpty("Sub-district is required"),
      district: isNotEmpty("District is required"),
      city: isNotEmpty("City is required"),
      postalCode: matches(
        /^\d{5}$/,
        "The postal code must consist of a maximum of 5 characters. It can include only numbers"
      ),
      country: isNotEmpty("Country is required"),
    },
    validateInputOnBlur: true,
  });

  const handleFileUploaded = (fieldName: string, files: FileResult[]) => {
    if (fieldName === "ktpFile") {
      form.setValues({ ktpFile: files[0] });
    }
    if (fieldName === "consentForm") {
      form.setValues({ consentForm: files[0] });
    }
  };

  const handleRemoveFile = (fieldName: string) => {
    form.setFieldValue(fieldName, undefined);
  };

  const handleSetClikCity = (cityId: string) => {
    const clikCity = getClickCity(cityId, cities);
    form.setValues({
      city: {
        id: Number(cityId),
        name: clikCity?.name ?? "",
        code: clikCity?.code ?? "",
      },
    });
  };

  const handleSetClikCountry = (countryId: string) => {
    const clikCountry = getClikCountry(countryId, countries);
    form.setValues({
      country: {
        id: Number(countryId),
        name: clikCountry?.name ?? "",
        code: clikCountry?.code ?? "",
      },
    });
  };

  useEffect(() => {
    onValueChange(form.values as RawEmployeeCandidateRequest, form.isValid());
  }, [form, onValueChange]);

  return (
    <Grid columns={3} gutter={20}>
      <Grid.Col lg={2} md={3}>
        <Flex direction="column" gap={12}>
          <SectionTitle flex={0} isSmall>
            Candidate Info
          </SectionTitle>
          <SimpleGrid
            cols={1}
            breakpoints={[{ minWidth: "sm", cols: 2 }]}
            spacing={20}
            verticalSpacing={12}
          >
            <Flex direction="column" gap={12}>
              <TextInput
                label="Candidate Name"
                placeholder="Candidate Name"
                size="m"
                required
                {...form.getInputProps("candidateName")}
              />
              <TextInput
                required
                label="Position Applied"
                placeholder="Enter Position Applied"
                size="m"
                {...form.getInputProps("position")}
              />
              <Select
                withinPortal
                dropdownPosition="bottom"
                required
                data={genderOptions}
                label="Gender"
                placeholder="Select Gender"
                size="m"
                {...form.getInputProps("gender")}
              />
              <DatePickerInput
                required
                label="Date of Birth"
                placeholder="Select Date of Birth"
                icon={<Calendar />}
                firstDayOfWeek={0}
                size="m"
                valueFormat="YYYY MMM DD"
                maxDate={new Date()}
                clearable
                popoverProps={{ withinPortal: true }}
                {...form.getInputProps("birthDate")}
              />
              <TextInput
                required
                label="Phone Number"
                placeholder="Enter Phone Number"
                size="m"
                {...form.getInputProps("phone")}
              />
            </Flex>
            <Flex direction="column" gap={12}>
              <TextInput
                required
                label="KTP Number"
                placeholder="Enter KTP Number"
                size="m"
                {...form.getInputProps("ktpNumber")}
              />
              <Input.Wrapper required label="KTP File" size="m">
                <Dropzone
                  maxWidth="100%"
                  value={form.values.ktpFile ? [form.values.ktpFile] : null}
                  onUploadSuccess={(files) =>
                    handleFileUploaded("ktpFile", files)
                  }
                  onRemove={() => handleRemoveFile("ktpFile")}
                />
              </Input.Wrapper>
              <Input.Wrapper required label="Consent Form" size="m">
                <Dropzone
                  maxWidth="100%"
                  value={
                    form.values.consentForm ? [form.values.consentForm] : null
                  }
                  onUploadSuccess={(files) =>
                    handleFileUploaded("consentForm", files)
                  }
                  onRemove={() => handleRemoveFile("consentForm")}
                />
              </Input.Wrapper>
            </Flex>
          </SimpleGrid>
        </Flex>
      </Grid.Col>
      <Grid.Col lg={1} md={3}>
        <Flex direction="column" gap={12}>
          <SectionTitle flex={0} isSmall>
            Candidate Address
          </SectionTitle>
          <TextInput
            required
            type="text"
            label="KTP Address"
            placeholder="Enter KTP Address"
            size="m"
            {...form.getInputProps("ktpAddress")}
          />
          <TextInput
            required
            label="Sub-district"
            placeholder="Enter Sub-district"
            size="m"
            {...form.getInputProps("subDistrict")}
          />
          <TextInput
            required
            label="District"
            placeholder="Enter District"
            size="m"
            {...form.getInputProps("district")}
          />
          <Select
            required
            size="m"
            placeholder="Salect City"
            label="City"
            data={getClikCityOptions(cities)}
            {...form.getInputProps("city")}
            value={form.values.city?.id.toString()}
            searchable
            clearable
            filterDataOnExactSearchMatch
            onChange={handleSetClikCity}
          />
          <TextInput
            required
            type="number"
            label="Postal Code"
            placeholder="Postal Code"
            size="m"
            {...form.getInputProps("postalCode")}
          />
          <Select
            required
            size="m"
            placeholder="Select Country"
            label="Country"
            data={getClikCountryOptions(countries)}
            {...form.getInputProps("country")}
            value={form.values.country?.id.toString()}
            searchable
            clearable
            filterDataOnExactSearchMatch
            onChange={handleSetClikCountry}
          />
        </Flex>
      </Grid.Col>
    </Grid>
  );
};

export default EmployeeCandidateRequestForm;
