import {
  Button,
  Flex,
  Modal,
  NumberInput,
  ScrollArea,
  Select,
  SimpleGrid,
  TextInput,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { isNotEmpty, useForm } from "@mantine/form";
import { useCallback } from "react";

import SvgCalendar from "../../../../../components/Icons/Calendar";
import { GiroType } from "../../../../../graphql/generated.ts";
import {
  GiroManagement,
  GiroManagementModalType,
} from "../../../../../types/giroManagement.ts";
import {
  booleanToString,
  stringToBoolean,
} from "../../../../../utils/boolean.ts";
import {
  parseStringToNumber,
  priceFormatter,
} from "../../../../../utils/number.ts";
import {
  accountNoMatchedOptions,
  giroStatusOptions,
  giroTypeOptions,
} from "../constants.ts";
import { useStyles } from "./GiroManagementModal.styles.ts";

interface Props {
  value?: GiroManagement | null | undefined;
  opened?: boolean;
  olNumbersList?: string[] | null;
  soNumbersList?: string[] | null;
  onClose: () => void;
  onSubmit: (value: GiroManagementModalType) => void;
}

const GiroManagementModal = ({
  value,
  opened = false,
  olNumbersList,
  soNumbersList,
  onClose,
  onSubmit,
}: Props) => {
  const { classes } = useStyles();

  const form = useForm({
    initialValues: {
      receivedDate: value?.receivedDate
        ? new Date(value.receivedDate)
        : undefined,
      type: value?.type,
      dueDate: value?.receivedDate ? new Date(value.dueDate) : undefined,
      buyerNumber: value?.buyerNumber && Number(value.buyerNumber),
      bank: value?.bank,
      bankAccountNo: value?.bankAccountNo && Number(value.bankAccountNo),
      bankAccountHolderName: value?.bankAccountHolderName,
      amount: value?.amount && Number(value.amount),
      olNo: value?.olNo,
      soNo: value?.soNo,
      status: value?.status,
      accountNoMatched: booleanToString(value?.accountNoMatched),
      number: value?.number,
    },
    validate: {
      receivedDate: isNotEmpty("Giro Management Received Date is required"),
      type: isNotEmpty("Giro Management Type is required"),
      dueDate: (value, values) =>
        values.type !== GiroType.GiroCollateral
          ? isNotEmpty("Giro Management Due Date is required")(value)
          : null,
      buyerNumber: isNotEmpty("Giro Management Buyer Number is required"),
      bank: isNotEmpty("Giro Management Bank is required"),
      bankAccountNo: isNotEmpty("Giro Management Bank Account No is required"),
      bankAccountHolderName: isNotEmpty(
        "Giro Management Bank Account Holder Name is required"
      ),
      amount: isNotEmpty("Giro Management Amount is required"),
      olNo: isNotEmpty("Giro Management OlNo is required"),
      soNo: isNotEmpty("Giro Management SoNo is required"),
      status: isNotEmpty("Giro Management Date is required"),
      accountNoMatched: isNotEmpty("Giro Account Number Matched is required"),
    },
    validateInputOnBlur: true,
    clearInputErrorOnChange: true,
  });

  const handleClose = useCallback(() => {
    form.reset();
    onClose();
  }, [form, onClose]);

  const handleSubmit = useCallback(() => {
    if (!form.isValid()) {
      return;
    }

    onSubmit({
      ...form.values,
      accountNoMatched: stringToBoolean(form.values.accountNoMatched, true),
    } as GiroManagementModalType);
    handleClose();
  }, [form, handleClose, onSubmit]);

  return (
    <Modal
      opened={opened}
      title={value ? "Edit GIRO" : "Add GIRO"}
      closeButtonProps={{
        size: 24,
        iconSize: 24,
      }}
      size="auto"
      scrollAreaComponent={ScrollArea.Autosize}
      onClose={handleClose}
    >
      <Flex direction="column" gap={20} className={classes.contentWrapper}>
        <SimpleGrid
          cols={2}
          breakpoints={[{ maxWidth: "lg", cols: 1 }]}
          spacing={20}
          verticalSpacing={12}
        >
          <Flex direction="column" gap={12}>
            <DatePickerInput
              label="GIRO Received Date "
              placeholder="Select GIRO Received Date"
              icon={<SvgCalendar />}
              firstDayOfWeek={0}
              size="m"
              valueFormat="YYYY MMM DD"
              popoverProps={{ withinPortal: true }}
              clearable
              required
              {...form.getInputProps("receivedDate")}
            />
            <Select
              withinPortal
              dropdownPosition="top"
              required
              data={giroTypeOptions}
              label="Type"
              placeholder="Select Type"
              size="m"
              {...form.getInputProps("type")}
              onChange={(value) => {
                form.clearFieldError("dueDate");
                form.getInputProps("type").onChange(value);
              }}
            />
            <DatePickerInput
              required={form.values.type !== GiroType.GiroCollateral}
              label="GIRO Due Date "
              placeholder="Select GIRO Due Date"
              icon={<SvgCalendar />}
              firstDayOfWeek={0}
              size="m"
              valueFormat="YYYY MMM DD"
              clearable
              popoverProps={{ withinPortal: true }}
              {...form.getInputProps("dueDate")}
            />
            <NumberInput
              required
              label="GIRO Buyer No"
              placeholder="Enter GIRO Buyer No"
              size="m"
              hideControls
              {...form.getInputProps("buyerNumber")}
            />
            <TextInput
              required
              label="Bank"
              placeholder="Enter Bank"
              size="m"
              {...form.getInputProps("bank")}
            />
            <NumberInput
              required
              label="Bank Account No"
              placeholder="Enter Bank Account No"
              size="m"
              {...form.getInputProps("bankAccountNo")}
            />
          </Flex>
          <Flex direction="column" gap={12}>
            <TextInput
              required
              label="Bank Account Holder Name"
              placeholder="Enter Bank Account Holder Name"
              size="m"
              {...form.getInputProps("bankAccountHolderName")}
            />
            <NumberInput
              required
              label="GIRO Amount"
              placeholder="Enter GIRO Amount"
              size="m"
              precision={2}
              parser={parseStringToNumber}
              formatter={priceFormatter}
              min={0}
              hideControls
              {...form.getInputProps("amount")}
            />
            <Select
              withinPortal
              dropdownPosition="top"
              required
              data={olNumbersList ?? []}
              label="OL No"
              placeholder="Select OL No"
              size="m"
              nothingFound="No options"
              {...form.getInputProps("olNo")}
            />
            <Select
              withinPortal
              dropdownPosition="top"
              required
              data={soNumbersList ?? []}
              label="SO No"
              placeholder="Select SO No"
              size="m"
              nothingFound="No options"
              {...form.getInputProps("soNo")}
            />
            <Select
              withinPortal
              dropdownPosition="top"
              required
              data={giroStatusOptions}
              label="GIRO Status"
              placeholder="Select GIRO Status"
              size="m"
              {...form.getInputProps("status")}
            />
            <Select
              withinPortal
              dropdownPosition="top"
              required
              data={accountNoMatchedOptions}
              label="GIRO account number matched?"
              placeholder="Select GIRO account number matched"
              size="m"
              {...form.getInputProps("accountNoMatched")}
            />
          </Flex>
        </SimpleGrid>
        <Flex gap={20} justify="end">
          <Button
            variant="outlineBlue"
            uppercase
            type="reset"
            onClick={onClose}
          >
            cancel
          </Button>
          <Button
            variant="filledBlue"
            uppercase
            type="submit"
            disabled={!form.isValid()}
            onClick={handleSubmit}
          >
            {value ? "save" : "add"}
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};

export default GiroManagementModal;
