import {
  Button,
  Flex,
  Input,
  Modal,
  ScrollArea,
  Textarea,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { isNotEmpty, useForm } from "@mantine/form";
import { isEmpty } from "lodash";
import { useCallback } from "react";

import Dropzone from "../../../../components/Dropzone/Dropzone.tsx";
import Calendar from "../../../../components/Icons/Calendar.tsx";
import {
  FileResult,
  UpdateCollectionDocumentInput,
} from "../../../../graphql/generated";
import { CollectionDocument } from "../../../../types/collectionDocument.ts";
import { useStyles } from "./EditCollectionDocumentModal.styles.ts";

interface Props {
  opened: boolean;
  value: Partial<CollectionDocument>;
  onClose: () => void;
  onSubmit: (value: UpdateCollectionDocumentInput) => void;
}

const EditCollectionDocumentModal = ({
  opened = false,
  value,
  onClose,
  onSubmit,
}: Props) => {
  const { classes } = useStyles();

  const form = useForm({
    initialValues: {
      remark: value.remark,
      sentAt: value.sentAt ? new Date(value.sentAt) : new Date(),
      receiptProof: value.receiptProof,
      signedDocuments: value.signedDocuments,
    },
    validate: {
      remark: isNotEmpty("Field is required"),
      sentAt: isNotEmpty("Field is required"),
      receiptProof: isNotEmpty("Field is required"),
      signedDocuments: isNotEmpty("Field is required"),
    },
    validateInputOnBlur: true,
  });

  const handleClose = useCallback(() => {
    onClose();
    form.reset();
  }, [form, onClose]);

  const handleFileUploaded = (fieldName: string, files: FileResult[]) => {
    if (fieldName === "receiptProof") {
      form.setValues({ receiptProof: files[0] });
    }
    if (fieldName === "signedDocuments") {
      form.setValues({
        signedDocuments: form.values.signedDocuments
          ? [...form.values.signedDocuments, ...files]
          : files,
      });
    }
  };

  const handleRemoveFile = (fieldName: string) => {
    form.setFieldValue(fieldName, undefined);
  };

  const handleSubmit = useCallback(() => {
    onSubmit({
      ...form.values,
      receiptProof: form.values.receiptProof?.filePath,
      signedDocuments: form.values.signedDocuments?.map((d) => d.filePath),
    } as UpdateCollectionDocumentInput);
    handleClose();
  }, [form.values, handleClose, onSubmit]);

  return (
    <Modal
      opened={opened}
      title="Edit Collection Document Information"
      scrollAreaComponent={ScrollArea.Autosize}
      closeButtonProps={{
        size: 24,
        iconSize: 24,
      }}
      onClose={handleClose}
    >
      <Flex direction="column" gap={20}>
        <Textarea
          required
          autosize
          label="Remark"
          placeholder="Enter Remark"
          minRows={3}
          maxRows={3}
          size="m"
          {...form.getInputProps("remark")}
        />
        <DatePickerInput
          required
          label="Sent Date"
          placeholder="Select Sent Date"
          size="m"
          icon={<Calendar />}
          firstDayOfWeek={0}
          maxDate={new Date()}
          className={classes.dateWrapper}
          popoverProps={{ withinPortal: true }}
          {...form.getInputProps("sentAt")}
        />
        <Input.Wrapper required label="Receipt Proof">
          <Dropzone
            value={form.values.receiptProof ? [form.values.receiptProof] : null}
            maxFiles={1}
            onUploadSuccess={(files) =>
              handleFileUploaded("receiptProof", files)
            }
            onRemove={() => handleRemoveFile("receiptProof")}
          />
        </Input.Wrapper>
        <Input.Wrapper required label="Signed Document">
          <Dropzone
            value={
              isEmpty(form?.values?.signedDocuments)
                ? null
                : form.values.signedDocuments
            }
            onUploadSuccess={(files) =>
              handleFileUploaded("signedDocuments", files)
            }
            onRemove={() => handleRemoveFile("signedDocuments")}
          />
        </Input.Wrapper>
        <Flex gap={20} justify="end">
          <Button
            variant="outlineBlue"
            uppercase
            type="reset"
            onClick={onClose}
          >
            cancel
          </Button>
          <Button
            variant="filledBlue"
            uppercase
            type="submit"
            disabled={!form.isValid()}
            onClick={handleSubmit}
          >
            save
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};

export default EditCollectionDocumentModal;
