import { Flex } from "@mantine/core";

import { SortDirection } from "./types.ts";

export const emptyTableSort = {
  columnAccessor: "",
  direction: SortDirection.asc,
};

export const TableEmptyValue = (
  <Flex justify="start" align="center">
    {" "}
    —{" "}
  </Flex>
);
