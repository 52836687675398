import { createStyles, MantineTheme, rem } from "@mantine/core";

import { ContractProfileQualityCode } from "../types.ts";

export const useStyles = createStyles(
  (
    theme: MantineTheme,
    { qualityCode }: { qualityCode?: ContractProfileQualityCode }
  ) => ({
    wrapper: {
      fontSize: rem(14),
      fontWeight: 400,
      lineHeight: 1.5,
      background:
        qualityCode && theme.colors.contractsTable[Number(qualityCode) - 1],
    },
  })
);
