import isNil from "lodash/isNil";
import { useQuery } from "urql";

import { GetSoNumbersListDocument } from "../../../graphql/generated.ts";

interface Props {
  customerId: number | null | undefined;
}

export function useGetSoNumbersList({ customerId }: Props) {
  return useQuery({
    query: GetSoNumbersListDocument,
    variables: {
      filter: {
        customerId: { equals: Number(customerId) },
        saleOrderNumber: { isNot: null },
      },
    },
    pause: isNil(customerId),
  });
}
