import { createStyles, rem } from "@mantine/core";

export const useStyles = createStyles(() => ({
  wrapper: {
    display: "flex",
    flexFlow: "column",
    alignItems: "flex-start",
    gap: rem(20),
    width: "100%",
  },
  grid: {
    width: "100%",
    alignItems: "flex-end",
    "@media (max-width: 48em)": {
      display: "flex",
      flexFlow: "column",
      alignItems: "stretch",
      gap: rem(12),
    },
  },
  addButton: {
    "@media (max-width: 48em)": {
      width: "100%",
    },
  },
  breadcrumbs: {
    alignSelf: "stretch",
  },
}));
