import { createStyles, MantineTheme } from "@mantine/core";

export const useStyles = createStyles((theme: MantineTheme) => ({
  iconWrapper: {
    svg: {
      color: `${theme.colors.dusk[0]}!important`,
    },

    "&:hover": {
      background: "transparent",
    },
  },
}));
