import { Select, SimpleGrid } from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { DatesRangeValue } from "@mantine/dates/lib/types/DatePickerValue";
import { useDisclosure } from "@mantine/hooks";
import { notifications } from "@mantine/notifications";
import { DataTableSortStatus } from "mantine-datatable";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import HandoverToCUModal from "../../../../components/HandoverToCUModal/HandoverToCUModal.tsx";
import SvgCalendar from "../../../../components/Icons/Calendar.tsx";
import SvgWarning from "../../../../components/Icons/Warning.tsx";
import SectionTitle from "../../../../components/Section/SectionTitle/SectionTitle.tsx";
import Table from "../../../../components/Table/Table.tsx";
import { SortDirection, TableRow } from "../../../../components/Table/types.ts";
import { AppRoute } from "../../../../constants.ts";
import {
  LimitRequestFilter,
  LimitRequestOrderBy,
  LimitStatus,
  OrderBy,
  UpdateLimitRequestInlineInput,
} from "../../../../graphql/generated.ts";
import { useFreezeLimitRequest } from "../../../../hooks/api/limitRequest/useFreezeLimitRequest.ts";
import { useLimitRequests } from "../../../../hooks/api/limitRequest/useLimitRequests.ts";
import { useUnFreezeLimitRequest } from "../../../../hooks/api/limitRequest/useUnFreezeLimitRequest.ts";
import { useUpdateLimitRequestInline } from "../../../../hooks/api/limitRequest/useUpdateLimitRequestInline.ts";
import { useMutationNotificationWrapper } from "../../../../hooks/useMutationNotificationWrapper.tsx";
import {
  LimitRequest,
  limitStatusOptions,
  limitTypeOptions,
} from "../../../../types/limitRequest.ts";
import { offerLetterStatusOptions } from "../../../../types/offerLetter.ts";
import { getPagesCount } from "../../../../utils/pagination.ts";
import { useLimitRequestTableData } from "./hooks/useLimitRequestTableData.tsx";
import { getLimitRequestApiFilter, getLimitRequestApiSort } from "./utils.ts";

interface Props {
  companyId: number | null | undefined;
}

export default function LimitRequestsTable({ companyId }: Props) {
  const navigate = useNavigate();

  const [
    freezeModalOpened,
    { open: openFreezeModal, close: closeFreezeModal },
  ] = useDisclosure(false);

  const [selectedItem, setSelectedItem] = useState<LimitRequest | null>(null);

  const [filter, setFilter] = useState<LimitRequestFilter>({});
  const [orderBy, setOrderBy] = useState<LimitRequestOrderBy>({
    createdAt: OrderBy.Desc,
  });
  const [page, setPage] = useState<number>(1);
  const [{ data, fetching, error }, refreshData] = useLimitRequests({
    filter: { ...filter, companyId: { equals: companyId } },
    orderBy,
    page,
  });

  const limitRequests: LimitRequest[] | undefined | null =
    data?.limitRequests?.data;
  const pageCount = getPagesCount(data?.limitRequests?.total);

  const [, updateLimitRequestCategory] = useMutationNotificationWrapper(
    useUpdateLimitRequestInline(),
    {
      success: { message: "Limit Request has been successfully updated." },
    }
  );

  const [, unFreezeLimitRequest] = useMutationNotificationWrapper(
    useUnFreezeLimitRequest(),
    {
      success: { message: "Limit Request  has been successfully unfrozen." },
    }
  );

  const [, freezeLimitRequest] = useMutationNotificationWrapper(
    useFreezeLimitRequest(),
    {
      success: { message: "Limit Request  has been successfully frozen." },
    }
  );

  const handleEdit = useCallback(
    (id: number, input: UpdateLimitRequestInlineInput) => {
      updateLimitRequestCategory({ id, input }).then(refreshData);
    },
    [refreshData, updateLimitRequestCategory]
  );

  const handleOpenFreezeModal = useCallback(
    (value: LimitRequest) => {
      setSelectedItem(value);
      openFreezeModal();
    },
    [openFreezeModal]
  );

  const handleCloseFreezeModal = useCallback(() => {
    setSelectedItem(null);
    closeFreezeModal();
  }, [closeFreezeModal, setSelectedItem]);

  const [columns, rows] = useLimitRequestTableData({
    data: limitRequests,
    onEdit: handleEdit,
    onFreezeModalOpen: handleOpenFreezeModal,
  });

  const handleSortChange = useCallback(
    (sort: DataTableSortStatus) => {
      setOrderBy(getLimitRequestApiSort(sort));
    },
    [setOrderBy]
  );

  const handleFilterChange = useCallback(
    (key: string, value: string | DatesRangeValue | null) => {
      setFilter(getLimitRequestApiFilter({ [key]: value }));
    },
    []
  );

  const handlePageChange = useCallback(
    (page: number) => {
      setPage(page);
    },
    [setPage]
  );

  const handleRowClik = useCallback(
    (record: TableRow) => {
      navigate(`${AppRoute.LimitRequest}/${record.id}`);
    },
    [navigate]
  );

  const handleChangeLimitStatus = useCallback(
    (note: string) => {
      if (!selectedItem) {
        return;
      }

      const args = {
        id: selectedItem?.id,
        input: { text: note, limitRequestId: selectedItem.id },
      };
      const handler =
        selectedItem.limit?.status === LimitStatus.Freeze
          ? unFreezeLimitRequest(args)
          : freezeLimitRequest(args);

      handler.then(() => {
        handleCloseFreezeModal();
        refreshData();
      });
    },
    [
      selectedItem,
      freezeLimitRequest,
      unFreezeLimitRequest,
      refreshData,
      handleCloseFreezeModal,
    ]
  );

  if (error) {
    notifications.clean();
    notifications.show({
      message:
        "Something went wrong while trying to fetch Limit Requests data.",
      icon: <SvgWarning />,
    });
  }

  if (!companyId) {
    return;
  }

  return (
    <>
      <SectionTitle>Limit Requested</SectionTitle>
      <SimpleGrid cols={3} spacing={20} verticalSpacing={8}>
        <Select
          size="m"
          clearable
          label="Limit Status"
          placeholder="Select Limit Status"
          data={limitStatusOptions}
          onChange={(value: string) => handleFilterChange("limitStatus", value)}
        />
        <Select
          size="m"
          clearable
          label="Limit Type"
          placeholder="Select Limit Type"
          data={limitTypeOptions}
          onChange={(value: string) => handleFilterChange("limitType", value)}
        />
        <DatePickerInput
          type="range"
          label="OL Expired Date"
          placeholder="Select OL Expired Date"
          icon={<SvgCalendar />}
          firstDayOfWeek={0}
          popoverProps={{ withinPortal: true, zIndex: 1000 }}
          size="m"
          clearable
          onChange={(value: DatesRangeValue) =>
            handleFilterChange("olExpiredDate", value)
          }
        />
      </SimpleGrid>
      <SimpleGrid cols={3} spacing={20} verticalSpacing={8}>
        <Select
          size="m"
          clearable
          label="OL Status"
          placeholder="Select OL Status"
          data={offerLetterStatusOptions}
          onChange={(value: string) => handleFilterChange("olStatus", value)}
        />
      </SimpleGrid>
      <Table
        columns={columns}
        rows={rows}
        defaultSort={{
          columnAccessor: "createdAt",
          direction: SortDirection.asc,
        }}
        loading={fetching}
        pagination={{
          pageCount: pageCount,
          page: page,
          onPageChange: handlePageChange,
        }}
        onRowClick={handleRowClik}
        onSortChange={handleSortChange}
      />
      {freezeModalOpened && selectedItem && (
        <HandoverToCUModal
          opened={true}
          title={
            selectedItem.limit?.status === LimitStatus.Freeze
              ? "Unfreeze Limit Request"
              : "Freeze Limit Request"
          }
          buttonTitle={
            selectedItem.limit?.status === LimitStatus.Freeze
              ? "Unfreeze"
              : "Freeze"
          }
          onClose={handleCloseFreezeModal}
          onSubmit={handleChangeLimitStatus}
        />
      )}
    </>
  );
}
