import { SelectItem } from "@mantine/core/lib/Select/types";
import { uniq } from "lodash";

import { Customer } from "../types/customer.ts";

export const getCompanyOptions = (
  companies: Pick<Customer, "id" | "name">[] | null | undefined
): SelectItem[] => {
  return (
    companies?.map((company) => ({
      value: company.id.toString(),
      label: company.name,
    })) ?? []
  );
};

export const getSalesTeamOptions = (
  companies: Pick<Customer, "id" | "salesTeamName">[] | null | undefined
) => {
  return uniq(
    companies?.map((company) => company.salesTeamName || "")?.filter(Boolean)
  );
};

export const getSalesNameOptions = (
  companies: Pick<Customer, "id" | "salesName">[] | null | undefined
) => {
  return uniq(
    companies?.map((company) => company.salesName || "")?.filter(Boolean)
  );
};
