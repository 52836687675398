import { Button, Flex, SimpleGrid } from "@mantine/core";

import SvgPlus from "../../../../components/Icons/Plus.tsx";
import { LimitRequestException } from "../../../../types/limitRequestException";
import { useStyles } from "../Exceptions.styles.ts";
import { useExceptionList } from "./hooks/useExceptionList.tsx";

interface Props {
  exceptions: LimitRequestException[] | null | undefined;
  onAdd: () => void;
  onEdit: (value: LimitRequestException) => void;
  onDelete: (id: number) => void;
}

const ExceptionList = ({ exceptions, onEdit, onDelete, onAdd }: Props) => {
  const { classes } = useStyles();

  const renderedExceptions = useExceptionList({
    data: exceptions,
    onEdit,
    onDelete,
  });

  return (
    <Flex direction="column" gap={4}>
      <Flex className={classes.label}>Exception</Flex>
      <Flex direction="column" gap={8}>
        {renderedExceptions && (
          <SimpleGrid w="100%" cols={2} spacing={8} verticalSpacing={8}>
            {renderedExceptions}
          </SimpleGrid>
        )}
        <Button
          variant="outlineBlue"
          leftIcon={<SvgPlus />}
          uppercase
          onClick={onAdd}
        >
          Add
        </Button>
      </Flex>
    </Flex>
  );
};

export default ExceptionList;
