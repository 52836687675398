import { SelectItem } from "@mantine/core/lib/Select/types";

import { ContractType } from "../../../../graphql/generated.ts";
import { ContractsList } from "./types.ts";

export const clikResultTitle = {
  [ContractType.Bond]: "Bond / Securities",
  [ContractType.Credit]: "Credit / Financing",
  [ContractType.Guarantee]: "Bank Guarantee",
  [ContractType.LetterOfCredit]: "Irrevocable LC",
  [ContractType.OtherFacilities]: "Other Facilities",
};

export const getContractDetailsOptions = (
  list: ContractsList[] | null | undefined,
  type: ContractType
): SelectItem[] => {
  if (!list) {
    return [];
  }

  return list.map((item, index) => {
    return {
      value: item.id.toString(),
      label: `Details of ${clikResultTitle[type]} ${index + 1}`,
    };
  });
};
