import { createStyles, rem } from "@mantine/core";

export const useStyles = createStyles((theme) => ({
  buttonsWrapper: {
    width: "100%",
    position: "fixed",
    bottom: 0,
    left: 0,
    boxShadow: "0px 0px 12px 0px rgba(55, 65, 82, 0.18)",
    background: theme.colors.white[0],
    padding: `${rem(24)} ${rem(20)}`,
    zIndex: 3,
  },
}));
