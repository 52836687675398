import { MantineTheme, rem } from "@mantine/core";

import { ThemeComponent } from "./helpers.ts";

const basicButton = {
  icon: {
    width: `${rem(24)}`,
    height: `${rem(24)}`,
    minHeight: "unset",
    minWidth: "unset",
  },
  gap: rem(12),
  height: rem(44),
  fontWeight: 600,
  lineHeight: 1.5,
  borderRadius: rem(4),
  padding: ` ${rem(8)} ${rem(20)}`,
  width: "fit-content",
};

const basicFilledButton = (
  theme: MantineTheme,
  background: string,
  hoverBg: string,
  disabledBg: string
) => ({
  root: {
    ...basicButton,
    color: theme.colors.white[0],
    background: background,
    "&:hover": {
      background: hoverBg,
    },
    "&:focus": {
      background: background,
      color: theme.colors.white[0],
    },
    "&:disabled": {
      color: theme.colors.white[0],
      background: disabledBg,
    },
  },
});

const blueFilledButton = (theme: MantineTheme) =>
  basicFilledButton(
    theme,
    `linear-gradient(357deg, ${theme.colors.dusk[0]} 8.1%, ${theme.colors.dusk[1]} 51.61%, ${theme.colors.dusk[2]} 64.51%, ${theme.colors.dusk[3]} 85.59%)`,
    theme.colors.midnightBlue[0],
    `linear-gradient(180deg, ${theme.colors.grayIce[0]} 0%, ${theme.colors.grayIce[1]} 100%)`
  );

const goldenFilledButton = (theme: MantineTheme) =>
  basicFilledButton(
    theme,
    `linear-gradient(180deg, ${theme.colors.golden[0]} 12.5%, ${theme.colors.bronze[0]} 220.45%)`,
    theme.colors.golden[0],
    `linear-gradient(180deg, ${theme.colors.grayIce[0]} 0%, ${theme.colors.grayIce[1]} 100%)`
  );

const blueOutlineButton = (theme: MantineTheme) => ({
  root: {
    ...basicButton,
    color: theme.colors.dusk[0],
    background: "transparent",
    border: `1px solid ${theme.colors.dusk[0]}`,
    "&:hover": {
      color: theme.colors.dusk[4],
      border: `1px solid ${theme.colors.dusk[4]}`,
      background: theme.colors.dusk[5],
    },
    "&:focus": {
      color: theme.colors.dusk[7],
      border: `1px solid ${theme.colors.dusk[7]}`,
      background: theme.colors.dusk[6],
    },
    "&:focus:not(:focus-visible)": {
      background: "transparent",
    },
    "&:disabled": {
      color: theme.colors.grayIce[0],
      border: `1px solid ${theme.colors.grayIce[0]}`,
    },
  },
});

const goldenOutlineButton = (theme: MantineTheme) => ({
  root: {
    ...basicButton,
    color: theme.colors.golden[0],
    background: theme.colors.white[0],
    border: `1px solid ${theme.colors.golden[0]}`,
    "&:hover": {
      color: theme.colors.golden[0],
      border: `1px solid ${theme.colors.golden[0]}`,
      background: theme.colors.golden[1],
    },
    "&:focus": {
      color: theme.colors.golden[0],
      border: `1px solid ${theme.colors.golden[0]}`,
      background: theme.colors.golden[3],
    },
    "&:disabled": {
      color: theme.colors.grayIce[0],
      border: `1px solid ${theme.colors.grayIce[0]}`,
      background: theme.colors.white[1],
    },
  },
});
export default function buttonTheme(): ThemeComponent {
  return {
    variants: {
      filledBlue: blueFilledButton,
      filledGolden: goldenFilledButton,
      outlineBlue: blueOutlineButton,
      outlineGolden: goldenOutlineButton,
    },
    sizes: {
      s: () => ({
        root: {
          height: rem(32),
          fontSize: rem(12),
        },
      }),
    },
  };
}
