import { Popover, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useMemo, useRef } from "react";

import { FileResult } from "../../graphql/generated";
import ActionIcon from "../ActionIcon/ActionIcon";
import SvgClose from "../Icons/Close";
import SvgDownload from "../Icons/Download";
import { useStyles } from "./DownloadFile.styles";

export interface DownloadFileProps {
  file: FileResult;
  onRemove?: (link: string) => void;
}

export default function DownloadFile({ file, onRemove }: DownloadFileProps) {
  const { classes } = useStyles();

  const nameRef = useRef<HTMLSpanElement>(null);

  const [opened, { close, open }] = useDisclosure(false);

  const nameWidth = nameRef.current?.offsetWidth || 0;
  const fillWidth = nameRef.current?.scrollWidth || 0;

  const displayPopover = fillWidth > nameWidth;

  const fileName = useMemo(() => {
    if (!file.filePath) {
      return;
    }
    const parts = decodeURI(file.filePath).split("/");
    return parts[parts.length - 1];
  }, [file.filePath]);

  return (
    <div className={classes.wrapper}>
      <Popover position="top" withinPortal opened={opened && displayPopover}>
        <Popover.Target>
          <div className={classes.nameWrapper}>
            <span
              ref={nameRef}
              className={classes.name}
              onMouseEnter={open}
              onMouseLeave={close}
            >
              {fileName}
            </span>
          </div>
        </Popover.Target>
        <Popover.Dropdown maw={300}>
          <Text size="sm">{fileName}</Text>
        </Popover.Dropdown>
      </Popover>
      <div className={classes.actions}>
        <ActionIcon href={file.url}>
          <SvgDownload />
        </ActionIcon>

        {onRemove && (
          <ActionIcon variant="error" onClick={() => onRemove(file.filePath)}>
            <SvgClose />
          </ActionIcon>
        )}
      </div>
    </div>
  );
}
