import { useQuery } from "urql";

import {
  CustomerFilter,
  CustomerOrderBy,
  GetCustomersDocument,
} from "../../../graphql/generated";
import { ApiHookProps } from "../types.ts";

export const useCustomers = ({
  filter,
  orderBy,
  pause,
}: ApiHookProps<CustomerFilter, CustomerOrderBy>) => {
  const variables = { filter, orderBy };
  return useQuery({ query: GetCustomersDocument, variables, pause });
};
