import { Flex } from "@mantine/core";

import AccordionMultifieldsCards from "../../../../components/AccordionMultifieldCards/AccordionMultifieldCards.tsx";
import SectionTitle from "../../../../components/Section/SectionTitle/SectionTitle.tsx";
import { useClikContacts } from "../../../../hooks/api/clikResult/useClikContacts.ts";
import { useCRContactsCardsInfo } from "./hooks/useCRContactsCardsInfo.tsx";

interface ContactsProps {
  clikResultId: number;
}

const ClikResultContacts = ({ clikResultId }: ContactsProps) => {
  const [{ data, fetching }] = useClikContacts({ clikResultId });

  const clikContacts = data?.clikContacts;

  const cardItems = useCRContactsCardsInfo({
    data: clikContacts,
  });

  return (
    <Flex direction="column" gap={16}>
      <SectionTitle variant="bronze">Contacts</SectionTitle>
      <AccordionMultifieldsCards cardItems={cardItems} loading={fetching} />
    </Flex>
  );
};

export default ClikResultContacts;
