import { createStyles, CSSObject, rem } from "@mantine/core";

export const useStyles = createStyles(() => ({
  button: {
    width: "100%",
    "@media (max-width: 65em)": {
      height: "fit-content",
    },
    "@media (max-width: 48em)": {
      paddingLeft: rem(36),
      paddingRight: rem(36),
    },

    span: {
      whiteSpace: "normal",
      height: "fit-content",
      textAlign: "center",
    },
  },
  collectorAssignedWrapper: {
    wordWrap: "break-all" as CSSObject["wordWrap"],
    overflowWrap: "anywhere",
  },
}));
