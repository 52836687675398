import isNil from "lodash/isNil";
import { useQuery } from "urql";

import { GetClikContractHistorySummaryDocument } from "../../../graphql/generated";

interface Props {
  clikResultId: number;
}

export function useClikContractHistorySummary({ clikResultId }: Props) {
  return useQuery({
    query: GetClikContractHistorySummaryDocument,
    variables: { clikResultId: Number(clikResultId) },
    pause: isNil(clikResultId),
  });
}
