import { Badge, Button, Flex } from "@mantine/core";
import { useMemo } from "react";

import SvgCheck from "../../../../components/Icons/Check.tsx";
import SvgLeftArrow from "../../../../components/Icons/LeftArrow.tsx";
import SvgPlus from "../../../../components/Icons/Plus.tsx";
import NoteList from "../../../../components/NoteList/NoteList.tsx";
import { LimitRequestStatus } from "../../../../graphql/generated.ts";
import { RiskReview } from "../../../../types/riskReview.ts";
import { useStyles } from "../RiskReviewCard.styles.ts";

interface Props {
  limitRequestStatus: LimitRequestStatus | null | undefined;
  canEdit: boolean;
  riskReview: RiskReview | null | undefined;
  onHandoverToCU: () => void;
  onHandoverToCC: () => void;
  onAdd: () => void;
}

export default function useRiskReviewCardFooter({
  limitRequestStatus,
  canEdit,
  riskReview,
  onHandoverToCU,
  onHandoverToCC,
  onAdd,
}: Props) {
  const { classes } = useStyles();

  const content = useMemo(() => {
    if (!riskReview) {
      return (
        <Button
          leftIcon={<SvgPlus />}
          variant="filledGolden"
          w="100%"
          uppercase
          disabled={
            limitRequestStatus !== LimitRequestStatus.OngoingRr || !canEdit
          }
          onClick={onAdd}
        >
          Add data
        </Button>
      );
    }

    if (limitRequestStatus === LimitRequestStatus.OngoingRr) {
      return (
        <>
          <Button
            variant="outlineGolden"
            leftIcon={<SvgLeftArrow />}
            w="50%"
            uppercase
            disabled={!canEdit}
            onClick={onHandoverToCU}
          >
            Handover to CU
          </Button>
          <Button
            variant="filledGolden"
            leftIcon={<SvgCheck />}
            w="50%"
            uppercase
            disabled={!canEdit}
            onClick={onHandoverToCC}
          >
            Handover to CC
          </Button>
        </>
      );
    }

    if (limitRequestStatus === LimitRequestStatus.OngoingCu) {
      return (
        <Badge variant="active" w="100%" size="m">
          <Flex>
            <SvgCheck />
            Handovered to CU
          </Flex>
        </Badge>
      );
    }

    return (
      <Badge variant="active" w="100%" size="m">
        <Flex>
          <SvgCheck />
          Handovered to CC
        </Flex>
      </Badge>
    );
  }, [
    riskReview,
    canEdit,
    limitRequestStatus,
    onAdd,
    onHandoverToCC,
    onHandoverToCU,
  ]);

  return useMemo(() => {
    return (
      <Flex
        gap={12}
        direction="column"
        w="100%"
        className={classes.footerControlWrapper}
      >
        <Flex gap={20} w="100%" justify="center">
          {content}
        </Flex>
        <NoteList notes={riskReview?.rejectNotes} />
      </Flex>
    );
  }, [classes, riskReview, content]);
}
