import { useQuery } from "urql";

import {
  EmployeeCandidateRequestFilter,
  EmployeeCandidateRequestOrderBy,
  GetEmployeeCandidateListDocument,
} from "../../../graphql/generated.ts";
import { getApiPaginationData } from "../../../utils/api.ts";
import { ApiHookProps } from "../types.ts";

export const useGetEmployeeCandidateRequests = ({
  filter,
  orderBy,
  pause,
  page,
}: ApiHookProps<
  EmployeeCandidateRequestFilter,
  EmployeeCandidateRequestOrderBy
>) => {
  const pagination = getApiPaginationData(page);
  const variables = {
    filter,
    orderBy,
    skip: pagination?.skip,
    take: pagination?.take,
  };
  return useQuery({
    query: GetEmployeeCandidateListDocument,
    variables,
    pause,
  });
};
