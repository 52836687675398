import { pickBy } from "lodash";

import {
  FileResult,
  PksFilter,
  PksStatus,
  UpdatePksInput,
} from "../../../../graphql/generated.ts";
import { Pks, pksStatusOptions } from "../../../../types/pks.ts";
import { PksFiltersValue } from "./types.ts";

export const getPksApiFilter = (value: PksFiltersValue): PksFilter => {
  const hasPksDueDatePeriod =
    !!value.pksDueDatePeriod &&
    !!value.pksDueDatePeriod[0] &&
    !!value.pksDueDatePeriod[1];
  return pickBy({
    pksStatus: value.pksStatus && { equals: value.pksStatus },
    expiryDate: !!value.pksDueDatePeriod &&
      hasPksDueDatePeriod && {
        gte: value.pksDueDatePeriod[0],
        lte: value.pksDueDatePeriod[1],
      },
  });
};

export const formatPksToUpdatePksInput = (data: Pks): UpdatePksInput => {
  return {
    ...data,
    pksFile: data.pksFile.filePath,
    signedPksFile: data.pksFile.filePath,
  };
};

export const getPksStatusOptions = (active: FileResult | null | undefined) =>
  pksStatusOptions.map((item) =>
    item.value === PksStatus.Active ? { ...item, disabled: !active } : item
  );
