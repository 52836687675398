import { ActionIcon, Tooltip } from "@mantine/core";

import SvgInfo from "../Icons/Info.tsx";
import { useStyles } from "./InfoTooltip.styles.ts";

interface InfoTooltip {
  tooltipText: string;
}

const InfoTooltip = ({ tooltipText }: InfoTooltip) => {
  const { classes } = useStyles();

  return (
    <Tooltip
      label={tooltipText}
      multiline
      transitionProps={{ duration: 200 }}
      withinPortal
    >
      <ActionIcon size={24} className={classes.iconWrapper}>
        <SvgInfo />
      </ActionIcon>
    </Tooltip>
  );
};

export default InfoTooltip;
