import type { SVGProps } from "react";
const SvgEdit = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 -960 960 960"
    fill="currentColor"
    {...props}
  >
    <path d="M224.615-160Q197-160 178.5-178.5 160-197 160-224.615v-510.77Q160-763 178.5-781.5 197-800 224.615-800h335.462l-40 40H224.615q-9.23 0-16.923 7.692Q200-744.615 200-735.385v510.77q0 9.23 7.692 16.923Q215.385-200 224.615-200h510.77q9.23 0 16.923-7.692Q760-215.385 760-224.615v-299.539l40-40v339.539Q800-197 781.5-178.5 763-160 735.385-160h-510.77ZM480-480Zm-80 80v-104.616l357.769-357.769q6.615-6.615 13.923-9.154 7.308-2.538 15.385-2.538 7.538 0 14.731 2.538Q809-869 814.846-863.154L859.308-820q6.385 6.615 9.692 14.577 3.308 7.961 3.308 16.038t-2.423 15.27q-2.423 7.192-9.039 13.808L500.769-400H400Zm432.539-388.615-44.462-46.77 44.462 46.77ZM440-440h43.692l266.616-266.615-21.847-21.846-24.384-23.385L440-487.769V-440Zm288.461-288.461-24.384-23.385 24.384 23.385 21.847 21.846-21.847-21.846Z" />
  </svg>
);
export default SvgEdit;
