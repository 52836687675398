import { find, set } from "lodash";
import { DataTableSortStatus } from "mantine-datatable";

import { ClikContractProfile } from "../../../../graphql/generated.ts";
import { ClikContractCommonDataOrderBy } from "../../../../graphql/generated.ts";
import { getApiOrderBy } from "../../../../utils/api.ts";
import { ClikContractProfileYear } from "./ClikResultGrantedContracts/types.ts";

export const getMonthKey = (referenceMonth: string) => {
  const months = [
    "jan",
    "feb",
    "mar",
    "apr",
    "may",
    "jun",
    "jul",
    "aug",
    "sep",
    "oct",
    "nov",
    "dec",
  ];
  return months[parseInt(referenceMonth) - 1];
};

export const formatClikContractProfileData = (data: ClikContractProfile[]) => {
  const transformedData: ClikContractProfileYear[] = [];

  data.forEach((entry) => {
    let yearObject = find(transformedData, {
      referenceYear: entry.referenceYear,
    });

    if (!yearObject) {
      yearObject = {
        referenceYear: entry.referenceYear,
        jan: null,
        feb: null,
        mar: null,
        apr: null,
        may: null,
        jun: null,
        jul: null,
        aug: null,
        sep: null,
        oct: null,
        nov: null,
        dec: null,
      };

      transformedData.push(yearObject);
    }

    const monthKey = getMonthKey(entry.referenceMonth);
    set(yearObject, [monthKey], {
      qualityCode: entry.qualityCode,
      qualityCodeDesc: entry.qualityCodeDesc,
      daysPastDue: entry.daysPastDue,
      grantedCreditId: entry.grantedCreditId,
    });
  });

  return transformedData;
};

export const getGrantedContractsApiSort = (tableSort: DataTableSortStatus) => {
  const {
    cbContractCode,
    contractType,
    contractPhase,
    role,
    startDate,
    dueDate,
    providerType,
    providerCode,
    providerContractNumber,
    referenceNo,
    referenceDate,
    ...apiSort
  } = getApiOrderBy(tableSort);
  let commonData: ClikContractCommonDataOrderBy | undefined = undefined;
  if (cbContractCode) {
    commonData = { cbContractCode };
  }
  if (contractType) {
    commonData = { contractType };
  }
  if (contractPhase) {
    commonData = { contractPhase };
  }
  if (role) {
    commonData = { role };
  }
  if (startDate) {
    commonData = { startDate };
  }
  if (dueDate) {
    commonData = { dueDate };
  }

  if (providerType) {
    commonData = { providerType };
  }
  if (providerCode) {
    commonData = { providerCode };
  }
  if (referenceNo) {
    commonData = { referenceNo };
  }
  if (referenceDate) {
    commonData = { referenceDate };
  }
  if (providerContractNumber) {
    commonData = { providerContractNumber };
  }
  return {
    ...apiSort,
    commonData,
  };
};
