import isNil from "lodash/isNil";

import { Customer } from "../types/customer.ts";

export const getDisplayedAddress = (customer: Customer): string | null => {
  const address = [customer.street, customer.city, customer.state]
    .filter((item) => !isNil(item) && item !== "")
    .join(", ");
  if (address === "") {
    return null;
  }
  return address;
};
