import { createStyles, rem } from "@mantine/core";

export const useStyles = createStyles((theme) => ({
  wrapper: {
    borderRadius: rem(4),
    border: `1px solid ${theme.colors.grayIce[0]}`,
    padding: rem(12),
    gap: rem(8),
  },
  notesDate: {
    fontWeight: 400,
    fontSize: rem(12),
    lineHeight: rem(15.6),
    color: theme.colors.grayIce[0],
  },
  showMore: {
    color: theme.colors.dusk[0],
    fontWeight: 500,
    fontSize: rem(12),
    lineHeight: rem(18),
    textDecoration: "underline",
    cursor: "pointer",
  },
}));
