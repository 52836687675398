import { isEmpty } from "lodash";
import { DataTableColumn } from "mantine-datatable";
import { useMemo } from "react";

import { TableData, TableRow } from "../../../../components/Table/types";
import { Invoice } from "../../../../types/invoice.ts";
import { getDisplayedAddress } from "../../../../utils/address.ts";
import { toLocalDate } from "../../../../utils/date.ts";
import { getDpdBucketDisplayName } from "../../../../utils/invoice.ts";
import { toLocalNumber, toPrice } from "../../../../utils/number.ts";
import { toHumanReadable } from "../../../../utils/string.ts";

interface Props {
  data: Invoice[] | null | undefined;
}

export const useCustomerPayPastDue = ({ data }: Props): TableData => {
  const columns: DataTableColumn<TableRow>[] = [
    {
      accessor: "id",
      hidden: true,
    },
    {
      accessor: "customerName",
      title: "Company Name",
      sortable: true,
      width: "350px",
    },
    {
      accessor: "invoiceNumber",
      title: "Inv No",
      sortable: true,
      width: "250px",
    },
    {
      accessor: "invoiceDueDate",
      title: "Inv Due Date",
      sortable: true,
      width: "150px",
    },
    {
      accessor: "totalInvoice",
      title: "Total Inv Amount",
      sortable: true,
      width: "125px",
    },
    {
      accessor: "totalPaid",
      title: "Total Paid Amount",
      sortable: true,
      width: "150px",
    },
    {
      accessor: "totalAmountDue",
      title: "OSP Amount",
      sortable: true,
      width: "125px",
    },
    {
      accessor: "dpd",
      title: "DPD",
      sortable: true,
      width: "125px",
    },
    {
      accessor: "dpdBucket",
      title: "DPD Bucket",
      sortable: true,
      width: "125px",
    },
    {
      accessor: "isRestructured",
      title: "Is Restructured",
      sortable: true,
      width: "125px",
    },
    {
      accessor: "lastPaymentDate",
      title: "Last Payment Date",
      width: "150px",
    },

    {
      accessor: "lastPaymentAmount",
      title: "Last Paid Amount",
      width: "200px",
    },
    {
      accessor: "paymentStatus",
      title: "Payment Status",
      sortable: true,
      width: "125px",
    },
    {
      accessor: "salesName",
      title: "Sales Name",
      sortable: true,
      width: "180px",
    },
    {
      accessor: "customerPhone",
      title: "Customer Phone Number",
      width: "180px",
      sortable: true,
    },
    {
      accessor: "salesTeamName",
      title: "Sales Team Name",
      sortable: true,
      width: "180px",
    },
    {
      accessor: "companyAddress",
      title: "Company Address",
      sortable: true,
      width: "250px",
    },
  ];

  const rows = useMemo(() => {
    return data?.map((item) => {
      return {
        id: item.id,
        invoiceNumber: item.invoiceNumber,
        customerName: item.customer.name,
        totalInvoice: toLocalNumber(item.totalInvoice),
        invoiceDueDate: toLocalDate(item.invoiceDueDate),
        totalPaid: toLocalNumber(item.totalPaid),
        totalAmountDue: toLocalNumber(item.totalAmountDue),
        dpd: toLocalNumber(item.dpd),
        dpdBucket: item.dpdBucket && getDpdBucketDisplayName(item.dpdBucket),
        isRestructured: item.isRestructured ? "Yes" : "No",
        lastPaymentDate:
          item.lastPayment?.paidAt && toLocalDate(item.lastPayment.paidAt),
        lastPaymentAmount: toPrice(item.lastPayment?.amount),
        paymentStatus: toHumanReadable(item.paymentStatus),
        salesName: item.salesName,
        customerPhone: !isEmpty(item.customer.phone)
          ? item.customer.phone
          : null,
        salesTeamName: item.salesTeamName,
        companyAddress: getDisplayedAddress(item.customer),
      };
    });
  }, [data]);

  return [columns, rows];
};
