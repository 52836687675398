import { useQuery } from "urql";

import { GetClikListOfGrantedCreditsDocument } from "../../../../graphql/generated.ts";

interface Props {
  clikResultId: number;
}

export const useClikListOfGrantedCredits = ({ clikResultId }: Props) => {
  return useQuery({
    query: GetClikListOfGrantedCreditsDocument,
    variables: { filter: { clikResultId: { equals: clikResultId } } },
  });
};
