import {
  Button,
  Flex,
  MediaQuery,
  MultiSelect,
  SimpleGrid,
} from "@mantine/core";
import { SelectItem } from "@mantine/core/lib/Select/types";
import { useForm } from "@mantine/form";
import { useDisclosure } from "@mantine/hooks";
import { useCallback, useMemo } from "react";

import FilterMobile from "../../../components/FilterMobile/FilterMobile.tsx";
import SvgFilter from "../../../components/Icons/Filter.tsx";
import SvgUnfold from "../../../components/Icons/Unfold.tsx";
import Title from "../../../components/Title/Title.tsx";
import { dpdBucketOptions } from "../../../constants";
import { Customer } from "../../../types/customer.ts";
import { Collector } from "../../../types/user.ts";
import { getFiltersCount } from "../../../utils/filter.ts";
import { getUserOptions } from "../../../utils/user.ts";
import { useStyles } from "./ActivityManagementToolbar.styles.ts";
import { isRestructuredData, paymentStatusData } from "./constants.ts";
import { FiltersValue } from "./types.ts";

interface ActivityManagementToolbarProps {
  collectors: Collector[] | null | undefined;
  customers: Customer[] | null | undefined;
  onValueChange: (value: Partial<FiltersValue>) => void;
}

const ActivityManagementToolbar = ({
  collectors,
  customers,
  onValueChange,
}: ActivityManagementToolbarProps) => {
  const { classes } = useStyles();

  const [
    filterModalOpened,
    { open: filterModalOpen, close: filterModalClose },
  ] = useDisclosure(false);

  const form = useForm({
    initialValues: {
      dpdBucket: [],
      companyName: [],
      paymentStatus: [],
      isRestructured: [],
      collector: [],
    },
  });

  const customersOptions: SelectItem[] = useMemo(() => {
    return (
      customers?.map((customer) => ({
        value: String(customer.id),
        label: customer.name,
      })) ?? []
    );
  }, [customers]);

  const collectorsOptions: SelectItem[] = useMemo(() => {
    return getUserOptions(collectors);
  }, [collectors]);

  const isFilterApplied = useMemo(
    () => !!getFiltersCount(form.values),
    [form.values]
  );

  const handleValueChange = useCallback(
    (value: Record<string, string[]>) => {
      form.setValues(value);
      onValueChange({ ...form.values, ...value });
    },
    [form, onValueChange]
  );

  const handleApplyFilter = useCallback(() => {
    onValueChange(form.values);
    filterModalClose();
  }, [filterModalClose, form.values, onValueChange]);

  const handleCleanFilters = useCallback(() => {
    form.reset();
    onValueChange({});
    filterModalClose();
  }, [filterModalClose, form, onValueChange]);

  return (
    <>
      <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
        <Flex direction="column" gap={16} className={classes.wrapper}>
          <Flex justify="space-between">
            <Title>Activity Management</Title>
            {isFilterApplied && (
              <Button
                variant="outlineGolden"
                uppercase
                onClick={handleCleanFilters}
              >
                clear filters
              </Button>
            )}
          </Flex>
          <SimpleGrid cols={3} spacing={20} verticalSpacing={8}>
            <MultiSelect
              size="m"
              data={dpdBucketOptions}
              label="DPD Bucket"
              placeholder="Select DPD Bucket"
              searchable
              nothingFound="No results found"
              rightSection={<SvgUnfold />}
              {...form.getInputProps("dpdBucket")}
              onChange={(dpdBucket) => handleValueChange({ dpdBucket })}
            />
            <MultiSelect
              size="m"
              data={customersOptions}
              label="Company Name"
              placeholder="Select Company Name"
              searchable
              nothingFound="No results found"
              rightSection={<SvgUnfold />}
              {...form.getInputProps("companyName")}
              onChange={(companyName) => handleValueChange({ companyName })}
            />
            <MultiSelect
              size="m"
              data={paymentStatusData}
              label="Payment Status"
              placeholder="Select Payment Status"
              searchable
              nothingFound="No results found"
              rightSection={<SvgUnfold />}
              {...form.getInputProps("paymentStatus")}
              onChange={(paymentStatus) => handleValueChange({ paymentStatus })}
            />
            <MultiSelect
              size="m"
              data={isRestructuredData}
              label="Is Restructured"
              placeholder="Select Is Restructured"
              searchable
              nothingFound="No results found"
              rightSection={<SvgUnfold />}
              {...form.getInputProps("isRestructured")}
              onChange={(isRestructured) =>
                handleValueChange({ isRestructured })
              }
            />
            <MultiSelect
              size="m"
              data={collectorsOptions}
              label="Collector"
              placeholder="Select Collector"
              searchable
              nothingFound="No results found"
              rightSection={<SvgUnfold />}
              {...form.getInputProps("collector")}
              onChange={(collector) => handleValueChange({ collector })}
            />
          </SimpleGrid>
        </Flex>
      </MediaQuery>
      <MediaQuery
        largerThan="sm"
        styles={{ display: "none" }}
        className={classes.wrapper}
      >
        <Flex direction="column" gap={16}>
          <Title className={classes.title}>Activity Management</Title>
          <Button
            variant="filledGolden"
            className={classes.filterButton}
            leftIcon={<SvgFilter />}
            uppercase
            fullWidth
            onClick={filterModalOpen}
          >
            Filter
            {isFilterApplied && ` (${getFiltersCount(form.values)})`}
          </Button>
        </Flex>
      </MediaQuery>
      <FilterMobile
        isActive={isFilterApplied}
        opened={filterModalOpened}
        onClose={filterModalClose}
        onClean={handleCleanFilters}
        onApply={handleApplyFilter}
      >
        <Flex direction="column" gap={12}>
          <MultiSelect
            size="m"
            data={dpdBucketOptions}
            label="DPD Bucket"
            placeholder="Select DPD Bucket"
            searchable
            nothingFound="No results found"
            rightSection={<SvgUnfold />}
            {...form.getInputProps("dpdBucket")}
          />
          <MultiSelect
            size="m"
            data={customersOptions}
            label="Company Name"
            placeholder="Select Company Name"
            searchable
            nothingFound="No results found"
            rightSection={<SvgUnfold />}
            {...form.getInputProps("companyName")}
          />
          <MultiSelect
            size="m"
            data={paymentStatusData}
            label="Payment Status"
            placeholder="Select Payment Status"
            searchable
            nothingFound="No results found"
            rightSection={<SvgUnfold />}
            {...form.getInputProps("paymentStatus")}
          />
          <MultiSelect
            size="m"
            data={isRestructuredData}
            label="Is Restructured"
            placeholder="Select Is Restructured"
            searchable
            nothingFound="No results found"
            rightSection={<SvgUnfold />}
            {...form.getInputProps("isRestructured")}
          />
          <MultiSelect
            size="m"
            data={collectorsOptions}
            label="Collector"
            placeholder="Select Collector"
            searchable
            nothingFound="No results found"
            rightSection={<SvgUnfold />}
            {...form.getInputProps("collector")}
          />
        </Flex>
      </FilterMobile>
    </>
  );
};

export default ActivityManagementToolbar;
