import { MantineTheme, rem } from "@mantine/styles";

import { ThemeComponent } from "./helpers.ts";

const defaultSelect = (theme: MantineTheme) => ({
  item: {
    "&[data-selected]": {
      color: theme.colors.black[0],
      background: theme.colors.golden[4],
      "&:hover": {
        background: theme.colors.golden[6],
      },
    },
  },
  description: {
    color: theme.colors.grayIce[0],
    fontSize: rem(14),
    lineHeight: 1.5,
  },
  error: {
    color: theme.colors.error[0],
    fontSize: rem(14),
    lineHeight: 1.5,
  },
  label: {
    color: theme.colors.midnightBlue[0],
    fontWeight: 500,
    lineHeight: 1.5,
    fontSize: rem(16),
  },
  rightSection: {},
  input: {
    background: theme.colors.white[0],
    borderRadius: rem(4),
    border: `1px solid ${theme.colors.grayIce[0]}`,
    lineHeight: 1.5,
    fontWeight: 500,
    "&::placeholder": {
      color: theme.colors.grayIce[0],
      fontWeight: 500,
    },
    "&:hover": {
      border: `1px solid ${theme.colors.dusk[0]}`,
    },
    "&:focus": {
      border: `1px solid ${theme.colors.dusk[0]}`,
    },
    "&:disabled": {
      background: "transparent",
    },
    "&[data-invalid]": {
      "&::placeholder": {
        color: theme.colors.error[0],
        fontWeight: 500,
      },
      color: theme.colors.error[0],
      border: `1px solid ${theme.colors.error[0]}`,
    },
  },
});

function defaultSelectTheme() {
  return {
    styles: (theme: MantineTheme) => ({
      ...defaultSelect(theme),
    }),
  };
}

export default function selectTheme(): ThemeComponent {
  return {
    ...defaultSelectTheme(),
    sizes: {
      s: () => ({
        input: {
          height: rem(32),
          padding: `0 ${rem(14)} 0 ${rem(12)}`,
          fontSize: rem(14),
        },
        item: {
          padding: `${rem(5)} ${rem(4)} ${rem(5)} ${rem(12)}`,
          fontSize: rem(14),
        },
        rightSection: {
          width: rem(20),
        },
      }),
      m: (theme) => ({
        input: {
          height: rem(44),
          padding: `0 ${rem(42)} 0 ${rem(12)}`,
          fontSize: rem(16),
        },
        item: {
          padding: `${rem(10)} ${rem(12)}`,
          fontSize: rem(16),
        },
        rightSection: {
          width: rem(36),
          color: theme.colors.grayIce[0],
          svg: {
            width: rem(20),
            height: rem(20),
          },
        },
      }),
    },
  };
}
