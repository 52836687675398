import { Flex } from "@mantine/core";

import { toPercent } from "../../../../utils/number.ts";
import { useStyles } from "./RatePin.styles.ts";
import { getConversationRateBgColor } from "./utils.ts";

interface Props {
  conversionRate: number;
}

const RatePin = ({ conversionRate }: Props) => {
  const { classes } = useStyles({
    conversationRateBgColor: getConversationRateBgColor(conversionRate),
  });

  return (
    <Flex gap={20} w="100%">
      <Flex className={classes.conversationRate} />
      {toPercent(conversionRate)}
    </Flex>
  );
};

export default RatePin;
