import { createStyles, rem } from "@mantine/core";

export const useStyles = createStyles((theme) => ({
  slaWrapper: {
    width: rem(52),
    height: rem(24),
    borderRadius: rem(2),
    padding: `${rem(8)} ${rem(12)}`,
    background: theme.colors.grayIce[4],
    fontSize: rem(14),
    lineHeight: rem(21),
  },
}));
