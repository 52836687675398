import { useQuery } from "urql";

import { GetCustomersDocument, OrderBy } from "../../../graphql/generated.ts";

export function useCompanies() {
  return useQuery({
    query: GetCustomersDocument,
    variables: { filter: { parentId: null }, orderBy: { name: OrderBy.Asc } },
  });
}
