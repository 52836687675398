import isNil from "lodash/isNil";
import { useQuery } from "urql";

import { GetCreditApprovalDocument } from "../../../graphql/generated.ts";

interface Props {
  id: number | string | null | undefined;
}

export const useCreditApproval = ({ id }: Props) => {
  return useQuery({
    query: GetCreditApprovalDocument,
    variables: { id: Number(id) },
    pause: isNil(id),
  });
};
