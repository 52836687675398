import { createStyles, rem } from "@mantine/core";

export const useStyles = createStyles((theme) => ({
  errorPageWrapper: {
    padding: rem(20),
  },
  title: {
    color: theme.colors.dusk[0],

    "@media (max-width: 48em)": {
      fontSize: rem(28),
      lineHeight: 1.2,
      textAlign: "center",
    },

    "@media (max-width: 30em)": {
      maxWidth: rem(200),
    },
  },
  textWrapper: {
    textAlign: "center",
    lineHeight: 1.5,

    "@media (max-width: 48em)": {
      fontSize: rem(14),
    },
  },
  backButton: {
    "@media (max-width: 30em)": {
      width: "100%",
    },
  },
}));
