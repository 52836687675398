import { Flex, Title } from "@mantine/core";

import Table from "../../../../components/Table/Table.tsx";
import { usePaymentHistory } from "../../../../hooks/api/paymentHistory/usePaymentHistory.ts";
import { usePaymentHistoryData } from "./hooks/usePaymentHistoryData.tsx";
import { useStyles } from "./PaymentHistory.styles.ts";

interface PaymentHistoryProps {
  invoiceId: number;
}

const PaymentHistory = ({ invoiceId }: PaymentHistoryProps) => {
  const { classes } = useStyles();

  const [{ data, fetching }] = usePaymentHistory({ invoiceId: invoiceId });

  const [columns, rows] = usePaymentHistoryData({
    data: data?.paymentHistory,
  });

  return (
    <>
      <Flex direction="column" gap={{ base: 16, sm: 20 }}>
        <Title size="h2" className={classes.title}>
          Customer DPD & Payment History
        </Title>
        <Table columns={columns} rows={rows} loading={fetching} />
      </Flex>
    </>
  );
};

export default PaymentHistory;
