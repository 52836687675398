import type { SVGProps } from "react";
const SvgFolder = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 -960 960 960"
    fill="currentColor"
    {...props}
  >
    <path d="M184.615-200Q157-200 138.5-218.5 120-237 120-264.615v-430.77Q120-723 138.5-741.5 157-760 184.615-760h199.231l80 80h311.539Q803-680 821.5-661.5 840-643 840-615.385v350.77Q840-237 821.5-218.5 803-200 775.385-200h-590.77Zm0-40h590.77q10.769 0 17.692-6.923T800-264.615v-350.77q0-10.769-6.923-17.692T775.385-640H447.769l-80-80H184.615q-10.769 0-17.692 6.923T160-695.385v430.77q0 10.769 6.923 17.692T184.615-240ZM160-240v-480 480Z" />
  </svg>
);
export default SvgFolder;
