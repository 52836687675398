import { useQuery } from "urql";

import {
  ClikContractProfileFilter,
  ClikContractProfileOrderBy,
  GetClikContractProfilesDocument,
} from "../../../../graphql/generated.ts";
import { getApiPaginationData } from "../../../../utils/api.ts";
import { ApiHookProps } from "../../types.ts";

export const useClikContractProfiles = ({
  filter,
  orderBy,
  page,
  pause = false,
}: ApiHookProps<ClikContractProfileFilter, ClikContractProfileOrderBy>) => {
  const pagination = getApiPaginationData(page);
  const variables = {
    filter,
    orderBy,
    skip: pagination?.skip,
    take: pagination?.take,
  };
  return useQuery({ query: GetClikContractProfilesDocument, variables, pause });
};
