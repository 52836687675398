import { SelectProps } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";

import { WaiveFilters } from "../../../components/TableWaive/types";

export function waiveFilter(
  form: UseFormReturnType<WaiveFilters>
): (fieldKey: keyof WaiveFilters) => SelectProps["onChange"] {
  return (fieldKey) => {
    return (fieldValue) => {
      form.setFieldValue(fieldKey, fieldValue);
    };
  };
}
