import { SelectItem } from "@mantine/core/lib/Select/types";
import { useDisclosure } from "@mantine/hooks";
import { useCallback, useMemo } from "react";

import { useCurrentUserContext } from "../../../components/Auth/CurrentUserProvider.tsx";
import MultiFieldCard from "../../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import HandoverToCUModal from "../../../components/HandoverToCUModal/HandoverToCUModal.tsx";
import {
  LimitRequestStatus,
  RolePermission,
} from "../../../graphql/generated.ts";
import { useCreateCreditUnderwriting } from "../../../hooks/api/creditUnderwriting/useCreateCreditUnderwriting.ts";
import { useCreditUnderwriting } from "../../../hooks/api/creditUnderwriting/useCreditUnderwriting.ts";
import { useUpdateCreditUnderwriting } from "../../../hooks/api/creditUnderwriting/useUpdateCreditUnderwriting.ts";
import { useHandoverLimitRequestToCU } from "../../../hooks/api/limitRequest/useHandoverLimitRequestToCU.ts";
import { useHandoverLimitRequestToCUManager } from "../../../hooks/api/limitRequest/useHandoverLimitRequestToCUManager.ts";
import { useHandoverLimitRequestToRR } from "../../../hooks/api/limitRequest/useHandoverLimitRequestToRR.ts";
import { useCUAssignees } from "../../../hooks/api/user/useCUAssignees.ts";
import { useMutationNotificationWrapper } from "../../../hooks/useMutationNotificationWrapper.tsx";
import { LimitRequestDetail } from "../../../types/limitRequest.ts";
import { getUserOptions } from "../../../utils/user.ts";
import { hasPermission } from "../../../utils/user.ts";
import LimitRequestDetailCardControl from "../LimitRequestDetailCardControl/LimitRequestDetailCardControl.tsx";
import CreditUnderwritingModal, {
  CreditUnderwritingModalSubmitValue,
} from "./CreditUnderwritingModal.tsx";
import useCreditUnderwritingCardData from "./hooks/useCreditUnderwritingCardData.tsx";
import useCreditUnderwritingCardFooter from "./hooks/useCreditUnderwritingCardFooter.tsx";

interface Props {
  limitRequest:
    | Pick<
        LimitRequestDetail,
        "id" | "creditUnderwriting" | "requestStatus" | "cuAssigned"
      >
    | null
    | undefined;
  onRefreshLimitRequest: () => void;
}

export default function CreditUnderwritingCard({
  limitRequest,
  onRefreshLimitRequest,
}: Props) {
  const id = limitRequest?.creditUnderwriting?.id;
  const [{ data: response, fetching }, refresh] = useCreditUnderwriting({
    id,
  });
  const creditUnderwriting = response?.creditUnderwriting;

  const [{ data: assigneesData }] = useCUAssignees();
  const assigneesOptions: SelectItem[] = useMemo(() => {
    return getUserOptions(assigneesData?.users.data);
  }, [assigneesData]);

  const { user } = useCurrentUserContext();

  const [editModalOpened, { open: openEditModal, close: closeEditModal }] =
    useDisclosure(false);
  const [
    handoverToCUModalOpened,
    { open: openHandoverToCUModal, close: closeHandoverToCUModal },
  ] = useDisclosure(false);

  const [, updateCreditUnderwriting] = useMutationNotificationWrapper(
    useUpdateCreditUnderwriting(),
    {
      success: {
        message: "Credit Underwriting data was successfully updated.",
      },
    }
  );

  const [, createCreditUnderwriting] = useMutationNotificationWrapper(
    useCreateCreditUnderwriting(),
    {
      success: {
        message: "Credit Underwriting data was successfully added.",
      },
    }
  );

  const [, handoverLimitRequestToRR] = useMutationNotificationWrapper(
    useHandoverLimitRequestToRR(),
    {
      success: {
        message: "Limit Request was successfully handovered to Risk Review.",
      },
    }
  );

  const [, handoverLimitRequestToCUManager] = useMutationNotificationWrapper(
    useHandoverLimitRequestToCUManager(),
    {
      success: {
        message: "Limit Request was successfully handovered to CU Manager.",
      },
    }
  );

  const [, handoverLimitRequestToCU] = useMutationNotificationWrapper(
    useHandoverLimitRequestToCU(),
    {
      success: {
        message:
          "Limit Request was successfully handovered back to CU Officer.",
      },
    }
  );

  const hasEditAccess = hasPermission(
    user,
    RolePermission.CanEditBuyerInfoLimitInfoCreditUnderwriting
  );

  const handleCreditUnderwritingSubmit = (
    assignedId: number,
    assignedDate: Date,
    input: CreditUnderwritingModalSubmitValue
  ) => {
    if (!limitRequest) {
      return;
    }

    if (id) {
      updateCreditUnderwriting({ id, assignedId, assignedDate, input })
        .then(() => onRefreshLimitRequest())
        .then(() => {
          refresh();
        });
    } else {
      createCreditUnderwriting({
        assignedId,
        assignedDate,
        input: { ...input, limitRequestId: limitRequest.id },
      })
        .then(() => onRefreshLimitRequest())
        .then(() => {
          refresh();
        });
    }
    closeEditModal();
  };

  const handleHandoverToRR = useCallback(() => {
    if (!limitRequest) {
      return;
    }
    handoverLimitRequestToRR({ id: limitRequest.id }).then((result) => {
      if (!result.error) {
        onRefreshLimitRequest();
      }
    });
  }, [handoverLimitRequestToRR, limitRequest, onRefreshLimitRequest]);

  const handleHandoverToCUManager = useCallback(() => {
    if (!limitRequest) {
      return;
    }
    handoverLimitRequestToCUManager({ id: limitRequest.id }).then((result) => {
      if (!result.error) {
        onRefreshLimitRequest();
      }
    });
  }, [handoverLimitRequestToCUManager, limitRequest, onRefreshLimitRequest]);

  const handleHandoverToCU = useCallback(
    (note: string) => {
      if (!limitRequest || !creditUnderwriting) {
        return;
      }
      handoverLimitRequestToCU({
        id: limitRequest?.id,
        input: { text: note, creditUnderwritingId: creditUnderwriting.id },
      }).then(() => {
        onRefreshLimitRequest();
      });
    },
    [
      limitRequest,
      creditUnderwriting,
      handoverLimitRequestToCU,
      onRefreshLimitRequest,
    ]
  );

  const cardInfo = useCreditUnderwritingCardData({
    limitRequest,
    creditUnderwriting,
  });

  const cardControl = useMemo(() => {
    if (!creditUnderwriting) {
      return null;
    }
    return (
      <LimitRequestDetailCardControl
        updatedBy={creditUnderwriting?.updatedBy}
        edit={
          hasEditAccess &&
          creditUnderwriting &&
          limitRequest?.requestStatus === LimitRequestStatus.OngoingCu
        }
        onEdit={openEditModal}
      />
    );
  }, [creditUnderwriting, hasEditAccess, limitRequest, openEditModal]);

  const cardFooter = useCreditUnderwritingCardFooter({
    limitRequest,
    creditUnderwriting,
    onAddData: openEditModal,
    onHandoverToRR: handleHandoverToRR,
    onHandoverToCUManager: handleHandoverToCUManager,
    onHandoverToCU: openHandoverToCUModal,
  });

  return (
    <>
      <MultiFieldCard
        label="Credit Underwriting"
        variant="blue"
        cols={3}
        loading={fetching || !limitRequest}
        items={cardInfo}
        cardControl={cardControl}
        cardFooterContent={cardFooter}
      />
      {editModalOpened && (
        <CreditUnderwritingModal
          value={creditUnderwriting}
          limitRequest={limitRequest}
          opened={true}
          assigneesOptions={assigneesOptions}
          onClose={closeEditModal}
          onSubmit={handleCreditUnderwritingSubmit}
        />
      )}
      <HandoverToCUModal
        title="Handover to CU Officer"
        opened={handoverToCUModalOpened}
        onClose={closeHandoverToCUModal}
        onSubmit={handleHandoverToCU}
      />
    </>
  );
}
