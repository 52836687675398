import { Badge, Flex } from "@mantine/core";
import { isEmpty } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import TableFilesDisplay from "../../../../components/TableFilesDisplay/TableFilesDisplay.tsx";
import { CreditApproval } from "../../../../types/creditApproval.ts";
import { LimitRequestConditionPrecedent } from "../../../../types/limitRequestConditionPrecedent.ts";
import { LimitRequestException } from "../../../../types/limitRequestException.ts";
import { toLocalDate } from "../../../../utils/date.ts";
import { toPercent, toPrice } from "../../../../utils/number.ts";
import { toHumanReadable } from "../../../../utils/string.ts";
import { useStyles } from "../CreditCommitteeCard.styles.ts";

interface Props {
  creditCommittee: Partial<CreditApproval> | null | undefined;
  exceptions: LimitRequestException[] | null | undefined;
  conditionPrecedents: LimitRequestConditionPrecedent[] | null | undefined;
}
export const useCreditCommitteeCardData = ({
  creditCommittee,
  exceptions,
  conditionPrecedents,
}: Props) => {
  const { classes } = useStyles();

  const [showAllConditionPrecedents, setShowAllConditionPrecedents] =
    useState(false);
  const [showAllExceptions, setShowAllExceptions] = useState(false);

  const generateConditionPrecedentCardItem = useCallback(
    (item: LimitRequestConditionPrecedent) => {
      return [
        {
          label: "Name",
          content: item.name,
        },
        {
          label: "Status",
          content: (
            <Badge
              variant={item.completed ? "active" : "inactive"}
              className={classes.badge}
            >
              {item.completed ? "Complete" : "Not Complete"}
            </Badge>
          ),
        },
        {
          label: "Type",
          content: toHumanReadable(item.type),
        },
        {
          label: "Due date",
          content: toLocalDate(item.dueDate),
        },
        {
          label: "Document Submission",
          content:
            item.documents &&
            (item.documents.length > 1 ? (
              <TableFilesDisplay files={item.documents} />
            ) : (
              <Link to={item.documents[0].url} target="_blank">
                Download
              </Link>
            )),
        },
        {
          label: "Notes",
          content: item.notes,
        },
      ];
    },
    [classes.badge]
  );

  const generateExceptionCardItem = useCallback(
    (item: LimitRequestException) => {
      return [
        {
          label: "Name",
          content: item.name,
        },
        {
          label: "Status",
          content: (
            <Badge
              variant={item.completed ? "active" : "inactive"}
              className={classes.badge}
            >
              {item.completed ? "Complete" : "Not Complete"}
            </Badge>
          ),
        },
        {
          label: "Document Submission",
          content:
            item.documents &&
            (item.documents.length > 1 ? (
              <TableFilesDisplay files={item.documents} />
            ) : (
              <Link to={item.documents[0].url} target="_blank">
                Download
              </Link>
            )),
        },
        {
          label: "Notes",
          content: item.notes,
        },
      ];
    },
    [classes.badge]
  );

  return useMemo(() => {
    return [
      {
        label: "CC date",
        content: toLocalDate(creditCommittee?.date),
      },
      {
        label: "CC result",
        content: toHumanReadable(creditCommittee?.result),
      },
      {
        label: "Limit approved",
        content: toPrice(creditCommittee?.limitRecommendation),
      },
      {
        label: "Tenor approved",
        content: creditCommittee?.tenorRecommendation,
      },
      {
        label: "Pricing approved",
        content: toPercent(
          creditCommittee?.pricingRecommendation,
          "percentage"
        ),
      },
      {
        label: "Condition precedence",
        content: !isEmpty(conditionPrecedents) ? (
          <Flex
            className={classes.expand}
            onClick={() =>
              setShowAllConditionPrecedents(!showAllConditionPrecedents)
            }
          >
            {showAllConditionPrecedents
              ? `Hide (${conditionPrecedents?.length})`
              : `Show All (${conditionPrecedents?.length})`}
          </Flex>
        ) : undefined,
        openNestedContent: showAllConditionPrecedents,
        nestedContent: conditionPrecedents
          ? conditionPrecedents.map(generateConditionPrecedentCardItem)
          : undefined,
      },
      {
        label: "Exception",
        content: !isEmpty(exceptions) ? (
          <Flex
            className={classes.expand}
            onClick={() => setShowAllExceptions(!showAllExceptions)}
          >
            {showAllExceptions
              ? `Hide (${exceptions?.length})`
              : `Show All (${exceptions?.length})`}
          </Flex>
        ) : undefined,
        openNestedContent: showAllExceptions,
        nestedContent: exceptions
          ? exceptions.map(generateExceptionCardItem)
          : undefined,
      },
      {
        label: "Notes",
        content: creditCommittee?.notes,
      },
    ];
  }, [
    creditCommittee,
    classes.expand,
    showAllConditionPrecedents,
    conditionPrecedents,
    generateConditionPrecedentCardItem,
    showAllExceptions,
    exceptions,
    generateExceptionCardItem,
  ]);
};
