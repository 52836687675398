import { Button } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useParams } from "react-router-dom";

import { useInvoices } from "../../../hooks/api/invoice/useInvoices";
import SvgPlus from "../../Icons/Plus";
import WaiveAddModal, {
  WaiveAddModalProps,
} from "../WaiveAddModal/WaiveAddModal";

export default function WaiveToolbar({
  onSubmit,
}: Pick<WaiveAddModalProps, "onSubmit">) {
  const { customerId } = useParams();

  const [{ data: invoicesData }] = useInvoices({
    filter: { customerId: { equals: Number(customerId) } },
  });

  const [modalOpened, { open: openModal, close: closeModal }] =
    useDisclosure(false);

  return (
    <>
      <Button
        uppercase
        variant="filledGolden"
        leftIcon={<SvgPlus />}
        onClick={openModal}
      >
        Add
      </Button>

      <WaiveAddModal
        invoices={invoicesData?.invoices?.data || []}
        opened={modalOpened}
        onClose={closeModal}
        onSubmit={onSubmit}
      />
    </>
  );
}
