import { createStyles, rem } from "@mantine/core";

export const useStyles = createStyles((theme) => ({
  contactInfoText: {
    fontSize: rem(14),
    fontWeight: 500,
    lineHeight: 1.3,
    color: theme.colors.midnightBlue[0],

    "&:hover": {
      textDecoration: "none",
    },
  },
}));
