import { Flex } from "@mantine/core";
import { DataTableSortStatus } from "mantine-datatable";
import { useCallback, useState } from "react";

import SectionTitle from "../../../../../components/Section/SectionTitle/SectionTitle.tsx";
import {
  ClikGrantedGuaranteeOrderBy,
  ContractType,
  ContractTypeFilterOptions,
  OrderBy,
} from "../../../../../graphql/generated.ts";
import { useClikGrantedDetailsOfGuarantees } from "../../../../../hooks/api/clikResult/clikContract/useClikGrantedDetailsOfGuarantees.ts";
import { useClikGrantedGuarantees } from "../../../../../hooks/api/clikResult/clikContract/useClikGrantedGuarantees.ts";
import { useClikListOfGrantedGuarantees } from "../../../../../hooks/api/clikResult/clikContract/useClikListOfGrantedGuarantees.ts";
import {
  ClikGrantedContract,
  ClikGrantedContractDetails,
} from "../../../../../types/clikContract.ts";
import ClikResultDetailsOfContract from "../ClikResultDetailsOfContract/ClikResultDetailsOfContract.tsx";
import ClikGrantedContracts from "../ClikResultGrantedContracts/ClikGrantedContracts.tsx";
import ClikResultNotGrantedContracts from "../ClikResultNotGrantedContracts/ClikResultNotGrantedContracts.tsx";
import { getContractDetailsOptions } from "../contants.ts";
import { getGrantedContractsApiSort } from "../utils.ts";

interface ClikResultBondContractsProps {
  clikResultId: number;
}

const ClikResultGuaranteeContracts = ({
  clikResultId,
}: ClikResultBondContractsProps) => {
  const [page, setPage] = useState<number>(1);
  const [orderBy, setOrderBy] = useState<ClikGrantedGuaranteeOrderBy>({
    id: OrderBy.Asc,
  });
  const [selectedContractId, setSelectedContractId] = useState<number | null>();

  const [
    { data: grantedGuaranteesResponse, fetching: grantedGuaranteesFetching },
  ] = useClikGrantedGuarantees({
    filter: { clikResultId: { equals: clikResultId } },
    orderBy,
    page,
  });

  const [
    {
      data: listOfGrantedGuaranteesResponse,
      fetching: listOfGrantedGuaranteesFetching,
    },
  ] = useClikListOfGrantedGuarantees({ clikResultId });

  const [
    {
      data: grantedDetailsOfGuaranteeResponse,
      fetching: grantedDetailsOfGuaranteeFetching,
    },
  ] = useClikGrantedDetailsOfGuarantees({ id: selectedContractId });

  const grantedGuaranteesData = grantedGuaranteesResponse?.clikGrantedGuarantees
    ?.data as ClikGrantedContract[];
  const grantedGuaranteesCount =
    grantedGuaranteesResponse?.clikGrantedGuarantees?.total ?? 0;

  const listOfGrantedGuaranteeData =
    listOfGrantedGuaranteesResponse?.clikGrantedGuarantees?.data;

  const grantedDetailsOfGuaranteeData =
    grantedDetailsOfGuaranteeResponse?.clikGrantedGuaranteeDetails;

  const handleOnSortChange = useCallback(
    (sort: DataTableSortStatus) => {
      setOrderBy(getGrantedContractsApiSort(sort));
    },
    [setOrderBy]
  );

  return (
    <Flex gap={12} direction="column">
      <SectionTitle variant="bronze">
        Contract Details - Bank Guarantee
      </SectionTitle>
      <Flex gap={20} direction="column">
        <ClikResultNotGrantedContracts
          clikResultId={clikResultId}
          type={ContractTypeFilterOptions.Guarantee}
        />
        <ClikGrantedContracts
          contracts={grantedGuaranteesData}
          total={grantedGuaranteesCount}
          loading={grantedGuaranteesFetching}
          type={ContractType.Guarantee}
          page={page}
          onSetPage={setPage}
          onSortChange={handleOnSortChange}
        />
        <ClikResultDetailsOfContract
          type={ContractType.Guarantee}
          data={grantedDetailsOfGuaranteeData as ClikGrantedContractDetails}
          loading={
            grantedDetailsOfGuaranteeFetching || listOfGrantedGuaranteesFetching
          }
          clikContractProfileFilter={
            selectedContractId
              ? {
                  grantedGuaranteeId: { equals: selectedContractId },
                }
              : undefined
          }
          detailsOptions={getContractDetailsOptions(
            listOfGrantedGuaranteeData,
            ContractType.Guarantee
          )}
          selectedContractId={selectedContractId}
          onSetSelectedContractId={setSelectedContractId}
        />
      </Flex>
    </Flex>
  );
};

export default ClikResultGuaranteeContracts;
