import { Button, Flex, Modal, ScrollArea, Textarea } from "@mantine/core";
import { isNotEmpty, useForm } from "@mantine/form";
import { useCallback } from "react";

interface Props {
  title?: string;
  buttonTitle?: string;
  opened: boolean;
  onClose: () => void;
  onSubmit: (value: string) => void;
}

const HandoverToCUModal = ({
  title,
  buttonTitle = "handover",
  opened,
  onClose,
  onSubmit,
}: Props) => {
  const form = useForm({
    initialValues: {
      note: undefined,
    },
    validate: {
      note: isNotEmpty("Note is required"),
    },
    validateInputOnBlur: true,
  });

  const handleClose = useCallback(() => {
    form.reset();
    onClose();
  }, [form, onClose]);

  const handleSubmit = useCallback(() => {
    if (!form.values.note) {
      return;
    }
    onSubmit(form.values.note);
    handleClose();
  }, [form, handleClose, onSubmit]);

  return (
    <Modal
      opened={opened}
      closeButtonProps={{
        size: 24,
        iconSize: 24,
      }}
      title={title ? title : "Handover to CU"}
      scrollAreaComponent={ScrollArea.Autosize}
      size="lg"
      onClose={handleClose}
    >
      <Flex direction="column" gap={20}>
        <Textarea
          label="Notes"
          placeholder="Enter Notes"
          autosize
          size="m"
          minRows={3}
          maxRows={3}
          {...form.getInputProps("note")}
        />
        <Flex gap={20} justify="flex-end">
          <Button variant="outlineBlue" uppercase onClick={handleClose}>
            cancel
          </Button>
          <Button
            variant="filledBlue"
            disabled={!form.isValid()}
            uppercase
            onClick={handleSubmit}
          >
            {buttonTitle}
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};

export default HandoverToCUModal;
