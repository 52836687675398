import { Button } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { DataTableSortStatus } from "mantine-datatable";
import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";

import {
  ApproveRestructureInput,
  CreateRestructureMutationVariables,
  OrderBy,
  RestructureFilter,
  RestructureOrderBy,
} from "../../graphql/generated";
import { useApproveRestructure } from "../../hooks/api/restructure/useApproveRestructure.ts";
import { useCreateRestructure } from "../../hooks/api/restructure/useCreateRestructure";
import { useRestructure } from "../../hooks/api/restructure/useRestructure";
import { useMutationNotificationWrapper } from "../../hooks/useMutationNotificationWrapper";
import { Restructure } from "../../types/restructure";
import { getPagesCount } from "../../utils/pagination";
import SvgPlus from "../Icons/Plus";
import Table from "../Table/Table";
import { SortDirection, TableRow } from "../Table/types";
import EditRestructureModal from "./EditRestructureModal/EditRestructureModal.tsx";
import { useTableRestructureData } from "./hooks/useTableRestructureData";
import { useStyles } from "./TableRestructure.styles";
import { getRestructureApiSort } from "./utils";

interface Props {
  withToolbar?: boolean;
  filter?: RestructureFilter;
  canAdd?: boolean;
  canEdit?: boolean;
  onSelectRow?: (record: Restructure) => void;
}

export default function TableRestructure({
  withToolbar,
  filter,
  canAdd,
  canEdit,
  onSelectRow,
}: Props) {
  const { classes } = useStyles();

  const [selectedRestructure, setSelectedRestructure] = useState<
    Restructure | null | undefined
  >();

  const { customerId } = useParams();

  const [
    editRestructuredModalOpened,
    { open: openEditRestructureModal, close: closeEditRestructureModal },
  ] = useDisclosure(false);

  const showToolbar = withToolbar && canAdd;

  const [orderBy, setOrderBy] = useState<RestructureOrderBy>({
    createdAt: OrderBy.Desc,
  });

  const [page, setPage] = useState<number>(1);

  const [
    { data: restructureData, fetching: restructureFetching },
    refetchRestructure,
  ] = useRestructure({
    orderBy,
    filter,
    page,
  });

  const [, approveRestructure] = useMutationNotificationWrapper(
    useApproveRestructure(),
    {
      success: {
        message: "Restructure Request has been updated",
      },
    }
  );

  const restructures = restructureData?.restructures?.data;

  const pageCount = getPagesCount(restructureData?.restructures?.total);

  const [{ fetching: createRestructureFetching }, createRestructure] =
    useMutationNotificationWrapper(useCreateRestructure(), {
      success: { message: "Restructure Request has been added." },
    });

  const handleOpenEditRestructureModal = useCallback(
    (id: number) => {
      const restructure = restructures?.find((item) => item.id === id);
      if (!restructure) {
        return;
      }
      setSelectedRestructure(restructure);
      openEditRestructureModal();
    },
    [openEditRestructureModal, restructures]
  );

  const handleCloseEditRestructureModal = useCallback(() => {
    closeEditRestructureModal();
    setSelectedRestructure(null);
  }, [closeEditRestructureModal]);

  const handleApproveRestructure = useCallback(
    (value: ApproveRestructureInput) => {
      if (!selectedRestructure) {
        return;
      }
      approveRestructure({ id: selectedRestructure?.id, input: value }).then(
        (result) => {
          if (!result.error) {
            refetchRestructure();
          }
        }
      );
      handleCloseEditRestructureModal();
    },
    [
      approveRestructure,
      handleCloseEditRestructureModal,
      refetchRestructure,
      selectedRestructure,
    ]
  );

  const [columns, rows] = useTableRestructureData({
    data: restructures,
    canEdit,
    onEdit: handleOpenEditRestructureModal,
  });

  const handleOnSortChange = useCallback(
    (sort: DataTableSortStatus) => {
      setOrderBy(getRestructureApiSort(sort));
    },
    [setOrderBy]
  );

  const handlePageChange = useCallback(
    (page: number) => {
      setPage(page);
    },
    [setPage]
  );

  const handleCreate = () => {
    const variables: CreateRestructureMutationVariables = {
      input: { customerId: Number(customerId) },
    };
    createRestructure(variables).then((data) => {
      if (!data.error) {
        refetchRestructure();
      }
    });
  };

  const handleRowClick = (row: TableRow) => {
    const record = restructures?.find((record) => record.id === row?.id);
    if (!record) {
      return;
    }
    onSelectRow && onSelectRow(record);
  };

  return (
    <>
      <div className={classes.wrapper}>
        {showToolbar && (
          <Button
            uppercase
            variant="filledGolden"
            leftIcon={<SvgPlus />}
            onClick={handleCreate}
            loading={createRestructureFetching}
          >
            Add
          </Button>
        )}
        <Table
          minWidth="100%"
          columns={columns}
          rows={rows}
          pagination={{
            pageCount: pageCount,
            page: page,
            onPageChange: handlePageChange,
          }}
          defaultSort={{
            columnAccessor: "createdAt",
            direction: SortDirection.desc,
          }}
          loading={restructureFetching}
          onSortChange={handleOnSortChange}
          onRowClick={handleRowClick}
        />
      </div>
      {selectedRestructure && (
        <EditRestructureModal
          opened={editRestructuredModalOpened}
          value={selectedRestructure}
          onClose={handleCloseEditRestructureModal}
          onSubmit={handleApproveRestructure}
        />
      )}
    </>
  );
}
