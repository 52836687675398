import { createStyles, rem } from "@mantine/core";

export const useStyles = createStyles(() => ({
  wrapper: {
    width: "100%",
  },
  title: {
    "@media (max-width: 48em)": {
      fontSize: rem(24),
    },
  },
  filterButton: {
    "@media (max-width: 48em)": {
      width: "100%",
    },
  },
}));
