import { useQuery } from "urql";

import { GetCreditUnderwritingDocument } from "../../../graphql/generated.ts";

interface Props {
  id: number | null | undefined;
}

export function useCreditUnderwriting({ id }: Props) {
  return useQuery({
    query: GetCreditUnderwritingDocument,
    variables: { id: Number(id) },
    pause: !id,
  });
}
