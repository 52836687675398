import { useMemo } from "react";

import { MultiFieldCardProps } from "../../../../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import { ClikCompanyShareholder } from "../../../../../graphql/generated.ts";
import { getCRDisplayAddress } from "../../utils.ts";

interface Props {
  data: ClikCompanyShareholder[] | null | undefined;
}

export const useCRCompanyShareholderCardsInfo = ({
  data,
}: Props): MultiFieldCardProps[] | null => {
  return useMemo(() => {
    if (!data) {
      return null;
    }

    return data?.map((item, index) => ({
      label: `ShareHolder Data  ${index + 1}: ${item.name.toUpperCase()}`,
      variant: "bronze",
      items: [
        {
          label: "Gender",
          content: item.gender,
        },
        {
          label: "Share of Ownership",
          content: item.share,
        },
        {
          label: "Position",
          content: item.position,
        },
        {
          label: "Mandate Status",
          content: item.mandateStatus,
        },
        {
          label: "Identity Type",
          content: item.indetification?.type,
        },
        {
          label: "Identity Number",
          content: item.indetification?.number,
        },
        {
          label: "Address",
          content: getCRDisplayAddress(item.address),
        },
      ],
    }));
  }, [data]);
};
