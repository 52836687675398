import {
  Button,
  Flex,
  Input,
  Modal,
  NumberInput,
  ScrollArea,
  Select,
  Textarea,
  TextInput,
} from "@mantine/core";
import { isNotEmpty, useForm } from "@mantine/form";
import { isEmpty } from "lodash";
import { useCallback } from "react";

import {
  ApproveWaiveInput,
  ApproveWaiveMutationVariables,
  FileResult,
  WaiveApprovalStatus,
} from "../../../graphql/generated";
import { approvalStatusOptions } from "../../../pages/Review/WaiveReview/constants.ts";
import { Waive } from "../../../types/waive";
import {
  formatToNumber,
  parseStringToNumber,
  priceFormatter,
} from "../../../utils/number.ts";
import Dropzone from "../../Dropzone/Dropzone";

interface Props {
  opened: boolean;
  value: Waive;
  onClose: () => void;
  onSubmit: (input: ApproveWaiveMutationVariables) => void;
}

export default function WaiveApproveModal({
  opened = false,
  value,
  onClose,
  onSubmit,
}: Props) {
  const form = useForm<Partial<Waive>>({
    initialValues: {
      amount: formatToNumber(value.amount),
      approvalStatus: value.approvalStatus,
      approvalDocument: value.approvalDocument,
      notes: value.notes,
    },
    validate: {
      amount: isNotEmpty("Field is required"),
      approvalStatus: isNotEmpty("Field is required"),
      approvalDocument: (value) => approvalDocumentValidate(value),
    },
    validateInputOnBlur: true,
  });

  const isRejected =
    form.values.approvalStatus === WaiveApprovalStatus.Rejected;

  const approvalDocumentValidate = (value?: FileResult | null) => {
    if (isRejected) {
      return null;
    }
    if (isEmpty(value)) {
      return "Field is required";
    }
  };

  const handleClose = useCallback(() => {
    onClose();
    form.reset();
  }, [form, onClose]);

  const handleSubmit = useCallback(() => {
    onSubmit({
      id: value.id,
      input: {
        ...form.values,
        approvalDocument: form.values.approvalDocument?.filePath,
      } as ApproveWaiveInput,
    });
    handleClose();
  }, [onSubmit, value.id, form.values, handleClose]);

  const handleFileUploaded = (files: FileResult[]) => {
    form.setValues({ approvalDocument: files[0] });
  };

  const handleRemove = () => {
    form.setValues({ approvalDocument: undefined });
  };

  return (
    <>
      <Modal
        opened={opened}
        title="Edit Waive Request"
        scrollAreaComponent={ScrollArea.Autosize}
        closeButtonProps={{
          size: 24,
          iconSize: 24,
        }}
        onClose={handleClose}
      >
        <Flex direction="column" gap={20}>
          <TextInput
            size="m"
            disabled
            label="Invoice Number"
            placeholder="Select Invoice Number"
            value={value?.invoice?.invoiceNumber}
          />

          <NumberInput
            required
            precision={2}
            parser={parseStringToNumber}
            formatter={priceFormatter}
            hideControls
            label="Waive Amount"
            placeholder="Enter Waive Amount"
            size="m"
            {...form.getInputProps("amount")}
          />

          <Select
            required
            label="Approval Status"
            placeholder="Enter Approval Status"
            size="m"
            data={approvalStatusOptions}
            {...form.getInputProps("approvalStatus")}
          />

          <Flex direction="column" gap={2}>
            <Input.Label required={!isRejected}>Approval Document</Input.Label>
            <Dropzone
              maxFiles={1}
              value={
                form.values?.approvalDocument
                  ? [form.values?.approvalDocument]
                  : null
              }
              onUploadSuccess={handleFileUploaded}
              onRemove={handleRemove}
            />
          </Flex>

          <Textarea
            label="Notes"
            placeholder="Enter Notes"
            autosize
            size="m"
            minRows={3}
            maxRows={3}
            {...form.getInputProps("notes")}
          />

          <Flex gap={20} justify="end">
            <Button
              variant="outlineBlue"
              uppercase
              type="reset"
              onClick={onClose}
            >
              cancel
            </Button>
            <Button
              variant="filledBlue"
              uppercase
              type="submit"
              disabled={!form.isValid()}
              onClick={handleSubmit}
            >
              Save
            </Button>
          </Flex>
        </Flex>
      </Modal>
    </>
  );
}
