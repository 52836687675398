import { SelectItem } from "@mantine/core/lib/Select/types";

import { RolePermission } from "../graphql/generated";
import { User } from "../types/user.ts";

export const getUserDisplayName = (
  user: User | Pick<User, "id" | "name" | "email"> | undefined | null
): string | null => {
  if (!user) {
    return null;
  }
  return user.name ?? user.email;
};

export const hasPermission = (
  user: User | undefined | null,
  permission: RolePermission
): boolean => {
  return user?.permissions?.includes(permission) ?? false;
};

export const getUserOptions = (
  data: Pick<User, "id" | "email" | "name">[] | null | undefined
): SelectItem[] => {
  return (
    data?.map((user) => ({
      value: String(user.id),
      label: user.name ?? user.email,
    })) ?? []
  );
};
