import { DataTableColumn } from "mantine-datatable";
import { useMemo } from "react";

import { TableData, TableRow } from "../../../../../components/Table/types.ts";
import { ContractType } from "../../../../../graphql/generated.ts";
import { ClikContractSummary } from "../../../../../types/clikResult.ts";
import { toHumanReadable } from "../../../../../utils/string.ts";

interface Props {
  data: ClikContractSummary[] | null | undefined;
}

export function useCRContractsSummary({ data }: Props): TableData {
  const columns: DataTableColumn<TableRow>[] = [
    {
      accessor: "id",
      hidden: true,
    },
    {
      accessor: "type",
      title: "Contract Category",
      width: "200px",
    },
    {
      accessor: "requested",
      title: "Requested",
      width: "100px",
    },
    {
      accessor: "refused",
      title: "Refused",
      width: "100px",
    },
    {
      accessor: "renounced",
      title: "Renounced",
      width: "100px",
    },
    {
      accessor: "active",
      title: "Active",
      width: "100px",
    },
    {
      accessor: "closed",
      title: "Closed",
      width: "100px",
    },
  ];

  const renderData = data?.length
    ? Object.values(ContractType).map((item, index) => {
        return (
          data.find((res) => res.type === item) ?? {
            id: index,
            type: item,
            requested: null,
            refused: null,
            renounced: null,
            active: null,
            closed: null,
          }
        );
      })
    : data;

  const rows = useMemo(() => {
    return renderData?.map((item) => ({
      id: item?.id,
      type: item?.type && toHumanReadable(item.type),
      requested: item?.requested,
      refused: item?.refused,
      renounced: item?.renounced,
      active: item?.active,
      closed: item?.closed,
    }));
  }, [renderData]);

  return [columns, rows];
}
