import type { SVGProps } from "react";

const SvgFile = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.5 13.8889V14.594C9.5 14.6994 9.53717 14.7935 9.61152 14.8761C9.68589 14.9587 9.77051 15 9.86538 15H10.0962C10.2039 15 10.2981 14.9551 10.3788 14.8654C10.4596 14.7756 10.5 14.6709 10.5 14.5513V13.8889H12C12.1423 13.8889 12.2612 13.8358 12.3567 13.7297C12.4522 13.6236 12.5 13.4915 12.5 13.3333V10C12.5 9.84187 12.4522 9.70975 12.3567 9.60364C12.2612 9.49751 12.1423 9.44444 12 9.44444H8.5V7.22222H12C12.1423 7.22222 12.2612 7.16916 12.3567 7.06303C12.4522 6.95692 12.5 6.8248 12.5 6.66667C12.5 6.50854 12.4522 6.37642 12.3567 6.27031C12.2612 6.16418 12.1423 6.11111 12 6.11111H10.5V5.40597C10.5 5.30056 10.4628 5.20655 10.3885 5.12392C10.3141 5.04131 10.2295 5 10.1346 5H9.90385C9.79615 5 9.70192 5.04487 9.62115 5.13461C9.54038 5.22435 9.5 5.32905 9.5 5.44872V6.11111H8C7.85768 6.11111 7.73877 6.16418 7.64327 6.27031C7.54776 6.37642 7.5 6.50854 7.5 6.66667V10C7.5 10.1581 7.54776 10.2902 7.64327 10.3964C7.73877 10.5025 7.85768 10.5556 8 10.5556H11.5V12.7778H8C7.85768 12.7778 7.73877 12.8308 7.64327 12.937C7.54776 13.0431 7.5 13.1752 7.5 13.3333C7.5 13.4915 7.54776 13.6236 7.64327 13.7297C7.73877 13.8358 7.85768 13.8889 8 13.8889H9.5ZM1.61537 20C1.15512 20 0.770833 19.8287 0.4625 19.4861C0.154167 19.1435 0 18.7165 0 18.2051V1.79486C0 1.28347 0.154167 0.856482 0.4625 0.513889C0.770833 0.171296 1.15512 0 1.61537 0H13.9058C14.1212 0 14.3305 0.0484357 14.5337 0.145306C14.7369 0.242158 14.9109 0.371074 15.0558 0.532055L19.5212 5.49358C19.666 5.65455 19.7821 5.84793 19.8692 6.07372C19.9564 6.2995 20 6.53205 20 6.77136V18.2051C20 18.7165 19.8458 19.1435 19.5375 19.4861C19.2292 19.8287 18.8449 20 18.3846 20H1.61537ZM1.61537 18.8889H18.3846C18.5385 18.8889 18.6795 18.8177 18.8077 18.6752C18.9359 18.5328 19 18.3761 19 18.2051V6.5L14.15 1.11111H1.61537C1.46154 1.11111 1.32052 1.18233 1.1923 1.32478C1.0641 1.46724 1 1.62393 1 1.79486V18.2051C1 18.3761 1.0641 18.5328 1.1923 18.6752C1.32052 18.8177 1.46154 18.8889 1.61537 18.8889Z"
      fill="currentColor"
    />
  </svg>
);

export default SvgFile;
