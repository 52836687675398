import { sortBy } from "lodash";

import { GiroStatus, GiroType } from "../../../../graphql/generated.ts";
import { toHumanReadable } from "../../../../utils/string.ts";

export const giroTypeOptions = sortBy(
  Object.values(GiroType).map((value) => {
    let label = toHumanReadable(value).toUpperCase();
    if (value === GiroType.Giro) {
      label = "GIRO Transaksi";
    }
    if (value === GiroType.GiroCollateral) {
      label = "GIRO Collateral";
    }
    return { value, label };
  }),
  "label"
);

export const giroStatusOptions = Object.values(GiroStatus).map((value) => {
  return { value, label: toHumanReadable(value) };
});

export const accountNoMatchedOptions = [
  { value: "true", label: "Yes" },
  { value: "false", label: "No" },
];
