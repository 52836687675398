import { createStyles, rem } from "@mantine/core";

export const useStyles = createStyles(() => ({
  titleWrapper: {
    "@media (max-width: 48em)": {
      fontSize: rem(24),
      lineHeight: 1.2,
    },
  },
  columnsWrapper: {
    "@media (max-width: 48em)": {
      gridTemplateColumns: "repeat(1, minmax(0, 1fr))",
    },
  },
}));
