import { Badge, Button, Flex } from "@mantine/core";
import { useMemo } from "react";

import SvgCheck from "../../../../components/Icons/Check.tsx";
import SvgPlus from "../../../../components/Icons/Plus.tsx";
import { LimitRequestStatus } from "../../../../graphql/generated.ts";
import { LimitRequest } from "../../../../types/limitRequest.ts";
import { OfferLetter } from "../../../../types/offerLetter.ts";

interface Props {
  limitRequest: Pick<LimitRequest, "requestStatus"> | null | undefined;
  canEdit: boolean;
  offeringLetter: OfferLetter | null | undefined;
  onAddData: () => void;
  onActivateLimit: () => void;
}

export const useOfferingLetterCardFooter = ({
  limitRequest,
  canEdit,
  onActivateLimit,
  offeringLetter,
  onAddData,
}: Props) => {
  const content = useMemo(() => {
    if (!offeringLetter) {
      return (
        <Button
          variant="filledGolden"
          leftIcon={<SvgPlus />}
          w="100%"
          uppercase
          disabled={
            limitRequest?.requestStatus !== LimitRequestStatus.Ol || !canEdit
          }
          onClick={onAddData}
        >
          Add Data
        </Button>
      );
    }

    if (limitRequest?.requestStatus !== LimitRequestStatus.Approved) {
      return (
        <Button
          variant="filledGolden"
          leftIcon={<SvgCheck />}
          w="100%"
          uppercase
          disabled={!canEdit}
          onClick={onActivateLimit}
        >
          Activate limit
        </Button>
      );
    }

    return (
      <Badge size="m" variant="active" w="100%" leftSection={<SvgCheck />}>
        Activated limit
      </Badge>
    );
  }, [
    offeringLetter,
    canEdit,
    limitRequest?.requestStatus,
    onActivateLimit,
    onAddData,
  ]);

  return (
    <Flex
      gap={20}
      w="100%"
      h="100%"
      justify="flex-end"
      align="end"
      direction="row"
    >
      {content}
    </Flex>
  );
};
