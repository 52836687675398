import { useMemo } from "react";
import { Link } from "react-router-dom";

import { OfferLetter } from "../../../../types/offerLetter.ts";
import { toLocalDate } from "../../../../utils/date.ts";
import { toHumanReadable } from "../../../../utils/string.ts";

interface Props {
  offeringLetter: OfferLetter | null | undefined;
}

export const useOfferingLetterCardData = ({ offeringLetter }: Props) => {
  return useMemo(() => {
    return [
      {
        label: "Ol No",
        content: offeringLetter?.olNo,
      },
      {
        label: "OL file",
        content: offeringLetter?.offerLetter && (
          <Link
            to={offeringLetter.offerLetter?.url}
            target="_blank"
            rel="noreferrer"
          >
            Download
          </Link>
        ),
      },
      {
        label: "OL signing date",
        content: toLocalDate(offeringLetter?.olSignedDate),
      },
      {
        label: "Signed OL",
        content: offeringLetter?.olSigned && (
          <Link
            to={offeringLetter.olSigned?.url}
            target="_blank"
            rel="noreferrer"
          >
            Download
          </Link>
        ),
      },
      {
        label: "OL expired date",
        content: toLocalDate(offeringLetter?.olExpiredDate),
      },
      {
        label: "OL Status",
        content: toHumanReadable(offeringLetter?.olStatus),
      },
    ];
  }, [offeringLetter]);
};
