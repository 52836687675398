import { Flex, MultiSelect, SimpleGrid } from "@mantine/core";
import { uniq } from "lodash";
import { useMemo, useState } from "react";

import SvgUnfold from "../../components/Icons/Unfold";
import Layout from "../../components/Layout/Layout";
import Title from "../../components/Title/Title";
import { useCustomers } from "../../hooks/api/customer/useCustomers";
import { useStyles } from "./CollectionDashboardPage.styles";
import CustomerPayPastDue from "./CustomerPayPastDue/CustomerPayPastDue.tsx";
import CustomersLongestDPD from "./CustomersLongestDPD/CustomersLongestDPD.tsx";
import CustomersNextDue from "./CustomersNextDue/CustomersNextDue.tsx";
import DashboardStatisticCards from "./DashboardStatisticCards/DashboardStatisticCards.tsx";
import NextMonthDuePerSalesTeam from "./NextMonthDuePerSalesTeam/NextMonthDuePerSalesTeam.tsx";
import NextPaymentDuePerMonth from "./NextPaymentDuePerMonth/NextPaymentDuePerMonth.tsx";
import OutstandingPrincipalAccumulative from "./OutstandingPrincipalAccumulative/OutstandingPrincipalAccumulative.tsx";
import OutstandingPrincipalPerBucket from "./OutstandingPrincipalPerBucket/OutstandingPrincipalPerBucket.tsx";

export default function CollectionDashboardPage() {
  const { classes } = useStyles();

  const [filter, setFilter] = useState<string[]>([]);

  const [{ data: customersData }] = useCustomers({});

  const salesTeamOptions = useMemo(() => {
    return uniq(
      customersData?.customers
        ?.map((customer) => customer.salesTeamName || "")
        ?.filter(Boolean)
    );
  }, [customersData?.customers]);

  return (
    <Layout>
      <Flex direction="column" gap={{ base: 16, sm: 24 }}>
        <Title>Collection Dashboard</Title>

        <SimpleGrid
          cols={3}
          spacing={20}
          verticalSpacing={8}
          className={classes.grid}
        >
          <MultiSelect
            size="m"
            searchable
            clearable
            multiple
            label="Sales Team"
            placeholder="Select Sales Team"
            data={salesTeamOptions}
            rightSection={<SvgUnfold />}
            value={filter || []}
            onChange={setFilter}
          />
        </SimpleGrid>

        <DashboardStatisticCards filter={filter} />

        <OutstandingPrincipalPerBucket filter={filter} />

        <OutstandingPrincipalAccumulative filter={filter} />

        <SimpleGrid
          cols={2}
          className={classes.next}
          spacing={20}
          verticalSpacing={16}
        >
          <NextPaymentDuePerMonth filter={filter} />

          <NextMonthDuePerSalesTeam filter={filter} />
        </SimpleGrid>

        <CustomersNextDue salesTeamName={filter} />

        <CustomersLongestDPD filter={filter} />

        <CustomerPayPastDue salesTeamName={filter} />
      </Flex>
    </Layout>
  );
}
