import {
  Flex,
  Input,
  NumberInput,
  Select,
  SimpleGrid,
  TextInput,
} from "@mantine/core";
import { isNotEmpty, matches, useForm } from "@mantine/form";
import { FormValidateInput } from "@mantine/form/lib/types";
import { useEffect, useMemo } from "react";

import {
  ClikCity,
  ClikCountry,
  FileResult,
  LimitRequestCategory,
} from "../../graphql/generated.ts";
import {
  limitRequestCategoryOptions,
  LimitRequestCompany,
  RawLimitRequest,
} from "../../types/limitRequest.ts";
import {
  getClickCity,
  getClikCityOptions,
  getClikCountry,
  getClikCountryOptions,
} from "../../utils/clikData.ts";
import { getCompanyOptions } from "../../utils/company.ts";
import { parseStringToNumber, priceFormatter } from "../../utils/number.ts";
import { phoneMatcher } from "../../utils/phone.ts";
import Dropzone from "../Dropzone/Dropzone.tsx";
import SectionTitle from "../Section/SectionTitle/SectionTitle.tsx";
import { limitRequestTypeOptions } from "./constants.ts";

interface LimitRequestInfoForm {
  disabledFields?: string[];
  value: RawLimitRequest | null;
  cities: ClikCity[] | null | undefined;
  countries: ClikCountry[] | null | undefined;
  companies: LimitRequestCompany[] | null | undefined;
  onValueChange: (value: RawLimitRequest, valid: boolean) => void;
}

const LimitRequestInfoForm = ({
  disabledFields,
  value,
  cities,
  countries,
  companies,
  onValueChange,
}: LimitRequestInfoForm) => {
  const validationRules = useMemo(() => {
    let rules: FormValidateInput<Record<string, unknown>> = {
      company: isNotEmpty("Company Name is required"),
      category: isNotEmpty("Category is required"),
      bussinessIdentifyNumber: isNotEmpty(
        "Business Identity Number is required"
      ),
      phone: phoneMatcher,
      npwpCompany: isNotEmpty("NPWP Company is required"),
      npwpAddress: isNotEmpty("NPWP Address is required"),
      subDistrict: isNotEmpty("Sub-district is required"),
      district: isNotEmpty("District is required"),
      city: isNotEmpty("City is required"),
      postalCode: matches(
        /^\d{5}$/,
        "The postal code must consist of a maximum of 5 characters. It can include only numbers"
      ),
      country: isNotEmpty("Country is required"),
      limitRequestAmount: isNotEmpty("Limit Request is required"),
    };
    if (value?.category === LimitRequestCategory.LimitRequest) {
      rules = {
        ...rules,
        tenorRequest: isNotEmpty("Tenor Request is required"),
        suratPengajuanLimit: isNotEmpty("Surat Pengajuan Limit is required"),
        formLOI: isNotEmpty("Form LOI is required"),
        limitRequestType: isNotEmpty("Limit Request Type is required"),
      };
    }
    return rules;
  }, [value?.category]);
  const form = useForm({
    initialValues: {
      company: value?.company,
      category: value?.category || LimitRequestCategory.LimitRequest,
      bussinessIdentifyNumber: value?.bussinessIdentifyNumber,
      phone: value?.phone,
      npwpCompany: value?.npwpCompany,
      npwpAddress: value?.npwpAddress,
      subDistrict: value?.subDistrict,
      district: value?.district,
      city: value?.city,
      postalCode: value?.postalCode,
      country: value?.country,
      limitRequestAmount: value?.limitRequestAmount
        ? Number(value?.limitRequestAmount)
        : 0,
      tenorRequest: value?.tenorRequest ?? 0,
      suratPengajuanLimit: value?.suratPengajuanLimit,
      formLOI: value?.formLOI,
      limitRequestType: value?.limitRequestType,
    },
    validate: validationRules,
    validateInputOnBlur: true,
  });

  const handleFileUploaded = (fieldName: string, files: FileResult[]) => {
    if (fieldName === "npwpCompany") {
      form.setValues({ npwpCompany: files[0] });
    }
    if (fieldName === "suratPengajuanLimit") {
      form.setValues({ suratPengajuanLimit: files[0] });
    }
    if (fieldName === "formLOI") {
      form.setValues({ formLOI: files[0] });
    }
  };

  const handleRemoveFile = (fieldName: string) => {
    form.setFieldValue(fieldName, undefined);
  };

  const handleSetCompany = (companyId: string) => {
    const company = companies?.find((c) => c.id === Number(companyId));
    form.setValues({
      company: {
        id: Number(companyId),
        name: company?.name ?? "",
        salesName: company?.salesName ?? "",
        salesTeamName: company?.salesTeamName ?? "",
      },
    });
  };

  const handleSetClikCity = (cityId: string) => {
    const clikCity = getClickCity(cityId, cities);
    form.setValues({
      city: {
        id: Number(cityId),
        name: clikCity?.name ?? "",
        code: clikCity?.code ?? "",
      },
    });
  };

  const handleSetClikCountry = (countryId: string) => {
    const clikCountry = getClikCountry(countryId, countries);
    form.setValues({
      country: {
        id: Number(countryId),
        name: clikCountry?.name ?? "",
        code: clikCountry?.code ?? "",
      },
    });
  };

  useEffect(() => {
    onValueChange(form.values as RawLimitRequest, form.isValid());
  }, [form, companies, onValueChange]);

  return (
    <SimpleGrid
      cols={3}
      breakpoints={[{ maxWidth: "sm", cols: 1 }]}
      spacing={20}
      verticalSpacing={16}
    >
      <Flex direction="column" gap={12}>
        <SectionTitle flex={0} isSmall>
          Company Info
        </SectionTitle>
        <Select
          required
          size="m"
          placeholder="Select Company Name"
          label="Company Name"
          disabled={disabledFields?.includes("company")}
          data={getCompanyOptions(companies)}
          {...form.getInputProps("company")}
          value={form.values.company?.id?.toString()}
          searchable
          filterDataOnExactSearchMatch
          onChange={handleSetCompany}
        />
        <TextInput
          disabled
          label="Sales Name"
          placeholder="Sales Name"
          size="m"
          value={form.values.company?.salesName ?? ""}
        />
        <TextInput
          disabled
          label="Sales Team"
          placeholder="Sales Team"
          size="m"
          value={form.values.company?.salesTeamName ?? ""}
        />
        <TextInput
          required
          label="Business Identity Number"
          placeholder="Enter Business Identity Number"
          size="m"
          {...form.getInputProps("bussinessIdentifyNumber")}
        />
        <TextInput
          required
          type="phone"
          label="Phone Number"
          placeholder="Enter Phone Number"
          size="m"
          {...form.getInputProps("phone")}
        />
        <Input.Wrapper required label="NPWP Company" size="m">
          <Dropzone
            maxWidth="100%"
            value={form.values.npwpCompany ? [form.values.npwpCompany] : null}
            onUploadSuccess={(files) =>
              handleFileUploaded("npwpCompany", files)
            }
            onRemove={() => handleRemoveFile("npwpCompany")}
          />
        </Input.Wrapper>
      </Flex>
      <Flex direction="column" gap={12}>
        <SectionTitle flex={0} isSmall>
          Company Address
        </SectionTitle>
        <TextInput
          required
          type="text"
          label="NPWP Address"
          placeholder="Enter NPWP Address"
          size="m"
          {...form.getInputProps("npwpAddress")}
        />
        <TextInput
          required
          label="Sub-district"
          placeholder="Enter Sub-district"
          size="m"
          {...form.getInputProps("subDistrict")}
        />
        <TextInput
          required
          label="District"
          placeholder="Enter District"
          size="m"
          {...form.getInputProps("district")}
        />
        <Select
          required
          size="m"
          placeholder="Salect City"
          label="City"
          data={getClikCityOptions(cities)}
          {...form.getInputProps("city")}
          value={form.values.city?.id.toString()}
          searchable
          filterDataOnExactSearchMatch
          onChange={handleSetClikCity}
        />
        <TextInput
          required
          type="number"
          label="Postal Code"
          placeholder="Postal Code"
          size="m"
          {...form.getInputProps("postalCode")}
        />
        <Select
          required
          size="m"
          placeholder="Select Country"
          label="Country"
          data={getClikCountryOptions(countries)}
          {...form.getInputProps("country")}
          value={form.values.country?.id.toString()}
          searchable
          filterDataOnExactSearchMatch
          onChange={handleSetClikCountry}
        />
      </Flex>
      <Flex direction="column" gap={12}>
        <SectionTitle flex={0} isSmall>
          Category
        </SectionTitle>
        <Select
          required
          size="m"
          placeholder="Select Category"
          label="Category"
          data={limitRequestCategoryOptions}
          disabled={true}
          {...form.getInputProps("category")}
        />
        <NumberInput
          required
          precision={2}
          parser={parseStringToNumber}
          formatter={priceFormatter}
          min={0}
          hideControls
          label="Limit Request"
          placeholder="Enter Limit Request"
          size="m"
          {...form.getInputProps("limitRequestAmount")}
        />
        {form.values.category === LimitRequestCategory.LimitRequest && (
          <>
            <NumberInput
              required
              hideControls
              type="number"
              label="Tenor Request (days)"
              placeholder="Enter Tenor Request"
              size="m"
              {...form.getInputProps("tenorRequest")}
            />
            <Input.Wrapper required label="Surat Pengajuan Limit" size="m">
              <Dropzone
                maxWidth="100%"
                value={
                  form.values.suratPengajuanLimit
                    ? [form.values.suratPengajuanLimit]
                    : null
                }
                onUploadSuccess={(files) =>
                  handleFileUploaded("suratPengajuanLimit", files)
                }
                onRemove={() => handleRemoveFile("suratPengajuanLimit")}
              />
            </Input.Wrapper>
            <Input.Wrapper required label="Form LOI" size="m">
              <Dropzone
                maxWidth="100%"
                value={form.values.formLOI ? [form.values.formLOI] : null}
                onUploadSuccess={(files) =>
                  handleFileUploaded("formLOI", files)
                }
                onRemove={() => handleRemoveFile("formLOI")}
              />
            </Input.Wrapper>
            <Select
              required
              size="m"
              placeholder="Select Limit Request Type"
              label="Limit Request Type"
              data={limitRequestTypeOptions}
              searchable
              filterDataOnExactSearchMatch
              {...form.getInputProps("limitRequestType")}
            />
          </>
        )}
      </Flex>
    </SimpleGrid>
  );
};

export default LimitRequestInfoForm;
