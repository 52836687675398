import { pickBy } from "lodash";
import { DataTableSortStatus } from "mantine-datatable";

import {
  CustomerOrderBy,
  LimitRequestFilter,
  LimitRequestOrderBy,
} from "../../graphql/generated.ts";
import { getApiOrderBy } from "../../utils/api.ts";
import { LimitRequestFiltersValue } from "./types.ts";

export const getLimitRequestApiSort = (
  tableSort: DataTableSortStatus
): LimitRequestOrderBy => {
  const { companyName, salesName, salesTeamName, ...apiSort } =
    getApiOrderBy(tableSort);
  let company: CustomerOrderBy | undefined = undefined;
  if (companyName) {
    company = { name: companyName };
  }
  if (salesName) {
    company = { salesName };
  }
  if (salesTeamName) {
    company = { salesTeamName };
  }
  return {
    ...apiSort,
    company,
  };
};

export const getLimitRequestApiFilter = (
  value: LimitRequestFiltersValue
): LimitRequestFilter => {
  return pickBy({
    requestStatus: value?.requestStatus && { equals: value.requestStatus },
    cuAssignedId: value?.cuAssignedId && { equals: value.cuAssignedId },
    company: pickBy({
      id: value?.companyName && { equals: Number(value.companyName) },
      salesName: value?.salesName && { equals: value.salesName },
      salesTeamName: value?.salesTeamName && { equals: value.salesTeamName },
    }),
  });
};
