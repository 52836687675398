import { createStyles, rem } from "@mantine/core";

export const useStyles = createStyles(
  (theme, { isActive, bgColor }: { isActive: boolean; bgColor: string }) => ({
    riskItem: {
      background: bgColor,
      border: isActive ? `2px solid ${theme.colors.dusk[0]}` : "none",
      borderRadius: rem(2),
      padding: `${rem(4)} ${rem(14)}`,
      width: rem(48),
      height: rem(28),

      fontSize: rem(14),
      fontWeight: 500,
      lineHeight: 1.5,
      color: isActive ? theme.colors.dusk[0] : theme.colors.white[0],
    },
  })
);
