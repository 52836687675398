import { useQuery } from "urql";

import {
  GetLimitRequestsDocument,
  LimitRequestFilter,
  LimitRequestOrderBy,
} from "../../../graphql/generated.ts";
import { getApiPaginationData } from "../../../utils/api.ts";
import { ApiHookProps } from "../types.ts";

export const useLimitRequests = ({
  filter,
  orderBy,
  page,
}: ApiHookProps<LimitRequestFilter, LimitRequestOrderBy>) => {
  const pagination = getApiPaginationData(page);
  const variables = {
    filter,
    orderBy,
    skip: pagination?.skip,
    take: pagination?.take,
  };
  return useQuery({ query: GetLimitRequestsDocument, variables });
};
