import { MultiFieldCardItem } from "../../../../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import { ClikSubjectAdditionalInfo } from "../../../../../graphql/generated.ts";
import { toLocalDate } from "../../../../../utils/date.ts";

interface Props {
  data: ClikSubjectAdditionalInfo | undefined | null;
}

export const useCRAdditionalInformation = ({
  data,
}: Props): MultiFieldCardItem[] | null => {
  if (!data) {
    return null;
  }

  return [
    {
      label: "Additional Information No",
      content: data.additionalInformationNo,
    },
    {
      label: "Provider Type",
      content: data.providerType,
    },
    {
      label: "Provider",
      content: data.providerCode,
    },
    {
      label: "Information Type",
      content: data.additionalInformationTypeDesc,
    },
    {
      label: "Details",
      content: data.additionalInformationValue,
    },
    {
      label: "Status",
      content: data.status,
    },
    {
      label: "Status Date",
      content: data.statusDate && toLocalDate(data.statusDate),
    },
    {
      label: "Last Reference Date",
      content: data.referenceDate && toLocalDate(data.referenceDate),
    },
  ];
};
