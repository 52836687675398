import { pickBy } from "lodash";
import moment from "moment/moment";

import {
  InvoiceFilter,
  PaymentStatusFilterOptions,
  PaymentTypeFilterOptions,
} from "../../../graphql/generated";
import { FiltersValue } from "../InvoicesFilterToolbar/types.ts";

export const getInvoiceApiFilter = (value: FiltersValue): InvoiceFilter => {
  return pickBy({
    invoiceDueDate: value.invoiceDueDate
      ? {
          equals: moment
            .utc(value.invoiceDueDate.toDateString())
            .startOf("day"),
        }
      : null,
    paymentType: value.paymentType?.length
      ? { in: value.paymentType as PaymentTypeFilterOptions[] }
      : null,
    paymentStatus: value.paymentStatus?.length
      ? { in: value.paymentStatus as PaymentStatusFilterOptions[] }
      : null,
  });
};
