import { Flex, Text } from "@mantine/core";
import { isEmpty, sortBy } from "lodash";
import { useCallback, useMemo, useState } from "react";

import ActionIcon from "../../components/ActionIcon/ActionIcon.tsx";
import SvgEdit from "../../components/Icons/Edit.tsx";
import { TableEmptyValue } from "../Table/constants.tsx";
import { useStyles } from "./CPExceptionTableCard.styles.ts";
import CPExceptionTableCardItem from "./CPExceptionTableCardItem.tsx";
import { TransactionHistoryTableCardItemProps } from "./types.ts";

interface Props {
  data: TransactionHistoryTableCardItemProps[] | null | undefined;
  isEdit?: boolean;
  onEdit?: () => void;
}

const CPExceptionTableCard = ({ data, isEdit, onEdit }: Props) => {
  const { classes } = useStyles();

  const [showAll, setShowAll] = useState(false);

  const displayedData = useMemo(() => {
    const sortedData = sortBy(data, "id");
    return showAll ? sortedData : sortedData?.slice(0, 1);
  }, [data, showAll]);

  const handleOnClick = useCallback(
    (event: React.MouseEvent<HTMLElement>) => {
      //  stop event propagation (won't trigger parent's onClick)
      event.stopPropagation();
      setShowAll(!showAll);
    },
    [showAll]
  );

  return (
    <Flex align="start" justify="space-between" mih="100%">
      <Flex direction="column">
        {!isEmpty(displayedData)
          ? displayedData.map((item, index) => (
              <CPExceptionTableCardItem key={index} {...item} />
            ))
          : TableEmptyValue}
        {!isEmpty(data) && data?.length && (
          <>
            {data.length > 1 && (
              <Text className={classes.showMore} onClick={handleOnClick}>
                {showAll ? "Hide" : `More ${data.length}`}
              </Text>
            )}
          </>
        )}
      </Flex>
      {isEdit && (
        <ActionIcon onClick={onEdit}>
          <SvgEdit />
        </ActionIcon>
      )}
    </Flex>
  );
};

export default CPExceptionTableCard;
