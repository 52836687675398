import { createStyles, rem } from "@mantine/core";

export const useStyles = createStyles(() => ({
  grid: {
    "@media (max-width: 48em)": {
      display: "flex",
      flexFlow: "column",
      alignItems: "stretch",
      gap: rem(12),
    },
  },
  next: {
    "@media (max-width: 60em)": {
      display: "flex",
      flexFlow: "column",
    },
  },
  firstCardWrapper: {
    "@media (max-width: 68em)": {
      height: rem(143),
    },
  },
  secondCardWrapper: {
    "@media (max-width: 76em)": {
      height: rem(143),
    },
  },
  thirdCardWrapper: {
    "@media (max-width: 54em)": {
      height: rem(143),
    },
  },
  sectionTitle: {
    "@media (max-width: 60em)": {
      flex: "1 0 0",
    },
  },
}));
