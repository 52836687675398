import isNil from "lodash/isNil";
import { useQuery } from "urql";

import { GetClikCompanyShareholderDocument } from "../../../graphql/generated";

interface Props {
  companyId: number | null | undefined;
}

export function useClikCompanyShareholder({ companyId }: Props) {
  return useQuery({
    query: GetClikCompanyShareholderDocument,
    variables: { subjectId: Number(companyId) },
    pause: isNil(companyId),
  });
}
