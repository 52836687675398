import { createStyles, MantineTheme, rem } from "@mantine/core";

export const useStyles = createStyles((theme: MantineTheme) => ({
  wrapper: {
    paddingLeft: rem(100),
  },
  yearWrapper: {
    color: theme.colors.dusk[0],
  },
}));
