import { Badge, Flex, Switch, Text } from "@mantine/core";
import { useCallback, useMemo } from "react";
import { Link } from "react-router-dom";

import ActionIcon from "../../../../components/ActionIcon/ActionIcon.tsx";
import { useCurrentUserContext } from "../../../../components/Auth/CurrentUserProvider.tsx";
import Edit from "../../../../components/Icons/Edit.tsx";
import {
  PengurusStatus,
  RolePermission,
} from "../../../../graphql/generated.ts";
import { RawPengurus } from "../../../../types/pengurus.ts";
import { toLocalDate } from "../../../../utils/date.ts";
import { toHumanReadable } from "../../../../utils/string.ts";
import { hasPermission } from "../../../../utils/user.ts";

interface Props {
  penguruses: RawPengurus[] | null | undefined;
  onEdit: (value: RawPengurus, index: number) => void;
  onStatusChange: (id: number, value: PengurusStatus) => void;
}

export const usePengurusesCardInfo = ({
  penguruses,
  onStatusChange,
  onEdit,
}: Props) => {
  const { user: currentUser } = useCurrentUserContext();

  const hasEditAccess = hasPermission(
    currentUser,
    RolePermission.CanEditBuyerInfoPengurusInfo
  );

  const handleStatusChange = useCallback(
    (item: RawPengurus) => {
      if (!item?.id) {
        return;
      }
      const newStatus =
        item.status === PengurusStatus.Active
          ? PengurusStatus.Inactive
          : PengurusStatus.Active;
      onStatusChange(item.id, newStatus);
    },
    [onStatusChange]
  );

  return useMemo(() => {
    return penguruses?.map((item, index) => ({
      cardControl: (
        <Flex gap={12}>
          <Badge
            size="s"
            variant={
              item.status === PengurusStatus.Active ? "active" : "inactive"
            }
          >
            {toHumanReadable(item.status)}
          </Badge>
          {hasEditAccess && (
            <ActionIcon
              disabled={item.status === PengurusStatus.Inactive}
              spaces={false}
              onClick={() => onEdit(item, index)}
            >
              <Edit />
            </ActionIcon>
          )}
        </Flex>
      ),
      customVariant: (
        <Flex gap={12}>
          {hasEditAccess && (
            <Switch
              variant="yellow"
              checked={item.status === PengurusStatus.Active}
              radius={2}
              onClick={() => handleStatusChange(item)}
            />
          )}
          <Text>{item.name}</Text>
        </Flex>
      ),
      items: [
        {
          label: "Pengurus Name",
          content: item.name,
        },
        {
          label: "KTP Pengurus",
          content: (
            <Link
              to={item.ktpPengurus.url}
              target="_blank"
              onClick={(e) => e.stopPropagation()}
            >
              Download
            </Link>
          ),
        },
        {
          label: "Gender",
          content: toHumanReadable(item.gender),
        },
        {
          label: "Date of Birth",
          content: toLocalDate(item.birthDate),
        },
        {
          label: "KTP Number",
          content: item.ktpNumber,
        },
        {
          label: "Phone",
          content: item.phone,
        },
        {
          label: "KTP Address",
          content: item.ktpAddress,
        },
        {
          label: "Sub-district",
          content: item.subDistrict,
        },
        {
          label: "District",
          content: item.district,
        },
        {
          label: "City",
          content: item.city.name,
        },
        {
          label: "Postal Code",
          content: item.postalCode,
        },
        {
          label: "Country",
          content: item.country.name,
        },
      ],
    }));
  }, [penguruses, hasEditAccess, onEdit, handleStatusChange]);
};
