import { createStyles, rem } from "@mantine/core";

export const useStyles = createStyles((theme) => ({
  wrapper: {
    "@media (max-width: 48em)": {
      marginBottom: rem(54),
    },
  },
  titleWrapper: {
    "@media (max-width: 48em)": {
      fontSize: rem(24),
      lineHeight: 1.2,
    },
  },
  buttonsWrapper: {
    width: "100%",
    position: "fixed",
    bottom: 0,
    left: 0,
    boxShadow: "0px 0px 12px 0px rgba(55, 65, 82, 0.18)",
    background: theme.colors.white[0],
    padding: `${rem(24)} ${rem(20)}`,
  },
  fileName: {
    color: theme.colors.midnightBlue[0],
    fontSize: rem(14),
    fontWeight: 500,
    lineHeight: 1.3,
    textDecoration: "underline",
    cursor: "pointer",
  },
}));
