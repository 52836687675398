import { createStyles, CSSObject, rem } from "@mantine/core";

export const useStyles = createStyles(() => ({
  userAccessSettingWrapper: {
    maxWidth: "fit-content",
    gap: rem(24),
    "@media (max-width: 48em)": {
      gap: rem(16),
    },
  },
  titleWrapper: {
    marginBottom: rem(-16),
    marginTop: rem(-9),
    "@media (max-width: 48em)": {
      marginBottom: rem(-12),
    },
  },
  inviteModalButton: {
    "@media (max-width: 48em)": {
      width: "100%",
    },
  },
  emailWrapper: {
    wordWrap: "break-all" as CSSObject["wordWrap"],
    overflowWrap: "anywhere",
  },
}));
