import isNil from "lodash/isNil";
import { useQuery } from "urql";

import { GetClikGrantedGuaranteeDetailsDocument } from "../../../../graphql/generated.ts";

interface Props {
  id: number | string | null | undefined;
}

export const useClikGrantedDetailsOfGuarantees = ({ id }: Props) => {
  return useQuery({
    query: GetClikGrantedGuaranteeDetailsDocument,
    variables: { id: Number(id) },
    pause: isNil(id),
  });
};
