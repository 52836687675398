import { Flex } from "@mantine/core";

import MultiFieldCard from "../../../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import NoDataMessage from "../../../../components/NoDataMessage/NoDataMessage.tsx";
import SectionTitle from "../../../../components/Section/SectionTitle/SectionTitle.tsx";
import { ClikSubjectAdditionalInfo } from "../../../../graphql/generated.ts";
import { useCRAdditionalInformation } from "./hooks/useCRAdditionalInformation.tsx";

interface Props {
  additionalInfo: ClikSubjectAdditionalInfo | null | undefined;
}

const ClikResultAdditionalInformation = ({ additionalInfo }: Props) => {
  const additionalInfoItems = useCRAdditionalInformation({
    data: additionalInfo,
  });

  if (!additionalInfo || !additionalInfoItems) {
    return (
      <Flex direction="column" gap={16}>
        <SectionTitle variant="bronze">Additional Info</SectionTitle>
        <NoDataMessage />
      </Flex>
    );
  }

  return (
    <Flex direction="column" gap={16}>
      <SectionTitle variant="bronze">Additional Info</SectionTitle>
      <MultiFieldCard
        items={additionalInfoItems}
        variant="blue"
        cols={2}
        label={additionalInfo.additionalInfoCategory ?? ""}
      />
    </Flex>
  );
};

export default ClikResultAdditionalInformation;
