import { Flex } from "@mantine/core";

import { ClikContractMonth } from "../types.ts";
import { useStyles } from "./ClikGrantedContractMonthItem.styles.ts";
import { qualityCodeDescItems } from "./constants.ts";

interface ClikGrantedContractMonthItemProps {
  item: ClikContractMonth | null;
}

const ClikGrantedContractMonthItem = ({
  item,
}: ClikGrantedContractMonthItemProps) => {
  const { classes } = useStyles({ qualityCode: item?.qualityCode });

  return (
    <Flex justify="center" align="center" className={classes.wrapper}>
      {item &&
        (item.qualityCode ? qualityCodeDescItems[item.qualityCode] : "N/A")}
    </Flex>
  );
};

export default ClikGrantedContractMonthItem;
