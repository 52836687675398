import { createStyles, rem } from "@mantine/core";

export const useStyles = createStyles((theme) => ({
  form: {
    borderRadius: rem(4),
    background: theme.colors.grayIce[6],
  },
  label: {
    fontWeight: 500,
    fontSize: rem(16),
    lineHeight: rem(24),
    color: theme.colors.midnightBlue[0],
  },
  status: {
    color: theme.colors.grayIce[0],
  },
  linkLabel: {
    whiteSpace: "nowrap",
  },
}));
