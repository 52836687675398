import { MantineTheme, rem } from "@mantine/core";

import { ThemeComponent } from "./helpers.ts";

const defaultTooltip = (theme: MantineTheme) => ({
  tooltip: {
    backgroundColor: theme.colors.white[0],
    borderRadius: rem(4),
    boxShadow: "0px 2px 19px 0px rgba(55, 65, 82, 0.10)",
    maxWidth: rem(248),
    padding: `${rem(12)} ${rem(11)}`,

    fontSize: rem(12),
    fontWeight: 400,
    lineHeight: 1.5,
    flex: "1 0 0",
    color: theme.colors.black[0],
  },
});

export default function tooltipTheme(): ThemeComponent {
  return {
    styles: (theme) => defaultTooltip(theme),
  };
}
