import { Flex } from "@mantine/core";

import AccordionMultifieldsCards from "../../../../components/AccordionMultifieldCards/AccordionMultifieldCards.tsx";
import NoDataMessage from "../../../../components/NoDataMessage/NoDataMessage.tsx";
import SectionTitle from "../../../../components/Section/SectionTitle/SectionTitle.tsx";
import { useClikCompanyShareholder } from "../../../../hooks/api/clikResult/useClikCompanyShareholder.ts";
import { useCRCompanyShareholderCardsInfo } from "./hooks/useCRCompanyShareholderCardsInfo.tsx";

interface ClikCompanyShareholderProps {
  companyId: number | undefined | null;
}

const ClikResultCompanyShareholder = ({
  companyId,
}: ClikCompanyShareholderProps) => {
  const [{ data, fetching }] = useClikCompanyShareholder({ companyId });

  const clikCompanyShareholders = data?.clikCompanyShareholder;

  const cardItems = useCRCompanyShareholderCardsInfo({
    data: clikCompanyShareholders,
  });

  if (!fetching && !cardItems?.length) {
    return (
      <Flex direction="column" gap={16}>
        <SectionTitle>Shareholder</SectionTitle>
        <NoDataMessage />
      </Flex>
    );
  }

  return (
    <Flex direction="column" gap={16}>
      <SectionTitle variant="bronze">Shareholder</SectionTitle>
      <AccordionMultifieldsCards cardItems={cardItems} loading={fetching} />
    </Flex>
  );
};

export default ClikResultCompanyShareholder;
