import { rem } from "@mantine/core";

import { ThemeComponent } from "./helpers.ts";

export default function appShellTheme(): ThemeComponent {
  return {
    styles: () => ({
      root: {
        padding: 0,
      },
      main: {
        width: "100%",
        "@media (max-width: 52em)": {
          padding: `${rem(80)} ${rem(16)} ${rem(16)} ${rem(16)}`,
        },
      },
    }),
  };
}
