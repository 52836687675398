import type { SVGProps } from "react";
const SvgUnfold = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 -960 960 960"
    fill="currentColor"
    {...props}
  >
    <path d="M480-149.231 326.154-303.077l28.769-28.769L480-206.769l125.077-125.077 28.769 28.769L480-149.231Zm-125.077-478L326.154-656 480-809.846 633.846-656l-28.769 28.769L480-752.307 354.923-627.231Z" />
  </svg>
);
export default SvgUnfold;
