import isNil from "lodash/isNil";
import { useQuery } from "urql";

import { GetLimitRequestExceptionsDocument } from "../../../graphql/generated.ts";

interface Props {
  limitRequestId: number | string | null | undefined;
}

export const useGetLimitRequestExceptions = ({ limitRequestId }: Props) => {
  return useQuery({
    query: GetLimitRequestExceptionsDocument,
    variables: {
      limitRequestId: Number(limitRequestId),
      filter: { active: { equals: true } },
    },
    pause: isNil(limitRequestId),
  });
};
