import { useQuery } from "urql";

import { GetCollectionSummaryDocument } from "../../../graphql/generated";

interface Props {
  customerId: number | string;
}

export function useCollectionSummary({ customerId }: Props) {
  return useQuery({
    query: GetCollectionSummaryDocument,
    variables: { customerId: Number(customerId) },
  });
}
