import { pickBy } from "lodash";
import { DataTableSortStatus } from "mantine-datatable";

import { ClikDataFilter } from "../../graphql/generated.ts";
import {
  ClikDataOrderBy,
  LimitRequestOrderBy,
} from "../../graphql/generated.ts";
import { getApiOrderBy } from "../../utils/api.ts";
import { FiltersValue } from "./types.ts";

export const geClikDataApiFilter = (value: FiltersValue): ClikDataFilter => {
  return pickBy({
    limitRequest: pickBy({
      companyId: value.companyName?.length && {
        in: value.companyName.map((c) => Number(c)),
      },
      category: value.category && { equals: value.category },
    }),
  });
};

export const getClikDataApiSort = (
  tableSort: DataTableSortStatus
): ClikDataOrderBy => {
  const { companyName, category, ...apiSort } = getApiOrderBy(tableSort);
  let limitRequest: LimitRequestOrderBy | undefined = undefined;
  if (companyName) {
    limitRequest = { company: { name: companyName } };
  }
  if (category) {
    limitRequest = { category };
  }
  return {
    ...apiSort,
    limitRequest,
  };
};
