import { useQuery } from "urql";

import {
  DashboardStatisticsDocument,
  QueryDashboardStatisticsArgs,
} from "../../../graphql/generated";

export function useDashboardStatistics(args: QueryDashboardStatisticsArgs) {
  return useQuery({
    query: DashboardStatisticsDocument,
    variables: args,
  });
}
