import isNil from "lodash/isNil";
import { useQuery } from "urql";

import { GetOfferingLetterDocument } from "../../../graphql/generated.ts";

interface Props {
  id: number | null | undefined;
}

export const useGetOfferingLetter = ({ id }: Props) => {
  return useQuery({
    query: GetOfferingLetterDocument,
    variables: {
      id: Number(id),
    },
    pause: isNil(id),
  });
};
