import { omit } from "lodash";

import {
  CreatePengurusInput,
  PengurusStatus,
  UpdatePengurusInput,
} from "../../../graphql/generated.ts";
import { RawPengurus } from "../../../types/pengurus.ts";

export const formatPengurusToUpdatePengurusInput = (
  pengurus: RawPengurus
): UpdatePengurusInput => {
  const res = {
    ...pengurus,
    cityId: Number(pengurus.city.id),
    countryId: Number(pengurus.country.id),
    ktpPengurus: pengurus.ktpPengurus.filePath,
  };
  return omit(res, ["city", "country", "id", "name"]) as UpdatePengurusInput;
};

export const formatPengurusToAddPengurusInput = (
  limitRequestId: number,
  pengurus: RawPengurus
): CreatePengurusInput => {
  const res = {
    ...pengurus,
    cityId: Number(pengurus.city.id),
    countryId: Number(pengurus.country.id),
    ktpPengurus: pengurus.ktpPengurus.filePath,
    status: PengurusStatus.Active,
    limitRequestId,
  };
  return omit(res, ["city", "country"]) as CreatePengurusInput;
};
