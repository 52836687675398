import { CSSObject } from "@mantine/core";
import { MantineTheme, rem } from "@mantine/styles";

import { ThemeComponent } from "./helpers.ts";

const defaultMultiSelect = (theme: MantineTheme) => ({
  item: {
    "&[data-selected]": {
      color: theme.colors.black[0],
      background: theme.colors.golden[4],
      "&:hover": {
        background: theme.colors.golden[6],
      },
    },
  },
  description: {
    color: theme.colors.grayIce[0],
    fontSize: rem(14),
    lineHeight: 1.5,
  },
  error: {
    color: theme.colors.error[0],
    fontSize: rem(14),
    lineHeight: 1.5,
  },
  label: {
    color: theme.colors.midnightBlue[0],
    fontWeight: 500,
    lineHeight: 1.5,
    fontSize: rem(16),
  },
  input: {
    background: theme.colors.white[0],
    borderRadius: rem(4),
    border: `1px solid ${theme.colors.grayIce[0]}`,
    lineHeight: 1.5,
    fontWeight: 500,
    "&::placeholder": {
      color: theme.colors.grayIce[0],
      fontWeight: 500,
    },
    "&:hover": {
      border: `1px solid ${theme.colors.dusk[0]}`,
    },
    "&:focus": {
      border: `1px solid ${theme.colors.dusk[0]}`,
    },
    "&:focus-within": {
      border: `1px solid ${theme.colors.dusk[0]}`,
    },
    "&:disabled": {
      background: "transparent",
    },
    "&[data-invalid]": {
      "&::placeholder": {
        color: theme.colors.error[0],
        fontWeight: 500,
      },
      color: theme.colors.error[0],
      border: `1px solid ${theme.colors.error[0]}`,
    },
  },
  searchInput: {
    border: "none!important",
    marginLeft: 0,
  },
  values: {
    margin: 0,
    display: "flex",
    gap: rem(8),
    width: "100%",
  },
  value: {
    margin: 0,
    gap: rem(10),
  },
  defaultValue: {
    background: theme.colors.golden[4],
    borderRadius: rem(2),
    padding: `0 0 0 ${rem(8)}`,
    margin: "0",
  },
  defaultValueLabel: {
    fontSize: rem(12),
    lineHeight: 1.5,
    color: theme.colors.black[0],
    overflow: "unset",
    wordBreak: "break-word" as CSSObject["wordBreak"],
    whiteSpace: "normal" as CSSObject["whiteSpace"],
  },
  defaultValueRemove: {
    color: theme.colors.golden[0],
  },
  nothingFound: {
    color: theme.colors.grayIce[0],
    fontSize: rem(16),
    lineHeight: 1.5,
    display: "flex",
    justifyContent: "start",
    padding: `0 ${rem(8)}`,
  },
});

function defaultMultiSelectTheme() {
  return {
    styles: (theme: MantineTheme) => ({
      ...defaultMultiSelect(theme),
    }),
  };
}

export default function multiSelectTheme(): ThemeComponent {
  return {
    ...defaultMultiSelectTheme(),
    sizes: {
      m: (theme) => ({
        input: {
          height: "auto",
          padding: `${rem(9)} ${rem(32)} ${rem(9)} ${rem(12)}`,
          fontSize: rem(16),
          display: "flex",
          alignItems: "center",
        },
        item: {
          padding: `${rem(10)} ${rem(12)}`,
          fontSize: rem(16),
        },
        rightSection: {
          width: rem(36),
          color: theme.colors.grayIce[0],
          svg: {
            width: rem(20),
            height: rem(20),
          },
        },
      }),
      l: () => ({
        root: {
          width: "100%",
        },
        input: {
          height: "auto",
          padding: `${rem(9)} ${rem(12)}`,
          fontSize: rem(16),
          display: "flex",
          alignItems: "center",
        },
        item: {
          padding: `${rem(10)} ${rem(12)}`,
          fontSize: rem(16),
        },
        rightSection: {
          paddingRight: rem(8),
        },
      }),
    },
  };
}
