import { Flex } from "@mantine/core";

import { useCurrentUserContext } from "../../components/Auth/CurrentUserProvider.tsx";
import Layout from "../../components/Layout/Layout";
import Title from "../../components/Title/Title";
import { RolePermission } from "../../graphql/generated.ts";
import { hasPermission } from "../../utils/user.ts";
import Page404 from "../404/Page404.tsx";
import NewRRAnalysisTable from "./NewRRAnalysis/NewRRAnalysisTable.tsx";
import OngoingCCTable from "./OngoingCC/OngoingCCTable.tsx";
import OngoingOLTable from "./OngoingOL/OngoingOLTable.tsx";

const RiskMonitoringPage = () => {
  const { user } = useCurrentUserContext();

  const hasAccess = hasPermission(user, RolePermission.CanViewRiskMonitoring);

  if (!hasAccess) {
    return <Page404 />;
  }

  return (
    <Layout>
      <Flex direction="column" gap={{ base: 16, sm: 24 }}>
        <Title>Risk Monitoring</Title>
        <NewRRAnalysisTable />
        <OngoingCCTable />
        <OngoingOLTable />
      </Flex>
    </Layout>
  );
};

export default RiskMonitoringPage;
