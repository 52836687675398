import { pickBy } from "lodash";

import { LimitRequestFilter } from "../../../graphql/generated.ts";
import { CreditLimitFunnelFilter } from "./types.ts";

export const getCreditLimitFunnelApiFilter = (
  filter: CreditLimitFunnelFilter
): LimitRequestFilter => {
  return pickBy({
    ...filter,
    limitRequestType: filter.limitRequestType && {
      equals: filter.limitRequestType,
    },
  });
};
