import { Flex } from "@mantine/core";
import { DataTableSortStatus } from "mantine-datatable";
import { useCallback, useState } from "react";

import MultiFieldCard from "../../../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import SectionTitle from "../../../../components/Section/SectionTitle/SectionTitle.tsx";
import Table from "../../../../components/Table/Table.tsx";
import { SortDirection } from "../../../../components/Table/types.ts";
import {
  ClikFootprintOrderBy,
  OrderBy,
} from "../../../../graphql/generated.ts";
import { useClikFootprints } from "../../../../hooks/api/clikResult/useClikFootprints.ts";
import { ClikFootprintStatistic } from "../../../../types/clikResult.ts";
import { getApiOrderBy } from "../../../../utils/api.ts";
import { getPagesCount } from "../../../../utils/pagination.ts";
import { useStyles } from "../ClikResultDetails.styles.tsx";
import { useCRFootprint } from "./hooks/useCRFootprint.tsx";
import { useCRFootprintStatisticCardsInfo } from "./hooks/useCRFootprintStatisticCardsInfo.tsx";

interface ClikResultFootprintProps {
  footprintsStatistic: ClikFootprintStatistic;
}

const ClikResultFootprint = ({
  footprintsStatistic,
}: ClikResultFootprintProps) => {
  const { classes } = useStyles();

  const [page, setPage] = useState<number>(1);
  const [orderBy, setOrderBy] = useState<ClikFootprintOrderBy>({
    id: OrderBy.Asc,
  });

  const [{ data, fetching }] = useClikFootprints({
    filter: { clikResultId: { equals: footprintsStatistic.id } },
    orderBy,
    page,
  });

  const footprints = data?.clikFootprints?.data;

  const pageCount = getPagesCount(data?.clikFootprints?.total);

  const [columns, rows] = useCRFootprint({ data: footprints });

  const footprintStatisticCardItems = useCRFootprintStatisticCardsInfo({
    data: footprintsStatistic,
  });

  const handlePageChange = useCallback(
    (page: number) => {
      setPage(page);
    },
    [setPage]
  );

  const handleSortChange = useCallback(
    (sort: DataTableSortStatus) => {
      setOrderBy(getApiOrderBy(sort));
    },
    [setOrderBy]
  );

  return (
    <Flex direction="column" gap={16}>
      <SectionTitle variant="bronze">Footprint</SectionTitle>
      <Flex gap={12} className={classes.contractBlockWrapper}>
        <Flex w="33.3%" h="fit-content" className={classes.contractBlockItem}>
          <MultiFieldCard
            items={footprintStatisticCardItems}
            label="Number of Enquiries in the last months"
            variant="bronze"
          />
        </Flex>
        <Flex w="77.7%" className={classes.contractBlockItem}>
          <Table
            columns={columns}
            rows={rows}
            minWidth="100%"
            defaultSort={{
              columnAccessor: "id",
              direction: SortDirection.asc,
            }}
            pagination={{
              pageCount: pageCount,
              page: page,
              onPageChange: handlePageChange,
            }}
            loading={fetching}
            onSortChange={handleSortChange}
          />
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ClikResultFootprint;
