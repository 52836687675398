import { Button, Select, SimpleGrid } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

import { useInvoices } from "../../../../hooks/api/invoice/useInvoices";
import SvgPlus from "../../../Icons/Plus";
import AddRestructureDetailModal, {
  AddRestructureDetailModalProps,
} from "../../AddRestructureDetailModal/AddRestructureDetailModal";
import { invoiceIdOptions } from "../../utils";
import { useStyles } from "../RestructureDetails.styles";

interface ToolbarProps {
  customerId: number;
  restructureId: number;
  canAdd: boolean;
  onFilter: (id: string | null) => void;
  onCreate: AddRestructureDetailModalProps["onSubmit"];
}

export default function RestructureDetailsToolbar({
  customerId,
  restructureId,
  canAdd,
  onFilter,
  onCreate,
}: ToolbarProps) {
  const { classes } = useStyles();

  const [{ data: invoicesData }] = useInvoices({
    filter: { customerId: { equals: Number(customerId) } },
  });

  const handleFilter = (id: string | null) => {
    onFilter(id);
  };

  const [modalOpened, { open: openModal, close: closeModal }] =
    useDisclosure(false);

  return (
    <SimpleGrid
      cols={3}
      spacing={20}
      verticalSpacing={8}
      className={classes.grid}
    >
      <Select
        clearable
        searchable
        size="m"
        placeholder="Select Invoice Number"
        label="Filter By Invoice Number"
        data={invoiceIdOptions(invoicesData?.invoices?.data || [])}
        onChange={handleFilter}
      />

      {canAdd && (
        <>
          <Button
            uppercase
            variant="filledGolden"
            leftIcon={<SvgPlus />}
            className={classes.addButton}
            onClick={openModal}
          >
            Add
          </Button>

          <AddRestructureDetailModal
            restructureId={restructureId}
            invoices={invoicesData?.invoices?.data || []}
            opened={modalOpened}
            onClose={closeModal}
            onSubmit={onCreate}
          />
        </>
      )}
    </SimpleGrid>
  );
}
