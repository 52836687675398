import {
  EmployeeCandidateHiringStatus,
  EmployeeCandidateResultCategory,
} from "../../graphql/generated.ts";
import { toHumanReadable } from "../../utils/string.ts";

export const hiringStatusOptions = Object.values(
  EmployeeCandidateHiringStatus
).map((value) => {
  return { value, label: toHumanReadable(value) };
});

export const clikResultCategoryOptions = Object.values(
  EmployeeCandidateResultCategory
).map((value) => {
  return { value, label: toHumanReadable(value) };
});
