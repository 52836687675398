import { useMemo } from "react";

import { MultiFieldCardProps } from "../../../../../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import {
  ClikContractGuarantee,
  ClikIdentification,
} from "../../../../../../graphql/generated.ts";

interface Props {
  data: ClikContractGuarantee[] | null | undefined;
}
export const useCRGuarantorsDetails = ({
  data,
}: Props): MultiFieldCardProps[] | null => {
  return useMemo(() => {
    if (!data) {
      return null;
    }
    const getGuarantorIdentities = (identity?: ClikIdentification | null) => {
      if (!identity) {
        return [];
      }

      return [
        {
          label: "Identity Type",
          content: identity.type,
        },
        {
          label: "Identity Number",
          content: identity.number,
        },
      ];
    };

    return data.map((item, index) => {
      return {
        label: `Detail of Guarantor ${index + 1}: ${item.guarantorType}`,
        items: [
          {
            label: "Guarantor Name as ID",
            content: item.guarantorNameAsID,
          },
          {
            label: "Guarantor Full Name",
            content: item?.guarantorFullName,
          },
          {
            label: "Percentage Guaranteed",
            content: item?.percentageGuaranteed,
          },
          {
            label: "Information",
            content: item?.information,
          },
          {
            label: "Address",
            content: item?.address,
          },
          {
            label: "Provider Type",
            content: item?.providerType,
          },
          {
            label: "Provider",
            content: item?.providerCode,
          },
          ...getGuarantorIdentities(item?.identity),
        ],
      };
    });
  }, [data]);
};
