import { Box, Flex, Text, Tooltip } from "@mantine/core";

import { useStyles } from "./TransactionHistory.styles.ts";

interface Props {
  label: string;
}

export const TransactionHistoryCardHeader = ({ label }: Props) => {
  const { classes } = useStyles();

  return (
    <Flex gap={12}>
      <Box className={classes.pin} />
      <Tooltip
        label={label}
        multiline
        transitionProps={{ duration: 200 }}
        withinPortal
        zIndex={1000}
      >
        <Text className={classes.label}>{label}</Text>
      </Tooltip>
    </Flex>
  );
};
