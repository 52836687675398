import { Flex } from "@mantine/core";

import { useStyles } from "./RiskGradeItem.styles.ts";

interface RiskGradeItemProps {
  color: string;
  value: string;
  active?: boolean;
}

const RiskGradeItem = ({
  active = false,
  value,
  color,
}: RiskGradeItemProps) => {
  const { classes } = useStyles({ isActive: active, bgColor: color });

  return (
    <Flex align="center" justify="center" className={classes.riskItem}>
      {value}
    </Flex>
  );
};

export default RiskGradeItem;
