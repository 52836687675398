import { useQuery } from "urql";

import { GetClikDataDocument } from "../../../graphql/generated.ts";

interface Props {
  id: number | string;
}

export const useClikData = ({ id }: Props) => {
  return useQuery({
    query: GetClikDataDocument,
    variables: { id: Number(id) },
  });
};
