import { useMemo } from "react";

import { MultiFieldCardItem } from "../../../../../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import { ClikGrantedContractDetails } from "../../../../../../types/clikContract.ts";
import { toLocalDate } from "../../../../../../utils/date.ts";

interface Props {
  data: ClikGrantedContractDetails | null | undefined;
}
export const useCRRestructuringDetailsOfContract = ({
  data,
}: Props): MultiFieldCardItem[] => {
  return useMemo(() => {
    return [
      {
        label: "Restructuring Plan",
        content: data?.restructuringPlan && toLocalDate(data.restructuringPlan),
      },
      {
        label: "Initial Restructuring Date",
        content:
          data?.initialRestructuringDate &&
          toLocalDate(data.initialRestructuringDate),
      },
      {
        label: "Final Restructuring Date",
        content: data?.finalRestructuringDate && data.finalRestructuringDate,
      },
      {
        label: "Frequency of Restructuring",
        content: data?.frequencyOfRestructuring,
      },
    ];
  }, [data]);
};
