import { DataTableColumn } from "mantine-datatable";
import { useMemo } from "react";

import { TableData, TableRow } from "../../../../components/Table/types";
import { toLocalDate } from "../../../../utils/date";
import { toPrice } from "../../../../utils/number";
import { toHumanReadable } from "../../../../utils/string";
import { getUserDisplayName } from "../../../../utils/user.ts";
import { OngoingCC } from "../../types.ts";

interface Props {
  data: OngoingCC[] | null | undefined;
}

export const useOngoingCCTableData = ({ data }: Props): TableData => {
  const columns: DataTableColumn<TableRow>[] = [
    {
      accessor: "id",
      hidden: true,
    },
    {
      accessor: "rMonAssignedDate",
      title: "RMon Assigned Date",
      sortable: true,
    },
    {
      accessor: "companyName",
      title: "Company Name",
      sortable: true,
    },
    {
      accessor: "salesName",
      title: "Sales Name",
      sortable: true,
    },
    {
      accessor: "salesTeamName",
      title: "Sales Team",
      sortable: true,
    },
    {
      accessor: "cuInsuranceLimit",
      title: "Insurance Limit",
      sortable: true,
    },

    {
      accessor: "cuLimitRecommendation",
      title: "CU Limit Recommendation",
      sortable: true,
    },
    {
      accessor: "limitRecommendation",
      title: "RR Limit Recommendation",
      sortable: true,
    },
    {
      accessor: "limitType",
      title: "Limit Type",
      sortable: true,
    },
    {
      accessor: "cuAssignedName",
      title: "CU Assigned",
      sortable: true,
    },
    {
      accessor: "rrAssignedName",
      title: "RR Assigned",
      sortable: true,
    },
    {
      accessor: "committeeDate",
      title: "Committee Date",
      sortable: true,
    },
    {
      accessor: "requestStatus",
      title: "Request Status",
    },
  ];

  const rows = useMemo(() => {
    return data?.map((item) => {
      return {
        id: item.id,
        rMonAssignedDate: toLocalDate(item.rMonAssignedDate),
        companyName: item.company.name,
        salesName: item.company.salesName,
        salesTeamName: item.company.salesTeamName,
        cuInsuranceLimit: toPrice(
          item.creditUnderwriting?.insuranceLimitApproved
        ),
        limitRecommendation: toPrice(item.riskReview?.limitRecommendation),
        cuLimitRecommendation: toPrice(
          item.creditUnderwriting?.limitRecommendation
        ),
        limitType:
          item.limitRequestType && toHumanReadable(item.limitRequestType),
        cuAssignedName: item.cuAssigned && getUserDisplayName(item.cuAssigned),
        rrAssignedName: item.rrAssigned && getUserDisplayName(item.rrAssigned),
        committeeDate: toLocalDate(item?.creditApproval?.date),
        requestStatus: toHumanReadable(item.requestStatus),
      };
    });
  }, [data]);

  return [columns, rows];
};
