import type { SVGProps } from "react";
const SvgChevronLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 -960 960 960"
    fill="currentColor"
    {...props}
  >
    <path d="M560-267.692 347.692-480 560-692.308 588.308-664l-184 184 184 184L560-267.692Z" />
  </svg>
);
export default SvgChevronLeft;
