import { useQuery } from "urql";

import { GetClikListOfGrantedCreditOtherFacilitiesDocument } from "../../../../graphql/generated.ts";

interface Props {
  clikResultId: number;
}

export const useClikListOfGrantedCreditOtherFacilities = ({
  clikResultId,
}: Props) => {
  return useQuery({
    query: GetClikListOfGrantedCreditOtherFacilitiesDocument,
    variables: { filter: { clikResultId: { equals: clikResultId } } },
  });
};
