import isNil from "lodash/isNil";
import { useQuery } from "urql";

import { GetCustomerDirectorInfoDocument } from "../../../graphql/generated.ts";

interface Props {
  customerId: number | string | null | undefined;
}
export const useGetCustomerDirectorInfo = ({ customerId }: Props) => {
  return useQuery({
    query: GetCustomerDirectorInfoDocument,
    variables: { customerId: Number(customerId) },
    pause: isNil(customerId),
  });
};
