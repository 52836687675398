import { Popover, Text, UnstyledButton } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";

import ActionIcon from "../ActionIcon/ActionIcon.tsx";
import SvgEdit from "../Icons/Edit.tsx";

interface DisableButtonProps {
  text: string;
}

const DisableButton = ({ text }: DisableButtonProps) => {
  const [opened, { close, open }] = useDisclosure(false);

  return (
    <Popover variant="table" withinPortal opened={opened}>
      <Popover.Target>
        <UnstyledButton onMouseEnter={open} onMouseLeave={close}>
          <ActionIcon variant="disabled">
            <SvgEdit />
          </ActionIcon>
        </UnstyledButton>
      </Popover.Target>
      <Popover.Dropdown>
        <Text>{text}</Text>
      </Popover.Dropdown>
    </Popover>
  );
};

export default DisableButton;
