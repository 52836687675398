import { useQuery } from "urql";

import { GetClikListOfGrantedBondsDocument } from "../../../../graphql/generated";

interface Props {
  clikResultId: number;
}

export const useClikListOfGrantedBonds = ({ clikResultId }: Props) => {
  return useQuery({
    query: GetClikListOfGrantedBondsDocument,
    variables: { filter: { clikResultId: { equals: clikResultId } } },
  });
};
