import type { SVGProps } from "react";

const SvgDelete = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_2359_71266)">
      <path d="M2.5 3.6001H3.8H14.2" fill="currentColor" />
      <path
        d="M2.5 3.6001H3.8H14.2"
        stroke="currentColor"
        strokeWidth="0.625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.8969 3.6V12.7C12.8969 13.0448 12.7599 13.3754 12.5161 13.6192C12.2723 13.863 11.9417 14 11.5969 14H5.09687C4.75209 14 4.42143 13.863 4.17764 13.6192C3.93384 13.3754 3.79688 13.0448 3.79688 12.7V3.6M5.74687 3.6V2.3C5.74687 1.95522 5.88384 1.62456 6.12764 1.38076C6.37143 1.13696 6.70209 1 7.04687 1H9.64687C9.99166 1 10.3223 1.13696 10.5661 1.38076C10.8099 1.62456 10.9469 1.95522 10.9469 2.3V3.6"
        stroke="currentColor"
        strokeWidth="0.625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.05066 6.8501V10.7501"
        stroke="currentColor"
        strokeWidth="0.625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.64844 6.8501V10.7501"
        stroke="currentColor"
        strokeWidth="0.625"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_2359_71266">
        <rect
          width="15"
          height="15"
          fill="white"
          transform="translate(0.5 0.5)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SvgDelete;
