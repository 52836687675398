import { createStyles, rem } from "@mantine/core";

export const useStyles = createStyles((theme) => ({
  wrapper: {
    padding: `0 ${rem(20)}`,
    background: theme.colors.dusk[0],
    borderBottom: `1px solid ${theme.colors.golden[0]}`,
    height: "100%",
    color: theme.colors.white[0],
  },
  info: {
    fontSize: rem(14),
  },
  role: {
    color: theme.colors.golden[0],
  },
  divider: {
    borderColor: theme.colors.dusk[8],
  },
}));
