import { Badge, Button, Flex, Select, Text } from "@mantine/core";
import { DataTableColumn } from "mantine-datatable";
import { useCallback, useMemo } from "react";

import CPExceptionTableCard from "../../../../components/CPExceptionTableCard/CPExceptionTableCard.tsx";
import InfoTooltip from "../../../../components/InfoTooltip/InfoTooltip.tsx";
import { TableData, TableRow } from "../../../../components/Table/types.ts";
import { boolSelectorOptions } from "../../../../constants.ts";
import {
  PaymentType,
  UpdateTransactionHistoryInput,
} from "../../../../graphql/generated.ts";
import { TransactionHistory } from "../../../../types/transactionHistory.ts";
import { booleanToString, stringToBoolean } from "../../../../utils/boolean.ts";
import { toLocalDate } from "../../../../utils/date.ts";
import { toPrice } from "../../../../utils/number.ts";
import { toHumanReadable } from "../../../../utils/string.ts";
import { ModalType } from "../types.ts";

interface Props {
  data: TransactionHistory[] | null | undefined;
  onApprove: (id: number) => void;
  onOpenEditModal: (type: ModalType, value: TransactionHistory) => void;
  onInlineEdit: (id: number, input: UpdateTransactionHistoryInput) => void;
}

export const useTransactionHistoryTableData = ({
  data,
  onApprove,
  onOpenEditModal,
  onInlineEdit,
}: Props): TableData => {
  const columns: DataTableColumn<TableRow>[] = [
    {
      accessor: "id",
      hidden: true,
    },
    {
      accessor: "quotationNo",
      title: "Quotation No",
      sortable: true,
      width: 200,
    },
    {
      accessor: "saleOrderNumber",
      title: "SO No",
      sortable: true,
      width: 200,
    },
    {
      accessor: "invoiceNumber",
      title: "Inv No",
      width: 200,
    },
    {
      accessor: "orderDate",
      title: "Order Date",
      sortable: true,
    },
    {
      accessor: "salesName",
      title: "Sales Name",
      sortable: true,
    },
    {
      accessor: "salesTeam",
      title: "Sales Team",
      sortable: true,
    },
    {
      accessor: "paymentType",
      title: "Payment Type",
      sortable: true,
    },
    {
      accessor: "paymentMethod",
      title: "Payment Method",
      sortable: true,
    },
    // {
    //   accessor: "limitUsed",
    //   title: "Limit Used",
    //   sortable: true,
    // },
    {
      accessor: "orderStatus",
      title: "Order Status",
      sortable: true,
    },
    {
      accessor: "totalInvoiced",
      title: "Total Invoiced",
      sortable: true,
    },
    {
      accessor: "totalPaid",
      title: "Total Paid",
      sortable: true,
    },
    {
      accessor: "totalAr",
      title: "Total AR",
      sortable: true,
    },
    {
      accessor: "dpd",
      title: "DPD",
      sortable: true,
    },
    {
      accessor: "paymentStatus",
      title: "Payment Status",
      sortable: true,
    },
    {
      accessor: "hasException",
      title: (
        <Flex align="center">
          <Text>Has Exceptions</Text>
          <InfoTooltip tooltipText="Only COD payment type can be changed." />
        </Flex>
      ),
      sortable: true,
    },
    {
      accessor: "isRestructured",
      title: "Is Restructured",
      sortable: true,
    },
    {
      accessor: "invoiceDueDate",
      title: "Inv Due Date",
      sortable: true,
    },
    {
      accessor: "outstandingPrincipal",
      title: "OSP",
      sortable: true,
    },
    {
      accessor: "lateCharge",
      title: "Late Charge",
      sortable: true,
    },
    {
      accessor: "conditionPrecedents",
      title: (
        <Flex align="center">
          <Text>Condition Precedents</Text>
          <InfoTooltip tooltipText="You can add an condition precedent only if a limit exists in the transaction history record." />
        </Flex>
      ),
      sortable: false,
      width: 300,
    },
    {
      accessor: "exceptions",
      title: (
        <Flex align="center">
          <Text>Exceptions</Text>
          <InfoTooltip tooltipText="You can add an exception only if a limit exists in the transaction history record." />
        </Flex>
      ),
      sortable: false,
      width: 300,
    },
    {
      accessor: "isApproved",
      title: (
        <Flex align="center">
          <Text>Approval</Text>
          <InfoTooltip tooltipText="Only TOP payment type can be approved" />
        </Flex>
      ),
      sortable: true,
      width: 200,
    },
  ];

  const handleApprove = useCallback(
    (id: number) => {
      onApprove(id);
    },
    [onApprove]
  );

  const handleOpenEditModal = useCallback(
    (type: ModalType, value: TransactionHistory) => {
      onOpenEditModal(type, value);
    },
    [onOpenEditModal]
  );

  const handleInlineEdit = useCallback(
    (id: number, input: UpdateTransactionHistoryInput) => {
      onInlineEdit(id, input);
    },
    [onInlineEdit]
  );

  const getExceptionColumn = useCallback(
    (item: TransactionHistory) => {
      if (item.paymentType === PaymentType.Cod) {
        return (
          <Select
            data={boolSelectorOptions}
            value={item?.hasException?.toString()}
            withinPortal
            zIndex={100}
            size="s"
            onChange={(value) =>
              handleInlineEdit(item.id, {
                hasException: stringToBoolean(value, true),
              })
            }
          />
        );
      }
      return item.hasException ? "True" : "False";
    },
    [handleInlineEdit]
  );

  const getRow = useCallback(
    (item: TransactionHistory): TableRow => {
      const conditionPrecedents = item.limit?.limitRequest?.conditionPrecedents;
      const exceptions = item?.limit?.limitRequest?.exceptions;
      return {
        id: item.id,
        quotationNo: item.quotationNo,
        saleOrderNumber: item.saleOrderNumber,
        invoiceNumber: item.invoice?.invoiceNumber,
        orderDate: toLocalDate(item.orderDate),
        salesName: item.salesName,
        salesTeam: item.salesTeam,
        paymentType: toHumanReadable(item.paymentType),
        paymentMethod: toHumanReadable(item.paymentMethod),
        // limitUsed: toPrice(item.limitUsed),
        orderStatus: toHumanReadable(item.orderStatus),
        totalInvoiced: toPrice(item.invoice?.totalInvoice),
        totalPaid: toPrice(item.invoice?.totalPaid),
        totalAr: toPrice(item.invoice?.totalAccountReceivable),
        dpd: item.invoice?.dpd,
        paymentStatus:
          item.invoice?.paymentStatus &&
          toHumanReadable(item.invoice?.paymentStatus),
        hasException: getExceptionColumn(item),
        isRestructured: booleanToString(item?.invoice?.isRestructured),
        invoiceDueDate: toLocalDate(item.invoice?.invoiceDueDate),
        outstandingPrincipal: toPrice(0),
        lateCharge: toPrice(item.invoice?.lateCharge),
        conditionPrecedents: (
          <CPExceptionTableCard
            data={conditionPrecedents}
            isEdit={!!item.limit?.limitRequest?.id}
            onEdit={() =>
              item.limit?.limitRequest?.id &&
              handleOpenEditModal(ModalType.ConditionPrecedents, item)
            }
          />
        ),
        exceptions: (
          <CPExceptionTableCard
            data={exceptions}
            isEdit={!!item.limit?.limitRequest?.id}
            onEdit={() => handleOpenEditModal(ModalType.Exceptions, item)}
          />
        ),
        isApproved: (
          <Flex justify="center">
            {item?.isApproved ? (
              <Badge variant="active" w="80%" size="sm">
                Approved
              </Badge>
            ) : (
              <Button
                variant="filledBlue"
                disabled={item.paymentType !== PaymentType.Top}
                uppercase
                onClick={() => handleApprove(item.id)}
              >
                Approve
              </Button>
            )}
          </Flex>
        ),
      };
    },
    [handleApprove, handleOpenEditModal, getExceptionColumn]
  );

  const rows = useMemo(() => {
    return data?.map(getRow);
  }, [data, getRow]);

  return [columns, rows];
};
