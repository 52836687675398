import { Flex } from "@mantine/core";

import SectionTitle from "../../../components/Section/SectionTitle/SectionTitle.tsx";
import Table from "../../../components/Table/Table";
import { QueryDashboardOutstandingPrincipalPerCollectionArgs } from "../../../graphql/generated";
import { useDashboardOutstandingPrincipalPerCollection } from "../../../hooks/api/dashboard/useDashboardOutstandingPrincipalPerCollection";
import { useOspPerBucketTableData } from "./hooks/useOspPerBucketTableData";

interface Props {
  filter: QueryDashboardOutstandingPrincipalPerCollectionArgs["salesTeamName"];
}

export default function OutstandingPrincipalPerBucket({ filter }: Props) {
  const [{ data, fetching }] = useDashboardOutstandingPrincipalPerCollection({
    salesTeamName: filter,
  });

  const [columns, rows] = useOspPerBucketTableData({
    data: data?.dashboardOutstandingPrincipalPerCollection,
  });

  return (
    <Flex direction="column" gap={12}>
      <SectionTitle variant="bronze">
        Outstanding Principal per Collection Bucket
      </SectionTitle>
      <Table minWidth="100%" columns={columns} rows={rows} loading={fetching} />
    </Flex>
  );
}
