import { useQuery } from "urql";

import {
  ClikGrantedCreditOtherFacilityFilter,
  ClikGrantedCreditOtherFacilityOrderBy,
  GetClikGrantedCreditOtherFacilityDocument,
} from "../../../../graphql/generated.ts";
import { getApiPaginationData } from "../../../../utils/api.ts";
import { ApiHookProps } from "../../types.ts";

export const useClikGrantedCreditOtherFacilities = ({
  filter,
  orderBy,
  page,
}: ApiHookProps<
  ClikGrantedCreditOtherFacilityFilter,
  ClikGrantedCreditOtherFacilityOrderBy
>) => {
  const pagination = getApiPaginationData(page);
  const variables = {
    filter,
    orderBy,
    skip: pagination?.skip,
    take: pagination?.take,
  };
  return useQuery({
    query: GetClikGrantedCreditOtherFacilityDocument,
    variables,
  });
};
