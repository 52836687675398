import { MantineTheme } from "@mantine/core";

import { ThemeComponent } from "./helpers.ts";

const goldenCheckbox = (theme: MantineTheme) => ({
  root: {
    input: {
      "&:checked": {
        backgroundColor: theme.colors.white[0],
        borderColor: theme.colors.grayIce[0],
      },
    },
  },
  icon: {
    color: `${theme.colors.golden[0]}!important`,
  },
});

export default function checkboxTheme(): ThemeComponent {
  return {
    variants: {
      golden: goldenCheckbox,
    },
  };
}
