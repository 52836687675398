import { useQuery } from "urql";

import {
  GetCollectorsDocument,
  UserRoleFilterOptions,
  UserStatusFilterOptions,
} from "../../../graphql/generated";

export function useCollectors() {
  const variables = {
    filter: {
      role: {
        in: [
          UserRoleFilterOptions.CollectionOfficer,
          UserRoleFilterOptions.CollectionManager,
          UserRoleFilterOptions.Admin,
          UserRoleFilterOptions.SuperAdmin,
        ],
      },
      status: { equals: UserStatusFilterOptions.Active },
    },
  };
  return useQuery({
    query: GetCollectorsDocument,
    variables,
  });
}
