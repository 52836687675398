import { AppRoute } from "../../constants";
import { RolePermission } from "../../graphql/generated";
import { User } from "../../types/user.ts";
import {
  canViewClikDataMenu,
  canViewCreditLimitMenu,
} from "../../utils/permissions.ts";
import { hasPermission } from "../../utils/user.ts";
import SvgCreditCard from "../Icons/CreditCard.tsx";
import SvgFile from "../Icons/File.tsx";
import SvgFolder from "../Icons/Folder.tsx";
import SvgSettings from "../Icons/Settings.tsx";

export const navigationLinks = [
  {
    label: "Setting",
    icon: <SvgSettings />,
    nestedLinks: [
      {
        label: "User Access",
        url: AppRoute.UserAccessSetting,
      },
    ],
    show: (user: User | null | undefined) =>
      hasPermission(user, RolePermission.CanViewUserAccessSetting),
  },
  {
    label: "Collection",
    icon: <SvgFolder />,
    show: (user: User | null | undefined) =>
      hasPermission(user, RolePermission.CanViewCollection) ||
      hasPermission(user, RolePermission.CanViewActivityManagement),
    nestedLinks: [
      {
        label: "Collection Dashboard",
        url: AppRoute.CollectionDashboard,
        show: (user: User | null | undefined) =>
          hasPermission(user, RolePermission.CanViewCollection),
      },
      {
        label: "Activity Management",
        url: AppRoute.ActivityManagement,
        show: (user: User | null | undefined) =>
          hasPermission(user, RolePermission.CanViewActivityManagement),
      },
      {
        label: "Review & Approval",
        url: AppRoute.ReviewAndApproval,
        show: (user: User | null | undefined) =>
          hasPermission(user, RolePermission.CanViewReviewApproval),
      },
    ],
  },
  {
    label: "CLIK",
    icon: <SvgFile />,
    show: (user: User | null | undefined) => canViewClikDataMenu(user),
    nestedLinks: [
      {
        label: "CLIK Data",
        url: AppRoute.ClikData,
        show: (user: User | null | undefined) =>
          hasPermission(user, RolePermission.CanViewClikData),
      },
      {
        label: "Employee Candidate",
        url: AppRoute.EmployeeCandidate,
        show: (user: User | null | undefined) =>
          hasPermission(user, RolePermission.CanViewEmployeeCandidateClik),
      },
    ],
  },
  {
    label: "Credit Limit",
    icon: <SvgCreditCard />,
    show: (user: User | null | undefined) => canViewCreditLimitMenu(user),
    nestedLinks: [
      {
        label: "Risk Funnel Dashboard",
        url: AppRoute.RiskFunnelDashboard,
        show: (user: User | null | undefined) =>
          hasPermission(user, RolePermission.CanViewRiskFunnelDashboard),
      },
      {
        label: "Credit Underwriting",
        url: AppRoute.CreditUnderwriting,
        show: (user: User | null | undefined) =>
          hasPermission(user, RolePermission.CanViewCreditUnderwriting),
      },
      {
        label: "Risk Review",
        url: AppRoute.RiskReview,
        show: (user: User | null | undefined) =>
          hasPermission(user, RolePermission.CanViewRiskReview),
      },
      {
        label: "Risk Monitoring",
        url: AppRoute.RiskMonitoring,

        show: (user: User | null | undefined) =>
          hasPermission(user, RolePermission.CanViewRiskMonitoring),
      },
    ],
  },
];
