import { createStyles, rem } from "@mantine/core";

export const useStyles = createStyles((theme) => ({
  buttonWrapper: {
    width: rem(240),
    height: rem(50),
    background: "#4688f1",
    color: theme.colors.white[0],
    boxShadow: "0 3px 4px 0 rgba(0,0,0,.25)",
    display: "flex",
    alignItems: "center",
    gap: rem(20),
    borderRadius: rem(4),

    "&:hover": {
      boxShadow: `0 0 6px #4688f1`,
    },
    "&:active": {
      background: "#4688f1",
    },
  },
  iconWrapper: {
    background: theme.colors.white[0],
    width: rem(46),
    height: rem(46),
    margin: rem(2),
    borderRadius: rem(2),
  },
  buttonLink: {
    textDecoration: "none",
  },
  buttonText: {
    fontFamily: "Roboto",
    fontSize: rem(16),
  },
}));
