import { Flex } from "@mantine/core";

import { useCurrentUserContext } from "../../components/Auth/CurrentUserProvider.tsx";
import Layout from "../../components/Layout/Layout";
import Title from "../../components/Title/Title";
import { RolePermission } from "../../graphql/generated";
import { hasPermission } from "../../utils/user.ts";
import Page404 from "../404/Page404.tsx";
import RestructureReview from "./RestructureReview/RestructureReview";
import WaiveReview from "./WaiveReview/WaiveReview";

export default function ReviewPage() {
  const { user } = useCurrentUserContext();

  const hasAccess = hasPermission(user, RolePermission.CanViewReviewApproval);

  if (!hasAccess) {
    return <Page404 />;
  }

  return (
    <Layout>
      <Flex direction="column" gap={{ base: 16, sm: 24 }}>
        <Title>Review & Approval</Title>

        <RestructureReview />

        <WaiveReview />
      </Flex>
    </Layout>
  );
}
