import { DataTableSortStatus } from "mantine-datatable";
import { useCallback, useState } from "react";

import {
  ApproveWaiveMutationVariables,
  CreateWaiveInput,
  OrderBy,
  RolePermission,
  UpdateWaiveMutationVariables,
  WaiveFilter,
  WaiveOrderBy,
} from "../../graphql/generated";
import { useApproveWaive } from "../../hooks/api/waive/useApproveWaive";
import { useCreateWaive } from "../../hooks/api/waive/useCreateWaive";
import { useUpdateWaive } from "../../hooks/api/waive/useUpdateWaive";
import { useWaive } from "../../hooks/api/waive/useWaive";
import { useMutationNotificationWrapper } from "../../hooks/useMutationNotificationWrapper";
import { Waive } from "../../types/waive";
import { getPagesCount } from "../../utils/pagination";
import { hasPermission } from "../../utils/user.ts";
import { useCurrentUserContext } from "../Auth/CurrentUserProvider.tsx";
import Table from "../Table/Table";
import { SortDirection } from "../Table/types";
import { useTableWaiveData } from "./hooks/useTableWaiveData";
import { useStyles } from "./TableWaive.styles";
import { getWaiveApiSort } from "./utils";
import WaiveApproveModal from "./WaiveApproveModal/WaiveApproveModal";
import WaiveEditModal from "./WaiveEditModal/WaiveEditModal";
import WaiveToolbar from "./WaiveToobar/WaiveToolbar";

interface TableWaiveProps {
  canAdd?: boolean;
  filter?: WaiveFilter;
}

export default function TableWaive({
  canAdd = false,
  filter,
}: TableWaiveProps) {
  const { classes } = useStyles();

  const { user } = useCurrentUserContext();

  const showEditModal = hasPermission(user, RolePermission.CanEditWaive);

  const showApproveModal = hasPermission(
    user,
    RolePermission.CanEditReviewApproval
  );

  const showActionsColumn = showEditModal || showApproveModal;

  const [orderBy, setOrderBy] = useState<WaiveOrderBy>({
    createdAt: OrderBy.Desc,
  });

  const [page, setPage] = useState<number>(1);

  const [{ data: waivesData, fetching: waivesFetching }, refetchWaives] =
    useWaive({
      orderBy,
      filter,
      page,
    });

  const waives = waivesData?.waives?.data;

  const pageCount = getPagesCount(waivesData?.waives?.total);

  const [, createWaive] = useMutationNotificationWrapper(useCreateWaive(), {
    success: { message: "Waive Request has been added." },
  });

  const [, updateWaive] = useMutationNotificationWrapper(useUpdateWaive(), {
    success: { message: "Waive Request has been updated." },
  });

  const [, approveWaive] = useMutationNotificationWrapper(useApproveWaive(), {
    success: { message: "Waive Request has been updated." },
  });

  const [editedRow, setEditedRow] = useState<Waive | null | undefined>(null);

  const openEditModal = (id: number) => {
    if (!waives) {
      return;
    }
    const row = waives.find((waive: Waive) => waive.id === id);
    setEditedRow(row);
  };

  const closeEditModal = () => {
    setEditedRow(null);
  };

  const [columns, rows] = useTableWaiveData({
    data: waives,
    showActionsColumn: showActionsColumn,
    onEdit: openEditModal,
  });

  const handleOnSortChange = useCallback(
    (sort: DataTableSortStatus) => {
      setOrderBy(getWaiveApiSort(sort));
    },
    [setOrderBy]
  );

  const handlePageChange = useCallback(
    (page: number) => {
      setPage(page);
    },
    [setPage]
  );

  const handleCreate = (input: CreateWaiveInput) => {
    createWaive({ input }).then((data) => {
      if (!data.error) {
        refetchWaives();
      }
    });
  };

  const handleUpdate = (variables: UpdateWaiveMutationVariables) => {
    updateWaive(variables).then((data) => {
      if (!data.error) {
        refetchWaives();
      }
    });
  };

  const handleApprove = (variables: ApproveWaiveMutationVariables) => {
    approveWaive(variables).then((data) => {
      if (!data.error) {
        refetchWaives();
      }
    });
  };

  return (
    <>
      <div className={classes.wrapper}>
        {canAdd && <WaiveToolbar onSubmit={handleCreate} />}

        <Table
          columns={columns}
          rows={rows}
          pagination={{
            pageCount: pageCount,
            page: page,
            onPageChange: handlePageChange,
          }}
          defaultSort={{
            columnAccessor: "createdAt",
            direction: SortDirection.desc,
          }}
          loading={waivesFetching}
          onSortChange={handleOnSortChange}
        />

        {showEditModal && editedRow && (
          <WaiveEditModal
            value={editedRow}
            opened={!!editedRow}
            onClose={closeEditModal}
            onSubmit={handleUpdate}
          />
        )}

        {showApproveModal && editedRow && (
          <WaiveApproveModal
            value={editedRow}
            opened={!!editedRow}
            onClose={closeEditModal}
            onSubmit={handleApprove}
          />
        )}
      </div>
    </>
  );
}
