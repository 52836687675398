import { Button, Flex, MediaQuery, Text } from "@mantine/core";

import Plus from "../../Icons/Plus.tsx";
import { useStyles } from "./AddPengurusButton.styles.ts";

interface AddPengurusButtonProps {
  onClick: () => void;
}

const AddPengurusButton = ({ onClick }: AddPengurusButtonProps) => {
  const { classes } = useStyles();

  return (
    <>
      <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
        <Flex
          direction="column"
          gap={20}
          justify="center"
          align="center"
          className={classes.buttonWrapper}
          onClick={onClick}
        >
          <Plus />
          <Text>Add Pengurus</Text>
        </Flex>
      </MediaQuery>
      <MediaQuery largerThan="sm" styles={{ display: "none" }}>
        <Button
          leftIcon={<Plus />}
          variant="filledGolden"
          w="100%"
          uppercase
          onClick={onClick}
        >
          Add Pengurus
        </Button>
      </MediaQuery>
    </>
  );
};

export default AddPengurusButton;
