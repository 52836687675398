import { Button, Flex, Text, Title } from "@mantine/core";
import { useNavigate } from "react-router-dom";

import { AppRoute } from "../../constants";
import { useStyles } from "./Page404.styles.ts";

export default function Page404() {
  const { classes } = useStyles();

  const navigate = useNavigate();

  return (
    <Flex
      direction="column"
      gap={40}
      justify="center"
      align="center"
      h="100vh"
      className={classes.errorPageWrapper}
    >
      <Flex direction="column" gap={20} align="center" maw={420}>
        <Title className={classes.title}>You have found a secret place</Title>
        <Text className={classes.textWrapper}>
          This page doesn&apos;t exist or you don&apos;t have access to it.
          Please contact your administrator.
        </Text>
      </Flex>
      <Button
        className={classes.backButton}
        variant="filledGolden"
        uppercase
        onClick={() => navigate(AppRoute.Home)}
      >
        Go to main page
      </Button>
    </Flex>
  );
}
