import type { SVGProps } from "react";
const SvgUploadFileError = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 -960 960 960"
    fill="currentColor"
    {...props}
  >
    <path d="m480-760 141.539 141.539-28.308 28.769L500-682.923v110.462l-40-40.001v-67.692l-40.769 40.769-29.923-29.923L480-760Zm-20 423.077V-500l40 40v123.077h-40Zm333.308 227.308L702.923-200H264.615Q237-200 218.5-218.5 200-237 200-264.615v-96.923h40v96.923q0 9.23 7.692 16.923Q255.385-240 264.615-240h398.308L109.615-793.308l28.539-28.538 683.692 683.692-28.538 28.539Zm-33.308-189-40-40v-22.923h40v62.923Z" />
  </svg>
);
export default SvgUploadFileError;
