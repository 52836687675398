import { Flex, SimpleGrid } from "@mantine/core";

import MultiFieldCard from "../../../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import SectionTitle from "../../../../components/Section/SectionTitle/SectionTitle.tsx";
import Table from "../../../../components/Table/Table.tsx";
import { CURRENCY } from "../../../../constants.ts";
import { useClikContractHistorySummary } from "../../../../hooks/api/clikResult/useClikContractHistorySummary.ts";
import { useClikContractSummary } from "../../../../hooks/api/clikResult/useClikContractSummary.ts";
import { useStyles } from "../ClikResultDetails.styles.tsx";
import { useCRContractsSummary } from "./hooks/useCRContractsSummary.tsx";
import { useCRContractSummaryCardInfo } from "./hooks/useCRContractSummaryCardInfo.tsx";
import { useCRFinancialSummaryCardsInfo } from "./hooks/useCRFinancialSummaryCardsInfo.tsx";

interface ClikResultSummaryProps {
  clikResultId: number;
}

const ClikResultContractSummary = ({
  clikResultId,
}: ClikResultSummaryProps) => {
  const { classes } = useStyles();

  const [
    { data: dataClikContractSummary, fetching: fetchingClikContractSummary },
  ] = useClikContractSummary({ clikResultId });
  const [
    {
      data: dataClikContractHistorySummary,
      fetching: fetchingClikContractHistorySummary,
    },
  ] = useClikContractHistorySummary({ clikResultId });

  const clikContractSummary = dataClikContractSummary?.clikContractSummary;
  const clikContractHistorySummary =
    dataClikContractHistorySummary?.clikContractHistorySummary;

  const contractsSummaryCardItems = useCRContractSummaryCardInfo({
    data: clikContractHistorySummary,
  });

  const [contractSummaryColumns, contractSummaryRows] = useCRContractsSummary({
    data: clikContractSummary,
  });

  const financialSummary = useCRFinancialSummaryCardsInfo({
    data: clikContractSummary,
    currency: clikContractHistorySummary?.currency ?? CURRENCY,
  });

  return (
    <Flex direction="column" gap={20}>
      <Flex direction="column" gap={16}>
        <SectionTitle variant="bronze">Contract Summary</SectionTitle>
        <Flex gap={12} className={classes.contractBlockWrapper}>
          <Flex w="33.3%" className={classes.contractBlockItem}>
            <MultiFieldCard
              items={contractsSummaryCardItems}
              label="Key Values"
              variant="bronze"
              loading={fetchingClikContractHistorySummary}
            />
          </Flex>
          <Flex
            w={contractsSummaryCardItems.length > 0 ? "77.7%" : "100%"}
            className={classes.contractBlockItem}
          >
            <Table
              columns={contractSummaryColumns}
              rows={contractSummaryRows}
              minWidth="100%"
              loading={fetchingClikContractHistorySummary}
            />
          </Flex>
        </Flex>
      </Flex>
      <Flex direction="column" gap={16}>
        <SectionTitle variant="bronze">Financial Summary</SectionTitle>
        <SimpleGrid
          cols={3}
          spacing={20}
          verticalSpacing={20}
          breakpoints={[
            { maxWidth: "sm", cols: 1 },
            { maxWidth: "lg", cols: 2 },
          ]}
        >
          <MultiFieldCard
            {...financialSummary.credit}
            loading={fetchingClikContractSummary}
          />
          <MultiFieldCard
            {...financialSummary.irrevocable}
            loading={fetchingClikContractSummary}
          />
          <MultiFieldCard
            {...financialSummary.other}
            loading={fetchingClikContractSummary}
          />
          <MultiFieldCard
            {...financialSummary.bond}
            loading={fetchingClikContractSummary}
          />
          <MultiFieldCard
            {...financialSummary.guarantee}
            loading={fetchingClikContractSummary}
          />
        </SimpleGrid>
      </Flex>
    </Flex>
  );
};

export default ClikResultContractSummary;
