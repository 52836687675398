import {
  Center,
  Flex,
  Image,
  Text,
  ThemeIcon,
  UnstyledButton,
} from "@mantine/core";
import { useNavigate } from "react-router-dom";

import { useCurrentUserContext } from "../../components/Auth/CurrentUserProvider.tsx";
import Logo from "../../components/Icons/Logo.tsx";
import Preloader from "../../components/Preloader/Preloader.tsx";
import appConfig from "../../config.ts";
import { AppRoute } from "../../constants";
import { useStyles } from "./LoginPage.styles.ts";

export default function LoginPage() {
  const { classes } = useStyles();

  const { user, fetching } = useCurrentUserContext();
  const navigate = useNavigate();

  if (fetching) {
    return <Preloader loading={true} />;
  }

  if (user) {
    navigate(AppRoute.Home);
  }

  return (
    <Center mx="auto" h="100vh">
      <Flex direction="column" align="center" gap={60}>
        <Logo />
        <a
          href={`${appConfig.apiUrl}${AppRoute.Login}/federated/google`}
          className={classes.buttonLink}
        >
          <UnstyledButton className={classes.buttonWrapper}>
            <ThemeIcon className={classes.iconWrapper}>
              <Image
                height={20}
                width={20}
                src="https://www.svgrepo.com/show/475656/google-color.svg"
              />
            </ThemeIcon>
            <Text className={classes.buttonText}>Sign in with Google</Text>
          </UnstyledButton>
        </a>
      </Flex>
    </Center>
  );
}
