import { padStart, pickBy } from "lodash";
import moment from "moment-timezone";

import {
  GiroManagementFilter,
  GiroType,
} from "../../../../graphql/generated.ts";
import { GiroManagementFiltersValue } from "./types.ts";

export const getGiroManagementApiFilter = (
  value: GiroManagementFiltersValue
): GiroManagementFilter => {
  const dueDatePeriod =
    !!value.dueDate && !!value.dueDate[0] && !!value.dueDate[1];

  return pickBy({
    type: value.type && { equals: value.type },
    status: value.status && { equals: value.status },
    dueDate: !!value.dueDate &&
      dueDatePeriod && {
        gte: value.dueDate[0],
        lte: value.dueDate[1],
      },
  });
};

export const getGiroNumber = (
  type: GiroType | undefined,
  partnerCode: string | null | undefined,
  count: number | null | undefined
) => {
  const parsedDate = moment(new Date(), "ddd MMM DD YYYY HH:mm:ss [GMT]ZZ");
  const formattedDate = parsedDate.format("DD-MM-YY");
  const formattedCount = padStart((count ? count + 1 : 1).toString(), 4, "0");
  return `${type}-${partnerCode}-${formattedDate}-${formattedCount}`;
};
