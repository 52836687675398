import { Flex, Select, SimpleGrid } from "@mantine/core";
import { SelectItem } from "@mantine/core/lib/Select/types";
import { isEmpty } from "lodash";
import isNil from "lodash/isNil";
import { DataTableSortStatus } from "mantine-datatable";
import { useCallback, useState } from "react";

import MultiFieldCard from "../../../../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import NoDataMessage from "../../../../../components/NoDataMessage/NoDataMessage.tsx";
import SectionTitle from "../../../../../components/Section/SectionTitle/SectionTitle.tsx";
import Table from "../../../../../components/Table/Table.tsx";
import { SortDirection } from "../../../../../components/Table/types.ts";
import Title from "../../../../../components/Title/Title.tsx";
import {
  ClikContractProfileFilter,
  ClikContractProfileOrderBy,
  ContractType,
  OrderBy,
} from "../../../../../graphql/generated.ts";
import { useClikContractProfiles } from "../../../../../hooks/api/clikResult/clikContract/useClikContractProfiles.ts";
import { ClikGrantedContractDetails } from "../../../../../types/clikContract.ts";
import { getApiOrderBy } from "../../../../../utils/api.ts";
import { getPagesCount } from "../../../../../utils/pagination.ts";
import { clikResultTitle } from "../contants.ts";
import ClikResultCollateral from "./ClikResultCollateral/ClikResultCollateral.tsx";
import { contractDetailsTitle } from "./constants.ts";
import { useCRContractDetailsCardsInfo } from "./hooks/useCRContractDetailsCardsInfo.tsx";
import { useCRCreditLimitDebitBalanceCardsInfo } from "./hooks/useCRCreditLimitDebitBalanceCardsInfo.tsx";
import { useCRGrantedDetailsOfContract } from "./hooks/useCRGrantedDetailsOfContract.tsx";
import { useCRGuarantorsDetails } from "./hooks/useCRGuarantorsDetails.tsx";
import { useCRHistoricalData } from "./hooks/useCRHistoricalData.tsx";
import { useCRMaximumDetailsOfContract } from "./hooks/useCRMaximumDetailsOfContract.tsx";
import { useCROverdueDetailsOfContract } from "./hooks/useCROverdueDetailsOfContract.tsx";
import { useCRRestructuringDetailsOfContract } from "./hooks/useCRRestructuringDetailsOfContract.tsx";

interface ClikResultDetailsOfContractProps {
  type: ContractType;
  loading: boolean;
  data: ClikGrantedContractDetails;
  clikContractProfileFilter: ClikContractProfileFilter | undefined;
  selectedContractId?: number | null;
  detailsOptions: SelectItem[];
  onSetSelectedContractId: (value: number | null) => void;
}

const ClikResultDetailsOfContract = ({
  loading,
  type,
  data,
  clikContractProfileFilter,
  selectedContractId,
  detailsOptions,
  onSetSelectedContractId,
}: ClikResultDetailsOfContractProps) => {
  const [page, setPage] = useState<number>(1);

  const [orderBy, setOrderBy] = useState<ClikContractProfileOrderBy>({
    id: OrderBy.Desc,
  });

  const [
    {
      data: clikContractProfilesResponse,
      fetching: fetchingClikContractProfiles,
    },
  ] = useClikContractProfiles({
    filter: clikContractProfileFilter,
    orderBy,
    page,
    pause: isNil(clikContractProfileFilter),
  });

  const clikContractProfilesData =
    clikContractProfilesResponse?.clikContractProfiles?.data;

  const clikContractProfilesCount =
    clikContractProfilesResponse?.clikContractProfiles?.total;

  const pageCount = getPagesCount(clikContractProfilesCount);

  const { main: mainDetails } = useCRContractDetailsCardsInfo({
    data: data?.commonData,
    information: data?.information,
  });

  const [historicalDataColumns, historicalDataRows] = useCRHistoricalData({
    data: clikContractProfilesData,
  });

  const creditLimitDebitBalanceCardsInfo =
    useCRCreditLimitDebitBalanceCardsInfo({ data, type });

  const overdueDetails = useCROverdueDetailsOfContract({ data, type });

  const restructuringDetails = useCRRestructuringDetailsOfContract({ data });

  const maximumDetails = useCRMaximumDetailsOfContract({
    data: data?.maximum,
    type,
  });

  const grantedDetails = useCRGrantedDetailsOfContract({ data, type });

  const guarantors = useCRGuarantorsDetails({ data: data?.guarantors });

  const handleSortChange = useCallback(
    (sort: DataTableSortStatus) => {
      setOrderBy(getApiOrderBy(sort));
    },
    [setOrderBy]
  );

  const handlePageChange = useCallback(
    (page: number) => {
      setPage(page);
    },
    [setPage]
  );

  const handleOnChangeSelectedContractId = useCallback(
    (value: string) => {
      if (isNil(value)) {
        onSetSelectedContractId(null);
        return;
      }
      onSetSelectedContractId(Number(value));
    },
    [onSetSelectedContractId]
  );

  if (isEmpty(detailsOptions)) {
    return (
      <Flex direction="column" gap={20}>
        <SectionTitle variant="bronze">
          {`Details of ${clikResultTitle[type]}`}
        </SectionTitle>
        {loading ? (
          <MultiFieldCard items={[]} cols={2} variant="blue" loading={true} />
        ) : (
          <NoDataMessage minWidth="100%" />
        )}
      </Flex>
    );
  }

  return (
    <Flex direction="column" gap={20}>
      <SectionTitle variant="bronze">
        {`Details of ${clikResultTitle[type]}`}
      </SectionTitle>

      <Flex maw={566}>
        <Select
          miw={300}
          data={detailsOptions}
          value={selectedContractId?.toString() ?? null}
          placeholder="Select to View Details"
          label="Select to View Details"
          clearable
          onChange={handleOnChangeSelectedContractId}
        />
      </Flex>

      {!!selectedContractId && (
        <>
          <MultiFieldCard
            label={`Detail of ${clikResultTitle[type]}`}
            variant="bronze"
            loading={loading}
            cols={2}
            items={mainDetails}
          />
          <Flex direction="column" gap={16}>
            <Title size="h2">Historical Data</Title>
            <Table
              loading={fetchingClikContractProfiles}
              columns={historicalDataColumns}
              rows={historicalDataRows}
              minWidth="100%"
              defaultSort={{
                columnAccessor: "invoiceNumber",
                direction: SortDirection.asc,
              }}
              pagination={{
                pageCount: pageCount,
                page: page,
                onPageChange: handlePageChange,
              }}
              onSortChange={handleSortChange}
            />
          </Flex>
          <MultiFieldCard
            label={`Granted ${contractDetailsTitle[type]}`}
            items={grantedDetails}
            cols={2}
            variant="blue"
            loading={loading}
          />
          <SimpleGrid cols={2} breakpoints={[{ maxWidth: "lg", cols: 1 }]}>
            <MultiFieldCard
              label={
                type === ContractType.OtherFacilities
                  ? "Debit Balance"
                  : "Credit Limit/Debit Balance"
              }
              variant="blue"
              loading={loading}
              items={creditLimitDebitBalanceCardsInfo}
            />
            {(type === ContractType.Credit ||
              type === ContractType.Bond ||
              type === ContractType.OtherFacilities) && (
              <MultiFieldCard
                label="Overdue"
                variant="blue"
                loading={loading}
                items={overdueDetails}
              />
            )}
            {type === ContractType.Credit && (
              <MultiFieldCard
                label="Restructuring"
                variant="blue"
                loading={loading}
                items={restructuringDetails}
              />
            )}
            <MultiFieldCard
              label={`${contractDetailsTitle[type]} Maximum`}
              variant="blue"
              loading={loading}
              items={maximumDetails}
            />
          </SimpleGrid>
          {type === ContractType.Credit &&
            data?.collaterals?.map((item, index) => (
              <ClikResultCollateral
                key={index}
                data={item}
                number={index + 1}
                loading={loading}
              />
            ))}
          {type === ContractType.Credit &&
            guarantors?.map((item, index) => (
              <MultiFieldCard
                key={index}
                cols={2}
                {...item}
                variant="blue"
                loading={loading}
              />
            ))}
        </>
      )}
    </Flex>
  );
};

export default ClikResultDetailsOfContract;
