import { MultiSelect, SimpleGrid } from "@mantine/core";
import { SelectItem } from "@mantine/core/lib/Select/types";
import { useForm } from "@mantine/form";
import { useCallback, useMemo } from "react";

import SvgUnfold from "../../../../components/Icons/Unfold.tsx";
import { dpdBucketOptions } from "../../../../constants.ts";
import { Customer } from "../../../../types/customer.ts";
import { useStyles } from "./CustomerPayPastDueToolbar.styles.ts";
import { FiltersValue } from "./types.ts";

interface CustomerPayPastDueToolbarProps {
  customers: Customer[] | null | undefined;
  onValueChange: (value: Partial<FiltersValue>) => void;
}

const CustomerPayPastDueToolbar = ({
  customers,
  onValueChange,
}: CustomerPayPastDueToolbarProps) => {
  const { classes } = useStyles();

  const form = useForm({
    initialValues: {
      dpdBucket: [],
      companyName: [],
    },
  });

  const customersOptions: SelectItem[] = useMemo(() => {
    return (
      customers?.map((customer) => ({
        value: String(customer.id),
        label: customer.name,
      })) ?? []
    );
  }, [customers]);

  const handleValueChange = useCallback(
    (value: Record<string, string[]>) => {
      form.setValues(value);
      onValueChange({ ...form.values, ...value });
    },
    [form, onValueChange]
  );

  return (
    <SimpleGrid cols={3} spacing={20} className={classes.wrapper}>
      <MultiSelect
        size="m"
        data={customersOptions}
        label="Company Name"
        placeholder="Select Company Name"
        searchable
        nothingFound="No results found"
        rightSection={<SvgUnfold />}
        {...form.getInputProps("companyName")}
        onChange={(companyName) => handleValueChange({ companyName })}
      />
      <MultiSelect
        size="m"
        data={dpdBucketOptions}
        label="DPD Bucket"
        placeholder="Select DPD Bucket"
        searchable
        nothingFound="No results found"
        rightSection={<SvgUnfold />}
        {...form.getInputProps("dpdBucket")}
        onChange={(dpdBucket) => handleValueChange({ dpdBucket })}
      />
    </SimpleGrid>
  );
};

export default CustomerPayPastDueToolbar;
