import { Flex } from "@mantine/core";
import { notifications } from "@mantine/notifications";
import { DataTableSortStatus } from "mantine-datatable";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";

import SvgWarning from "../../../components/Icons/Warning.tsx";
import SectionTitle from "../../../components/Section/SectionTitle/SectionTitle.tsx";
import Table from "../../../components/Table/Table.tsx";
import { SortDirection, TableRow } from "../../../components/Table/types.ts";
import { AppRoute } from "../../../constants.ts";
import {
  LimitRequestOrderBy,
  LimitRequestStatusFilterOptions,
  OrderBy,
  UpdateRrAssignedInput,
} from "../../../graphql/generated.ts";
import { useUpdateRRAssigned } from "../../../hooks/api/creditLimit/useUpdateRRAssigned.ts";
import { useLimitRequests } from "../../../hooks/api/limitRequest/useLimitRequests.ts";
import { useRRAssignees } from "../../../hooks/api/user/useRRAssignees.ts";
import { useMutationNotificationWrapper } from "../../../hooks/useMutationNotificationWrapper.tsx";
import { getPagesCount } from "../../../utils/pagination.ts";
import { NewCURecommendation } from "../types.ts";
import { getCURecommendationApiSort } from "../utils.ts";
import { useNewCURecommendationTableData } from "./hooks/useNewCURecommendationTableData.tsx";

const NewCURecommendationTable = () => {
  const navigate = useNavigate();

  const [orderBy, setOrderBy] = useState<LimitRequestOrderBy>({
    createdAt: OrderBy.Desc,
  });
  const [page, setPage] = useState<number>(1);

  const [{ data, fetching, error }, refreshData] = useLimitRequests({
    filter: {
      requestStatus: {
        in: [
          LimitRequestStatusFilterOptions.OngoingCuManagerReview,
          LimitRequestStatusFilterOptions.OngoingCu,
        ],
      },
    },
    orderBy,
    page,
  });

  const [{ data: assigneesData }] = useRRAssignees();

  const assignees = assigneesData?.users?.data;

  const [, updateRRAssigned] = useMutationNotificationWrapper(
    useUpdateRRAssigned(),
    {
      success: { message: "RR Assigned has been successfully updated." },
    }
  );

  const newCURecommendations: NewCURecommendation[] | undefined | null =
    data?.limitRequests?.data;

  const pageCount = getPagesCount(data?.limitRequests?.total);

  const handlePageChange = useCallback(
    (page: number) => {
      setPage(page);
    },
    [setPage]
  );

  const handleSortChange = useCallback(
    (sort: DataTableSortStatus) => {
      setOrderBy(getCURecommendationApiSort(sort));
    },
    [setOrderBy]
  );

  const handleEditRRAssigned = useCallback(
    (id: number, input: UpdateRrAssignedInput) => {
      updateRRAssigned({ id, input }).then(refreshData);
    },
    [updateRRAssigned, refreshData]
  );

  const handleRowClik = useCallback(
    (record: TableRow) => {
      navigate(`${AppRoute.LimitRequest}/${record.id}`);
    },
    [navigate]
  );

  const [columns, rows] = useNewCURecommendationTableData({
    data: newCURecommendations,
    assignees,
    onEditRRAssigned: handleEditRRAssigned,
  });

  if (error) {
    notifications.clean();
    notifications.show({
      message:
        "Something went wrong while trying to fetch New CU Recommendation data.",
      icon: <SvgWarning />,
    });
  }

  return (
    <Flex direction="column" gap={12}>
      <SectionTitle>New CU Recommendation</SectionTitle>
      <Table
        columns={columns}
        rows={rows}
        defaultSort={{
          columnAccessor: "createdAt",
          direction: SortDirection.asc,
        }}
        loading={fetching}
        pagination={{
          pageCount: pageCount,
          page: page,
          onPageChange: handlePageChange,
        }}
        onRowClick={handleRowClik}
        onSortChange={handleSortChange}
      />
    </Flex>
  );
};

export default NewCURecommendationTable;
