import moment from "moment-timezone";

export const TIMEZONE = "Asia/Jakarta";

export const DATE_FORMAT = "MMM D, YYYY, HH:mm";

export const DAY_FORMAT = "MMM D, YYYY";

export const toLocalDateTime = (
  value: string | undefined | null
): string | null => {
  if (!value) {
    return null;
  }
  return moment.tz(value, TIMEZONE).format(DATE_FORMAT);
};

export const toLocalDate = (
  value: string | undefined | null
): string | null => {
  if (!value) {
    return null;
  }
  return moment.tz(value, TIMEZONE).format(DAY_FORMAT);
};
