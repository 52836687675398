import { useQuery } from "urql";

import {
  GetCreditLimitFunnelDocument,
  QueryCreditLimitFunnelArgs,
} from "../../../graphql/generated.ts";

export const useGetCreditLimitFunnel = ({
  filter,
  periodStart,
  periodEnd,
}: QueryCreditLimitFunnelArgs) => {
  return useQuery({
    query: GetCreditLimitFunnelDocument,
    variables: { filter, periodStart, periodEnd },
  });
};
