import isNil from "lodash/isNil";
import { useQuery } from "urql";

import { GetClikGrantedCreditOtherFacilityDetailsDocument } from "../../../../graphql/generated.ts";

interface Props {
  id: number | string | null | undefined;
}

export const useClikGrantedDetailsOfCreditOtherFacilities = ({ id }: Props) => {
  return useQuery({
    query: GetClikGrantedCreditOtherFacilityDetailsDocument,
    variables: { id: Number(id) },
    pause: isNil(id),
  });
};
