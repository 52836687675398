import { Button, Flex, Modal, ScrollArea, SimpleGrid } from "@mantine/core";
import { useCallback, useState } from "react";

import MultiFieldCard from "../../../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import SvgPlus from "../../../../components/Icons/Plus.tsx";
import { UpdateLimitRequestConditionPrecedentInput } from "../../../../graphql/generated.ts";
import { LimitRequestConditionPrecedent } from "../../../../types/limitRequestConditionPrecedent.ts";
import ConditionPrecedentsForm from "./ConditionPrecedentsForm.tsx";
import { useConditionPrecedentsList } from "./hooks/useConditionPrecedencesList.tsx";

interface Props {
  data: LimitRequestConditionPrecedent[] | null | undefined;
  opened: boolean;
  onClose: () => void;
  onSubmit: (
    value: UpdateLimitRequestConditionPrecedentInput,
    id?: number
  ) => void;
  onSave: () => void;
}

const ConditionPrecedentsModal = ({
  data,
  opened,
  onClose,
  onSubmit,
  onSave,
}: Props) => {
  const [selectedItem, setSelectedItem] =
    useState<LimitRequestConditionPrecedent | null>(null);
  const [openForm, setOpen] = useState<boolean>(false);

  const handleCloseForm = useCallback(() => {
    setOpen(false);
    setSelectedItem(null);
  }, [setOpen, setSelectedItem]);

  const handleSubmitForm = useCallback(
    (value: UpdateLimitRequestConditionPrecedentInput, id?: number) => {
      onSubmit(value, id);
      handleCloseForm();
    },
    [onSubmit, handleCloseForm]
  );

  const handleSave = useCallback(() => {
    handleCloseForm();
    onClose();
    onSave();
  }, [onClose, handleCloseForm, onSave]);

  const handleOpen = () => setOpen(true);

  const handleEditForm = (value: LimitRequestConditionPrecedent) => {
    setSelectedItem(value);
    handleOpen();
  };

  const conditionPrecedentsItems = useConditionPrecedentsList({
    data,
    onEdit: handleEditForm,
  });

  return (
    <Modal
      opened={opened}
      title={
        !openForm || selectedItem
          ? "Edit Condition Precedents"
          : "Add Condition Precedents"
      }
      closeButtonProps={{
        size: 24,
        iconSize: 24,
      }}
      scrollAreaComponent={ScrollArea.Autosize}
      size="lg"
      onClose={onClose}
    >
      {openForm ? (
        <ConditionPrecedentsForm
          value={selectedItem}
          onClose={handleCloseForm}
          onSubmit={handleSubmitForm}
        />
      ) : (
        <Flex direction="column" gap={20}>
          <Flex direction="column" gap={8}>
            {conditionPrecedentsItems && (
              <SimpleGrid w="100%" cols={2} spacing={8} verticalSpacing={8}>
                {conditionPrecedentsItems.map((item, index) => (
                  <MultiFieldCard key={index} {...item} />
                ))}
              </SimpleGrid>
            )}
            <Button
              variant="outlineBlue"
              leftIcon={<SvgPlus />}
              uppercase
              onClick={handleOpen}
            >
              Add
            </Button>
          </Flex>
          <Flex gap={20} justify="end">
            <Button variant="outlineBlue" uppercase onClick={onClose}>
              cancel
            </Button>
            <Button variant="filledBlue" uppercase onClick={handleSave}>
              save
            </Button>
          </Flex>
        </Flex>
      )}
    </Modal>
  );
};

export default ConditionPrecedentsModal;
