import { Flex } from "@mantine/core";
import { useParams } from "react-router-dom";

import Preloader from "../../../components/Preloader/Preloader.tsx";
import { useCustomer } from "../../../hooks/api/customer/useCustomer.ts";
import CompanyInfo from "./CompanyInfo/CompanyInfo.tsx";
import ContactInfo from "./ContactInfo/ContactInfo.tsx";

const OdooContactDataTab = () => {
  const { customerId } = useParams() as { customerId: string };

  const [{ data, fetching }] = useCustomer({ id: customerId });

  return (
    <>
      <Preloader loading={fetching} />
      {data?.customer && (
        <Flex direction="column" gap={{ base: 20, sm: 32 }}>
          <CompanyInfo customer={data.customer} loading={fetching} />
          <ContactInfo customer={data.customer} loading={fetching} />
        </Flex>
      )}
    </>
  );
};

export default OdooContactDataTab;
