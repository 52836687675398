import { createStyles, rem } from "@mantine/core";

export const useStyles = createStyles(() => ({
  contentWrapper: {
    minWidth: rem(1000),
    "@media (max-width: 74em)": {
      minWidth: rem(672),
    },
    "@media (max-width: 56em)": {
      minWidth: "100%",
    },
  },
}));
