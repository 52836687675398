import { SelectItem } from "@mantine/core/lib/Select/types";

import { ClikCity, ClikCountry } from "../graphql/generated.ts";
import { toHumanReadable } from "./string.ts";

export const getClikCityOptions = (
  cities: ClikCity[] | null | undefined
): SelectItem[] => {
  return (
    cities?.map((city) => ({
      value: city.id.toString(),
      label: city.name,
    })) ?? []
  );
};

export const getClikCountryOptions = (
  countries: ClikCountry[] | null | undefined
): SelectItem[] => {
  return (
    countries?.map((country) => ({
      value: country.id.toString(),
      label: toHumanReadable(country.name),
    })) ?? []
  );
};

export const getClickCity = (
  id: number | string,
  cities: ClikCity[] | null | undefined
): ClikCity | null => {
  return cities?.find((item) => item.id === Number(id)) ?? null;
};

export const getClikCountry = (
  id: number | string,
  countries: ClikCountry[] | null | undefined
): ClikCountry | null => {
  return countries?.find((item) => item.id === Number(id)) ?? null;
};
