import {
  CreditUnderwriting as GqlGeneratedCreditUnderwriting,
  CuAnalysisResult,
  CuInsuranceResult,
  CuLoiResultCategory,
  CuResultCategory,
} from "../graphql/generated.ts";
import { toHumanReadable } from "../utils/string.ts";
import { Note } from "./note.ts";
import { User } from "./user.ts";

export type CreditUnderwriting = Omit<
  GqlGeneratedCreditUnderwriting,
  "limitRequestId" | "assigned" | "updatedBy" | "rejectNotes"
> & {
  assignedId?: number;
  assignedDate?: Date | undefined | null;
  updatedBy: Pick<User, "id" | "name" | "email">;
  rejectNotes: Pick<Note, "text" | "createdAt">[];
};

export const cuResultCategoryOptions = Object.values(CuResultCategory).map(
  (value) => {
    return { value, label: toHumanReadable(value) };
  }
);

export const cuLoiResultCategoryOptions = Object.values(
  CuLoiResultCategory
).map((value) => {
  return { value, label: toHumanReadable(value) };
});

export const cuInsuranceResultOptions = Object.values(CuInsuranceResult).map(
  (value) => {
    return { value, label: toHumanReadable(value) };
  }
);

export const cuAnalysisResultOptions = Object.values(CuAnalysisResult).map(
  (value) => {
    return { value, label: toHumanReadable(value) };
  }
);
