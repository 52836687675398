import { createStyles, rem } from "@mantine/core";

export const useStyles = createStyles((theme) => ({
  wrapper: {
    width: "100vw",
    height: "100vh",
    background: `linear-gradient(357deg, ${theme.colors.dusk[0]} 8.1%, ${theme.colors.dusk[1]} 51.61%, ${theme.colors.dusk[2]} 64.51%, ${theme.colors.dusk[3]} 85.59%)`,
    padding: rem(20),
    paddingTop: rem(80),
    position: "fixed",
    left: 0,
    top: 0,
    zIndex: 10,
  },
  logoutWrapper: {
    textDecoration: "none",
  },
  divider: {
    borderColor: theme.colors.dusk[8],
  },
}));
