import { DataTableColumn } from "mantine-datatable";
import { useMemo } from "react";

import { TableData, TableRow } from "../../../../../components/Table/types.ts";
import { PaymentHistory } from "../../../../../types/paymentHistory.ts";
import { toLocalDate } from "../../../../../utils/date.ts";
import { toLocalNumber } from "../../../../../utils/number.ts";

interface Props {
  data: PaymentHistory | null | undefined;
}

export function usePaymentHistoryData({ data }: Props): TableData {
  const columns: DataTableColumn<TableRow>[] = [
    {
      accessor: "dpd",
      title: "DPD",
      width: "125px",
    },
    {
      accessor: "date",
      title: "Date",
      width: "150px",
    },
    {
      accessor: "outstandingPrinciple",
      title: "OSP",
      width: "125px",
    },
    {
      accessor: "lateCharge",
      title: "Late Charge",
      width: "125px",
    },
    {
      accessor: "accountReceivables",
      title: "AR",
      width: "125px",
    },
    {
      accessor: "paidAmount",
      title: "Paid Amount",
      width: "125px",
    },
  ];

  const rows = useMemo(() => {
    return data?.map((item) => {
      return {
        dpd: toLocalNumber(item.dpd),
        date: toLocalDate(item.date),
        outstandingPrinciple: toLocalNumber(item.outstandingPrincipal),
        lateCharge: toLocalNumber(item.lateCharge),
        accountReceivables: toLocalNumber(item.accountReceivables),
        paidAmount: toLocalNumber(item.paidAmount),
      };
    });
  }, [data]);

  return [columns, rows];
}
