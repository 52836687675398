import { Divider, Flex, Text } from "@mantine/core";
import { useCallback, useMemo, useState } from "react";
import { MouseEvent } from "react";

import { Note } from "../../types/note.ts";
import { toLocalDate } from "../../utils/date.ts";
import Spoiler from "../Spoiler/Spoiler.tsx";
import { useStyles } from "./NoteList.styles.ts";

interface Props {
  notes: Pick<Note, "text" | "createdAt">[] | undefined | null;
}

const TableNoteList = ({ notes }: Props) => {
  const { classes } = useStyles();
  const [showAll, setShowAll] = useState(false);

  const displayedNotes = useMemo(() => {
    if (!notes) {
      return [];
    }
    return showAll ? notes : notes.slice(0, 1);
  }, [notes, showAll]);

  const renderNote = useCallback(
    (note: Pick<Note, "text" | "createdAt">, index: number) => {
      return (
        <Flex key={index} justify="space-between">
          <Spoiler text={note.text} maxStringLength={8} width="70%" />
          <Text className={classes.notesDate}>
            {toLocalDate(note.createdAt)}
          </Text>
        </Flex>
      );
    },
    [classes]
  );

  const onClickShowMore = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      setShowAll(!showAll);
    },
    [showAll]
  );

  if (!notes?.length) {
    return;
  }

  return (
    <Flex
      w="100%"
      direction="column"
      className={classes.wrapper}
      onClick={(e) => e.stopPropagation()}
    >
      {displayedNotes.map((note, index) => {
        return (
          <>
            {renderNote(note, index)}
            {displayedNotes.length > index + 1 && <Divider />}
          </>
        );
      })}
      {notes.length > 1 && (
        <Text className={classes.showMore} onClick={onClickShowMore}>
          {showAll ? "Hide" : `More ${notes.length}`}
        </Text>
      )}
    </Flex>
  );
};

export default TableNoteList;
