import { createStyles, CSSObject, rem } from "@mantine/core";

export const useStyles = createStyles(() => ({
  wrapper: {
    display: "flex",
    flexFlow: "column",
    alignItems: "flex-start",
    gap: rem(20),
    maxWidth: "100%",

    "@media (max-width: 48em)": {
      alignItems: "stretch",
      button: {
        width: "auto",
      },
    },
  },
  collectorAssignedWrapper: {
    wordWrap: "break-all" as CSSObject["wordWrap"],
    overflowWrap: "anywhere",
  },
}));
