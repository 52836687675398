import { DataTableSortStatus } from "mantine-datatable";
import { useCallback, useState } from "react";

import {
  CreateRestructureDetailInput,
  OrderBy,
  RestructureApprovalStatus,
  RestructureDetailOrderBy,
  UpdateRestructureDetailInput,
} from "../../../graphql/generated";
import { useCreateRestructureDetail } from "../../../hooks/api/restructure/useCreateRestructureDetail";
import { useRestructureDetail } from "../../../hooks/api/restructure/useRestructureDetail";
import { useUpdateRestructureDetail } from "../../../hooks/api/restructure/useUpdateRestructureDetail";
import { useMutationNotificationWrapper } from "../../../hooks/useMutationNotificationWrapper";
import { Restructure, RestructureDetail } from "../../../types/restructure";
import { getPagesCount } from "../../../utils/pagination";
import Table from "../../Table/Table";
import { SortDirection } from "../../Table/types";
import EditRestructureDetailModal from "../EditRestructureDetailModal/EditRestructureDetailModal";
import { useRestructureDetailsTableData } from "../hooks/useRestructureDetailsTableData";
import RestructureBreadcrumbs, {
  RestructureBreadcrumbsProps,
} from "../RestructureBreadcrumbs/RestructureBreadcrumbs";
import {
  getRestructureDetailsApiFilter,
  getRestructureDetailsApiSort,
} from "../utils";
import { useStyles } from "./RestructureDetails.styles";
import RestructureDetailsToolbar from "./RestructureDetailsToolbar/RestructureDetailsToolbar";

interface RestructureDetailsProps extends RestructureBreadcrumbsProps {
  restructure: Restructure;
  canEdit?: boolean;
  canAdd?: boolean;
}

export default function RestructureDetails({
  restructure,
  canEdit = false,
  canAdd = false,
  onBreadcrumbsClick,
}: RestructureDetailsProps) {
  const { classes } = useStyles();

  const [invoiceIdFilter, setInvoiceIdFilter] = useState<string | null>(null);

  const [orderBy, setOrderBy] = useState<RestructureDetailOrderBy>({
    dueDate: OrderBy.Desc,
  });

  const [page, setPage] = useState<number>(1);

  const [{ data: detailsData, fetching }, refetchDetails] =
    useRestructureDetail({
      orderBy,
      filter: getRestructureDetailsApiFilter({
        restructureId: restructure.id,
        invoiceId: invoiceIdFilter,
      }),
      page,
    });

  const restructureDetails = detailsData?.restructureDetails?.data;

  const pageCount = getPagesCount(detailsData?.restructureDetails?.total);

  const [, createDetail] = useMutationNotificationWrapper(
    useCreateRestructureDetail(),
    {
      success: { message: "Restructure Request Details have been added." },
    }
  );
  const [, updateDetail] = useMutationNotificationWrapper(
    useUpdateRestructureDetail(),
    {
      success: { message: "Restructure Request Details have been updated." },
    }
  );

  const [editedRow, setEditedRow] = useState<
    RestructureDetail | null | undefined
  >(null);

  const isPending =
    restructure.approvalStatus === RestructureApprovalStatus.Pending;

  const openEditModal = (id: number) => {
    if (!detailsData?.restructureDetails?.data) return;
    const row = detailsData.restructureDetails.data.find(
      (waive) => waive.id === id
    );
    setEditedRow(row);
  };

  const closeEditModal = () => {
    setEditedRow(null);
  };

  const [columns, rows] = useRestructureDetailsTableData({
    data: restructureDetails,
    showActionsColumn: canEdit,
    restructureStatus: restructure.approvalStatus,
    onEdit: openEditModal,
  });

  const handleOnSortChange = useCallback(
    (sort: DataTableSortStatus) => {
      setOrderBy(getRestructureDetailsApiSort(sort));
    },
    [setOrderBy]
  );

  const handlePageChange = useCallback(
    (page: number) => {
      setPage(page);
    },
    [setPage]
  );

  const handleCreate = (input: CreateRestructureDetailInput) => {
    createDetail({ input }).then((data) => {
      if (!data.error) {
        refetchDetails();
      }
    });
  };

  const handleUpdate = (input: UpdateRestructureDetailInput) => {
    if (!editedRow?.id) return;
    updateDetail({
      id: editedRow.id,
      input: input,
    }).then((data) => {
      if (!data.error) {
        refetchDetails();
      }
    });
  };

  return (
    <div className={classes.wrapper}>
      <RestructureBreadcrumbs
        className={classes.breadcrumbs}
        onBreadcrumbsClick={onBreadcrumbsClick}
      />

      <RestructureDetailsToolbar
        customerId={restructure.customer.id}
        restructureId={restructure.id}
        canAdd={canAdd && isPending}
        onFilter={setInvoiceIdFilter}
        onCreate={handleCreate}
      />
      <Table
        columns={columns}
        rows={rows}
        pagination={{
          pageCount: pageCount,
          page: page,
          onPageChange: handlePageChange,
        }}
        defaultSort={{
          columnAccessor: "dueDate",
          direction: SortDirection.desc,
        }}
        loading={fetching}
        onSortChange={handleOnSortChange}
      />

      {isPending && editedRow && (
        <EditRestructureDetailModal
          value={editedRow}
          opened={!!editedRow}
          onClose={closeEditModal}
          onSubmit={handleUpdate}
        />
      )}
    </div>
  );
}
