import { DataTableColumn } from "mantine-datatable";
import { useMemo } from "react";

import { TableData, TableRow } from "../../../../../components/Table/types.ts";
import { ClikAddress } from "../../../../../types/clikResult.ts";
import { toLocalDate } from "../../../../../utils/date.ts";
import { getCRCurrentFlagState, getCRDisplayAddress } from "../../utils.ts";

interface Props {
  data: ClikAddress[] | null | undefined;
}

export function useCRAddressesTableData({ data }: Props): TableData {
  const columns: DataTableColumn<TableRow>[] = [
    {
      accessor: "id",
      hidden: true,
    },
    {
      accessor: "current",
      title: "State",
      sortable: true,
      width: "200px",
    },
    {
      accessor: "address",
      title: "Address",
      sortable: true,
    },
    {
      accessor: "lastUpdatedDate",
      title: "Last Update Date",
      sortable: true,
      width: "230px",
    },
  ];

  const rows = useMemo(() => {
    return data?.map((item) => {
      return {
        id: item.id,
        current: getCRCurrentFlagState(item.current),
        address: getCRDisplayAddress(item),
        lastUpdatedDate:
          item.lastUpdatedDate && toLocalDate(item.lastUpdatedDate),
      };
    });
  }, [data]);

  return [columns, rows];
}
