import { ContractProfileQualityCode } from "../types.ts";

export const qualityCodeDescItems: Record<ContractProfileQualityCode, string> =
  {
    "1": "OK",
    "2": "1-89",
    "3": "90-119",
    "4": "120-179",
    "5": "180+",
  };
