import { Flex } from "@mantine/core";
import { DataTableSortStatus } from "mantine-datatable";
import { useCallback, useState } from "react";

import Table from "../../../../../components/Table/Table.tsx";
import { SortDirection } from "../../../../../components/Table/types.ts";
import Title from "../../../../../components/Title/Title.tsx";
import {
  ClikNotGrantedContractOrderBy,
  ContractTypeFilterOptions,
  OrderBy,
} from "../../../../../graphql/generated.ts";
import { useClikNotGrantedContract } from "../../../../../hooks/api/clikResult/clikContract/useClikNotGrantedContracts.ts";
import { getApiOrderBy } from "../../../../../utils/api.ts";
import { getPagesCount } from "../../../../../utils/pagination.ts";
import { clikResultTitle } from "../contants.ts";
import { useCRNotGrantedContracts } from "./hooks/useCRNotGrantedContracts.tsx";

interface ClikResultNotGrantedContractsProps {
  clikResultId: number;
  type: ContractTypeFilterOptions;
}

const ClikResultNotGrantedContracts = ({
  clikResultId,
  type,
}: ClikResultNotGrantedContractsProps) => {
  const [page, setPage] = useState<number>(1);
  const [orderBy, setOrderBy] = useState<ClikNotGrantedContractOrderBy>({
    id: OrderBy.Asc,
  });

  const [
    {
      data: notGrantedContractsResponse,
      fetching: notGrantedContractsFetching,
    },
  ] = useClikNotGrantedContract({
    filter: {
      clikResultId: { equals: clikResultId },
      type: { equals: type },
    },
    orderBy,
    page,
  });
  const notGrantedContractsData =
    notGrantedContractsResponse?.clikNotGrantedContract?.data;
  const notGrantedContractsCount =
    notGrantedContractsResponse?.clikNotGrantedContract?.total ?? 0;

  const [columns, rows] = useCRNotGrantedContracts({
    data: notGrantedContractsData,
    type,
  });
  const pageCount = getPagesCount(notGrantedContractsCount);

  const handleOnSortChange = useCallback(
    (sort: DataTableSortStatus) => {
      setOrderBy(getApiOrderBy(sort));
    },
    [setOrderBy]
  );

  const handlePageChange = useCallback(
    (page: number) => {
      setPage(page);
    },
    [setPage]
  );

  return (
    <Flex gap={16} direction="column">
      <Title size="h2">
        {`${clikResultTitle[type]} (Requested, Renounced and Refused)`}
      </Title>
      <Table
        loading={notGrantedContractsFetching}
        columns={columns}
        rows={rows}
        minWidth="100%"
        pagination={{
          pageCount: pageCount,
          page: page,
          onPageChange: handlePageChange,
        }}
        defaultSort={{
          columnAccessor: "id",
          direction: SortDirection.asc,
        }}
        onSortChange={handleOnSortChange}
      />
    </Flex>
  );
};

export default ClikResultNotGrantedContracts;
