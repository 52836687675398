import { MantineTheme, rem } from "@mantine/styles";

import { ThemeComponent } from "./helpers.ts";

export default function navLinkTheme(): ThemeComponent {
  return {
    styles: (theme: MantineTheme) => ({
      root: {
        color: theme.colors.white[0],
        fontSize: rem(16),
        lineHeight: 1.55,
        borderRadius: rem(4),
        gap: rem(12),
        height: rem(44),
        alignItems: "center",
        minWidth: rem(44),
        padding: `${rem(8)} ${rem(10)}`,

        "&:hover": {
          background: theme.colors.midnightBlue[1],
          color: theme.colors.golden[0],
        },

        "&[data-active]": {
          background: theme.colors.dusk[0],
          color: theme.colors.golden[0],

          "&:hover": {
            background: theme.colors.dusk[9],
            color: theme.colors.golden[0],
          },
        },
      },
      children: {
        padding: 0,

        button: {
          paddingLeft: rem(32),
        },
      },
    }),
  };
}
