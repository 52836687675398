import { ThemeComponent } from "./helpers.ts";

const defaultDropzone = () => ({
  root: {
    border: "none",
    padding: 0,
    background: "transparent",

    "&:hover": {
      background: "transparent",
    },
  },
});

export default function dropzoneTheme(): ThemeComponent {
  return {
    styles: () => defaultDropzone(),
  };
}
