import { Flex, Modal, Text } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { Link } from "react-router-dom";

import { FileResult } from "../../graphql/generated";
import ActionIcon from "../ActionIcon/ActionIcon.tsx";
import DownloadFile from "../DownloadFile/DownloadFile.tsx";
import SvgDownload from "../Icons/Download.tsx";
import { useStyles } from "./TableFilesDisplay.styles.ts";

interface MultipleFilesProps {
  files: FileResult[];
  disabled?: boolean;
}

const TableFilesDisplay = ({ files, disabled = false }: MultipleFilesProps) => {
  const { classes } = useStyles();

  const [opened, { open, close }] = useDisclosure(false);

  if (!files.length) {
    return null;
  }

  if (disabled) {
    return <Text>{`${files.length} file(s)`}</Text>;
  }

  return (
    <>
      {files.length > 1 ? (
        <Text
          className={classes.files}
          onClick={open}
        >{`${files.length} files`}</Text>
      ) : (
        <Link
          to={files[0].url}
          target="_blank"
          onClick={(e) => e.stopPropagation()}
        >
          <ActionIcon>
            <SvgDownload />
          </ActionIcon>
        </Link>
      )}
      <Modal opened={opened} onClose={close} title="Attachment">
        <Flex direction="column" gap={8}>
          {files.map((item, index) => (
            <DownloadFile file={item} key={index} />
          ))}
        </Flex>
      </Modal>
    </>
  );
};

export default TableFilesDisplay;
