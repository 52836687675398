import { Flex } from "@mantine/core";

import { TableRow } from "../../../../../components/Table/types.ts";
import { ClikContractProfile } from "../../../../../graphql/generated.ts";
import { formatClikContractProfileData } from "../utils.ts";
import ClikGrantedContractMonthItem from "./ClikGrantedContractMonthItem/ClikGrantedContractMonthItem.tsx";

interface Props {
  data: ClikContractProfile[] | null | undefined;
  className?: string;
}

export function getCRClikGrantedContractsProfileColumns() {
  return [
    {
      accessor: "referenceYear",
      title: false,
      sortable: false,
      width: "100px",
    },
    {
      accessor: "jan",
      title: "Jan",
      sortable: false,
      width: "100px",
    },
    {
      accessor: "feb",
      title: "Feb",
      sortable: false,
      width: "100px",
    },
    {
      accessor: "mar",
      title: "Mar",
      sortable: false,
      width: "100px",
    },
    {
      accessor: "apr",
      title: "Apr",
      sortable: false,
      width: "100px",
    },
    {
      accessor: "may",
      title: "May",
      sortable: false,
      width: "100px",
    },
    {
      accessor: "jun",
      title: "Jun",
      sortable: false,
      width: "100px",
    },
    {
      accessor: "jul",
      title: "Jul",
      sortable: false,
      width: "100px",
    },
    {
      accessor: "aug",
      title: "Aug",
      sortable: false,
      width: "100px",
    },
    {
      accessor: "sep",
      title: "Sep",
      sortable: false,
      width: "100px",
    },
    {
      accessor: "oct",
      title: "Oct",
      sortable: false,
      width: "100px",
    },
    {
      accessor: "nov",
      title: "Nov",
      sortable: false,
      width: "100px",
    },
    {
      accessor: "dec",
      title: "Dec",
      sortable: false,
      width: "100px",
    },
  ];
}

export function getCRCLikGrantedContractsProfileRows({
  data,
  className,
}: Props): TableRow[] | undefined {
  if (!data) {
    return;
  }
  const transformedData = formatClikContractProfileData(data);

  return transformedData.map((item) => {
    return {
      referenceYear: (
        <Flex justify="center" className={className}>
          {item.referenceYear}
        </Flex>
      ),
      jan: <ClikGrantedContractMonthItem item={item.jan} />,
      feb: <ClikGrantedContractMonthItem item={item.feb} />,
      mar: <ClikGrantedContractMonthItem item={item.mar} />,
      apr: <ClikGrantedContractMonthItem item={item.apr} />,
      may: <ClikGrantedContractMonthItem item={item.may} />,
      jun: <ClikGrantedContractMonthItem item={item.jun} />,
      jul: <ClikGrantedContractMonthItem item={item.jul} />,
      aug: <ClikGrantedContractMonthItem item={item.aug} />,
      sep: <ClikGrantedContractMonthItem item={item.sep} />,
      oct: <ClikGrantedContractMonthItem item={item.oct} />,
      nov: <ClikGrantedContractMonthItem item={item.nov} />,
      dec: <ClikGrantedContractMonthItem item={item.dec} />,
    };
  });
}
