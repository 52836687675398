import { Flex } from "@mantine/core";

import AccordionMultifieldsCards from "../../../../components/AccordionMultifieldCards/AccordionMultifieldCards.tsx";
import NoDataMessage from "../../../../components/NoDataMessage/NoDataMessage.tsx";
import SectionTitle from "../../../../components/Section/SectionTitle/SectionTitle.tsx";
import { useClikNegativeEvents } from "../../../../hooks/api/clikResult/useClikNegativeEvent.ts";
import { useCRNegativeEventCardInfo } from "./hooks/useCRNegativeEventCardInfo.tsx";

interface ClikResultNegativeEventProps {
  clikResultId: number;
}

const ClikResultNegativeEvent = ({
  clikResultId,
}: ClikResultNegativeEventProps) => {
  const [{ data, fetching }] = useClikNegativeEvents({ clikResultId });

  const negativeEventsInfo = useCRNegativeEventCardInfo({
    data: data?.clikNegativeEvents,
  });

  if (!fetching && !negativeEventsInfo?.length) {
    return (
      <Flex direction="column" gap={16}>
        <SectionTitle variant="bronze">Negative Events</SectionTitle>
        <NoDataMessage />
      </Flex>
    );
  }

  return (
    <Flex direction="column" gap={16}>
      <SectionTitle variant="bronze">Negative Events</SectionTitle>
      <AccordionMultifieldsCards
        cardItems={negativeEventsInfo}
        loading={fetching}
      />
    </Flex>
  );
};

export default ClikResultNegativeEvent;
