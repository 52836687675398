import {
  ActionIcon as MantineActionIcon,
  ActionIconProps as MantineActionIconProps,
} from "@mantine/core";
import classNames from "classnames";
import { ReactNode } from "react";
import { Link } from "react-router-dom";

import { ActionIconStylesProps, useStyles } from "./ActionIcon.styles";

export type ActionIconProps = ActionIconStylesProps &
  MantineActionIconProps & {
    children: string | ReactNode;
    onClick?: () => void;
    href?: string;
  };

export default function ActionIcon({
  size,
  variant,
  children,
  onClick,
  href,
  spaces = true,
  ...props
}: ActionIconProps) {
  const variantSize = spaces ? size || 32 : 20;

  const { classes } = useStyles({ size: variantSize, variant, spaces });

  const Icon = () => {
    return <div className={classes.icon}>{children}</div>;
  };

  if (onClick) {
    return (
      <MantineActionIcon
        className={classNames(classes.button, classes.wrapper)}
        size={variantSize}
        {...props}
        onClick={onClick}
      >
        <Icon />
      </MantineActionIcon>
    );
  }

  if (href) {
    return (
      <div className={classes.wrapper}>
        <Link
          to={href}
          target="_blank"
          rel="noreferrer"
          className={classes.button}
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <Icon />
        </Link>
      </div>
    );
  }

  return (
    <div className={classes.wrapper}>
      <Icon />
    </div>
  );
}
