import { useQuery } from "urql";

import {
  ClikGrantedLetterOfCreditFilter,
  ClikGrantedLetterOfCreditOrderBy,
  GetClikGrantedLettersOfCreditDocument,
} from "../../../../graphql/generated.ts";
import { getApiPaginationData } from "../../../../utils/api.ts";
import { ApiHookProps } from "../../types.ts";

export const useClikGrantedLettersOfCredit = ({
  filter,
  orderBy,
  page,
}: ApiHookProps<
  ClikGrantedLetterOfCreditFilter,
  ClikGrantedLetterOfCreditOrderBy
>) => {
  const pagination = getApiPaginationData(page);
  const variables = {
    filter,
    orderBy,
    skip: pagination?.skip,
    take: pagination?.take,
  };
  return useQuery({ query: GetClikGrantedLettersOfCreditDocument, variables });
};
