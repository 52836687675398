import type { SVGProps } from "react";
const SvgFilter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 -960 960 960"
    fill="currentColor"
    {...props}
  >
    <path d="M470.769-200q-13.154 0-21.962-8.807Q440-217.615 440-230.769v-223.077L224.154-726.769q-8.077-10.77-2.193-22Q227.846-760 240.769-760h478.462q12.923 0 18.808 11.231 5.884 11.23-2.193 22L520-453.846v223.077q0 13.154-8.807 21.962Q502.385-200 489.231-200h-18.462ZM480-468l198-252H282l198 252Zm0 0Z" />
  </svg>
);
export default SvgFilter;
