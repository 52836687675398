import Spoiler from "../../../../components/Spoiler/Spoiler.tsx";
import { TableEmptyValue } from "../../../../components/Table/constants.tsx";
import { ClikContractLinkedSubject } from "../../../../graphql/generated.ts";

interface Props {
  data: ClikContractLinkedSubject[] | null | undefined;
}
const ClikResultContractLinkedSubjects = ({ data }: Props) => {
  if (!data?.length) {
    return TableEmptyValue;
  }

  const text = data
    .map((item) => `<b>${item.cbSubjectCode}</b> ${item.name} - ${item.role}`)
    .join("<br/>");

  return <Spoiler text={text} />;
};

export default ClikResultContractLinkedSubjects;
