import {
  CaResult,
  CreditApproval as GqlGeneratedCreditApproval,
  Maybe,
  User,
} from "../graphql/generated.ts";
import { toHumanReadable } from "../utils/string.ts";

export type CreditApproval = Pick<
  GqlGeneratedCreditApproval,
  | "id"
  | "createdAt"
  | "notes"
  | "date"
  | "result"
  | "limitRecommendation"
  | "tenorRecommendation"
  | "pricingRecommendation"
> & {
  updatedBy?: Maybe<Pick<User, "id" | "name" | "email">>;
};

export const creditApprovalResultOptions = Object.values(CaResult).map(
  (value) => {
    return { value, label: toHumanReadable(value) };
  }
);
