import { useDisclosure } from "@mantine/hooks";
import { useCallback, useMemo } from "react";

import { useCurrentUserContext } from "../../../components/Auth/CurrentUserProvider.tsx";
import MultiFieldCard from "../../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import {
  LimitRequestStatus,
  RolePermission,
  UpdateCreditApprovalInput,
} from "../../../graphql/generated.ts";
import { useCreateCreditApproval } from "../../../hooks/api/creditApproval/useCreateCreditApproval.ts";
import { useCreditApproval } from "../../../hooks/api/creditApproval/useCreditApproval.ts";
import { useUpdateCreditApproval } from "../../../hooks/api/creditApproval/useUpdateCreditApproval.ts";
import { useHandoverLimitRequestToOL } from "../../../hooks/api/limitRequest/useHandoverLimitRequestToOL.ts";
import { useGetLimitRequestConditionPrecedents } from "../../../hooks/api/limitRequestConditionPrecedent/useGetLimitRequestConditionPrecedents.ts";
import { useGetLimitRequestExceptions } from "../../../hooks/api/limitRequestException/useGetLimitRequestExceptions.ts";
import { useMutationNotificationWrapper } from "../../../hooks/useMutationNotificationWrapper.tsx";
import { LimitRequestDetail } from "../../../types/limitRequest.ts";
import { hasPermission } from "../../../utils/user.ts";
import LimitRequestDetailCardControl from "../LimitRequestDetailCardControl/LimitRequestDetailCardControl.tsx";
import CreditCommitteeModal from "./CreditCommitteeModal.tsx";
import { useCreditCommitteeCardData } from "./hooks/useCreditCommitteeCardData.tsx";
import { useCreditCommitteeCardFooter } from "./hooks/useCreditCommitteeCardFooter.tsx";

interface Props {
  limitRequest:
    | Pick<LimitRequestDetail, "id" | "creditApproval" | "requestStatus">
    | null
    | undefined;
  onRefreshLimitRequest: () => void;
}

const CreditCommitteeCard = ({
  limitRequest,
  onRefreshLimitRequest,
}: Props) => {
  const [
    creditCommitteeModalOpened,
    { open: creditCommitteeModalOpen, close: creditCommitteeModalClose },
  ] = useDisclosure(false);

  const [
    { data: creditCommitteeResponse, fetching: fetchingCreditCommittee },
    refreshCreditCommittee,
  ] = useCreditApproval({
    id: limitRequest?.creditApproval?.id,
  });
  const creditCommittee = creditCommitteeResponse?.creditApproval;

  const { user } = useCurrentUserContext();

  const [{ data: conditionPrecedentsResponse }, refreshConditionPrecedence] =
    useGetLimitRequestConditionPrecedents({
      limitRequestId: limitRequest?.id,
    });
  const conditionPrecedents =
    conditionPrecedentsResponse?.limitRequestConditionPrecedents?.filter(
      (item) => item.active
    );

  const [{ data: exceptionsResponse }, refreshExceptionsList] =
    useGetLimitRequestExceptions({
      limitRequestId: limitRequest?.id,
    });
  const exceptions = exceptionsResponse?.limitRequestExceptions?.filter(
    (item) => item.active
  );

  const [, updateCreditCommittee] = useMutationNotificationWrapper(
    useUpdateCreditApproval(),
    {
      success: { message: "Credit Committee data was successfully updated." },
    }
  );

  const [, createCreditCommittee] = useMutationNotificationWrapper(
    useCreateCreditApproval(),
    {
      success: { message: "Credit Committee data was successfully created." },
    }
  );

  const [, handoverLimitRequestToOL] = useMutationNotificationWrapper(
    useHandoverLimitRequestToOL(),
    {
      success: {
        message:
          "Limit Request Status was successfully handovered to Offering Letter.",
      },
    }
  );

  const hasEditAccess = hasPermission(
    user,
    RolePermission.CanEditBuyerInfoLimitInfoCreditCommittee
  );

  const handleHandoverOL = useCallback(() => {
    if (!limitRequest?.id) {
      return;
    }
    handoverLimitRequestToOL({ id: limitRequest.id }).then(
      onRefreshLimitRequest
    );
  }, [limitRequest, handoverLimitRequestToOL, onRefreshLimitRequest]);

  const handleUpdateCreditCommittee = useCallback(
    (input: UpdateCreditApprovalInput) => {
      if (!limitRequest?.id) {
        return;
      }
      if (!creditCommittee) {
        createCreditCommittee({
          input: { ...input, limitRequestId: limitRequest.id },
        })
          .then(() => onRefreshLimitRequest())
          .then(() => refreshCreditCommittee());
      }
      if (!creditCommittee?.id) {
        return;
      }
      updateCreditCommittee({ id: Number(creditCommittee.id), input })
        .then(() => onRefreshLimitRequest())
        .then(() => refreshCreditCommittee());
    },
    [
      refreshCreditCommittee,
      onRefreshLimitRequest,
      creditCommittee,
      limitRequest,
      createCreditCommittee,
      updateCreditCommittee,
    ]
  );

  const creditCommitteeInfo = useCreditCommitteeCardData({
    creditCommittee,
    exceptions,
    conditionPrecedents,
  });

  const cardControl = useMemo(() => {
    if (!creditCommittee) {
      return null;
    }
    return (
      <LimitRequestDetailCardControl
        updatedBy={creditCommittee?.updatedBy}
        edit={
          hasEditAccess &&
          creditCommittee &&
          limitRequest?.requestStatus === LimitRequestStatus.OngoingCc
        }
        onEdit={creditCommitteeModalOpen}
      />
    );
  }, [creditCommittee, hasEditAccess, limitRequest, creditCommitteeModalOpen]);

  const cardFooterContent = useCreditCommitteeCardFooter({
    creditCommittee,
    canEdit: hasEditAccess,
    limitRequestStatus: limitRequest?.requestStatus,
    onAdd: creditCommitteeModalOpen,
    onHandoverToOL: handleHandoverOL,
  });

  return (
    <>
      <MultiFieldCard
        label="Credit Committee"
        items={creditCommitteeInfo}
        cardControl={cardControl}
        loading={fetchingCreditCommittee}
        cardFooterContent={cardFooterContent}
        variant="blue"
        disabled={
          !creditCommittee &&
          limitRequest?.requestStatus !== LimitRequestStatus.OngoingCc
        }
      />
      {creditCommitteeModalOpened && (
        <CreditCommitteeModal
          value={creditCommittee}
          limitRequestId={limitRequest?.id}
          conditionPrecedents={conditionPrecedents}
          exceptions={exceptions}
          opened={true}
          onClose={creditCommitteeModalClose}
          onSubmit={handleUpdateCreditCommittee}
          onRefreshConditionPrecedence={refreshConditionPrecedence}
          onRefreshExceptionsList={refreshExceptionsList}
        />
      )}
    </>
  );
};

export default CreditCommitteeCard;
