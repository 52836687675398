import { useQuery } from "urql";

import {
  GetLimitUtilizationDocument,
  LimitRequestFilter,
  LimitRequestOrderBy,
} from "../../../graphql/generated.ts";
import { ApiHookProps } from "../types.ts";

export const useGetLimitUtilization = ({
  filter,
}: ApiHookProps<LimitRequestFilter, LimitRequestOrderBy>) => {
  return useQuery({
    query: GetLimitUtilizationDocument,
    variables: { filter },
  });
};
