import { TabProps } from "@mantine/core";

import { RolePermission } from "../../graphql/generated.ts";
import { User } from "../../types/user.ts";
import { hasPermission } from "../../utils/user.ts";

interface Tab extends TabProps {
  label: string;
  show?: (user: User | null | undefined) => boolean;
}

export enum TabValue {
  CompanyInfo = "companyInfo",
  PengurusInfo = "pengurusInfo",
  ClikResult = "clikResult",
  LimitInfo = "limitInfo",
  TransactionHistory = "transactionHistory",
}

export const tabs: Tab[] = [
  {
    value: TabValue.CompanyInfo,
    label: "Company Info",
    show: (user: User | null | undefined) =>
      hasPermission(user, RolePermission.CanViewBuyerInfoCompanyInfo),
  },
  {
    value: TabValue.PengurusInfo,
    label: "Pengurus Info",
    show: (user: User | null | undefined) =>
      hasPermission(user, RolePermission.CanViewBuyerInfoPengurusInfo),
  },
  {
    value: TabValue.ClikResult,
    label: "CLIK Result",
    show: (user: User | null | undefined) =>
      hasPermission(user, RolePermission.CanViewClikResult),
  },
  {
    value: TabValue.LimitInfo,
    label: "Limit Info",
    show: (user: User | null | undefined) =>
      hasPermission(user, RolePermission.CanViewBuyerInfoLimitInfo),
  },
  {
    value: TabValue.TransactionHistory,
    label: "Transaction History",
    show: (user: User | null | undefined) =>
      hasPermission(user, RolePermission.CanViewTransactionHistory),
  },
];
