import { Flex } from "@mantine/core";
import { useMemo } from "react";
import { Link } from "react-router-dom";

import ActionIcon from "../../../components/ActionIcon/ActionIcon.tsx";
import Delete from "../../../components/Icons/Delete.tsx";
import Edit from "../../../components/Icons/Edit.tsx";
import { RawPengurus } from "../../../types/pengurus.ts";
import { toLocalDate } from "../../../utils/date.ts";
import { toHumanReadable } from "../../../utils/string.ts";
import { useStyles } from "../LimitRequestCreate.styles.ts";

interface Props {
  penguruses: RawPengurus[];
  onEdit: (value: RawPengurus, index: number) => void;
  onDelete: (value: number) => void;
}

export const usePengurusesInfo = ({ penguruses, onEdit, onDelete }: Props) => {
  const { classes } = useStyles();

  return useMemo(() => {
    return penguruses?.map((item, index) => ({
      label: item.name,
      cardControl: (
        <Flex gap={8}>
          <ActionIcon spaces={false} onClick={() => onEdit(item, index)}>
            <Edit />
          </ActionIcon>
          <ActionIcon
            variant="error"
            spaces={false}
            onClick={() => onDelete(index)}
          >
            <Delete />
          </ActionIcon>
        </Flex>
      ),
      items: [
        {
          label: "Pengurus Name",
          content: item.name,
        },
        {
          label: "KTP Pengurus",
          content: (
            <Link
              className={classes.fileName}
              to={item.ktpPengurus.url}
              target="_blank"
              onClick={(e) => e.stopPropagation()}
            >
              Download
            </Link>
          ),
        },
        {
          label: "Gender",
          content: toHumanReadable(item.gender),
        },
        {
          label: "Date of Birth",
          content: toLocalDate(item.birthDate),
        },
        {
          label: "KTP Number",
          content: item.ktpNumber,
        },
        {
          label: "Phone",
          content: item.phone,
        },
        {
          label: "KTP Address",
          content: item.ktpAddress,
        },
        {
          label: "Sub-district",
          content: item.subDistrict,
        },
        {
          label: "District",
          content: item.district,
        },
        {
          label: "City",
          content: item.city.name,
        },
        {
          label: "Postal Code",
          content: item.postalCode,
        },
        {
          label: "Country",
          content: item.country.name,
        },
      ],
    }));
  }, [penguruses, classes, onEdit, onDelete]);
};
