import { rem } from "@mantine/core";

import { ThemeComponent } from "./helpers.ts";

const offsetTop = 85;

const defaultModal = () => ({
  inner: {
    alignItems: "center",
    justifyContent: "center",
    paddingTop: offsetTop,
  },
  content: {
    overflow: "hidden",
    "&, & > div > div": {
      maxHeight: `calc(100dvh - (5dvh + ${offsetTop}px))`,
    },
  },
  header: {
    padding: `${rem(20)} ${rem(10)} ${rem(20)} ${rem(20)}`,
    marginRight: rem(10),
  },
  close: {
    height: rem(24),
    width: rem(24),
    svg: {
      height: rem(24),
      width: rem(24),
    },
  },
  title: {
    fontFamily: "League Spartan",
    fontWeight: 500,
    fontSize: rem(28),
    lineHeight: 1.2,
  },
});

export default function modalTheme(): ThemeComponent {
  return {
    styles: () => defaultModal(),
    sizes: {
      xl: () => ({
        body: {
          height: "100%",
        },
      }),
    },
  };
}
