import {
  MediaQuery,
  Pagination as MantinePagination,
  PaginationProps,
} from "@mantine/core";

import { useStyles } from "./Pagination.styles";

export default function Pagination(props: PaginationProps) {
  const { classes } = useStyles();

  return (
    <div className={classes.pagination}>
      {/*desktop*/}
      <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
        <MantinePagination size={36} spacing={12} {...props} />
      </MediaQuery>

      {/*mobile*/}
      <MediaQuery largerThan="sm" styles={{ display: "none" }}>
        <MantinePagination size={28} spacing={12} boundaries={0} {...props} />
      </MediaQuery>
    </div>
  );
}
