import { useQuery } from "urql";

import { GetClikListOfGrantedLettersOfCreditDocument } from "../../../../graphql/generated.ts";

interface Props {
  clikResultId: number;
}

export const useClikListOfGrantedLettersOfCredit = ({
  clikResultId,
}: Props) => {
  return useQuery({
    query: GetClikListOfGrantedLettersOfCreditDocument,
    variables: { filter: { clikResultId: { equals: clikResultId } } },
  });
};
