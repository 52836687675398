import "./styles/globals.css";

import * as Sentry from "@sentry/react";
import React from "react";
import ReactDOM from "react-dom/client";

import App from "./App";
import appConfig from "./config.ts";

Sentry.init({
  dsn: appConfig.sentryDsn,
  integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    "127.0.0.1",
    "https://ice.t.gearheart.io",
    "https://ice-staging.t.gearheart.io",
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  environment: appConfig.environment,
  enabled: appConfig.environment !== "local",
});

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
