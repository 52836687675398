import isNil from "lodash/isNil";
import { useQuery } from "urql";

import { GetPengurusesDocument } from "../../../graphql/generated.ts";

interface Props {
  limitRequestId: number | string | null | undefined;
}

export function usePenguruses({ limitRequestId }: Props) {
  return useQuery({
    query: GetPengurusesDocument,
    variables: { limitRequestId: Number(limitRequestId) },
    pause: isNil(limitRequestId),
  });
}
