import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useCurrentUserContext } from "../components/Auth/CurrentUserProvider.tsx";
import { AppRoute } from "../constants.ts";
import { UserRole } from "../graphql/generated";

export const MainPage = () => {
  const navigate = useNavigate();

  const { user: currentUser } = useCurrentUserContext();

  const getRoute = useCallback(() => {
    switch (currentUser?.role) {
      case UserRole.Admin:
      case UserRole.SuperAdmin:
        return AppRoute.UserAccessSetting;

      case UserRole.CollectionOfficer:
      case UserRole.CollectionManager:
      case UserRole.FinanceManager:
      case UserRole.FinanceOfficer:
      case UserRole.Chief:
        return AppRoute.CollectionDashboard;

      case UserRole.CuManager:
      case UserRole.CuOfficer:
      case UserRole.RrManager:
      case UserRole.RrOfficer:
      case UserRole.RmonOfficer:
      case UserRole.RmonManager:
      case UserRole.Operations:
        return AppRoute.RiskFunnelDashboard;

      case UserRole.Hr:
        return AppRoute.EmployeeCandidate;

      default:
        return AppRoute.ActivityManagement;
    }
  }, [currentUser]);

  useEffect(() => {
    navigate(getRoute());
  }, [navigate, getRoute]);

  return null;
};
