import { Flex } from "@mantine/core";
import { DataTableSortStatus } from "mantine-datatable";
import { useCallback, useState } from "react";

import SectionTitle from "../../../../../components/Section/SectionTitle/SectionTitle.tsx";
import {
  ClikGrantedCreditOrderBy,
  ContractType,
  ContractTypeFilterOptions,
  OrderBy,
} from "../../../../../graphql/generated.ts";
import { useClikGrantedCredits } from "../../../../../hooks/api/clikResult/clikContract/useClikGrantedCredits.ts";
import { useClikGrantedDetailsOfCredit } from "../../../../../hooks/api/clikResult/clikContract/useClikGrantedDetailsOfCredit.ts";
import { useClikListOfGrantedCredits } from "../../../../../hooks/api/clikResult/clikContract/useClikListOfGrantedCredits.ts";
import {
  ClikGrantedContract,
  ClikGrantedContractDetails,
} from "../../../../../types/clikContract.ts";
import ClikResultDetailsOfContract from "../ClikResultDetailsOfContract/ClikResultDetailsOfContract.tsx";
import ClikGrantedContracts from "../ClikResultGrantedContracts/ClikGrantedContracts.tsx";
import ClikResultNotGrantedContracts from "../ClikResultNotGrantedContracts/ClikResultNotGrantedContracts.tsx";
import { getContractDetailsOptions } from "../contants.ts";
import { getGrantedContractsApiSort } from "../utils.ts";

interface ClikResultCreditContractsProps {
  clikResultId: number;
}

const ClikResultCreditContracts = ({
  clikResultId,
}: ClikResultCreditContractsProps) => {
  const [page, setPage] = useState<number>(1);
  const [orderBy, setOrderBy] = useState<ClikGrantedCreditOrderBy>({
    id: OrderBy.Asc,
  });
  const [selectedContractId, setSelectedContractId] = useState<number | null>();

  const [{ data: grantedCreditsResponse, fetching: grantedCreditsFetching }] =
    useClikGrantedCredits({
      filter: { clikResultId: { equals: clikResultId } },
      orderBy,
      page,
    });

  const [
    {
      data: listOfGrantedCreditsResponse,
      fetching: listOfGrantedCreditsFetching,
    },
  ] = useClikListOfGrantedCredits({
    clikResultId,
  });

  const [
    {
      data: grantedDetailsOfCreditResponse,
      fetching: grantedDetailsOfCreditFetching,
    },
  ] = useClikGrantedDetailsOfCredit({ id: selectedContractId });

  const grantedCreditsData = grantedCreditsResponse?.clikGrantedCredits
    ?.data as ClikGrantedContract[];
  const grantedCreditsCount =
    grantedCreditsResponse?.clikGrantedCredits?.total ?? 0;

  const listOfGrantedCreditsData =
    listOfGrantedCreditsResponse?.clikGrantedCredits?.data;

  const grantedDetailsOfCreditData =
    grantedDetailsOfCreditResponse?.clikGrantedCreditDetails;

  const handleOnSortChange = useCallback(
    (sort: DataTableSortStatus) => {
      setOrderBy(getGrantedContractsApiSort(sort));
    },
    [setOrderBy]
  );

  return (
    <Flex gap={12} direction="column">
      <SectionTitle variant="bronze">
        Contract Details - Credit / Financing
      </SectionTitle>
      <Flex gap={20} direction="column">
        <ClikResultNotGrantedContracts
          clikResultId={clikResultId}
          type={ContractTypeFilterOptions.Credit}
        />
        <ClikGrantedContracts
          contracts={grantedCreditsData}
          total={grantedCreditsCount}
          loading={grantedCreditsFetching}
          type={ContractType.Credit}
          page={page}
          onSetPage={setPage}
          onSortChange={handleOnSortChange}
        />
        <ClikResultDetailsOfContract
          type={ContractType.Credit}
          data={grantedDetailsOfCreditData as ClikGrantedContractDetails}
          loading={
            grantedDetailsOfCreditFetching || listOfGrantedCreditsFetching
          }
          clikContractProfileFilter={
            selectedContractId
              ? {
                  grantedCreditId: { equals: selectedContractId },
                }
              : undefined
          }
          detailsOptions={getContractDetailsOptions(
            listOfGrantedCreditsData,
            ContractType.Credit
          )}
          selectedContractId={selectedContractId}
          onSetSelectedContractId={setSelectedContractId}
        />
      </Flex>
    </Flex>
  );
};

export default ClikResultCreditContracts;
