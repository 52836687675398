import isNil from "lodash/isNil";
import { useQuery } from "urql";

import { GetClikGrantedLetterOfCreditDetailsDocument } from "../../../../graphql/generated.ts";

interface Props {
  id: number | string | null | undefined;
}

export const useClikGrantedDetailsOfLettersOfCredit = ({ id }: Props) => {
  return useQuery({
    query: GetClikGrantedLetterOfCreditDetailsDocument,
    variables: { id: Number(id) },
    pause: isNil(id),
  });
};
