import { Button, Flex } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { DataTableSortStatus } from "mantine-datatable";
import { useCallback, useState } from "react";

import { useCurrentUserContext } from "../../components/Auth/CurrentUserProvider";
import SvgPlus from "../../components/Icons/Plus";
import Layout from "../../components/Layout/Layout";
import Preloader from "../../components/Preloader/Preloader.tsx";
import Table from "../../components/Table/Table";
import { SortDirection } from "../../components/Table/types.ts";
import Title from "../../components/Title/Title.tsx";
import {
  InviteUserInput,
  OrderBy,
  RolePermission,
  UpdateUserInput,
  UserOrderBy,
} from "../../graphql/generated";
import { useInviteUser } from "../../hooks/api/user/useInviteUser";
import { useUpdateUser } from "../../hooks/api/user/useUpdateUser.ts";
import { useUsers } from "../../hooks/api/user/useUsers";
import { useMutationNotificationWrapper } from "../../hooks/useMutationNotificationWrapper.tsx";
import { getApiOrderBy } from "../../utils/api";
import { getPagesCount } from "../../utils/pagination.ts";
import { hasPermission } from "../../utils/user.ts";
import Page404 from "../404/Page404";
import { useUsersTableData } from "./hooks/useUsersTableData";
import InviteUserModal from "./InviteUserModal/InviteUserModal";
import { useStyles } from "./UserAccessSetting.styles.ts";

export default function UserAccessSettingPage() {
  const { classes } = useStyles();

  const { user } = useCurrentUserContext();

  const hasAccess = hasPermission(
    user,
    RolePermission.CanViewUserAccessSetting
  );

  const canAdd = hasPermission(user, RolePermission.CanAddUser);

  const canEdit = hasPermission(user, RolePermission.CanEditUser);

  const [
    inviteModalOpened,
    { open: inviteModalOpen, close: inviteModalClose },
  ] = useDisclosure(false);

  const [orderBy, setOrderBy] = useState<UserOrderBy>({
    createdAt: OrderBy.Asc,
  });
  const [page, setPage] = useState<number>(1);
  const [{ data, fetching }, refreshUsers] = useUsers({
    orderBy,
    pause: !hasAccess,
    page,
  });

  const [, inviteUser] = useMutationNotificationWrapper(useInviteUser(), {
    success: { message: "User has been added to the list." },
  });
  const [, updateUser] = useMutationNotificationWrapper(useUpdateUser(), {
    success: { message: "User has been updated." },
  });

  const handleUpdateUser = useCallback(
    (id: number, input: UpdateUserInput) => {
      updateUser({ id, input }).then((data) => {
        if (!data.error) {
          refreshUsers();
        }
      });
    },
    [refreshUsers, updateUser]
  );

  const [columns, rows] = useUsersTableData({
    data: data?.users.data,
    canEdit,
    onChange: handleUpdateUser,
  });

  const pageCount = getPagesCount(data?.users.total);

  const handleSortChange = useCallback(
    (sort: DataTableSortStatus) => {
      setOrderBy(getApiOrderBy(sort));
    },
    [setOrderBy]
  );

  const handlePageChange = useCallback(
    (page: number) => {
      setPage(page);
    },
    [setPage]
  );

  const handleInviteUser = useCallback(
    (input: InviteUserInput) => {
      inviteUser({ input }).then((data) => {
        if (!data.error) {
          refreshUsers();
        }
      });
    },
    [inviteUser, refreshUsers]
  );

  if (!hasAccess) {
    return <Page404 />;
  }

  return (
    <Layout>
      <Preloader loading={fetching} />
      <Flex className={classes.userAccessSettingWrapper} direction="column">
        <Flex className={classes.titleWrapper}>
          <Title size="h1">User Access Setting</Title>
        </Flex>
        {canAdd && (
          <Button
            uppercase
            variant="filledGolden"
            leftIcon={<SvgPlus />}
            className={classes.inviteModalButton}
            onClick={inviteModalOpen}
          >
            Add
          </Button>
        )}
        {rows && (
          <Table
            columns={columns}
            rows={rows}
            pagination={{
              pageCount: pageCount,
              page: page,
              onPageChange: handlePageChange,
            }}
            defaultSort={{
              columnAccessor: "createdAt",
              direction: SortDirection.asc,
            }}
            onSortChange={handleSortChange}
          />
        )}
        <InviteUserModal
          opened={inviteModalOpened}
          onSubmit={handleInviteUser}
          onClose={inviteModalClose}
        />
      </Flex>
    </Layout>
  );
}
