import isNil from "lodash/isNil";

import { ContractType } from "../../../graphql/generated.ts";
import { ClikAddress, ClikContractSummary } from "../../../types/clikResult.ts";
import { cbScoreItems } from "./ClikResultCbScore/constants.ts";

export const getCRSubjectMatched = (
  isSubjectMatched: boolean | null | undefined
) => (isSubjectMatched ? "Subject Matched" : "Subject Not Matched");

export const getCRCurrentFlagState = (current: boolean | null | undefined) =>
  current ? "Current" : "Historical";

export const getCRDisplayAddress = (
  item: ClikAddress | null | undefined
): string | null => {
  if (!item) {
    return null;
  }
  const address = item.address
    .split(",")
    .map((a) => a.trim())
    .filter(Boolean)
    .join(", ");
  const fullAddress = [
    address,
    item.subDistrict,
    item.district,
    item.city,
    item.postalCode,
    item.country,
  ]
    .filter((item) => !isNil(item) && item !== "")
    .join(", ");
  if (fullAddress === "") {
    return null;
  }
  return fullAddress;
};

export const getCbScoreRisk = (cbScoreRisk: string | undefined | null) =>
  cbScoreItems.find((item) => cbScoreRisk?.includes(item.value))?.value;

export const getContractSummaryByType = (
  data: ClikContractSummary[] | null | undefined,
  type: ContractType
): ClikContractSummary | null => {
  return data?.find((item) => item.type === type) ?? null;
};

export const getCRContractListing = (listing: string) => {
  return listing === "Y" ? "Yes" : "No";
};
