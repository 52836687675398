import { Badge, Flex, Text, Tooltip } from "@mantine/core";

import ActionIcon from "../../../components/ActionIcon/ActionIcon.tsx";
import Edit from "../../../components/Icons/Edit.tsx";
import { User } from "../../../types/user.ts";
import { getUserDisplayName } from "../../../utils/user.ts";
import { useStyles } from "./LimitRequestDetailCardControl.styles.ts";

interface Props {
  updatedBy: Pick<User, "id" | "name" | "email"> | null | undefined;
  edit?: boolean;
  onEdit: () => void;
}

export default function LimitRequestDetailCardControl({
  updatedBy,
  edit,
  onEdit,
}: Props) {
  const { classes } = useStyles();

  return (
    <Flex gap={12}>
      {updatedBy && (
        <Badge variant="updated" size="s">
          <Flex gap={4}>
            <Text className={classes.labelUpdated}>Updated by:</Text>
            <Tooltip
              label={getUserDisplayName(updatedBy)}
              multiline
              transitionProps={{ duration: 200 }}
              withinPortal
              zIndex={1000}
            >
              <Text className={classes.nameUpdated}>
                {getUserDisplayName(updatedBy)}
              </Text>
            </Tooltip>
          </Flex>
        </Badge>
      )}

      {edit && (
        <ActionIcon spaces={false} onClick={onEdit}>
          <Edit />
        </ActionIcon>
      )}
    </Flex>
  );
}
