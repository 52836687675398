import { Flex } from "@mantine/core";
import { DataTableSortStatus } from "mantine-datatable";
import { useCallback, useState } from "react";

import SectionTitle from "../../../../components/Section/SectionTitle/SectionTitle.tsx";
import Table from "../../../../components/Table/Table.tsx";
import { SortDirection } from "../../../../components/Table/types.ts";
import {
  ClikAccountReceivableOrderBy,
  OrderBy,
} from "../../../../graphql/generated.ts";
import { useClikAccountReceivables } from "../../../../hooks/api/clikResult/useClikAccountReceivables.ts";
import { getApiOrderBy } from "../../../../utils/api.ts";
import { getPagesCount } from "../../../../utils/pagination.ts";
import { useCRAccountReceivablesTableData } from "./hooks/useCRAccountReceivablesTableData.tsx";

interface Props {
  clikResultId: number;
}

const ClikResultAccountReceivables = ({ clikResultId }: Props) => {
  const [page, setPage] = useState<number>(1);
  const [orderBy, setOrderBy] = useState<ClikAccountReceivableOrderBy>({
    id: OrderBy.Asc,
  });

  const [{ data, fetching }] = useClikAccountReceivables({
    filter: { clikResultId: { equals: clikResultId } },
    orderBy,
    page,
  });

  const accountReceivables = data?.clikAccountReceivables?.data;
  const pageCount = getPagesCount(data?.clikAccountReceivables?.total);

  const [columns, rows] = useCRAccountReceivablesTableData({
    data: accountReceivables,
  });

  const handleSortChange = useCallback(
    (sort: DataTableSortStatus) => {
      setOrderBy(getApiOrderBy(sort));
    },
    [setOrderBy]
  );

  return (
    <Flex direction="column" gap={16}>
      <SectionTitle variant="bronze">Account Receivables</SectionTitle>
      <Table
        columns={columns}
        rows={rows}
        minWidth="100%"
        defaultSort={{
          columnAccessor: "id",
          direction: SortDirection.asc,
        }}
        pagination={{
          pageCount: pageCount,
          page: page,
          onPageChange: setPage,
        }}
        loading={fetching}
        onSortChange={handleSortChange}
      />
    </Flex>
  );
};

export default ClikResultAccountReceivables;
