import { createStyles, MantineTheme, rem } from "@mantine/core";
import { CSSProperties } from "react";

export const useStyles = createStyles(
  (
    theme: MantineTheme,
    { minWidth }: { minWidth?: CSSProperties["minWidth"] }
  ) => ({
    empty: {
      minWidth: minWidth || "100%",
      maxWidth: "100%",
      width: "100%",
      minHeight: 200,
      padding: 20,
      borderRadius: rem(4),
      background: "rgba(176, 182, 189, 0.08)",
      color: theme.colors.grayIce[0],
      display: "flex",
      flexFlow: "column",
      alignItems: "center",
      justifyContent: "center",
      gap: rem(12),
      fontSize: rem(14),
    },
  })
);
