export enum IsRestructured {
  yes = "YES",
  no = "NO",
}

export type FiltersValue = {
  dpdBucket?: string[];
  companyName?: string[];
  paymentStatus?: string[];
  isRestructured?: string[];
  collector?: string[];
};
