import { useQuery } from "urql";

import {
  GetTransactionHistoryListDocument,
  TransactionHistoryFilter,
  TransactionHistoryOrderBy,
} from "../../../graphql/generated.ts";
import { getApiPaginationData } from "../../../utils/api.ts";
import { ApiHookProps } from "../types.ts";

export const useGetTransactionHistoryList = ({
  filter,
  orderBy,
  page,
}: ApiHookProps<TransactionHistoryFilter, TransactionHistoryOrderBy>) => {
  const pagination = getApiPaginationData(page);
  return useQuery({
    query: GetTransactionHistoryListDocument,
    variables: {
      filter,
      orderBy,
      skip: pagination?.skip,
      take: pagination?.take,
    },
  });
};
