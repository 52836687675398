import { omit } from "lodash";

import {
  EmployeeCandidateRequestInput,
  RawEmployeeCandidateRequest,
} from "../../../../types/employeeCandidateRequest.ts";
import { toClikPhoneFormat } from "../../../../utils/phone.ts";

export const formatEmployeeCandidateRequestToCreateInput = (
  data: RawEmployeeCandidateRequest
): EmployeeCandidateRequestInput => {
  const res = {
    ...data,
    cityId: Number(data.city?.id),
    countryId: Number(data.country?.id),
    phone: toClikPhoneFormat(data.phone),
    postalCode: data.postalCode?.toString(),
    ktpFile: data.ktpFile?.filePath,
    consentForm: data.consentForm?.filePath,
  };
  return omit(res, ["city", "country"]) as EmployeeCandidateRequestInput;
};
