import { useMemo } from "react";

import { MultiFieldCardItem } from "../../../../../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import { ContractType } from "../../../../../../graphql/generated.ts";
import { ClikGrantedContractDetails } from "../../../../../../types/clikContract.ts";
import { toLocalDate } from "../../../../../../utils/date.ts";
import {
  toCurrencyPrice,
  toLocalNumber,
} from "../../../../../../utils/number.ts";
import { getCRContractListing } from "../../../utils.ts";

interface Props {
  data: ClikGrantedContractDetails | null | undefined;
  type: ContractType;
}

export const useCRGrantedDetailsOfContract = ({
  data,
  type,
}: Props): MultiFieldCardItem[] => {
  return useMemo(() => {
    if (type === ContractType.Credit) {
      return [
        {
          label: "Characteristic Credit",
          content: data?.codeCharacteristicCredit,
        },
        {
          label: "Agreement Credit",
          content: data?.codeAgreementCredit,
        },
        {
          label: "Original Agreement Number",
          content: data?.originalAgreementNumber,
        },
        {
          label: "Original Agreement Date",
          content:
            data?.originalAgreementDate &&
            toLocalDate(data.originalAgreementDate),
        },
        {
          label: "Final Agreement Number",
          content: data?.finalAgreementNumber,
        },
        {
          label: "Final Agreement Date",
          content:
            data?.finalAgreementDate && toLocalDate(data.finalAgreementDate),
        },
        {
          label: "Credit Frequency",
          content: data?.frequencyOfCredit,
        },
        {
          label: "Credit Start Date",
          content:
            data?.startDateOfCredit && toLocalDate(data.startDateOfCredit),
        },
        {
          label: "Debtor Category",
          content: data?.debtorCategory,
        },
        {
          label: "Usage Type",
          content: data?.usageType,
        },
        {
          label: "Usage Orientation",
          content: data?.usageOrientation,
        },
        {
          label: "Economic Sector",
          content: data?.economicSector,
        },
        {
          label: "Project Value",
          content: data?.projectValue && toCurrencyPrice(data.projectValue),
        },
        {
          label: "Type of Interest Rate",
          content: data?.typeOfInterestRate,
        },
        {
          label: "Interest Rate",
          content: data?.interestRate && `${toLocalNumber(data.interestRate)}%`,
        },
        {
          label: "Financing Program",
          content: data?.financigProgram,
        },
        {
          label: "City",
          content: data?.city,
        },
      ];
    }

    if (type === ContractType.Bond) {
      return [
        {
          label: "Sovereign Rating",
          content: data?.sovereignRate,
        },
        {
          label: "Listing",
          content: data?.listing && getCRContractListing(data.listing),
        },
        {
          label: "Bond Rating",
          content: data?.bondRating,
        },
        {
          label: "Ownership Purpose",
          content: data?.ownershipPurpose,
        },
        {
          label: "Purchase date",
          content: data?.purchaseDate && toLocalDate(data.purchaseDate),
        },
        {
          label: "Interest Rate",
          content: data?.interestRate && `${toLocalNumber(data.interestRate)}%`,
        },
      ];
    }

    if (type === ContractType.LetterOfCredit) {
      return [
        {
          label: "Destination",
          content: data?.destination,
        },
        {
          label: "Original Agreement Number",
          content: data?.originalAgreementNumber,
        },
        {
          label: "Original Agreement Date",
          content:
            data?.originalAgreementDate &&
            toLocalDate(data.originalAgreementDate),
        },
        {
          label: "Final Agreement Number",
          content: data?.finalAgreementNumber,
        },
        {
          label: "Final Agreement Date",
          content:
            data?.finalAgreementDate && toLocalDate(data.finalAgreementDate),
        },
        {
          label: "Bank Beneficiary",
          content: data?.bankBeneficiery,
        },
        {
          label: "Default Date",
          content: data?.defaultDate && toLocalDate(data.defaultDate),
        },
      ];
    }

    if (type === ContractType.Guarantee) {
      return [
        {
          label: "Guarantee Objective",
          content: data?.guaranteeObjective,
        },
        {
          label: "Original Agreement Number",
          content: data?.originalAgreementNumber,
        },
        {
          label: "Original Agreement Date",
          content:
            data?.originalAgreementDate &&
            toLocalDate(data.originalAgreementDate),
        },
        {
          label: "Final Agreement Number",
          content: data?.finalAgreementNumber,
        },
        {
          label: "Final Agreement Date",
          content:
            data?.finalAgreementDate && toLocalDate(data.finalAgreementDate),
        },
        {
          label: "Guarantor Name",
          content: data?.guarantorName,
        },
        {
          label: "Default Date",
          content: data?.defaultDate,
        },
      ];
    }

    if (type === ContractType.OtherFacilities) {
      return [
        {
          label: "Interest Rate",
          content: data?.interestRate && `${toLocalNumber(data.interestRate)}%`,
        },
      ];
    }
    return [];
  }, [data, type]);
};
