import { MantineTheme, rem } from "@mantine/core";

import { ThemeComponent } from "./helpers.ts";

const defaultBreadcrumbs = (theme: MantineTheme) => ({
  root: {
    background: `linear-gradient(357deg, ${theme.colors.dusk[0]} 8.1%, ${theme.colors.dusk[1]} 51.61%, ${theme.colors.dusk[2]} 64.51%, ${theme.colors.dusk[3]} 85.59%)`,
    height: rem(44),
    padding: rem(20),
    borderRadius: rem(4),
    fontSize: rem(16),
    fontWeight: 600,
    lineHeight: 1.5,

    "&>div": {
      cursor: "default",
      "&:hover": {
        textDecorationLine: "none",
      },
    },

    "&>div:first-of-type": {
      cursor: "pointer",
      textDecorationLine: "underline",

      "&:hover": {
        textDecorationLine: "none",
      },
    },

    "@media (max-width: 48em)": {
      fontSize: rem(12),
    },
  },
  breadcrumb: {
    color: theme.colors.white[0],
  },

  separator: {
    color: theme.colors.white[0],
    fontSize: rem(16),
    marginLeft: rem(8),
    marginRight: rem(8),

    "@media (max-width: 48em)": {
      marginLeft: rem(4),
      marginRight: rem(4),
    },
  },
});

export default function breadcrumbsTheme(): ThemeComponent {
  return {
    styles: (theme) => defaultBreadcrumbs(theme),
  };
}
