import { useQuery } from "urql";

import { GetClikListOfGrantedGuaranteesDocument } from "../../../../graphql/generated.ts";

interface Props {
  clikResultId: number;
}

export const useClikListOfGrantedGuarantees = ({ clikResultId }: Props) => {
  return useQuery({
    query: GetClikListOfGrantedGuaranteesDocument,
    variables: { filter: { clikResultId: { equals: clikResultId } } },
  });
};
