import type { SVGProps } from "react";

const SvgLeftArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 25 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M9.1665 10L4.1665 15L9.1665 20"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.1665 4V11C20.1665 12.0609 19.7451 13.0783 18.9949 13.8284C18.2448 14.5786 17.2274 15 16.1665 15H4.1665"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default SvgLeftArrow;
