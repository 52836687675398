import { useMemo } from "react";

import { MultiFieldCardItem } from "../../../../../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import { ClikContractCommonData } from "../../../../../../graphql/generated.ts";
import { toLocalDate } from "../../../../../../utils/date.ts";

interface Props {
  data: ClikContractCommonData | null | undefined;
  information?: string | null;
}

interface CRContractDetailInfo {
  main: MultiFieldCardItem[];
}

export const useCRContractDetailsCardsInfo = ({
  data,
  information,
}: Props): CRContractDetailInfo => {
  const main = useMemo(() => {
    return [
      {
        label: "Provider Type",
        content: data?.providerType,
      },
      {
        label: "Provider",
        content: data?.providerCode,
      },
      {
        label: "CB Contract Code",
        content: data?.cbContractCode,
      },
      {
        label: "Provider Contract No.",
        content: data?.providerContractNumber,
      },
      {
        label: "Role",
        content: data?.role,
      },
      {
        label: "Last Update Date",
        content: data?.referenceDate && toLocalDate(data?.referenceDate),
      },
      {
        label: "Start Date",
        content: data?.startDate && toLocalDate(data.startDate),
      },
      {
        label: "Due Date",
        content: data?.dueDate && toLocalDate(data.dueDate),
      },
      {
        label: "Past Due Status",
        content: data?.pastDueStaus,
      },
      {
        label: "Contract Status",
        content: data?.contractStatus,
      },
      {
        label: "Contract Status Date",
        content:
          data?.contractStatusDate && toLocalDate(data.contractStatusDate),
      },
      {
        label: "Information",
        content: information,
      },
    ];
  }, [data, information]);

  return { main };
};
