import { DataTableSortStatus } from "mantine-datatable";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { AppRoute } from "../../../constants.ts";
import {
  InvoiceFilter,
  InvoiceOrderBy,
  OrderBy,
} from "../../../graphql/generated";
import { useInvoices } from "../../../hooks/api/invoice/useInvoices.ts";
import { useUpdateInvoice } from "../../../hooks/api/invoice/useUpdateInvoice.ts";
import { useCollectors } from "../../../hooks/api/user/useCollectors.ts";
import { useMutationNotificationWrapper } from "../../../hooks/useMutationNotificationWrapper.tsx";
import { Invoice } from "../../../types/invoice.ts";
import { getPagesCount } from "../../../utils/pagination";
import Table from "../../Table/Table.tsx";
import { SortDirection, TableRow } from "../../Table/types.ts";
import { useInvoicesTableData } from "./hooks/useInvoicesTableData.tsx";
import { getInvoiceApiSort } from "./utils";

interface InvoicesTableProps {
  filter: InvoiceFilter;
  isNavigate?: boolean;
  canEdit?: boolean;
  onSelectRow?: (invoice: Invoice) => void;
}
const InvoicesTable = ({
  filter,
  isNavigate,
  canEdit,
  onSelectRow,
}: InvoicesTableProps) => {
  const navigate = useNavigate();

  const [orderBy, setOrderBy] = useState<InvoiceOrderBy>({
    invoiceNumber: OrderBy.Asc,
  });

  const [page, setPage] = useState<number>(1);

  const [{ data: invoicesData, fetching }, refreshInvoices] = useInvoices({
    filter,
    orderBy,
    page,
  });

  const invoices = invoicesData?.invoices?.data;

  const pageCount = getPagesCount(invoicesData?.invoices?.total);

  const [{ data: collectorsData }] = useCollectors();

  const [, updateInvoice] = useMutationNotificationWrapper(useUpdateInvoice(), {
    success: { message: "Invoice has been updated." },
  });

  useEffect(() => {
    setPage(1);
  }, [filter]);

  const handleUpdateCollector = useCallback(
    (id: number, value: string) => {
      updateInvoice({ id, input: { collectorAssignedId: Number(value) } }).then(
        (data) => {
          if (!data.error) {
            refreshInvoices();
          }
        }
      );
    },
    [refreshInvoices, updateInvoice]
  );

  const [columns, rows] = useInvoicesTableData({
    data: invoices,
    collectors: collectorsData?.users.data,
    canEditCollectorAssigned: canEdit,
    onChange: handleUpdateCollector,
  });

  const handleOnSortChange = useCallback(
    (sort: DataTableSortStatus) => {
      setOrderBy(getInvoiceApiSort(sort));
    },
    [setOrderBy]
  );

  const handlePageChange = useCallback(
    (page: number) => {
      setPage(page);
    },
    [setPage]
  );

  const handleInvoiceClick = (record: TableRow) => {
    if (!invoices) return;
    const invoice = invoices.find((item: Invoice) => item.id === record.id);
    if (!invoice) return;
    onSelectRow && onSelectRow(invoice);
    isNavigate &&
      navigate(`${AppRoute.ActivityManagement}/${invoice.customer.id}`);
  };

  return (
    <>
      <Table
        columns={columns}
        rows={rows}
        defaultSort={{
          columnAccessor: "invoiceNumber",
          direction: SortDirection.asc,
        }}
        pagination={{
          pageCount: pageCount,
          page: page,
          onPageChange: handlePageChange,
        }}
        loading={fetching}
        onSortChange={handleOnSortChange}
        onRowClick={handleInvoiceClick}
      />
    </>
  );
};

export default InvoicesTable;
