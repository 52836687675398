import { Flex } from "@mantine/core";
import classNames from "classnames";

import { StyleProps, useStyles } from "./SectionTitle.styles.ts";

interface SectionTitleProps extends StyleProps {
  children: string;
  className?: string;
}

const SectionTitle = ({
  children,
  className,
  ...styleProps
}: SectionTitleProps) => {
  const { classes } = useStyles(styleProps);

  return (
    <Flex
      direction="column"
      justify="center"
      align="flex-start"
      className={classNames(classes.sectionTitle, className)}
    >
      {children}
    </Flex>
  );
};

export default SectionTitle;
