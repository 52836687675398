import { useMemo } from "react";
import { Link } from "react-router-dom";

import { MultiFieldCardProps } from "../../../../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import { ClikContact } from "../../../../../types/clikContact.ts";
import { toLocalDate } from "../../../../../utils/date.ts";
import { getCRCurrentFlagState } from "../../utils.ts";

interface Props {
  data: ClikContact[] | null | undefined;
}

export const useCRContactsCardsInfo = ({
  data,
}: Props): MultiFieldCardProps[] | null => {
  return useMemo(() => {
    if (!data) {
      return null;
    }

    return data?.map((item) => ({
      label: `${getCRCurrentFlagState(item.current)} - Contact`,
      variant: item.current ? "bronze" : "blue",
      items: [
        {
          label: "Phone Number",
          content: item.phoneNumber && (
            <Link to={`tel:${item.phoneNumber}`}>{item.phoneNumber}</Link>
          ),
        },
        {
          label: "Cell Phone Number",
          content: item.cellphoneNumber && (
            <Link to={`tel:${item.cellphoneNumber}`}>
              {item.cellphoneNumber}
            </Link>
          ),
        },
        {
          label: "Email Address",
          content: item.emailAddress && (
            <Link to={`mailto:${item.emailAddress}`}>
              {item.emailAddress.toLowerCase()}
            </Link>
          ),
        },
        {
          label: "Last Update Date",
          content: item.lastUpdatedDate && toLocalDate(item.lastUpdatedDate),
        },
      ],
    }));
  }, [data]);
};
