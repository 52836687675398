import { DataTableColumn } from "mantine-datatable";
import { useMemo } from "react";

import { TableData, TableRow } from "../../../../components/Table/types";
import { OutstandingPrincipalPerCollection } from "../../../../graphql/generated";
import { getDpdBucketDisplayName } from "../../../../utils/invoice.ts";
import { toLocalNumber, toPercent } from "../../../../utils/number";

interface Props {
  data: OutstandingPrincipalPerCollection[] | null | undefined;
}

export function useOspPerBucketTableData({ data }: Props): TableData {
  const columns: DataTableColumn<TableRow>[] = [
    {
      accessor: "collectionBucket",
      title: "Collection Bucket",
    },
    {
      accessor: "outstandingPrincipal",
      title: "Outstanding Principal",
    },
    {
      accessor: "outstandingPrincipalAmount",
      title: "% Outstanding Amount",
    },
    {
      accessor: "transactionNumber",
      title: "# of Transaction",
    },
    {
      accessor: "outstandingAccount",
      title: "% Outstanding Account",
    },
  ];

  const rows = useMemo(() => {
    return data?.map((item) => {
      return {
        collectionBucket: getDpdBucketDisplayName(item.collectionBucket),
        outstandingPrincipal: toLocalNumber(item.outstandingPrincipal),
        outstandingPrincipalAmount: toPercent(item.outstandingPrincipalAmount),
        transactionNumber: item.transactionNumber,
        outstandingAccount: toPercent(item.outstandingAccount),
      };
    });
  }, [data]);

  return [columns, rows];
}
