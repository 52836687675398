import {
  Button,
  Flex,
  Input,
  MediaQuery,
  Modal,
  ScrollArea,
  Select,
  SimpleGrid,
  TextInput,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { isNotEmpty, matches, useForm } from "@mantine/form";
import { isNull } from "lodash";
import { useCallback } from "react";

import { genderOptions } from "../../../constants.ts";
import {
  ClikCity,
  ClikCountry,
  FileResult,
} from "../../../graphql/generated.ts";
import { RawPengurus } from "../../../types/pengurus.ts";
import {
  getClickCity,
  getClikCityOptions,
  getClikCountry,
  getClikCountryOptions,
} from "../../../utils/clikData.ts";
import { phoneMatcher, toClikPhoneFormat } from "../../../utils/phone.ts";
import Dropzone from "../../Dropzone/Dropzone.tsx";
import Calendar from "../../Icons/Calendar.tsx";
import { useStyles } from "./PengurusFormModal.styles.ts";

interface PengurusFormProps {
  opened: boolean;
  value: RawPengurus | null;
  cities: ClikCity[] | null | undefined;
  countries: ClikCountry[] | null | undefined;
  disabledFields?: string[] | null;
  onClose: () => void;
  onSubmit: (value: RawPengurus) => void;
}

const PengurusFormModal = ({
  opened,
  value,
  cities,
  countries,
  disabledFields,
  onClose,
  onSubmit,
}: PengurusFormProps) => {
  const { classes } = useStyles();

  const form = useForm({
    initialValues: {
      id: value?.id,
      name: value?.name,
      ktpAddress: value?.ktpAddress,
      ktpNumber: value?.ktpNumber,
      phone: value?.phone,
      gender: value?.gender,
      birthDate: value?.birthDate && new Date(value.birthDate),
      subDistrict: value?.subDistrict,
      district: value?.district,
      city: value?.city,
      postalCode: value?.postalCode,
      country: value?.country,
      ktpPengurus: value?.ktpPengurus,
    },
    validate: {
      name: matches(/^[A-Za-z\s]+$/, "Name must contain only letters"),
      ktpAddress: isNotEmpty("Field is required"),
      ktpNumber: isNotEmpty("Field is required"),
      phone: phoneMatcher,
      gender: isNotEmpty("Field is required"),
      birthDate: isNotEmpty("Field is required"),
      subDistrict: isNotEmpty("Field is required"),
      district: isNotEmpty("Field is required"),
      city: isNotEmpty("Field is required"),
      postalCode: matches(
        /^\d{5}$/,
        "The postal code must consist of a maximum of 5 characters. It can include only numbers"
      ),
      country: isNotEmpty("Field is required"),
      ktpPengurus: isNotEmpty("Field is required"),
    },
    validateInputOnBlur: true,
  });

  const handleClose = useCallback(() => {
    form.reset();
    onClose();
  }, [form, onClose]);

  const handleFileUploaded = (files: FileResult[]) => {
    form.setValues({ ktpPengurus: files[0] });
  };

  const handleRemoveFile = () => {
    form.setValues({ ktpPengurus: undefined });
  };

  const handleSetClikCity = (cityId: string) => {
    const clikCity = getClickCity(cityId, cities);
    form.setValues({
      city: {
        id: Number(cityId),
        name: clikCity?.name ?? "",
        code: clikCity?.code ?? "",
      },
    });
  };

  const handleSetClikCountry = (countryId: string) => {
    const clikCountry = getClikCountry(countryId, countries);
    form.setValues({
      country: {
        id: Number(countryId),
        name: clikCountry?.name ?? "",
        code: clikCountry?.code ?? "",
      },
    });
  };

  const handleSubmit = useCallback(() => {
    if (!form.isValid() || !form.values.phone) {
      return;
    }
    onSubmit({
      ...form.values,
      phone: toClikPhoneFormat(form.values.phone),
    } as RawPengurus);
    handleClose();
  }, [form, handleClose, onSubmit]);

  return (
    <Modal
      size="xl"
      opened={opened}
      title={isNull(value) ? "Add Pengurus" : "Update Pengurus"}
      scrollAreaComponent={ScrollArea.Autosize}
      closeButtonProps={{
        size: 24,
        iconSize: 24,
      }}
      onClose={handleClose}
    >
      <Flex direction="column" gap={20} pb={{ base: 84, sm: 0 }}>
        <SimpleGrid
          cols={1}
          breakpoints={[{ minWidth: "sm", cols: 2 }]}
          spacing={20}
          verticalSpacing={12}
        >
          <Flex direction="column" gap={{ base: 12, sm: 20 }}>
            <TextInput
              required
              label="Pengurus Name"
              placeholder="Enter Pengurus Name"
              size="m"
              disabled={disabledFields?.includes("name")}
              {...form.getInputProps("name")}
            />
            <Input.Wrapper required label="KTP Pengurus" size="m" w="100%">
              <Dropzone
                maxFiles={1}
                maxWidth="unset"
                value={
                  form?.values?.ktpPengurus ? [form.values.ktpPengurus] : null
                }
                onUploadSuccess={handleFileUploaded}
                onRemove={handleRemoveFile}
              />
            </Input.Wrapper>
            <Select
              withinPortal
              dropdownPosition="bottom"
              required
              data={genderOptions}
              label="Gender"
              placeholder="Select Gender"
              size="m"
              {...form.getInputProps("gender")}
            />
            <DatePickerInput
              required
              label="Date of Birth"
              placeholder="Select Date of Birth"
              icon={<Calendar />}
              firstDayOfWeek={0}
              size="m"
              valueFormat="YYYY MMM DD"
              maxDate={new Date()}
              popoverProps={{ withinPortal: true }}
              {...form.getInputProps("birthDate")}
            />
            <TextInput
              required
              label="KTP Number"
              placeholder="Enter KTP Number"
              size="m"
              {...form.getInputProps("ktpNumber")}
            />
            <TextInput
              required
              label="Phone"
              placeholder="Enter Phone"
              size="m"
              {...form.getInputProps("phone")}
            />
          </Flex>
          <Flex direction="column" gap={{ base: 12, sm: 20 }}>
            <TextInput
              required
              label="KTP Address"
              placeholder="Enter KTP Address"
              size="m"
              {...form.getInputProps("ktpAddress")}
            />
            <TextInput
              required
              label="Sub-district"
              placeholder="Enter Sub-district"
              size="m"
              {...form.getInputProps("subDistrict")}
            />
            <TextInput
              required
              label="District"
              placeholder="Enter District"
              size="m"
              {...form.getInputProps("district")}
            />
            <Select
              withinPortal
              dropdownPosition="bottom"
              required
              data={getClikCityOptions(cities)}
              label="City"
              placeholder="Select City"
              size="m"
              searchable
              filterDataOnExactSearchMatch
              {...form.getInputProps("city")}
              value={form.values.city?.id.toString()}
              onChange={handleSetClikCity}
            />
            <TextInput
              required
              type="number"
              label="Postal Code"
              placeholder="Postal Code"
              size="m"
              {...form.getInputProps("postalCode")}
            />
            <Select
              withinPortal
              dropdownPosition="bottom"
              required
              data={getClikCountryOptions(countries)}
              label="Country"
              placeholder="Select Country"
              size="m"
              searchable
              filterDataOnExactSearchMatch
              {...form.getInputProps("countryId")}
              value={form.values.country?.id.toString()}
              onChange={handleSetClikCountry}
            />
          </Flex>
        </SimpleGrid>
        <MediaQuery smallerThan="sm" styles={{ display: "none" }}>
          <Flex justify="flex-end" gap={20}>
            <Button
              uppercase
              variant="outlineBlue"
              type="reset"
              onClick={onClose}
            >
              cancel
            </Button>
            <Button
              uppercase
              variant="filledBlue"
              type="submit"
              disabled={!form.isValid()}
              onClick={handleSubmit}
            >
              save
            </Button>
          </Flex>
        </MediaQuery>
      </Flex>
      <MediaQuery largerThan="sm" styles={{ display: "none" }}>
        <Flex gap={20} w="100%" className={classes.mobileButtonBlockWrapper}>
          <Button
            uppercase
            variant="outlineBlue"
            w="100%"
            type="reset"
            onClick={onClose}
          >
            cancel
          </Button>
          <Button
            uppercase
            variant="filledBlue"
            type="submit"
            w="100%"
            disabled={!form.isValid()}
            onClick={handleSubmit}
          >
            save
          </Button>
        </Flex>
      </MediaQuery>
    </Modal>
  );
};

export default PengurusFormModal;
