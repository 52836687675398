import { useDisclosure } from "@mantine/hooks";
import { useCallback, useMemo } from "react";

import { useCurrentUserContext } from "../../../components/Auth/CurrentUserProvider.tsx";
import MultiFieldCard from "../../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import {
  LimitRequestStatus,
  RolePermission,
  UpdateOfferLetterInput,
} from "../../../graphql/generated.ts";
import { useActivateLimit } from "../../../hooks/api/limitRequest/useActivateLimit.ts";
import { useCreateOfferLetter } from "../../../hooks/api/offerLetter/useCreateOfferLetter.ts";
import { useGetOfferingLetter } from "../../../hooks/api/offerLetter/useGetOfferingLetter.ts";
import { useUpdateOfferLetter } from "../../../hooks/api/offerLetter/useUpdateOfferLetter.ts";
import { useMutationNotificationWrapper } from "../../../hooks/useMutationNotificationWrapper.tsx";
import { LimitRequestDetail } from "../../../types/limitRequest.ts";
import { hasPermission } from "../../../utils/user.ts";
import LimitRequestDetailCardControl from "../LimitRequestDetailCardControl/LimitRequestDetailCardControl.tsx";
import { useOfferingLetterCardData } from "./hooks/useOfferingLetterCardData.tsx";
import { useOfferingLetterCardFooter } from "./hooks/useOfferingLetterCardFooter.tsx";
import OfferingLetterModal from "./OfferingLetterModal.tsx";

interface Props {
  limitRequest:
    | Pick<LimitRequestDetail, "id" | "requestStatus" | "offerLetter">
    | null
    | undefined;
  onRefreshLimitRequest: () => void;
}

const OfferingLetterCard = ({ limitRequest, onRefreshLimitRequest }: Props) => {
  const [{ data: offeringLetterResponse, fetching }, refresh] =
    useGetOfferingLetter({
      id: limitRequest?.offerLetter?.id,
    });

  const offeringLetter = offeringLetterResponse?.offerLetter;

  const { user } = useCurrentUserContext();

  const [modalOpened, { open: openModal, close: closeModal }] =
    useDisclosure(false);

  const [, updateOfferLetter] = useMutationNotificationWrapper(
    useUpdateOfferLetter(),
    {
      success: {
        message: "Offer Letter data was successfully updated.",
      },
    }
  );

  const [, createOfferLetter] = useMutationNotificationWrapper(
    useCreateOfferLetter(),
    {
      success: {
        message: "Offer Letter data was successfully added.",
      },
    }
  );

  const [, activateLimit] = useMutationNotificationWrapper(useActivateLimit(), {
    success: {
      message: "Limit Request was successfully activated.",
    },
  });

  const hasEditAccess = hasPermission(
    user,
    RolePermission.CanEditBuyerInfoLimitInfoOfferingLetter
  );

  const handleActivateLimit = useCallback(() => {
    if (!limitRequest) {
      return;
    }
    activateLimit({ id: limitRequest.id }).then((result) => {
      if (!result.error) {
        onRefreshLimitRequest();
      }
    });
  }, [activateLimit, limitRequest, onRefreshLimitRequest]);

  const offeringLetterInfo = useOfferingLetterCardData({ offeringLetter });

  const cardControl = useMemo(() => {
    if (!offeringLetter) {
      return null;
    }
    return (
      <LimitRequestDetailCardControl
        updatedBy={offeringLetter?.updatedBy}
        edit={
          hasEditAccess &&
          offeringLetter &&
          limitRequest?.requestStatus === LimitRequestStatus.Ol
        }
        onEdit={openModal}
      />
    );
  }, [offeringLetter, hasEditAccess, limitRequest, openModal]);

  const cardFooterContent = useOfferingLetterCardFooter({
    offeringLetter,
    canEdit: hasEditAccess,
    limitRequest,
    onActivateLimit: handleActivateLimit,
    onAddData: openModal,
  });

  const handleOfferLetterSubmit = (input: UpdateOfferLetterInput) => {
    if (!limitRequest) {
      return;
    }
    if (limitRequest?.offerLetter?.id) {
      updateOfferLetter({ id: limitRequest?.offerLetter?.id, input }).then(
        (result) => {
          if (!result.error) {
            refresh();
          }
        }
      );
    } else {
      createOfferLetter({
        input: { ...input, limitRequestId: limitRequest.id },
      })
        .then((result) => {
          if (!result.error) {
            onRefreshLimitRequest();
          }
        })
        .then(() => refresh());
    }
    closeModal();
  };

  return (
    <>
      <MultiFieldCard
        label="Offering Letter"
        variant="blue"
        loading={fetching}
        items={offeringLetterInfo}
        cardControl={cardControl}
        cardFooterContent={cardFooterContent}
        disabled={
          !offeringLetter &&
          limitRequest?.requestStatus !== LimitRequestStatus.Ol
        }
      />
      {modalOpened && (
        <OfferingLetterModal
          value={offeringLetter}
          opened={true}
          onClose={closeModal}
          onSubmit={handleOfferLetterSubmit}
        />
      )}
    </>
  );
};

export default OfferingLetterCard;
