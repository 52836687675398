import { Button, Flex, Modal, ScrollArea, SimpleGrid } from "@mantine/core";
import { useCallback, useState } from "react";

import MultiFieldCard from "../../../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import SvgPlus from "../../../../components/Icons/Plus.tsx";
import { UpdateLimitRequestExceptionInput } from "../../../../graphql/generated.ts";
import { LimitRequestException } from "../../../../types/limitRequestException.ts";
import ExceptionForm from "./ExceptionForm.tsx";
import { useExceptionsList } from "./hooks/useExceptionsList.tsx";

interface Props {
  data: LimitRequestException[] | null | undefined;
  opened: boolean;
  onClose: () => void;
  onSubmit: (value: UpdateLimitRequestExceptionInput, id?: number) => void;
  onSave: () => void;
}

const ExceptionModal = ({ data, opened, onClose, onSave, onSubmit }: Props) => {
  const [selectedItem, setSelectedItem] =
    useState<LimitRequestException | null>(null);
  const [openForm, setOpen] = useState<boolean>(false);

  const handleCloseForm = useCallback(() => {
    setOpen(false);
    setSelectedItem(null);
  }, [setOpen, setSelectedItem]);

  const handleSubmitForm = useCallback(
    (value: UpdateLimitRequestExceptionInput, id?: number) => {
      onSubmit(value, id);
      handleCloseForm();
    },
    [onSubmit, handleCloseForm]
  );

  const handleSave = useCallback(() => {
    onClose();
    onSave();
  }, [onClose, onSave]);

  const handleOpen = () => setOpen(true);

  const handleEditForm = (value: LimitRequestException) => {
    setSelectedItem(value);
    handleOpen();
  };

  const exceptionItems = useExceptionsList({
    data,
    onEdit: handleEditForm,
  });

  return (
    <Modal
      opened={opened}
      title={!openForm || selectedItem ? "Edit Exceptions" : "Add Exceptions"}
      closeButtonProps={{
        size: 24,
        iconSize: 24,
      }}
      scrollAreaComponent={ScrollArea.Autosize}
      size="lg"
      onClose={onClose}
    >
      {openForm ? (
        <ExceptionForm
          value={selectedItem}
          onClose={handleCloseForm}
          onSubmit={handleSubmitForm}
        />
      ) : (
        <Flex direction="column" gap={20}>
          <Flex direction="column" gap={8}>
            {exceptionItems && (
              <SimpleGrid w="100%" cols={2} spacing={8} verticalSpacing={8}>
                {exceptionItems.map((item, index) => (
                  <MultiFieldCard key={index} {...item} />
                ))}
              </SimpleGrid>
            )}
            <Button
              variant="outlineBlue"
              leftIcon={<SvgPlus />}
              uppercase
              onClick={handleOpen}
            >
              Add
            </Button>
          </Flex>
          <Flex gap={20} justify="end">
            <Button variant="outlineBlue" uppercase onClick={onClose}>
              cancel
            </Button>
            <Button variant="filledBlue" uppercase onClick={handleSave}>
              save
            </Button>
          </Flex>
        </Flex>
      )}
    </Modal>
  );
};

export default ExceptionModal;
