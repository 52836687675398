import { useMemo } from "react";
import { Link } from "react-router-dom";

import { DirectorInfo } from "../../../../types/directorInfo.ts";

interface Props {
  data: DirectorInfo | null | undefined;
}

export const useCustomerDirectorInfoCard = ({ data }: Props) => {
  return useMemo(() => {
    return [
      {
        label: "Director Name",
        content: data?.directorName,
      },
      {
        label: "Director Title",
        content: data?.directorTitle,
      },
      {
        label: "Director NIK",
        content: data?.directorNik,
      },
      {
        label: "Director Phone No",
        content: data?.directorPhone && (
          <Link to={"tel:" + data.directorPhone} target="_blank">
            {data.directorPhone}
          </Link>
        ),
      },
    ];
  }, [data]);
};
