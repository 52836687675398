import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Client, fetchExchange, Provider as UrqlProvider } from "urql";

import { AuthGuard } from "./components/Auth/AuthGuard.tsx";
import { CurrentUserProvider } from "./components/Auth/CurrentUserProvider.tsx";
import appConfig from "./config.ts";
import { AppRoute } from "./constants";
import Page404 from "./pages/404/Page404.tsx";
import ActivityManagementPage from "./pages/ActivityManagement/ActivityManagement.tsx";
import ActivityManagementDetailPage from "./pages/ActivityManagementDetail/ActivityManagementDetail.tsx";
import ClikDataPage from "./pages/ClikData/ClikDataPage.tsx";
import ClikDataDetailsPage from "./pages/ClikDataDetails/ClikDataDetailsPage.tsx";
import ClikResultPage from "./pages/ClikResult/ClikResultPage.tsx";
import CollectionDashboardPage from "./pages/CollectionDashboard/CollectionDashboardPage.tsx";
import CreditUnderwritingPage from "./pages/CreditUnderwriting/CreditUnderwritingPage.tsx";
import EmployeeCandidatePage from "./pages/EmployeeCandidateRequest/EmployeeCandidate.tsx";
import EmployeeCandidateRequestCreate from "./pages/EmployeeCandidateRequestCreate/EmployeeCandidateRequestCreate.tsx";
import LimitRequestCreatePage from "./pages/LimitRequestCreate/LimitRequestCreatePage.tsx";
import LimitRequestDetailPage from "./pages/LimitRequestDetail/LimitRequestDetailPage.tsx";
import LoginAccessDeniedPage from "./pages/Login/LoginAccessDeniedPage.tsx";
import LoginPage from "./pages/Login/LoginPage.tsx";
import { MainPage } from "./pages/MainPage.tsx";
import ReviewPage from "./pages/Review/ReviewPage";
import RiskFunnelDashboardPage from "./pages/RiskFunnelDashboard/RiskFunnelDashboardPage.tsx";
import RiskMonitoringPage from "./pages/RiskMonitoring/RiskMonitoringPage.tsx";
import RiskReviewPage from "./pages/RiskReview/RiskReviewPage.tsx";
import UserAccessSettingPage from "./pages/UserAccessSetting/UserAccessSettingPage.tsx";
import { theme } from "./utils/mantine/theme";

const router = createBrowserRouter(
  [
    {
      path: AppRoute.Home,
      element: <AuthGuard component={<MainPage />} />,
    },
    {
      path: AppRoute.Login,
      element: <LoginPage />,
    },
    {
      path: AppRoute.AccessDenied,
      element: <LoginAccessDeniedPage />,
    },
    {
      path: AppRoute.UserAccessSetting,
      element: <AuthGuard component={<UserAccessSettingPage />} />,
    },
    {
      path: AppRoute.ActivityManagement,
      element: <AuthGuard component={<ActivityManagementPage />} />,
    },
    {
      path: `${AppRoute.ActivityManagement}/:customerId`,
      element: <AuthGuard component={<ActivityManagementDetailPage />} />,
    },
    {
      path: AppRoute.ReviewAndApproval,
      element: <AuthGuard component={<ReviewPage />} />,
    },
    {
      path: AppRoute.CollectionDashboard,
      element: <AuthGuard component={<CollectionDashboardPage />} />,
    },
    {
      path: AppRoute.LimitRequestCreate,
      element: <AuthGuard component={<LimitRequestCreatePage />} />,
    },
    {
      path: AppRoute.ClikData,
      element: <AuthGuard component={<ClikDataPage />} />,
    },
    {
      path: `${AppRoute.ClikData}/:id`,
      element: <AuthGuard component={<ClikDataDetailsPage />} />,
    },
    {
      path: AppRoute.EmployeeCandidate,
      element: <AuthGuard component={<EmployeeCandidatePage />} />,
    },
    {
      path: AppRoute.EmployeeCandidateCreate,
      element: <AuthGuard component={<EmployeeCandidateRequestCreate />} />,
    },
    {
      path: `${AppRoute.ClikResult}/:id`,
      element: <AuthGuard component={<ClikResultPage />} />,
    },
    {
      path: `${AppRoute.LimitRequest}/:id`,
      element: <AuthGuard component={<LimitRequestDetailPage />} />,
    },
    {
      path: AppRoute.CreditUnderwriting,
      element: <AuthGuard component={<CreditUnderwritingPage />} />,
    },
    {
      path: `${AppRoute.RiskFunnelDashboard}`,
      element: <AuthGuard component={<RiskFunnelDashboardPage />} />,
    },
    {
      path: `${AppRoute.RiskMonitoring}`,
      element: <AuthGuard component={<RiskMonitoringPage />} />,
    },
    {
      path: `${AppRoute.RiskReview}`,
      element: <AuthGuard component={<RiskReviewPage />} />,
    },
    {
      path: "*",
      element: <Page404 />,
    },
  ],
  { basename: AppRoute.Home }
);

export default function App() {
  const client = new Client({
    url: `${appConfig.apiUrl}/graphql`,
    fetchOptions: { credentials: "include" },
    exchanges: [fetchExchange],
  });

  return (
    <UrqlProvider value={client}>
      <CurrentUserProvider>
        <MantineProvider theme={theme} withGlobalStyles withNormalizeCSS>
          <Notifications position="top-right" style={{ zIndex: 4000 }} />
          <RouterProvider router={router} />
        </MantineProvider>
      </CurrentUserProvider>
    </UrqlProvider>
  );
}
