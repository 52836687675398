import { useQuery } from "urql";

import {
  GetCollectorsDocument,
  UserRoleFilterOptions,
  UserStatusFilterOptions,
} from "../../../graphql/generated";

export const useCUAssignees = () => {
  const variables = {
    filter: {
      role: {
        in: [UserRoleFilterOptions.CuManager, UserRoleFilterOptions.CuOfficer],
      },
      status: { equals: UserStatusFilterOptions.Active },
    },
  };
  return useQuery({
    query: GetCollectorsDocument,
    variables,
  });
};
