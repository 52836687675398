import {
  OfferLetter as GqlGeneratedOfferLetter,
  OfferLetterStatus,
} from "../graphql/generated.ts";
import { toHumanReadable } from "../utils/string.ts";
import { User } from "./user.ts";

export type OfferLetter = Omit<GqlGeneratedOfferLetter, "updatedBy"> & {
  updatedBy: Pick<User, "id" | "name" | "email">;
};

export const offerLetterStatusOptions = Object.values(OfferLetterStatus).map(
  (value) => {
    return { value, label: toHumanReadable(value) };
  }
);
