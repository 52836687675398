import { DataTableColumn } from "mantine-datatable";
import { useMemo } from "react";

import { TableData, TableRow } from "../../../../components/Table/types";
import { toLocalDate } from "../../../../utils/date";
import { toLocalNumber } from "../../../../utils/number";
import { toHumanReadable } from "../../../../utils/string";
import { getUserDisplayName } from "../../../../utils/user.ts";
import { OngoingCUType } from "../../types.ts";

interface Props {
  data: OngoingCUType[] | null | undefined;
}

export const useOngoingCUTableData = ({ data }: Props): TableData => {
  const columns: DataTableColumn<TableRow>[] = [
    {
      accessor: "id",
      hidden: true,
    },
    {
      accessor: "cuAssignedDate",
      title: "CU Assigned Date",
      sortable: true,
    },
    {
      accessor: "createdAt",
      title: "Limit Request Date",
      sortable: true,
    },
    {
      accessor: "companyName",
      title: "Company Name",
      sortable: true,
    },
    {
      accessor: "salesName",
      title: "Sales Name",
      sortable: true,
    },
    {
      accessor: "salesTeamName",
      title: "Sales Team",
      sortable: true,
    },

    {
      accessor: "limitRequestAmount",
      title: "Limit Request",
      sortable: true,
    },
    {
      accessor: "tenorRequest",
      title: "Tenor Request",
      sortable: true,
    },
    {
      accessor: "limitType",
      title: "Limit Type",
      sortable: true,
    },
    {
      accessor: "cuAssignedId",
      title: "CU Assigned",
      sortable: true,
    },
    {
      accessor: "requestStatus",
      title: "CU Status",
      sortable: true,
    },
  ];

  const rows = useMemo(() => {
    return data?.map((item) => {
      return {
        id: item.id,
        cuAssignedDate: toLocalDate(item.cuAssignedDate),
        createdAt: toLocalDate(item.createdAt),
        companyName: item.company.name,
        salesName: item.company.salesName,
        salesTeamName: item.company.salesTeamName,
        limitRequestAmount: toLocalNumber(item.limitRequestAmount),
        tenorRequest: toLocalNumber(item.tenorRequest),
        limitType:
          item?.limitRequestType && toHumanReadable(item.limitRequestType),
        cuAssignedId: item?.cuAssigned && getUserDisplayName(item.cuAssigned),
        requestStatus: toHumanReadable(item.requestStatus),
      };
    });
  }, [data]);

  return [columns, rows];
};
