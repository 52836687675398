import { pickBy } from "lodash";

import { DpdBucketFilterOptions, InvoiceFilter } from "../../graphql/generated";
import { FiltersValue } from "./CustomerPayPastDue/CustomerPayPastDueToolbar/types.ts";

export const getInvoiceApiFilter = (value: FiltersValue): InvoiceFilter => {
  return pickBy({
    dpdBucket: value.dpdBucket?.length
      ? { in: value.dpdBucket as DpdBucketFilterOptions[] }
      : null,
    customerId: value.companyName?.length
      ? { in: value.companyName?.map((c) => Number(c)) }
      : null,
  });
};
