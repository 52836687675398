import { DataTableColumn } from "mantine-datatable";
import { ReactNode } from "react";

export type TableRow = Record<string, string | ReactNode>;

export type TableData = [DataTableColumn<TableRow>[], TableRow[] | undefined];

export enum SortDirection {
  asc = "asc",
  desc = "desc",
}
