import { useMemo } from "react";
import { Link } from "react-router-dom";

import { CreditUnderwriting } from "../../../../types/creditUnderwriting.ts";
import { LimitRequestDetail } from "../../../../types/limitRequest.ts";
import { toLocalDate } from "../../../../utils/date.ts";
import { toCurrencyPrice, toPercent } from "../../../../utils/number.ts";
import { toHumanReadable } from "../../../../utils/string.ts";
import { getUserDisplayName } from "../../../../utils/user.ts";

interface Props {
  limitRequest:
    | Pick<LimitRequestDetail, "cuAssigned" | "cuAssignedDate">
    | null
    | undefined;
  creditUnderwriting: CreditUnderwriting | null | undefined;
}

export default function useCreditUnderwritingCardData({
  limitRequest,
  creditUnderwriting,
}: Props) {
  return useMemo(() => {
    return [
      {
        label: "CU Assigned Date",
        content:
          limitRequest?.cuAssignedDate &&
          toLocalDate(limitRequest.cuAssignedDate),
      },
      {
        label: "CU Assigned",
        content: getUserDisplayName(limitRequest?.cuAssigned),
      },
      {
        label: "CLIK Result Review Date",
        content:
          creditUnderwriting?.clikResultReviewDate &&
          toLocalDate(creditUnderwriting.clikResultReviewDate),
      },
      {
        label: "CLIK Result Category",
        content:
          creditUnderwriting?.clikResultCategory &&
          toHumanReadable(creditUnderwriting.clikResultCategory),
      },
      {
        label: "LOI Result Date",
        content:
          creditUnderwriting?.loiResultDate &&
          toLocalDate(creditUnderwriting.loiResultDate),
      },
      {
        label: "LOI Result",
        content: creditUnderwriting?.loiResultFile?.url && (
          <Link to={creditUnderwriting?.loiResultFile?.url} target="_blank">
            Download
          </Link>
        ),
      },
      {
        label: "LOI Result Category",
        content:
          creditUnderwriting?.loiResultCategory &&
          toHumanReadable(creditUnderwriting.loiResultCategory),
      },

      {
        label: "Insurance Request Date",
        content:
          creditUnderwriting?.insuranceRequestDate &&
          toLocalDate(creditUnderwriting.insuranceRequestDate),
      },
      {
        label: "Insurance Result Date",
        content:
          creditUnderwriting?.insuranceResultDate &&
          toLocalDate(creditUnderwriting.insuranceResultDate),
      },
      {
        label: "Insurance Result",
        content:
          creditUnderwriting?.insuranceResult &&
          toHumanReadable(creditUnderwriting.insuranceResult),
      },
      {
        label: "Insurance Limit Approved",
        content: toCurrencyPrice(creditUnderwriting?.insuranceLimitApproved),
      },
      {
        label: "Visit Date",
        content: toLocalDate(creditUnderwriting?.visitDate),
      },
      {
        label: "Document Collection Date",
        content: toLocalDate(creditUnderwriting?.documentCollectionStartDate),
      },
      {
        label: "Document Completed Date",
        content: toLocalDate(creditUnderwriting?.documentCompletedDate),
      },

      {
        label: "CU Analysis Result Date",
        content: toLocalDate(creditUnderwriting?.analysisResultDate),
      },
      {
        label: "CU Analysis Result",
        content:
          creditUnderwriting?.analysisResult &&
          toHumanReadable(creditUnderwriting.analysisResult),
      },
      {
        label: "CU Analysis",
        content: creditUnderwriting?.analysisFile?.url && (
          <Link to={creditUnderwriting?.analysisFile?.url} target="_blank">
            Download
          </Link>
        ),
      },
      {
        label: "CU Limit Recommendation",
        content: toCurrencyPrice(creditUnderwriting?.limitRecommendation),
      },
      {
        label: "CU Tenor Recommendation",
        content: creditUnderwriting?.tenorRecommendation,
      },
      {
        label: "CU Pricing Recommendation",
        content: toPercent(
          creditUnderwriting?.pricingRecommendation,
          "percentage"
        ),
      },
      {
        label: "CU Notes",
        content: creditUnderwriting?.notes,
      },
    ];
  }, [creditUnderwriting, limitRequest]);
}
