import { useMemo } from "react";

import { MultiFieldCardItem } from "../../../../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import { ClikFootprintStatistic } from "../../../../../types/clikResult.ts";

interface Props {
  data: ClikFootprintStatistic | null | undefined;
}

export const useCRFootprintStatisticCardsInfo = ({
  data,
}: Props): MultiFieldCardItem[] => {
  return useMemo(() => {
    return [
      {
        label: "1 Month",
        content: data?.footprintsCountOneMonth,
      },
      {
        label: "3 Months",
        content: data?.footprintsCountThreeMonth,
      },
      {
        label: "6 Months",
        content: data?.footprintsCountSixMonth,
      },
      {
        label: "12 Months",
        content: data?.footprintsCountTwelveMonth,
      },
    ];
  }, [data]);
};
