import { Text } from "@mantine/core";
import { isEmpty } from "lodash";
import { DataTableColumn } from "mantine-datatable";
import { useMemo } from "react";

import Spoiler from "../../../../components/Spoiler/Spoiler.tsx";
import { TableData, TableRow } from "../../../../components/Table/types.ts";
import TableFilesDisplay from "../../../../components/TableFilesDisplay/TableFilesDisplay.tsx";
import { CollectionActivity } from "../../../../types/collectionActivity.ts";
import { formatLogDetails } from "../../../../utils/collectionActivity.ts";
import { toLocalDate } from "../../../../utils/date.ts";
import { toLocalNumber } from "../../../../utils/number.ts";
import { toHumanReadable } from "../../../../utils/string.ts";
import { getUserDisplayName } from "../../../../utils/user.ts";
import { useStyles } from "../collectionActivityTab.styles.ts";

interface Props {
  data: CollectionActivity[] | null | undefined;
}

export function useCollectionActivityTableData({ data }: Props): TableData {
  const { classes } = useStyles();

  const columns: DataTableColumn<TableRow>[] = [
    {
      accessor: "invoiceNumber",
      title: "Inv No",
      sortable: true,
      width: "250px",
    },
    {
      accessor: "createdAt",
      title: "Date",
      sortable: true,
      width: "150px",
    },
    {
      accessor: "dpd",
      title: "DPD",
      sortable: true,
      width: "100px",
    },
    {
      accessor: "collectorAssigned",
      title: "Collector Assigned",
      sortable: true,
      width: "200px",
    },
    {
      accessor: "category",
      title: "Collection Activity Category",
      sortable: true,
      width: "200px",
    },
    {
      accessor: "detailCollectionActivity",
      title: "Detail Collection Activity",
      sortable: true,
      width: "200px",
    },
    {
      accessor: "resultCategory",
      title: "Result Category",
      sortable: true,
      width: "200px",
    },
    {
      accessor: "resultDescription",
      title: "Result Description",
      sortable: true,
      width: "200px",
    },
    {
      accessor: "paidAmount",
      title: "Paid Amount",
      sortable: true,
      width: "200px",
    },
    {
      accessor: "paymentStatus",
      title: "Payment Status",
      sortable: true,
      width: "200px",
    },
    {
      accessor: "attachment",
      title: "Attachment",
      width: "135px",
    },
    {
      accessor: "logDetails",
      title: "Log Details",
      width: "300px",
    },
  ];

  const rows = useMemo(() => {
    return data?.map((item) => {
      return {
        invoiceNumber: item?.invoice?.invoiceNumber,
        createdAt: item.createdAt && toLocalDate(item.createdAt),
        dpd: toLocalNumber(item.invoice.dpd),
        collectorAssigned: item.collectorAssigned && (
          <Text className={classes.collectorAssignedWrapper}>
            {getUserDisplayName(item.collectorAssigned)}
          </Text>
        ),
        category: item.category && toHumanReadable(item.category),
        detailCollectionActivity: item.detailCollectionActivity,
        resultCategory:
          item.resultCategory && toHumanReadable(item.resultCategory),
        resultDescription: item.resultDescription,
        paidAmount:
          item?.invoice?.totalPaid && toLocalNumber(item?.invoice?.totalPaid),
        paymentStatus:
          item?.invoice?.paymentStatus &&
          toHumanReadable(item?.invoice?.paymentStatus),
        attachment:
          item.attachment && !isEmpty(item.attachment) ? (
            <TableFilesDisplay files={[item.attachment]} />
          ) : null,
        logDetails: !isEmpty(item?.logDetails) ? (
          <Spoiler text={formatLogDetails(item.logDetails)} />
        ) : null,
      };
    });
  }, [data, classes]);

  return [columns, rows];
}
