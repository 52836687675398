import { Button, Flex, SimpleGrid } from "@mantine/core";

import SvgPlus from "../../../../components/Icons/Plus.tsx";
import { LimitRequestConditionPrecedent } from "../../../../types/limitRequestConditionPrecedent.ts";
import { useStyles } from "../ConditionPrecedents.styles.ts";
import { useConditionPrecedentList } from "./hooks/useConditionPrecedentList.tsx";

interface Props {
  conditionPrecedents: LimitRequestConditionPrecedent[] | null | undefined;
  onAdd: () => void;
  onEdit: (value: LimitRequestConditionPrecedent) => void;
  onDelete: (id: number) => void;
}

const ConditionPrecedentList = ({
  conditionPrecedents,
  onAdd,
  onEdit,
  onDelete,
}: Props) => {
  const { classes } = useStyles();

  const renderedConditionPrecedents = useConditionPrecedentList({
    data: conditionPrecedents,
    onEdit,
    onDelete,
  });

  return (
    <Flex direction="column">
      <Flex className={classes.label}>Condition precedence</Flex>
      <Flex direction="column" gap={8}>
        {renderedConditionPrecedents && (
          <SimpleGrid w="100%" cols={2} spacing={8} verticalSpacing={8}>
            {renderedConditionPrecedents}
          </SimpleGrid>
        )}
        <Button
          variant="outlineBlue"
          leftIcon={<SvgPlus />}
          uppercase
          onClick={onAdd}
        >
          Add
        </Button>
      </Flex>
    </Flex>
  );
};

export default ConditionPrecedentList;
