import { useQuery } from "urql";

import {
  ClikDataFilter,
  ClikDataOrderBy,
  GetClikDataListDocument,
} from "../../../graphql/generated.ts";
import { getApiPaginationData } from "../../../utils/api.ts";
import { ApiHookProps } from "../types.ts";

export const useClikDataList = ({
  filter,
  orderBy,
  page,
}: ApiHookProps<ClikDataFilter, ClikDataOrderBy>) => {
  const pagination = getApiPaginationData(page);
  const variables = {
    filter,
    orderBy,
    skip: pagination?.skip,
    take: pagination?.take,
  };
  return useQuery({ query: GetClikDataListDocument, variables });
};
