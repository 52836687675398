export const cbScoreItems = [
  {
    value: "A",
    color: "#D90A19",
  },
  {
    value: "B",
    color: "#ED5737",
  },
  {
    value: "C",
    color: "#FB722F",
  },
  {
    value: "D",
    color: "#FD8F31",
  },
  {
    value: "E",
    color: "#FBB733",
  },
  {
    value: "F",
    color: "#D7D178",
  },
  {
    value: "G",
    color: "#ABD297",
  },
  {
    value: "H",
    color: "#7DBE93",
  },
  {
    value: "I",
    color: "#55A56D",
  },
  {
    value: "J",
    color: "#149148",
  },
];
