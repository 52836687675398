import { createStyles, rem } from "@mantine/core";

export const useStyles = createStyles((theme) => ({
  buttonWrapper: {
    width: "100%",
    height: "100%",
    minHeight: rem(320),
    borderRadius: rem(4),
    color: theme.colors.dusk[0],
    border: `1px dashed ${theme.colors.dusk[0]}`,
    textTransform: "uppercase",
    fontSize: rem(20),
    fontWeight: 600,
    lineHeight: 1.5,
    cursor: "pointer",

    "&:hover": {
      background: theme.colors.grayIce[6],
    },
  },
}));
