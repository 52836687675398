import type { SVGProps } from "react";
const SvgPlus = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 -960 960 960"
    fill="currentColor"
    {...props}
  >
    <path d="M460-460H240v-40h220v-220h40v220h220v40H500v220h-40v-220Z" />
  </svg>
);
export default SvgPlus;
