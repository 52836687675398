import { LimitTypeName } from "../../../../graphql/generated.ts";

export const LimitTypeNameOptions = {
  [LimitTypeName.LimitRequested]: "Limit requested",
  [LimitTypeName.ClikPassed]: "CLIK passed",
  [LimitTypeName.LoiPassed]: "LOI passed",
  [LimitTypeName.InsuranceApproved]: "Insurance Approved",
  [LimitTypeName.DocumentCompleted]: "Document completed",
  [LimitTypeName.CuApproved]: "CU approved",
  [LimitTypeName.RrApproved]: "RR Approved",
  [LimitTypeName.CaApproved]: "Committee approved",
  [LimitTypeName.OlSigned]: "PKS/OL signed",
  [LimitTypeName.LimitActive]: "Limit active",
};
