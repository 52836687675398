import { Box, Flex, SimpleGrid } from "@mantine/core";

import SingleFieldCard from "../../../components/Cards/SingleFieldCard/SingleFieldCard";
import SectionTitle from "../../../components/Section/SectionTitle/SectionTitle";
import { CURRENCY } from "../../../constants.ts";
import { QueryDashboardStatisticsArgs } from "../../../graphql/generated";
import { useDashboardStatistics } from "../../../hooks/api/dashboard/useDashboardStatistics";
import { toLocalNumber, toPercent } from "../../../utils/number";
import { useStyles } from "../CollectionDashboardPage.styles";

interface Props {
  filter: QueryDashboardStatisticsArgs["salesTeamName"];
}

export default function DashboardStatisticCards({ filter }: Props) {
  const { classes } = useStyles();

  const [{ data, fetching }] = useDashboardStatistics({
    salesTeamName: filter,
  });
  const statisticsData = data?.dashboardStatistics;

  return (
    <SimpleGrid
      cols={3}
      spacing={20}
      verticalSpacing={8}
      className={classes.grid}
    >
      <Flex direction="column" gap={12}>
        <SectionTitle isSmall flex={1}>
          {`Outstanding Principal (${CURRENCY})`}
        </SectionTitle>
        <Box className={classes.firstCardWrapper}>
          <SingleFieldCard
            label={`Total outstanding principal (${CURRENCY})`}
            value={toLocalNumber(
              statisticsData?.outstandingPrincipalInAmount
                .totalOutstandingPrincipal
            )}
            loading={fetching}
          />
        </Box>
        <Box className={classes.secondCardWrapper}>
          <SingleFieldCard
            label={`Total outstanding invoice amount (${CURRENCY})`}
            value={toLocalNumber(
              statisticsData?.outstandingPrincipalInAmount
                .totalOutstandingInvoiceAmount
            )}
            loading={fetching}
          />
        </Box>
        <Box className={classes.thirdCardWrapper}>
          <SingleFieldCard
            label="Outstanding principal"
            value={toPercent(
              statisticsData?.outstandingPrincipalInAmount
                .outstandingPrincipalPercent
            )}
            loading={fetching}
          />
        </Box>
      </Flex>

      <Flex direction="column" gap={12}>
        <SectionTitle isSmall flex={1}>
          Outstanding Principal (# of Invoice)
        </SectionTitle>
        <Box className={classes.firstCardWrapper}>
          <SingleFieldCard
            label="Total outstanding invoice"
            value={
              statisticsData?.outstandingPrincipalInAccount
                .totalOutstandingInvoice
            }
            loading={fetching}
          />
        </Box>
        <Box className={classes.secondCardWrapper}>
          <SingleFieldCard
            label="Total TOP invoice"
            value={
              statisticsData?.outstandingPrincipalInAccount.totalTopInvoice
            }
            loading={fetching}
          />
        </Box>
        <Box className={classes.thirdCardWrapper}>
          <SingleFieldCard
            label="% outstanding invoice"
            value={toPercent(
              statisticsData?.outstandingPrincipalInAccount
                .outstandingInvoicePercent
            )}
            loading={fetching}
          />
        </Box>
      </Flex>

      <Flex direction="column" gap={12}>
        <SectionTitle isSmall flex={1}>
          First Payment Default (unique customer)
        </SectionTitle>
        <Box className={classes.firstCardWrapper}>
          <SingleFieldCard
            label="Total FPD customer"
            value={statisticsData?.firstPaymentDefault.totalFpdCustomer}
            loading={fetching}
          />
        </Box>
        <Box className={classes.secondCardWrapper}>
          <SingleFieldCard
            label="Total TOP customer"
            value={statisticsData?.firstPaymentDefault.totalTopCustomer}
            loading={fetching}
          />
        </Box>
        <Box className={classes.thirdCardWrapper}>
          <SingleFieldCard
            label="% FPD customer"
            value={toPercent(
              statisticsData?.firstPaymentDefault.fpdCustomerPercent
            )}
            loading={fetching}
          />
        </Box>
      </Flex>
    </SimpleGrid>
  );
}
