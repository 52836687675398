import { createStyles, rem } from "@mantine/core";
import { MantineTheme } from "@mantine/styles";

export const useStyles = createStyles((theme: MantineTheme) => ({
  tabList: {
    gap: rem(12),

    "@media (max-width: 48em)": {
      display: "grid",
      gridTemplateColumns: "repeat(2, 1fr)",
      border: 0,
    },
  },
  tab: {
    fontSize: rem(16),
    width: rem(230),
    height: rem(46),
    "&[data-active]": {
      borderTop: `1px solid ${theme.colors.grayIce[0]}`,
      borderLeft: `1px solid ${theme.colors.grayIce[0]}`,
      borderBottom: `1px solid ${theme.colors.grayIce[0]}`,
      borderRight: 0,
    },

    "@media (max-width: 48em)": {
      width: "50%",
    },
  },
  panel: {
    borderLeft: 0,
    borderTop: `1px solid ${theme.colors.grayIce[0]}`,
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,

    "@media (max-width: 48em)": {
      height: "unset",
      padding: rem(12),
      borderRadius: rem(3),
    },
  },
  customer: {
    fontSize: rem(14),
    whiteSpace: "nowrap",
    width: "fit-content",
  },
  customersWrapper: {
    width: "max-content",
    paddingBottom: rem(20),
  },
  scrollArea: {
    minWidth: "100%",
    maxWidth: "100%",

    "@media (max-width: 48em)": {
      height: rem(312),
    },
  },
  empty: {
    width: "100%",
    height: "100%",
    color: theme.colors.grayIce[0],
    fontSize: rem(14),
    lineHeight: 1.5,
    textTransform: "capitalize",
  },
}));
