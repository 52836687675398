type AppConfig = {
  apiUrl: string;
  environment: string;
  sentryDsn: string;
};

const appConfig: AppConfig = {
  apiUrl: import.meta.env.VITE_API_URL ?? "",
  environment: import.meta.env.VITE_ENVIRONMENT ?? "",
  sentryDsn: import.meta.env.VITE_SENTRY_DSN ?? "",
};

export default appConfig;
