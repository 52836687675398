import { DataTableColumn } from "mantine-datatable";
import { useMemo } from "react";

import { TableData, TableRow } from "../../../../../components/Table/types.ts";
import { ClikAccountReceivable } from "../../../../../graphql/generated.ts";
import { toLocalDate } from "../../../../../utils/date.ts";
import { toCurrencyPrice } from "../../../../../utils/number.ts";

interface Props {
  data: ClikAccountReceivable[] | null | undefined;
}

export function useCRAccountReceivablesTableData({ data }: Props): TableData {
  const columns: DataTableColumn<TableRow>[] = [
    {
      accessor: "providerCode",
      title: "Provider",
      sortable: true,
      width: "150px",
    },
    {
      accessor: "providerType",
      title: "Provider Type",
      sortable: true,
      width: "250px",
    },
    {
      accessor: "transactionType",
      title: "Transaction Type",
      sortable: true,
      width: "200px",
    },
    {
      accessor: "transactionStatus",
      title: "Transaction Status",
      sortable: true,
      width: "200px",
    },
    {
      accessor: "transactionAmount",
      title: "Transaction Amount",
      sortable: true,
      width: "200px",
    },
    {
      accessor: "documentDate",
      title: "Document Date",
      sortable: true,
      width: "150px",
    },
    {
      accessor: "expiryDate",
      title: "Expiry/Due Date",
      sortable: true,
      width: "150px",
    },
    {
      accessor: "closingDate",
      title: "Closing Date",
      sortable: true,
      width: "150px",
    },
    {
      accessor: "disputeFlag",
      title: "Dispute Flag",
      sortable: true,
      width: "150px",
    },
    {
      accessor: "disputeDate",
      title: "Dispute Date",
      sortable: true,
      width: "150px",
    },
    {
      accessor: "referenceDate",
      title: "Last Reference Date",
      sortable: true,
      width: "150px",
    },
  ];

  const rows = useMemo(() => {
    return data?.map((item) => ({
      providerCode: item.providerCode,
      providerType: item.providerType,
      transactionType: item.transactionType,
      transactionStatus: item.transactionStatus,
      transactionAmount:
        item.transactionAmount && toCurrencyPrice(item.transactionAmount),
      documentDate: item.documentDate && toLocalDate(item.documentDate),
      expiryDate: item.expiryDate && toLocalDate(item.expiryDate),
      closingDate: item.closingDate && toLocalDate(item.closingDate),
      disputeFlag: item.disputeFlag,
      disputeDate: item.disputeDate && toLocalDate(item.disputeDate),
      referenceDate: item.referenceDate && toLocalDate(item.referenceDate),
    }));
  }, [data]);

  return [columns, rows];
}
