import {
  RestructureApprovalStatusFilterOptions,
  UserRole,
} from "../../graphql/generated";
import { toHumanReadable } from "../../utils/string.ts";

export const restructureCreateAllowedRoles: UserRole[] = [UserRole.SuperAdmin];

export const restructureApprovalStatusOptions = Object.values(
  RestructureApprovalStatusFilterOptions
).map((item) => ({
  value: item,
  label: toHumanReadable(item),
}));
