import get from "lodash/get";
import isBoolean from "lodash/isBoolean";

export const stringToBoolean = (
  value: string | null,
  strict = false
): boolean | null => {
  const stringToBooleanMap = {
    true: true,
    false: false,
  };

  if (!value) {
    return strict ? null : false;
  }

  return get(stringToBooleanMap, value, null);
};

export const booleanToString = (value: boolean | null | undefined) => {
  if (!isBoolean(value)) {
    return null;
  }
  return value ? "True" : "False";
};
