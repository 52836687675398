import { useCallback, useState } from "react";

import { UpdateLimitRequestConditionPrecedentInput } from "../../../graphql/generated.ts";
import { useCreateLimitRequestConditionPrecedent } from "../../../hooks/api/limitRequestConditionPrecedent/useCreateLimitRequestConditionPrecedent.ts";
import { useDeleteLimitRequestConditionPrecedent } from "../../../hooks/api/limitRequestConditionPrecedent/useDeleteLimitRequestConditionPrecedent.ts";
import { useUpdateLimitRequestConditionPrecedent } from "../../../hooks/api/limitRequestConditionPrecedent/useUpdateLimitRequestConditionPrecedent.ts";
import { useMutationNotificationWrapper } from "../../../hooks/useMutationNotificationWrapper.tsx";
import { LimitRequestConditionPrecedent } from "../../../types/limitRequestConditionPrecedent.ts";
import ConditionPrecedentForm from "./ConditionPrecedentForm/ConditionPrecedentForm.tsx";
import ConditionPrecedentList from "./ConditionPrecedentList/ConditionPrecedentList.tsx";

interface Props {
  limitRequestId: number;
  conditionPrecedents: LimitRequestConditionPrecedent[] | null | undefined;
  onRefreshConditionPrecedents: () => void;
}

const ConditionPrecedents = ({
  limitRequestId,
  conditionPrecedents,
  onRefreshConditionPrecedents,
}: Props) => {
  const [selectedItem, setSelectedItem] =
    useState<LimitRequestConditionPrecedent | null>(null);
  const [editMode, setEditMode] = useState<boolean>(false);

  const [, createConditionPrecedent] = useMutationNotificationWrapper(
    useCreateLimitRequestConditionPrecedent(),
    {
      success: {
        message: "Condition Precedent was successfully created.",
      },
    }
  );

  const [, updateConditionPrecedent] = useMutationNotificationWrapper(
    useUpdateLimitRequestConditionPrecedent(),
    {
      success: {
        message: "Condition Precedent was successfully updated.",
      },
    }
  );

  const [, deleteConditionPrecedence] = useMutationNotificationWrapper(
    useDeleteLimitRequestConditionPrecedent(),
    {
      success: {
        message: "Condition Precedent was successfully deleted.",
      },
    }
  );

  const handleSetEditMode = useCallback((editMode: boolean) => {
    if (editMode) {
      setEditMode(true);
      return;
    }
    setEditMode(false);
    setSelectedItem(null);
  }, []);

  const handleSubmitEditForm = useCallback(
    (input: UpdateLimitRequestConditionPrecedentInput) => {
      if (selectedItem) {
        updateConditionPrecedent({ id: selectedItem.id, input }).then(() => {
          handleSetEditMode(false);
          onRefreshConditionPrecedents();
        });
        return;
      }
      createConditionPrecedent({
        input: { ...input, limitRequestId: limitRequestId },
      }).then(() => {
        handleSetEditMode(false);
        onRefreshConditionPrecedents();
      });
    },
    [
      selectedItem,
      createConditionPrecedent,
      limitRequestId,
      updateConditionPrecedent,
      handleSetEditMode,
      onRefreshConditionPrecedents,
    ]
  );

  const handleDelete = useCallback(
    (id: number) => {
      deleteConditionPrecedence({ id }).then(onRefreshConditionPrecedents);
    },
    [deleteConditionPrecedence, onRefreshConditionPrecedents]
  );

  const handleEditConditionPrecedence = (
    item: LimitRequestConditionPrecedent
  ) => {
    handleSetEditMode(true);
    setSelectedItem(item);
  };

  return (
    <>
      {editMode ? (
        <ConditionPrecedentForm
          value={selectedItem}
          onClose={() => handleSetEditMode(false)}
          onSubmit={handleSubmitEditForm}
        />
      ) : (
        <ConditionPrecedentList
          conditionPrecedents={conditionPrecedents}
          onAdd={() => handleSetEditMode(true)}
          onEdit={handleEditConditionPrecedence}
          onDelete={handleDelete}
        />
      )}
    </>
  );
};

export default ConditionPrecedents;
