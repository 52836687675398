import { Flex } from "@mantine/core";
import { notifications } from "@mantine/notifications";

import SvgWarning from "../../../components/Icons/Warning.tsx";
import SectionTitle from "../../../components/Section/SectionTitle/SectionTitle.tsx";
import Table from "../../../components/Table/Table.tsx";
import { useGetLimitUtilization } from "../../../hooks/api/riskFunnelDashboard/useGetLimitUtilization.ts";
import { useLimitUtilizationTableData } from "./hooks/useLimitUtilizationTableData.tsx";

const LimitUtilizationTable = () => {
  const [{ data, fetching, error }] = useGetLimitUtilization({});

  const [columns, rows] = useLimitUtilizationTableData({
    data: data?.limitUtilization,
  });

  if (error) {
    notifications.clean();
    notifications.show({
      message:
        "Something went wrong while trying to fetch Credit Limit Funnel data.",
      icon: <SvgWarning />,
    });
  }

  return (
    <Flex direction="column" gap={20}>
      <SectionTitle variant="bronze">Limit utilization</SectionTitle>
      <Table columns={columns} rows={rows} minWidth="100%" loading={fetching} />
    </Flex>
  );
};

export default LimitUtilizationTable;
