import { Flex, Select, SimpleGrid } from "@mantine/core";
import { DatePickerInput, DatePickerValue } from "@mantine/dates";
import { notifications } from "@mantine/notifications";
import { useCallback, useState } from "react";

import SvgCalendar from "../../../components/Icons/Calendar.tsx";
import SvgWarning from "../../../components/Icons/Warning.tsx";
import SectionTitle from "../../../components/Section/SectionTitle/SectionTitle.tsx";
import Table from "../../../components/Table/Table.tsx";
import { QueryCreditLimitFunnelArgs } from "../../../graphql/generated.ts";
import { useGetCreditLimitFunnel } from "../../../hooks/api/riskFunnelDashboard/useGetCreditLimitFunnel.ts";
import { limitTypeOptions } from "../../../types/limitRequest.ts";
import { useCreditLimitFunnelTableData } from "./hooks/useCreditLimitFunnelTableData.tsx";
import { getCreditLimitFunnelApiFilter } from "./utils.ts";

const CreditLimitFunnelTable = () => {
  const [queryArgs, setQueryArgs] = useState<QueryCreditLimitFunnelArgs>({});

  const [{ data, fetching, error }] = useGetCreditLimitFunnel(queryArgs);

  const [columns, rows] = useCreditLimitFunnelTableData({
    data: data?.creditLimitFunnel,
  });

  const handleFilterChange = useCallback(
    (key: string, value: string | DatePickerValue | null) => {
      if (key === "periodStart" || key === "periodEnd") {
        setQueryArgs((queryArgs) => ({ ...queryArgs, [key]: value }));
        return;
      }
      setQueryArgs((queryArgs) => {
        const filter = getCreditLimitFunnelApiFilter({
          ...queryArgs.filter,
          [key]: value,
        });
        return { ...queryArgs, filter };
      });
    },
    []
  );

  if (error) {
    notifications.clean();
    notifications.show({
      message:
        "Something went wrong while trying to fetch Credit Limit Funnel data.",
      icon: <SvgWarning />,
    });
  }
  return (
    <Flex direction="column" gap={20}>
      <SectionTitle>Credit Limit Funnel</SectionTitle>
      <SimpleGrid
        w="100%"
        cols={3}
        breakpoints={[
          { maxWidth: "sm", cols: 1 },
          { maxWidth: "lg", cols: 2 },
        ]}
        spacing={16}
        verticalSpacing={8}
      >
        <DatePickerInput
          type="default"
          label="Period Start"
          placeholder="Select Period Start"
          icon={<SvgCalendar />}
          firstDayOfWeek={0}
          popoverProps={{ withinPortal: true, zIndex: 1000 }}
          size="m"
          clearable
          onChange={(value) => handleFilterChange("periodStart", value)}
        />
        <DatePickerInput
          type="default"
          label="Period End"
          placeholder="Select Period End"
          icon={<SvgCalendar />}
          firstDayOfWeek={0}
          popoverProps={{ withinPortal: true, zIndex: 1000 }}
          size="m"
          clearable
          onChange={(value) => handleFilterChange("periodEnd", value)}
        />
        <Select
          data={limitTypeOptions}
          size="m"
          label="Limit Type"
          placeholder="Select Limit Type"
          clearable
          onChange={(value: string) =>
            handleFilterChange("limitRequestType", value)
          }
        />
      </SimpleGrid>
      <Table columns={columns} rows={rows} minWidth="100%" loading={fetching} />
    </Flex>
  );
};

export default CreditLimitFunnelTable;
