import { Text } from "@mantine/core";
import { DataTableColumn } from "mantine-datatable";
import { useMemo } from "react";

import ActionIcon from "../../../../components/ActionIcon/ActionIcon.tsx";
import SvgEdit from "../../../../components/Icons/Edit.tsx";
import Spoiler from "../../../../components/Spoiler/Spoiler.tsx";
import { TableData, TableRow } from "../../../../components/Table/types.ts";
import TableFilesDisplay from "../../../../components/TableFilesDisplay/TableFilesDisplay.tsx";
import { CollectionDocumentType } from "../../../../graphql/generated";
import { CollectionDocument } from "../../../../types/collectionDocument.ts";
import { toLocalDate } from "../../../../utils/date.ts";
import { toHumanReadable } from "../../../../utils/string.ts";
import { getUserDisplayName } from "../../../../utils/user.ts";
import { useStyles } from "../CollectionDocumentsTab.styles.ts";

interface Props {
  data: CollectionDocument[] | null | undefined;
  onEdit: (id: number) => void;
}

export function useCollectionDocumentData({ data, onEdit }: Props): TableData {
  const { classes } = useStyles();

  const columns: DataTableColumn<TableRow>[] = [
    {
      accessor: "createdAt",
      title: "Date",
      sortable: true,
      width: "150px",
    },
    {
      accessor: "collectorAssigned",
      title: "Collector Assigned",
      sortable: true,
      width: "175px",
    },
    {
      accessor: "documentType",
      title: "Document Type",
      sortable: true,
      width: "200px",
    },
    {
      accessor: "document",
      title: "Document File",
      width: "125px",
    },
    {
      accessor: "remark",
      title: "Remark",
      sortable: true,
      width: "300px",
    },
    {
      accessor: "sentAt",
      title: "Sent Date",
      sortable: true,
      width: "150px",
    },
    {
      accessor: "receiptProof",
      title: "Receipt Proof",
      sortable: false,
      width: "125px",
    },
    {
      accessor: "signedDocument",
      title: "Signed Document",
      width: "125px",
    },
    {
      accessor: "actions",
      title: "Actions",
      width: "100px",
    },
  ];

  const rows = useMemo(() => {
    return data?.map((item) => {
      return {
        createdAt: toLocalDate(item.createdAt),
        collectorAssigned: item?.collectorAssigned && (
          <Text className={classes.collectorAssignedWrapper}>
            {getUserDisplayName(item?.collectorAssigned)}
          </Text>
        ),
        documentType:
          item.documentType === CollectionDocumentType.SuratPengakuanHutang
            ? toHumanReadable(item.documentType)
            : toHumanReadable(item.documentType).toUpperCase(),
        document: item.document && (
          <TableFilesDisplay files={[item.document]} />
        ),
        remark: item.remark && <Spoiler text={item.remark} />,
        sentAt: toLocalDate(item.sentAt),
        receiptProof: item.receiptProof && (
          <TableFilesDisplay files={[item.receiptProof]} />
        ),
        signedDocument: item.signedDocuments?.length ? (
          <TableFilesDisplay files={item.signedDocuments} />
        ) : null,
        actions: (
          <ActionIcon onClick={() => onEdit(item.id)}>
            <SvgEdit />
          </ActionIcon>
        ),
      };
    });
  }, [data, classes, onEdit]);

  return [columns, rows];
}
