import { useMemo } from "react";

import { MultiFieldCardItem } from "../../../../../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import { ContractType } from "../../../../../../graphql/generated.ts";
import { ClikGrantedContractDetails } from "../../../../../../types/clikContract.ts";
import { toCurrencyPrice } from "../../../../../../utils/number.ts";

interface Props {
  data: ClikGrantedContractDetails | null | undefined;
  type: ContractType;
}

export const useCROverdueDetailsOfContract = ({
  data,
  type,
}: Props): MultiFieldCardItem[] => {
  return useMemo(() => {
    if (type === ContractType.Credit) {
      return [
        {
          label: "Principal Overdue Amount",
          content:
            data?.principalOverdueAmount &&
            toCurrencyPrice(data.principalOverdueAmount),
        },
        {
          label: "Interest Overdue Amount",
          content:
            data?.interestOverdueAmount &&
            toCurrencyPrice(data.interestOverdueAmount),
        },
        {
          label: "Days Past Due",
          content: data?.daysPastDue,
        },
        {
          label: "Overdue Payments Number",
          content: data?.overduePaymentsNumber,
        },
        {
          label: "Bad Credit Cause",
          content: data?.badCreditCause,
        },
        {
          label: "Bad Credit Date",
          content: data?.badCreditDate,
        },
        {
          label: "Penalty",
          content: data?.penalty && toCurrencyPrice(data.penalty),
        },
      ];
    }

    if (type === ContractType.Bond || type === ContractType.OtherFacilities) {
      return [
        {
          label: "Overdue Amount",
          content: data?.overdueAmount && toCurrencyPrice(data.overdueAmount),
        },
        {
          label: "Days Past Due",
          content: data?.daysPastDue,
        },
        {
          label: "Bad Credit Cause",
          content: data?.badCreditCause,
        },
        {
          label: "Bad Credit Date",
          content: data?.badCreditDate,
        },
      ];
    }

    return [];
  }, [data, type]);
};
