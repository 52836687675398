export const getConversationRateBgColor = (item: number) => {
  const range = Number(item).toFixed(1);
  switch (range) {
    case "0.0":
      return "#D90A19";
    case "0.1":
      return "#ED5737";
    case "0.2":
      return "#FB722F";
    case "0.3":
      return "#FD8F31";
    case "0.4":
      return "#FBB733";
    case "0.5":
      return "#D7D178";
    case "0.6":
      return "#ABD297";
    case "0.7":
      return "#7DBE93";
    case "0.8":
      return "#55A56D";
    case "0.9":
    case "1.0":
      return "#149148";
    default:
      return "#149148"; // default case for item >= 91
  }
};
