import { Flex } from "@mantine/core";
import { DataTableSortStatus } from "mantine-datatable";
import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";

import Table from "../../../components/Table/Table.tsx";
import { SortDirection } from "../../../components/Table/types.ts";
import {
  CollectionActivityFilter,
  CollectionActivityOrderBy,
  OrderBy,
} from "../../../graphql/generated";
import { useCollectionActivities } from "../../../hooks/api/collectionActivity/useCollectionActivities.ts";
import { useCollectors } from "../../../hooks/api/user/useCollectors.ts";
import { getPagesCount } from "../../../utils/pagination";
import InvoicesFilterToolbar from "../InvoicesFilterToolbar/InvoicesFilterToolbar.tsx";
import { FiltersValue } from "../InvoicesFilterToolbar/types.ts";
import { useCollectionActivityTableData } from "./hooks/useCollectionActivityTableData.tsx";
import {
  getCollectionActivitiesApiFilter,
  getCollectionActivityApiSort,
} from "./utils.ts";

const CollectionActivityTab = () => {
  const { customerId } = useParams() as { customerId: string };
  const [filter, setFilter] = useState<CollectionActivityFilter>({});

  const [orderBy, setOrderBy] = useState<CollectionActivityOrderBy>({
    createdAt: OrderBy.Asc,
  });

  const [page, setPage] = useState<number>(1);

  const [{ data: collectionActivitiesData, fetching }] =
    useCollectionActivities({
      filter: {
        collectorAssignedId: filter.collectorAssignedId,
        invoice: {
          ...filter.invoice,
          customerId: {
            equals: Number(customerId),
          },
        },
      },
      orderBy,
      page,
    });

  const [{ data: collectorsData }] = useCollectors();

  const collectionActivities =
    collectionActivitiesData?.collectionActivities?.data;

  const pageCount = getPagesCount(
    collectionActivitiesData?.collectionActivities?.total
  );

  const [columns, rows] = useCollectionActivityTableData({
    data: collectionActivities,
  });

  const handleOnSortChange = useCallback(
    (sort: DataTableSortStatus) => {
      setOrderBy(getCollectionActivityApiSort(sort));
    },
    [setOrderBy]
  );

  const handleFilterChange = useCallback((value: FiltersValue) => {
    setFilter(getCollectionActivitiesApiFilter(value));
    setPage(1);
  }, []);

  const handlePageChange = useCallback(
    (page: number) => {
      setPage(page);
    },
    [setPage]
  );

  return (
    <>
      <Flex direction="column" align="flex-start" gap={{ base: 14, sm: 20 }}>
        <InvoicesFilterToolbar
          collectors={collectorsData?.users.data}
          onValueChange={handleFilterChange}
        />

        <Table
          columns={columns}
          rows={rows}
          pagination={{
            pageCount: pageCount,
            page: page,
            onPageChange: handlePageChange,
          }}
          defaultSort={{
            columnAccessor: "createdAt",
            direction: SortDirection.asc,
          }}
          loading={fetching}
          onSortChange={handleOnSortChange}
        />
      </Flex>
    </>
  );
};

export default CollectionActivityTab;
