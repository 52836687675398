import {
  Button,
  Checkbox,
  Flex,
  Input,
  Select,
  Text,
  Textarea,
  TextInput,
} from "@mantine/core";
import { DatePickerInput } from "@mantine/dates";
import { isNotEmpty, useForm } from "@mantine/form";
import { isEmpty } from "lodash";
import { useCallback } from "react";

import Dropzone from "../../../../components/Dropzone/Dropzone.tsx";
import SvgCalendar from "../../../../components/Icons/Calendar.tsx";
import {
  FileResult,
  UpdateLimitRequestConditionPrecedentInput,
} from "../../../../graphql/generated.ts";
import {
  LimitRequestConditionPrecedent,
  limitRequestCpTypeOptions,
} from "../../../../types/limitRequestConditionPrecedent.ts";
import { useStyles } from "../ConditionPrecedents.styles.ts";

interface Props {
  value: LimitRequestConditionPrecedent | null | undefined;
  onClose: () => void;
  onSubmit: (value: UpdateLimitRequestConditionPrecedentInput) => void;
}

const ConditionPrecedentForm = ({ value, onClose, onSubmit }: Props) => {
  const { classes } = useStyles();

  const form = useForm({
    initialValues: {
      completed: value?.completed ?? false,
      name: value?.name,
      type: value?.type,
      dueDate: value?.dueDate ? new Date(value?.dueDate) : undefined,
      documents: value?.documents,
      notes: value?.notes,
    },
    validate: {
      name: isNotEmpty("Name is required"),
      type: isNotEmpty("Type is required"),
    },
    validateInputOnBlur: true,
  });

  const handleClose = useCallback(() => {
    form.reset();
    onClose();
  }, [form, onClose]);

  const handleFileUploaded = (files: FileResult[]) => {
    form.setValues({
      documents: form.values.documents
        ? [...form.values.documents, ...files]
        : files,
    });
  };

  const handleRemoveFile = (value: string) => {
    const filteredFiles = form.values.documents?.filter(
      (doc) => doc.filePath !== value
    );
    form.setFieldValue("documents", filteredFiles);
  };

  const handleSubmit = useCallback(() => {
    if (!form.isValid()) {
      return;
    }
    onSubmit({
      ...form.values,
      documents: form.values.documents?.map((d) => d.filePath),
    } as UpdateLimitRequestConditionPrecedentInput);
    handleClose();
  }, [form, handleClose, onSubmit]);

  return (
    <Flex direction="column" gap={4}>
      <Flex gap={12} className={classes.label}>
        <Text>Condition precedence</Text>
        <Text className={classes.status}>(Editing)</Text>
      </Flex>
      <Flex direction="column" p={20} gap={12} className={classes.form}>
        {value && (
          <Checkbox
            required
            label="Completed"
            size="md"
            variant="golden"
            {...form.getInputProps("completed")}
          />
        )}
        <TextInput
          required
          label="Name"
          placeholder="Enter Name"
          size="m"
          {...form.getInputProps("name")}
        />
        <Select
          withinPortal
          dropdownPosition="top"
          required
          data={limitRequestCpTypeOptions}
          label="Type"
          placeholder="Select Type"
          size="m"
          {...form.getInputProps("type")}
        />
        <DatePickerInput
          label="Due date"
          placeholder="Select Due date"
          icon={<SvgCalendar />}
          firstDayOfWeek={0}
          size="m"
          valueFormat="YYYY MMM DD"
          popoverProps={{ withinPortal: true, zIndex: 1000 }}
          clearable
          {...form.getInputProps("dueDate")}
        />
        <Input.Wrapper label="Document Submission">
          <Dropzone
            value={
              isEmpty(form?.values?.documents) ? null : form.values.documents
            }
            maxWidth="unset"
            onUploadSuccess={(files) => handleFileUploaded(files)}
            onRemove={(value) => handleRemoveFile(value)}
          />
        </Input.Wrapper>
        <Textarea
          label="Notes"
          placeholder="Enter Notes"
          autosize
          size="m"
          minRows={3}
          maxRows={3}
          {...form.getInputProps("notes")}
        />
        <Flex gap={20}>
          <Button variant="outlineBlue" uppercase onClick={handleClose}>
            cancel
          </Button>
          <Button
            variant="filledBlue"
            uppercase
            disabled={!form.isValid()}
            onClick={handleSubmit}
          >
            {value ? "edit" : "add"}
          </Button>
        </Flex>
      </Flex>
    </Flex>
  );
};

export default ConditionPrecedentForm;
