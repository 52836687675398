import { DataTableColumn } from "mantine-datatable";
import { useMemo } from "react";

import {
  TableData,
  TableRow,
} from "../../../../../../components/Table/types.ts";
import { ContractTypeFilterOptions } from "../../../../../../graphql/generated.ts";
import { ClikNotGrantedContract } from "../../../../../../types/clikContract.ts";
import { toLocalDate } from "../../../../../../utils/date.ts";
import { toCurrencyPrice } from "../../../../../../utils/number.ts";
import { toHumanReadable } from "../../../../../../utils/string.ts";
import ClikResultContractLinkedSubjects from "../../ClikResultContractLinkedSubjects.tsx";

interface Props {
  data: ClikNotGrantedContract[] | null | undefined;
  type: ContractTypeFilterOptions;
}

export function useCRNotGrantedContracts({ data, type }: Props): TableData {
  const columns: DataTableColumn<TableRow>[] = [
    {
      accessor: "id",
      hidden: true,
    },
    {
      accessor: "referenceNo",
      title: "No.",
      width: "100px",
    },
    {
      accessor: "cbContractCode",
      title: "CB Contract Code",
      sortable: true,
      width: "150px",
    },
    {
      accessor: "providerContractNumber",
      title: "Provider Contract Number",
      sortable: true,
      width: "150px",
    },
    {
      accessor: "contractType",
      title: "Contract Type",
      sortable: true,
      width: "150px",
    },
    {
      accessor: "contractPhase",
      title: "Contract Phase",
      sortable: true,
      width: "150px",
    },
    {
      accessor: "role",
      title: "Role",
      sortable: true,
      width: "150px",
    },
    {
      accessor: "providerType",
      title: "Provider Type",
      sortable: true,
      width: "150px",
    },
    {
      accessor: "providerCode",
      title: "Provider",
      sortable: true,
      width: "150px",
    },
    {
      accessor: "contractRequestDate",
      title: "Contract Request Date",
      sortable: true,
      width: "150px",
    },
    {
      accessor: "lastUpdateDate",
      title: "Last Update Date",
      sortable: true,
      width: "150px",
    },
    {
      accessor: "applicationAmount",
      title: "Application Amount",
      sortable: true,
      width: "150px",
      hidden: type === ContractTypeFilterOptions.Credit,
    },
    {
      accessor: "dueDate",
      title: "Due Date",
      sortable: true,
      width: "200px",
      hidden: type === ContractTypeFilterOptions.Credit,
    },
    {
      accessor: "originalAgreementNumber",
      title: "Original Agreement Number",
      sortable: true,
      width: "200px",
      hidden: type === ContractTypeFilterOptions.Credit,
    },
    {
      accessor: "originalAgreementDate",
      title: "Original Agreement Date",
      sortable: true,
      width: "200px",
      hidden: type === ContractTypeFilterOptions.Credit,
    },
    {
      accessor: "linkedSubject",
      title: "Linked Subjects List",
      sortable: false,
      width: "350px",
      hidden: type !== ContractTypeFilterOptions.Credit,
    },
    {
      accessor: "note",
      title: "Note",
      sortable: true,
      width: "200px",
    },
  ];

  const rows = useMemo(() => {
    return data?.map((item, index) => {
      return {
        id: item.id,
        referenceNo: index + 1,
        cbContractCode: item.cbContractCode,
        providerContractNumber: item.providerContractNumber,
        contractType: toHumanReadable(item.contractType),
        contractPhase: item.contractPhase,
        role: item.role,
        providerType: item.providerType,
        providerCode: item.providerCode,
        contractRequestDate:
          item.contractRequestDate && toLocalDate(item.contractRequestDate),
        lastUpdateDate: item.lastUpdateDate && toLocalDate(item.lastUpdateDate),
        applicationAmount: toCurrencyPrice(item.applicationAmount),
        dueDate: item.dueDate && toLocalDate(item.dueDate),
        originalAgreementNumber: item.originalAgreementNumber,
        originalAgreementDate:
          item.originalAgreementDate && toLocalDate(item.originalAgreementDate),
        linkedSubject: (
          <ClikResultContractLinkedSubjects data={item.linkedSubjects} />
        ),
        note: item.note,
      };
    });
  }, [data]);

  return [columns, rows];
}
