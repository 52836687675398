import { useQuery } from "urql";

import {
  GetRestructureDetailsDocument,
  RestructureDetailFilter,
  RestructureDetailOrderBy,
} from "../../../graphql/generated";
import { getApiPaginationData } from "../../../utils/api";
import { ApiHookProps } from "../types.ts";

export function useRestructureDetail({
  filter,
  orderBy,
  pause,
  page,
}: ApiHookProps<RestructureDetailFilter, RestructureDetailOrderBy>) {
  const pagination = getApiPaginationData(page);
  const variables = {
    filter,
    orderBy,
    skip: pagination?.skip,
    take: pagination?.take,
  };

  return useQuery({
    query: GetRestructureDetailsDocument,
    variables: variables,
    pause,
  });
}
