import { createStyles, rem } from "@mantine/core";

export const useStyles = createStyles(
  (theme, { bgColor }: { bgColor: string | undefined }) => ({
    risk: {
      fontFamily: "League Spartan",
      fontSize: rem(24),
      fontWeight: 500,
      lineHeight: 1.5,
      color: theme.colors.midnightBlue[0],
    },
    riskDescription: {
      background: bgColor,
      borderRadius: rem(2),
      padding: `${rem(5)} ${rem(12)}`,

      fontSize: rem(14),
      fontWeight: 500,
      lineHeight: 1.5,
      color: theme.colors.dusk[0],
    },
  })
);
