import {
  Maybe,
  Note,
  RiskReview as GqlGeneratedRiskReview,
  RrAnalysisResult,
  RrRiskCategory,
  User,
} from "../graphql/generated.ts";
import { toHumanReadable } from "../utils/string.ts";

export type RiskReview = Omit<
  GqlGeneratedRiskReview,
  "updatedBy" | "rejectNotes"
> & {
  assignedId?: number;
  assignedDate?: Date | undefined | null;
  updatedBy?: Maybe<Pick<User, "id" | "name" | "email">>;
  rejectNotes: Array<
    Omit<Note, "createdBy"> & {
      createdBy: Pick<User, "id" | "name" | "email">;
    }
  >;
};

export const rrAnalysisResultOptions = Object.values(RrAnalysisResult).map(
  (value) => {
    return { value, label: toHumanReadable(value) };
  }
);

export const rrRiskCategoryOptions = Object.values(RrRiskCategory).map(
  (value) => {
    return { value, label: toHumanReadable(value) };
  }
);
