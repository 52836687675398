import { MantineTheme, rem } from "@mantine/core";

import { ThemeComponent } from "./helpers.ts";

const spoilerDefault = (theme: MantineTheme) => ({
  control: {
    color: theme.colors.dusk[0],
    fontSize: rem(12),
    fontWeight: 500,
    lineHeight: 1.5,
    textDecorationLine: "underline",
  },
});

export default function spoilerTheme(): ThemeComponent {
  return {
    styles: (theme) => spoilerDefault(theme),
  };
}
