export enum nextDue {
  DAYS_3 = "3-days",
  DAYS_7 = "7-days",
  DAYS_14 = "14-days",
  MONTH_1 = "1-month",
  MONTHS_2 = "2-months",
  MONTHS_3 = "3-months",
}

export const nextDueOptions = [
  {
    value: nextDue.DAYS_3,
    label: "3 days",
  },
  {
    value: nextDue.DAYS_7,
    label: "7 days",
  },
  {
    value: nextDue.DAYS_14,
    label: "14 days",
  },
  {
    value: nextDue.MONTH_1,
    label: "1 month",
  },
  {
    value: nextDue.MONTHS_2,
    label: "2 months",
  },
  {
    value: nextDue.MONTHS_3,
    label: "3 months",
  },
];
