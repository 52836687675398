import startCase from "lodash/startCase";

import { toLocalDateTime } from "./date.ts";

export function formatLogDetails(logDetails: Record<string, string>[]): string {
  let result = "";
  logDetails.forEach((log) => {
    const field = startCase(log.field.split(/(?=[A-Z])/).join(" "));
    result += `${log.userName} changed field ${field} on ${toLocalDateTime(
      log.date
    )} <br/>`;
  });
  return result;
}
