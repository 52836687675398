import { PaymentStatus, PaymentType } from "../../../graphql/generated.ts";

export enum ModalType {
  ConditionPrecedents = "conditionPrecedents",
  Exceptions = "exceptions",
}

export type TransactionHistoryFiltersValue = {
  salesName?: string;
  salesTeam?: string;
  paymentType?: PaymentType;
  paymentStatus?: PaymentStatus;
  hasException?: boolean;
  isRestructured?: boolean;
};
