import { Flex, SimpleGrid, Text } from "@mantine/core";
import { Link } from "react-router-dom";

import MultiFieldCard from "../../../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import NoDataMessage from "../../../../components/NoDataMessage/NoDataMessage.tsx";
import SectionTitle from "../../../../components/Section/SectionTitle/SectionTitle.tsx";
import { Customer } from "../../../../types/customer.ts";
import { toHumanReadable } from "../../../../utils/string.ts";
import { useStyles } from "./ContactInfo.styles.ts";

interface ContactInfoProps {
  customer: Customer;
  loading?: boolean;
}

const ContactInfo = ({ customer, loading }: ContactInfoProps) => {
  const { classes } = useStyles();

  const items = customer.pic?.map((item) => ({
    label: item.type ? toHumanReadable(item.type) : "PIC",
    items: [
      {
        label: "PIC Name",
        content: item.name && (
          <Text className={classes.contactInfoText}>{item.name}</Text>
        ),
      },
      {
        label: "PIC Role",
        content: item.jobPosition && (
          <Text className={classes.contactInfoText}>{item.jobPosition}</Text>
        ),
      },
      {
        label: "Phone",
        content: item.phone && (
          <Link to={`tel:${item.phone}`} className={classes.contactInfoText}>
            {item.phone}
          </Link>
        ),
      },
      {
        label: "Mobile",
        content: item.mobile && (
          <Link to={`tel:${item.mobile}`} className={classes.contactInfoText}>
            {item.mobile}
          </Link>
        ),
      },
      {
        label: "Email",
        content: item.email && (
          <Link to={`mailto:${item.email}`} className={classes.contactInfoText}>
            {item.email}
          </Link>
        ),
      },
    ],
  }));

  return (
    <>
      {items && (
        <Flex direction="column" gap={12}>
          <SectionTitle variant="bronze">Contact Info</SectionTitle>
          <SimpleGrid
            cols={1}
            breakpoints={[
              { minWidth: "sm", cols: items?.length ? 2 : 1 },
              { minWidth: "lg", cols: items?.length ? 3 : 1 },
            ]}
            spacing={20}
            verticalSpacing={12}
          >
            {items?.length ? (
              items.map((item, index) => (
                <MultiFieldCard
                  key={index}
                  variant="bronze"
                  loading={loading}
                  {...item}
                />
              ))
            ) : (
              <NoDataMessage />
            )}
          </SimpleGrid>
        </Flex>
      )}
    </>
  );
};

export default ContactInfo;
