import { Badge, Button, Flex } from "@mantine/core";
import { useMemo } from "react";

import SvgCheck from "../../../../components/Icons/Check.tsx";
import SvgPlus from "../../../../components/Icons/Plus.tsx";
import { LimitRequestStatus } from "../../../../graphql/generated.ts";
import { CreditApproval } from "../../../../types/creditApproval.ts";

interface Props {
  creditCommittee: Partial<CreditApproval> | null | undefined;
  canEdit: boolean;
  limitRequestStatus: LimitRequestStatus | null | undefined;
  onAdd: () => void;
  onHandoverToOL: () => void;
}

export const useCreditCommitteeCardFooter = ({
  creditCommittee,
  canEdit,
  limitRequestStatus,
  onAdd,
  onHandoverToOL,
}: Props) => {
  const renderedContent = useMemo(() => {
    if (!creditCommittee) {
      return (
        <Button
          leftIcon={<SvgPlus />}
          variant="filledGolden"
          w="100%"
          uppercase
          disabled={
            limitRequestStatus !== LimitRequestStatus.OngoingCc || !canEdit
          }
          onClick={onAdd}
        >
          Add data
        </Button>
      );
    }
    if (limitRequestStatus === LimitRequestStatus.OngoingCc) {
      return (
        <Button
          variant="filledGolden"
          leftIcon={<SvgCheck />}
          uppercase
          w="100%"
          disabled={!canEdit}
          onClick={onHandoverToOL}
        >
          Handover to OL
        </Button>
      );
    }
    return (
      <Badge variant="active" w="100%" size="m">
        <Flex>
          <SvgCheck />
          Handovered to OL
        </Flex>
      </Badge>
    );
  }, [creditCommittee, canEdit, limitRequestStatus, onAdd, onHandoverToOL]);

  return useMemo(() => {
    return (
      <Flex w="100%" h="100%" align="end">
        {renderedContent}
      </Flex>
    );
  }, [renderedContent]);
};
