import { Button, Flex, Modal, ModalProps, ScrollArea } from "@mantine/core";

export interface ConfirmationModalProps extends ModalProps {
  message: string;
  onConfirm: () => void;
}

export default function ConfirmationModal({
  message,
  onConfirm,
  onClose,
}: ConfirmationModalProps) {
  return (
    <Modal
      opened={true}
      scrollAreaComponent={ScrollArea.Autosize}
      closeButtonProps={{
        size: 24,
        iconSize: 24,
      }}
      onClose={onClose}
    >
      <Flex direction="column" gap={20}>
        {message}
        <Flex gap={20} justify="end">
          <Button
            variant="outlineBlue"
            uppercase
            type="reset"
            onClick={onClose}
          >
            no
          </Button>
          <Button variant="filledBlue" uppercase onClick={onConfirm}>
            yes
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
}
