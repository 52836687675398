import { DataTableColumn } from "mantine-datatable";
import { useMemo } from "react";

import { TableData, TableRow } from "../../../../components/Table/types.ts";
import { NextMonthDuePerSalesTeam } from "../../../../types/dashboard.ts";
import { toLocalNumber } from "../../../../utils/number.ts";

interface Props {
  data: NextMonthDuePerSalesTeam[] | null | undefined;
}

export const useNextMonthDueTeamTableData = ({ data }: Props): TableData => {
  const columns: DataTableColumn<TableRow>[] = [
    {
      accessor: "salesTeamName",
      title: "Sales Team",
    },
    {
      accessor: "trx",
      title: "Unpaid / Partially Paid TRX",
    },
    {
      accessor: "amount",
      title: "Unpaid / Partially Paid Amount:",
    },
  ];

  const rows = useMemo(() => {
    return data?.map((item) => {
      return {
        salesTeamName: item.salesTeamName,
        trx: item.trx,
        amount: toLocalNumber(item.amount),
      };
    });
  }, [data]);

  return [columns, rows];
};
