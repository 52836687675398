import { sortBy } from "lodash";

import {
  CreditUnderwriting,
  Limit,
  LimitRequest as GqlGeneratedLimitRequest,
  LimitRequestCategory,
  LimitRequestType,
  LimitStatus,
  LimitTopProductCategory,
  Note,
  RiskReview,
  User,
} from "../graphql/generated.ts";
import { toHumanReadable } from "../utils/string.ts";
import { ClikResult } from "./clikResult.ts";
import { CreditApproval } from "./creditApproval.ts";
import { Customer } from "./customer.ts";
import { OfferLetter } from "./offerLetter.ts";
import { RawPengurus } from "./pengurus.ts";
import { Collector } from "./user.ts";

export type LimitRequestDetail = Omit<
  GqlGeneratedLimitRequest,
  | "penguruses"
  | "company"
  | "cuAssigned"
  | "offerLetter"
  | "lastClikResult"
  | "creditUnderwriting"
  | "riskReview"
  | "conditionPrecedents"
  | "exceptions"
  | "creditApproval"
  | "rrAssigned"
> & {
  company: Partial<LimitRequestCompany>;
  cuAssigned?: Collector | null;
  lastClikResult?: Partial<ClikResult> | null;
  creditUnderwriting?: Pick<CreditUnderwriting, "id"> | null;
  riskReview?: Pick<RiskReview, "id"> | null;
  creditApproval?: Pick<RiskReview, "id"> | null;
  offerLetter?: Pick<OfferLetter, "id"> | null;
  rrAssigned?: Collector | null;
};

export type LimitRequest = Omit<
  GqlGeneratedLimitRequest,
  | "penguruses"
  | "company"
  | "cuAssigned"
  | "offerLetter"
  | "lastClikResult"
  | "creditUnderwriting"
  | "riskReview"
  | "conditionPrecedents"
  | "exceptions"
  | "creditApproval"
  | "rejectNotes"
  | "rrAssigned"
  | "rMonAssigned"
  | "rMonAssignedDate"
  | "limit"
> & {
  company: Partial<LimitRequestCompany>;
  cuAssigned?: Collector | null;
  rMonAssigned?: Collector | null;
  rrAssigned?: Collector | null;
  lastClikResult?: Partial<ClikResult> | null;
  offerLetter?: Pick<
    OfferLetter,
    | "olNo"
    | "olSigned"
    | "pgSigned"
    | "pksSigned"
    | "olSignedDate"
    | "olExpiredDate"
  > | null;
  rejectNotes?:
    | Array<
        Omit<Note, "createdBy"> & {
          createdBy: Pick<User, "id" | "name" | "email">;
        }
      >
    | null
    | undefined;
  creditApproval?: Partial<CreditApproval> | null | undefined;
  limit?: Partial<Limit> | null;
};

export type RawLimitRequest = Omit<
  LimitRequest,
  "id" | "createdAt" | "cuAssigned" | "limitRemaining" | "limitUtilized"
> & {
  id?: number;
  penguruses?: RawPengurus[];
  cuAssigned?: Collector;
};

export type LimitRequestCompany = Pick<
  Customer,
  "id" | "name" | "salesName" | "salesTeamName" | "partnerCode"
>;

export const limitTypeOptions = Object.values(LimitRequestType).map((value) => {
  return { value, label: toHumanReadable(value) };
});

export const limitStatusOptions = sortBy(
  Object.values(LimitStatus).map((value) => {
    return { value, label: toHumanReadable(value) };
  }),
  "label"
);

export const limitRequestCategoryOptions = Object.values(
  LimitRequestCategory
).map((value) => {
  return { value, label: toHumanReadable(value) };
});

export const topProductCategoryOptions = Object.values([
  LimitTopProductCategory.TopBronze,
  LimitTopProductCategory.TopGold,
  LimitTopProductCategory.TopPlatinum,
  LimitTopProductCategory.TopSilver,
]).map((value) => {
  return { value, label: toHumanReadable(value) };
});
