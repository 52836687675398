import { MantineTheme, rem } from "@mantine/core";

import { ThemeComponent } from "./helpers.ts";

const basicNotification = (theme: MantineTheme) => ({
  root: {
    top: "3.75rem",
    boxShadow: `0 ${rem(2)} ${rem(19)} 0 rgba(55, 65, 82, 0.10)`,
    padding: rem(16),
    gap: rem(20),

    "&[data-with-icon]": {
      paddingLeft: rem(20),
    },
  },
  icon: {
    background: "transparent",
    weight: rem(40),
    height: rem(40),
    margin: 0,
    svg: {
      flexShrink: 0,
    },
  },
  title: {
    color: theme.colors.midnightBlue[0],
    marginBottom: 0,
    fontWeight: 500,
    lineHeight: 1.5,
    fontSize: rem(16),
  },
  description: {
    lineHeight: 1.5,
  },
  closeButton: {
    width: rem(24),
    height: rem(24),
    svg: {
      width: rem(24),
      height: rem(24),
    },
  },
  loader: {
    width: `${rem(33.33)}!important`,
    height: `${rem(33.33)}!important`,
    marginRight: rem(-3.5),
    marginLeft: rem(-3.5),
  },
});

export default function notificationTheme(): ThemeComponent {
  return {
    styles: (theme) => basicNotification(theme),
  };
}
