import isNil from "lodash/isNil";
import { useQuery } from "urql";

import { GetLimitRequestConditionPrecedentsDocument } from "../../../graphql/generated.ts";

interface Props {
  limitRequestId: number | string | null | undefined;
}

export const useGetLimitRequestConditionPrecedents = ({
  limitRequestId,
}: Props) => {
  return useQuery({
    query: GetLimitRequestConditionPrecedentsDocument,
    variables: {
      limitRequestId: Number(limitRequestId),
      filter: { active: { is: true } },
    },
    pause: isNil(limitRequestId),
  });
};
