import { createStyles, rem } from "@mantine/core";

import {
  ThemeGradientBackground,
  themeGradientBackground,
} from "../../../utils/mantine/themeGradientBackground.ts";

export interface StyleProps {
  variant?: ThemeGradientBackground;
  flex?: number;
  isSmall?: boolean;
}

export const useStyles = createStyles(
  (theme, { variant = "blue", flex, isSmall }: StyleProps) => ({
    sectionTitle: {
      padding: isSmall ? `${rem(12)} ${rem(20)}` : rem(20),
      height: rem(44),
      flex: flex ?? "1 0 0",
      borderRadius: rem(4),
      fontWeight: 600,
      lineHeight: 1.5,
      color: theme.colors.white[0],
      ...themeGradientBackground(theme, variant),
    },
  })
);
