import { createStyles, rem } from "@mantine/core";

export const useStyles = createStyles(() => ({
  labelUpdated: {
    textTransform: "unset",
    fontWeight: 400,
    fontSize: rem(12),
    lineHeight: rem(15.6),
  },
  nameUpdated: {
    textTransform: "unset",
    fontWeight: 500,
    fontSize: rem(12),
    lineHeight: rem(15.6),
  },
  expand: {
    fontWeight: 500,
    fontSize: rem(14),
    lineHeight: rem(21),
    textDecoration: "underline",
    whiteSpace: "nowrap",
  },
  badge: {
    textTransform: "unset",
  },
  footerControlWrapper: {
    paddingTop: rem(8),
  },
}));
