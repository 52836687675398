import type { SVGProps } from "react";
const SvgCalendar = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={24}
    height={24}
    viewBox="0 -960 960 960"
    fill="currentColor"
    {...props}
  >
    <path d="M224.615-120Q197-120 178.5-138.5 160-157 160-184.615v-510.77Q160-723 178.5-741.5 197-760 224.615-760h70.769v-89.231h43.077V-760h286.154v-89.231h40.001V-760h70.769Q763-760 781.5-741.5 800-723 800-695.385v510.77Q800-157 781.5-138.5 763-120 735.385-120h-510.77Zm0-40h510.77q9.23 0 16.923-7.692Q760-175.385 760-184.615v-350.77H200v350.77q0 9.23 7.692 16.923Q215.385-160 224.615-160ZM200-575.385h560v-120q0-9.23-7.692-16.923Q744.615-720 735.385-720h-510.77q-9.23 0-16.923 7.692Q200-704.615 200-695.385v120Zm0 0V-720v144.615Z" />
  </svg>
);
export default SvgCalendar;
