import { Badge } from "@mantine/core";
import { useMemo } from "react";
import { Link } from "react-router-dom";

import ActionIcon from "../../../../../components/ActionIcon/ActionIcon.tsx";
import { MultiFieldCardProps } from "../../../../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import SvgEdit from "../../../../../components/Icons/Edit.tsx";
import Spoiler from "../../../../../components/Spoiler/Spoiler.tsx";
import TableFilesDisplay from "../../../../../components/TableFilesDisplay/TableFilesDisplay.tsx";
import { LimitRequestException } from "../../../../../types/limitRequestException.ts";
import { useStyles } from "../../TransactionHistory.styles.ts";
import { TransactionHistoryCardHeader } from "../../TransactionHistoryCardHeader.tsx";

interface Props {
  data: LimitRequestException[] | null | undefined;
  onEdit: (value: LimitRequestException) => void;
}

export const useExceptionsList = ({
  data,
  onEdit,
}: Props): MultiFieldCardProps[] | null => {
  const { classes } = useStyles();
  return useMemo(() => {
    if (!data) {
      return null;
    }
    return data?.map((item) => ({
      cardControl: (
        <ActionIcon spaces={false} onClick={() => onEdit(item)}>
          <SvgEdit />
        </ActionIcon>
      ),
      customVariant: <TransactionHistoryCardHeader label={item.name} />,
      items: [
        {
          label: "Status",
          content: (
            <Badge size="s" variant={item?.completed ? "active" : "inactive"}>
              {item?.completed ? "Complete" : "Not Complete"}
            </Badge>
          ),
        },
        {
          label: "Document Submission",
          content:
            item.documents &&
            (item.documents.length > 1 ? (
              <TableFilesDisplay files={item.documents} />
            ) : (
              <Link
                to={item.documents[0].url}
                target="_blank"
                className={classes.downloadLink}
              >
                Download
              </Link>
            )),
        },
        {
          label: "Notes",
          content: item?.notes && <Spoiler text={item.notes} />,
        },
      ],
    }));
  }, [data, classes, onEdit]);
};
