import {
  LimitRequestConditionPrecedent as GqlGeneratedLimitRequestConditionPrecedent,
  LimitRequestConditionPrecedentType,
} from "../graphql/generated.ts";
import { toHumanReadable } from "../utils/string.ts";

export type LimitRequestConditionPrecedent = Pick<
  GqlGeneratedLimitRequestConditionPrecedent,
  | "id"
  | "completed"
  | "createdAt"
  | "dueDate"
  | "limitRequestId"
  | "name"
  | "notes"
  | "type"
  | "updatedAt"
  | "documents"
>;

export const limitRequestCpTypeOptions = Object.values(
  LimitRequestConditionPrecedentType
).map((value) => {
  return { value, label: toHumanReadable(value) };
});
