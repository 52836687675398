import { LoadingOverlay } from "@mantine/core";
import { useCallback, useEffect, useRef, useState } from "react";

import { useStyles } from "./Preloader.styles.ts";

interface Props {
  loading: boolean;
  debounce?: number;
}

const Preloader = ({ loading, debounce = 600 }: Props) => {
  const { classes } = useStyles();

  const [visible, setVisible] = useState(false);

  const timeout = useRef<ReturnType<typeof setTimeout>>();

  const clearVisible = useCallback(() => {
    setVisible(false);
    if (timeout) {
      clearTimeout(timeout.current);
    }
  }, []);

  useEffect(() => {
    if (loading) {
      clearVisible();
      timeout.current = setTimeout(() => {
        setVisible(true);
      }, debounce);
    } else {
      clearVisible();
    }
  }, [clearVisible, debounce, loading]);

  return (
    <LoadingOverlay
      visible={visible}
      overlayBlur={1}
      className={classes.preloaderWrapper}
    />
  );
};

export default Preloader;
