import { Flex } from "@mantine/core";

import SectionTitle from "../../../components/Section/SectionTitle/SectionTitle.tsx";
import Table from "../../../components/Table/Table.tsx";
import { QueryDashboardOutstandingPrincipalAccumulativeArgs } from "../../../graphql/generated";
import { useDashboardOutstandingPrincipalAccumulative } from "../../../hooks/api/dashboardOutstandingPrincipalAccumulative/useDashboardOutstandingPrincipalAccumulative.ts";
import { useOutstandingPrincipalAccumulative } from "./hooks/useOutstandingPrincipalAccumulative.tsx";

interface Props {
  filter: QueryDashboardOutstandingPrincipalAccumulativeArgs["salesTeamName"];
}

const OutstandingPrincipalAccumulative = ({ filter }: Props) => {
  const [{ data, fetching }] = useDashboardOutstandingPrincipalAccumulative({
    salesTeamName: filter,
  });

  const [columns, rows] = useOutstandingPrincipalAccumulative({
    data: data?.dashboardOutstandingPrincipalAccumulative,
  });

  return (
    <Flex direction="column" gap={12}>
      <SectionTitle>Outstanding Principal Accumulative</SectionTitle>
      <Table columns={columns} rows={rows} minWidth="100%" loading={fetching} />
    </Flex>
  );
};

export default OutstandingPrincipalAccumulative;
