import { Select } from "@mantine/core";
import { DataTableColumn } from "mantine-datatable";
import { useMemo } from "react";

import ActionIcon from "../../../../../components/ActionIcon/ActionIcon.tsx";
import SvgEdit from "../../../../../components/Icons/Edit.tsx";
import { TableData, TableRow } from "../../../../../components/Table/types.ts";
import { GiroStatus, GiroType } from "../../../../../graphql/generated.ts";
import {
  GiroManagement,
  RawGiroManagementType,
} from "../../../../../types/giroManagement.ts";
import { booleanToString } from "../../../../../utils/boolean.ts";
import { toLocalDate } from "../../../../../utils/date.ts";
import { priceFormatter } from "../../../../../utils/number.ts";
import { giroStatusOptions, giroTypeOptions } from "../constants.ts";

interface Props {
  data: GiroManagement[] | null | undefined;
  canEdit: boolean;
  olNumbersList: string[];
  soNumbersList: string[];
  onInlineEdit: (
    giroManagementId: number,
    value: RawGiroManagementType
  ) => void;
  onOpenEditModal: (giroManagement: GiroManagement) => void;
}

export function useGiroManagementTableData({
  data,
  canEdit,
  soNumbersList,
  olNumbersList,
  onInlineEdit,
  onOpenEditModal,
}: Props): TableData {
  const columns: DataTableColumn<TableRow>[] = [
    {
      accessor: "id",
      hidden: true,
    },
    {
      accessor: "number",
      title: "GIRO No",
      sortable: true,
      width: 250,
    },
    {
      accessor: "receivedDate",
      title: "GIRO Received Date",
      sortable: true,
      width: 200,
    },
    {
      title: "Type",
      accessor: "type",
      sortable: true,
      width: 230,
    },
    {
      title: "GIRO Due Date",
      accessor: "dueDate",
      sortable: true,
      width: 200,
    },
    {
      title: "GIRO Buyer No",
      accessor: "buyerNumber",
      sortable: true,
      width: 230,
    },
    {
      title: "Bank",
      accessor: "bank",
      sortable: true,
      width: 230,
    },
    {
      title: "Bank Account No",
      accessor: "bankAccountNo",
      sortable: true,
      width: 230,
    },
    {
      title: "Bank Account Holder Name",
      accessor: "bankAccountHolderName",
      sortable: true,
      width: 230,
    },
    {
      title: "GIRO Amount",
      accessor: "amount",
      sortable: true,
      width: 230,
    },
    {
      title: "OL No",
      accessor: "olNo",
      sortable: true,
      width: 230,
    },
    {
      title: "SO No",
      accessor: "soNo",
      sortable: true,
      width: 230,
    },
    {
      title: "GIRO Status",
      accessor: "status",
      sortable: true,
      width: 230,
    },
    {
      title: "GIRO Account Number Matched",
      accessor: "accountNoMatched",
      sortable: false,
      width: 230,
    },
    {
      accessor: "actions",
      title: "Actions",
      width: 150,
      hidden: !canEdit,
    },
  ];

  const rows = useMemo(() => {
    return data?.map((item) => {
      return {
        id: item.id,
        number: item.number,
        receivedDate: toLocalDate(item.receivedDate),
        type: (
          <Select
            data={giroTypeOptions}
            value={item.type}
            withinPortal
            zIndex={100}
            size="s"
            disabled={!canEdit}
            onChange={(value) =>
              onInlineEdit(item.id, { ...item, type: value as GiroType })
            }
          />
        ),
        dueDate: toLocalDate(item.dueDate),
        buyerNumber: item.buyerNumber,
        bank: item.bank,
        bankAccountNo: item.bankAccountNo,
        bankAccountHolderName: item.bankAccountHolderName,
        amount: priceFormatter(item.amount),
        olNo: (
          <Select
            data={olNumbersList}
            value={item.olNo}
            withinPortal
            zIndex={100}
            size="s"
            disabled={!canEdit}
            onChange={(value) => {
              onInlineEdit(item.id, { ...item, olNo: value as string });
            }}
          />
        ),
        soNo: (
          <Select
            data={soNumbersList}
            value={item.soNo}
            withinPortal
            zIndex={100}
            size="s"
            disabled={!canEdit}
            onChange={(value) => {
              onInlineEdit(item.id, { ...item, soNo: value as string });
            }}
          />
        ),
        status: (
          <Select
            data={giroStatusOptions}
            value={item.status}
            withinPortal
            zIndex={100}
            size="s"
            disabled={!canEdit}
            onChange={(value) =>
              onInlineEdit(item.id, { ...item, status: value as GiroStatus })
            }
          />
        ),
        accountNoMatched: booleanToString(item.accountNoMatched),
        actions: (
          <ActionIcon onClick={() => onOpenEditModal(item)}>
            <SvgEdit />
          </ActionIcon>
        ),
      };
    });
  }, [
    data,
    canEdit,
    onInlineEdit,
    onOpenEditModal,
    olNumbersList,
    soNumbersList,
  ]);

  return [columns, rows];
}
