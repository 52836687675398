import { createStyles, rem } from "@mantine/core";

export const useStyles = createStyles(() => ({
  companyInfoWrapper: {
    "@media (max-width: 48em)": {
      flexDirection: "column",
      gap: rem(12),
    },
  },
}));
