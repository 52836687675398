import { useMemo } from "react";

import { MultiFieldCardItem } from "../../../../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import { ClikResult } from "../../../../../types/clikResult.ts";
import { toLocalDate } from "../../../../../utils/date.ts";
import { toCurrencyPrice } from "../../../../../utils/number.ts";
import { toHumanReadable } from "../../../../../utils/string.ts";

interface Props {
  clikResult: ClikResult;
}

export interface CRIndividualInfo {
  subject: MultiFieldCardItem[];
  indentificationDocuments: MultiFieldCardItem[];
  applicationSummary: MultiFieldCardItem[];
}

export const useCRIndividualResultInfoCards = ({
  clikResult,
}: Props): CRIndividualInfo => {
  const subject = useMemo(() => {
    return [
      {
        label: "CB Subject Code",
        content: clikResult?.individualSubject?.cbSubjectCode,
      },
      {
        label: "Provider Subject No",
        content: clikResult?.individualSubject?.providerSubjectNumber,
      },
      {
        label: "Last Update Date",
        content:
          clikResult?.individualSubject?.lastUpdatedDate &&
          toLocalDate(clikResult.individualSubject.lastUpdatedDate),
      },
      {
        label: "Resident",
        content:
          clikResult?.individualSubject?.resident &&
          toHumanReadable(clikResult.individualSubject.resident),
      },
      {
        label: "Name As Id",
        content: clikResult?.individualSubject?.nameAsId,
      },
      {
        label: "Full Name",
        content: clikResult?.individualSubject?.fullName,
      },
      {
        label: "Mother's Name",
        content: clikResult?.individualSubject?.mothersName,
      },
      {
        label: "Gender",
        content:
          clikResult?.individualSubject?.gender &&
          toHumanReadable(clikResult.individualSubject.gender),
      },
      {
        label: "Date of Birth",
        content:
          clikResult?.individualSubject?.dateOfBirth &&
          toLocalDate(clikResult.individualSubject.dateOfBirth),
      },
      {
        label: "Place of Birth",
        content: clikResult?.individualSubject?.placeOfBirth,
      },
      {
        label: "Marital Status",
        content: clikResult?.individualSubject?.maritalStatus,
      },
      {
        label: "Educational Status",
        content: clikResult?.individualSubject?.educationStatus,
      },
    ];
  }, [clikResult]);

  const indentificationDocuments = useMemo(() => {
    const documentsList =
      clikResult?.individualSubject?.identities.map((item) => ({
        label: item.type,
        content: item.number,
      })) ?? [];

    return [
      ...documentsList,
      {
        label: "NPWP/Tax Registration Number",
        content: clikResult?.individualSubject?.npwpId,
      },
    ];
  }, [clikResult]);

  const applicationSummary = useMemo(() => {
    return [
      {
        label: "CB Contract Code",
        content: clikResult?.applicationSummary?.cbContractCode,
      },
      {
        label: "Contract Type",
        content:
          clikResult?.applicationSummary?.contractType &&
          toHumanReadable(clikResult.applicationSummary.contractType),
      },
      {
        label: "Contract Phase",
        content:
          clikResult?.applicationSummary?.contractPhase &&
          toHumanReadable(clikResult.applicationSummary.contractPhase),
      },
      {
        label: "Request Date",
        content:
          clikResult?.applicationSummary?.requestDate &&
          toLocalDate(clikResult.applicationSummary.requestDate),
      },
      {
        label: "Application Amount",
        content:
          clikResult?.applicationSummary?.amount &&
          toCurrencyPrice(clikResult.applicationSummary.amount),
      },
      {
        label: "Due Date",
        content:
          clikResult?.applicationSummary?.dueDate &&
          toLocalDate(clikResult.applicationSummary.dueDate),
      },
      {
        label: "Role",
        content: clikResult?.applicationSummary?.role,
      },
      {
        label: "Company Role",
        content: clikResult?.applicationSummary?.companyRole,
      },
      {
        label: "Account Number",
        content: clikResult?.applicationSummary?.accountNumber,
      },
      {
        label: "Original Agreement Number",
        content: clikResult?.applicationSummary?.originalAgreementNumber,
      },
      {
        label: "Original Agreement Date",
        content:
          clikResult?.applicationSummary?.originalAgreementDate &&
          toLocalDate(clikResult.applicationSummary.originalAgreementDate),
      },
      {
        label: "Currency",
        content: clikResult?.applicationSummary?.currency,
      },
    ];
  }, [clikResult]);

  return { subject, indentificationDocuments, applicationSummary };
};
