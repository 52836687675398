import { useQuery } from "urql";

import {
  GetUsersDocument,
  UserFilter,
  UserOrderBy,
} from "../../../graphql/generated";
import { getApiPaginationData } from "../../../utils/api.ts";
import { ApiHookProps } from "../types.ts";

export function useUsers({
  filter,
  orderBy,
  pause,
  page,
}: ApiHookProps<UserFilter, UserOrderBy>) {
  const pagination = getApiPaginationData(page);
  const variables = {
    filter,
    orderBy,
    skip: pagination?.skip,
    take: pagination?.take,
  };
  return useQuery({
    query: GetUsersDocument,
    variables,
    pause,
  });
}
