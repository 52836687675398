import { SelectProps } from "@mantine/core";
import { UseFormReturnType } from "@mantine/form";

import { RestructureFilters } from "./types";

export function restructureFilter(
  form: UseFormReturnType<RestructureFilters>
): (fieldKey: keyof RestructureFilters) => SelectProps["onChange"] {
  return (fieldKey) => {
    return (fieldValue) => {
      form.setFieldValue(fieldKey, fieldValue);
    };
  };
}
