import isNil from "lodash/isNil";
import { useQuery } from "urql";

import { GetCustomerDocument } from "../../../graphql/generated";

interface Props {
  id: number | string;
}

export function useCustomer({ id }: Props) {
  return useQuery({
    query: GetCustomerDocument,
    variables: { id: Number(id) },
    pause: isNil(id),
  });
}
