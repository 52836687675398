import { createStyles, rem } from "@mantine/core";

export const useStyles = createStyles((theme) => ({
  clikResultViewText: {
    fontSize: rem(12),
    fontWeight: 500,
    lineHeight: 1.5,
    color: theme.colors.dusk[0],
    textDecoration: "underline",
    cursor: "pointer",
  },
}));
