import { createStyles, rem } from "@mantine/core";

export const useStyles = createStyles(() => ({
  wrapper: {
    "@media (max-width: 48em)": {
      display: "flex",
      flexDirection: "column",
      gap: rem(12),
    },
  },
}));
