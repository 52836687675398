import { useQuery } from "urql";

import { ClikCitiesDocument, OrderBy } from "../../../graphql/generated";

export function useClikCities() {
  return useQuery({
    query: ClikCitiesDocument,
    variables: {
      orderBy: {
        name: OrderBy.Asc,
      },
    },
  });
}
