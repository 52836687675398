import { useMemo } from "react";

import { MultiFieldCardItem } from "../../../../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import { ClikContractHistorySummary } from "../../../../../types/clikResult.ts";
import { toPrice } from "../../../../../utils/number.ts";

interface Props {
  data: ClikContractHistorySummary | null | undefined;
}

export const useCRContractSummaryCardInfo = ({
  data,
}: Props): MultiFieldCardItem[] => {
  return useMemo(() => {
    if (!data) {
      return [];
    }

    return [
      {
        label: "Contracts number",
        content: data.contractsNumber,
      },
      {
        label: "Reporting Providers Number",
        content: data.reportingProvidersNumber,
      },
      {
        label: "Total Debit Balance",
        content: data.totalDebitBalance && toPrice(data.totalDebitBalance),
      },
      {
        label: "Total Overdue",
        content: data.totalOverdue && toPrice(data.totalOverdue),
      },
      {
        label: "Total Credit Limit",
        content: data.totalCreditLimit && toPrice(data.totalCreditLimit),
      },
      {
        label: "Total Potential Exposure",
        content:
          data.totalPotentialExposure && toPrice(data.totalPotentialExposure),
      },
      {
        label: "Currency",
        content: data.currencyDesc,
      },
    ];
  }, [data]);
};
