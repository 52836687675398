import { Flex, Select, SimpleGrid } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { DataTableSortStatus } from "mantine-datatable";
import { useCallback, useMemo, useState } from "react";

import { useCurrentUserContext } from "../../../components/Auth/CurrentUserProvider.tsx";
import Table from "../../../components/Table/Table.tsx";
import { SortDirection } from "../../../components/Table/types.ts";
import { boolSelectorOptions } from "../../../constants.ts";
import {
  OrderBy,
  PaymentStatus,
  RolePermission,
  TransactionHistoryOrderBy,
  UpdateLimitRequestConditionPrecedentInput,
  UpdateLimitRequestExceptionInput,
  UpdateTransactionHistoryInput,
} from "../../../graphql/generated.ts";
import { useCustomers } from "../../../hooks/api/customer/useCustomers.ts";
import { useCreateLimitRequestConditionPrecedent } from "../../../hooks/api/limitRequestConditionPrecedent/useCreateLimitRequestConditionPrecedent.ts";
import { useUpdateLimitRequestConditionPrecedent } from "../../../hooks/api/limitRequestConditionPrecedent/useUpdateLimitRequestConditionPrecedent.ts";
import { useCreateLimitRequestException } from "../../../hooks/api/limitRequestException/useCreateLimitRequestException.ts";
import { useUpdateLimitRequestException } from "../../../hooks/api/limitRequestException/useUpdateLimitRequestException.ts";
import { useApproveTransactionHistory } from "../../../hooks/api/transactionHistory/useApproveTransactionHistory.ts";
import { useGetTransactionHistoryList } from "../../../hooks/api/transactionHistory/useGetTransactionHistoryList.ts";
import { useUpdateTransactionHistory } from "../../../hooks/api/transactionHistory/useUpdateTransactionHistory.ts";
import { useMutationNotificationWrapper } from "../../../hooks/useMutationNotificationWrapper.tsx";
import { LimitRequestConditionPrecedent } from "../../../types/limitRequestConditionPrecedent.ts";
import { LimitRequestException } from "../../../types/limitRequestException.ts";
import {
  paymentStatusData,
  paymentTypeData,
  TransactionHistory,
} from "../../../types/transactionHistory.ts";
import { stringToBoolean } from "../../../utils/boolean.ts";
import {
  getSalesNameOptions,
  getSalesTeamOptions,
} from "../../../utils/company.ts";
import { getPagesCount } from "../../../utils/pagination.ts";
import { hasPermission } from "../../../utils/user.ts";
import Page404 from "../../404/Page404.tsx";
import ConditionPrecedentsModal from "./ConditionPrecedentsModal/ConditionPrecedentsModal.tsx";
import ExceptionModal from "./ExceptionModal/ExceptionModal.tsx";
import { useTransactionHistoryTableData } from "./hooks/useTransactionHistoryTableData.tsx";
import { ModalType, TransactionHistoryFiltersValue } from "./types.ts";
import {
  getTransactionHistoryApiFilter,
  getTransactionHistoryRequestApiSort,
} from "./utils.ts";

interface TransactionHistoryListProps {
  customerId: number | null | undefined;
}

const TransactionHistoryList = ({
  customerId,
}: TransactionHistoryListProps) => {
  const [filter, setFilter] = useState<TransactionHistoryFiltersValue>({});
  const apiFilter = useMemo(() => {
    return getTransactionHistoryApiFilter(filter);
  }, [filter]);
  const [page, setPage] = useState<number>(1);
  const [orderBy, setOrderBy] = useState<TransactionHistoryOrderBy>({
    createdAt: OrderBy.Asc,
  });

  const [selectedLimitRequestId, setSelectedLimitRequestId] = useState<
    number | null | undefined
  >(null);
  const [selectedConditionPrecedents, setSelectedConditionalPrecedents] =
    useState<LimitRequestConditionPrecedent[] | null | undefined>(null);
  const [selectedExceptions, setSelectedExceptions] = useState<
    LimitRequestException[] | null | undefined
  >(null);

  const [
    conditionPrecedenceEditModalOpened,
    {
      open: conditionPrecedenceEditModalOpen,
      close: conditionPrecedenceEditModalClose,
    },
  ] = useDisclosure(false);

  const [
    exceptionEditModalOpened,
    { open: exceptionEditModalOpen, close: exceptionEditModalClose },
  ] = useDisclosure(false);

  const [{ data, fetching }, refresh] = useGetTransactionHistoryList({
    filter: { ...apiFilter, customerId: { equals: customerId } },
    orderBy,
    page,
  });

  const [{ data: companiesData }] = useCustomers({});

  const companies = companiesData?.customers;

  const { user: currentUser } = useCurrentUserContext();

  const [, approveTransactionHistory] = useMutationNotificationWrapper(
    useApproveTransactionHistory(),
    {
      success: {
        message: "Transaction History has been approved.",
      },
    }
  );

  const [, updateTransactionHistory] = useMutationNotificationWrapper(
    useUpdateTransactionHistory(),
    {
      success: {
        message: "Transaction History has been updated.",
      },
    }
  );

  const [, updateException] = useMutationNotificationWrapper(
    useUpdateLimitRequestException(),
    {
      success: { message: "Exception was successfully updated." },
    }
  );

  const [, createException] = useMutationNotificationWrapper(
    useCreateLimitRequestException(),
    {
      success: { message: "Exception was successfully created." },
    }
  );

  const [, updateConditionPrecedent] = useMutationNotificationWrapper(
    useUpdateLimitRequestConditionPrecedent(),
    {
      success: {
        message: "Condition Precedent was successfully updated.",
      },
    }
  );

  const [, createConditionPrecedent] = useMutationNotificationWrapper(
    useCreateLimitRequestConditionPrecedent(),
    {
      success: {
        message: "Condition Precedent was successfully created.",
      },
    }
  );

  const transactionHistoryData: TransactionHistory[] | undefined | null =
    data?.transactionHistory?.data;

  const pageCount = getPagesCount(data?.transactionHistory?.total);

  const hasAccess = hasPermission(
    currentUser,
    RolePermission.CanViewTransactionHistory
  );

  const handlePageChange = useCallback(
    (page: number) => {
      setPage(page);
    },
    [setPage]
  );

  const handleOnSortChange = useCallback(
    (sort: DataTableSortStatus) => {
      setOrderBy(getTransactionHistoryRequestApiSort(sort));
    },
    [setOrderBy]
  );

  const handleOpenEditModal = (type: ModalType, value: TransactionHistory) => {
    setSelectedLimitRequestId(value.limit?.limitRequest?.id);
    if (type === ModalType.ConditionPrecedents) {
      setSelectedConditionalPrecedents(
        value.limit?.limitRequest?.conditionPrecedents
      );
      return conditionPrecedenceEditModalOpen();
    }

    if (type === ModalType.Exceptions) {
      setSelectedExceptions(value.limit?.limitRequest?.exceptions);
      return exceptionEditModalOpen();
    }
  };

  const handleCloseConditionPrecedenceModal = () => {
    setSelectedConditionalPrecedents(null);
    setSelectedLimitRequestId(null);
    conditionPrecedenceEditModalClose();
  };

  const handleCloseExceptionModal = () => {
    setSelectedExceptions(null);
    setSelectedLimitRequestId(null);
    exceptionEditModalClose();
  };

  const handleApproveTransactionHistory = useCallback(
    (id: number) => {
      approveTransactionHistory({ id }).then(refresh);
    },
    [approveTransactionHistory, refresh]
  );

  const handleUpdateTransactionHistory = useCallback(
    (id: number, input: UpdateTransactionHistoryInput) => {
      updateTransactionHistory({ id, input }).then(refresh);
    },
    [updateTransactionHistory, refresh]
  );

  const handleSubmitException = useCallback(
    (input: UpdateLimitRequestExceptionInput, id?: number) => {
      if (id) {
        updateException({ id, input }).then((res) => {
          const updatedExceptionResult = res.data?.updateLimitRequestException;
          const updatedExceptions = selectedExceptions?.map((item) =>
            item.id === id ? updatedExceptionResult ?? item : item
          );

          setSelectedExceptions(updatedExceptions);
          refresh();
        });
        return;
      }
      if (!selectedLimitRequestId) {
        return;
      }
      createException({
        input: { ...input, limitRequestId: selectedLimitRequestId },
      }).then((res) => {
        const createdExceptionResult = res?.data?.createLimitRequestException;
        if (!createdExceptionResult) {
          return;
        }
        setSelectedExceptions(
          selectedExceptions
            ? [...selectedExceptions, createdExceptionResult]
            : [createdExceptionResult]
        );
        refresh();
      });
    },
    [
      selectedLimitRequestId,
      selectedExceptions,
      updateException,
      createException,
      refresh,
    ]
  );

  const handleSubmitConditionPrecedence = useCallback(
    (input: UpdateLimitRequestConditionPrecedentInput, id?: number) => {
      if (id) {
        updateConditionPrecedent({ id, input }).then((res) => {
          const updatedCPResult =
            res.data?.updateLimitRequestConditionPrecedent;
          const updatedConditionPrecedents = selectedConditionPrecedents?.map(
            (item) => (item.id === id ? updatedCPResult ?? item : item)
          );

          setSelectedConditionalPrecedents(updatedConditionPrecedents);
          refresh();
        });
        return;
      }
      if (!selectedLimitRequestId) {
        return;
      }
      createConditionPrecedent({
        input: { ...input, limitRequestId: selectedLimitRequestId },
      }).then((res) => {
        const createdCPResult = res?.data?.createLimitRequestConditionPrecedent;
        if (!createdCPResult) {
          return;
        }
        setSelectedConditionalPrecedents(
          selectedConditionPrecedents
            ? [...selectedConditionPrecedents, createdCPResult]
            : [createdCPResult]
        );
        refresh();
      });
    },
    [
      selectedLimitRequestId,
      selectedConditionPrecedents,
      updateConditionPrecedent,
      createConditionPrecedent,
      refresh,
    ]
  );

  const handleFilterChange = useCallback((key: string, value: unknown) => {
    setFilter((prevFilter) => {
      return { ...prevFilter, [key]: value };
    });
  }, []);

  console.log("filter: ", filter);

  const [columns, rows] = useTransactionHistoryTableData({
    data: transactionHistoryData,
    onApprove: handleApproveTransactionHistory,
    onOpenEditModal: handleOpenEditModal,
    onInlineEdit: handleUpdateTransactionHistory,
  });

  if (!hasAccess) {
    return <Page404 />;
  }
  return (
    <Flex direction="column" gap={16}>
      <SimpleGrid
        w="100%"
        cols={3}
        breakpoints={[
          { maxWidth: "sm", cols: 1 },
          { maxWidth: "lg", cols: 2 },
        ]}
        spacing={20}
        verticalSpacing={12}
      >
        <Select
          size="m"
          clearable
          searchable
          label="Sales Name"
          placeholder="Select Sales Name"
          data={getSalesNameOptions(companies)}
          onChange={(value) => handleFilterChange("salesName", value)}
        />
        <Select
          size="m"
          clearable
          searchable
          label="Payment Status"
          placeholder="Select Payment Status"
          data={paymentStatusData}
          onChange={(value: PaymentStatus) =>
            handleFilterChange("paymentStatus", value)
          }
        />
        <Select
          size="m"
          clearable
          searchable
          label="Has Exceptions"
          placeholder="Select if it has exceptions"
          data={boolSelectorOptions}
          onChange={(value) =>
            handleFilterChange("hasException", stringToBoolean(value))
          }
        />
        <Select
          size="m"
          clearable
          searchable
          label="Sales Team"
          placeholder="Select Sales Team"
          data={getSalesTeamOptions(companies)}
          onChange={(value) => handleFilterChange("salesTeam", value)}
        />
        <Select
          size="m"
          clearable
          searchable
          label="Payment Type"
          placeholder="Select Payment Type"
          data={paymentTypeData}
          onChange={(value) => handleFilterChange("paymentType", value)}
        />
        <Select
          size="m"
          clearable
          searchable
          label="Is Restructured"
          placeholder="Select if it is Restructured"
          data={boolSelectorOptions}
          onChange={(value) =>
            handleFilterChange("isRestructured", stringToBoolean(value))
          }
        />
      </SimpleGrid>
      <Table
        columns={columns}
        rows={rows}
        defaultSort={{
          columnAccessor: "createdAt",
          direction: SortDirection.asc,
        }}
        loading={fetching}
        pagination={{
          pageCount: pageCount,
          page: page,
          onPageChange: handlePageChange,
        }}
        onSortChange={handleOnSortChange}
      />
      {conditionPrecedenceEditModalOpened && (
        <ConditionPrecedentsModal
          data={selectedConditionPrecedents}
          opened={true}
          onClose={handleCloseConditionPrecedenceModal}
          onSubmit={handleSubmitConditionPrecedence}
          onSave={handleCloseConditionPrecedenceModal}
        />
      )}
      {exceptionEditModalOpened && (
        <ExceptionModal
          data={selectedExceptions}
          opened={true}
          onClose={handleCloseExceptionModal}
          onSubmit={handleSubmitException}
          onSave={handleCloseExceptionModal}
        />
      )}
    </Flex>
  );
};

export default TransactionHistoryList;
