import { MantineTheme, rem } from "@mantine/core";

import { ThemeComponent } from "./helpers";

const pagination = (theme: MantineTheme) => ({
  control: {
    fontSize: rem(14),
    lineHeight: 1.5,
    padding: 0,
    "&[data-active]": {
      background: `linear-gradient(357deg, ${theme.colors.dusk[0]} 8.1%, ${theme.colors.dusk[1]} 51.61%, ${theme.colors.dusk[2]} 64.51%, ${theme.colors.dusk[3]} 85.59%)`,
      border: 0,
    },
  },
});

export default function paginationTheme(): ThemeComponent {
  return {
    styles: (theme) => ({
      ...pagination(theme),
    }),
  };
}
