import { Flex } from "@mantine/core";
import { DataTableSortStatus } from "mantine-datatable";
import { useCallback, useState } from "react";

import SectionTitle from "../../../../../components/Section/SectionTitle/SectionTitle.tsx";
import {
  ClikGrantedCreditOtherFacilityOrderBy,
  ContractType,
  ContractTypeFilterOptions,
  OrderBy,
} from "../../../../../graphql/generated.ts";
import { useClikGrantedCreditOtherFacilities } from "../../../../../hooks/api/clikResult/clikContract/useClikGrantedCreditOtherFacilities.ts";
import { useClikGrantedDetailsOfCreditOtherFacilities } from "../../../../../hooks/api/clikResult/clikContract/useClikGrantedDetailsOfCreditOtherFacilities.ts";
import { useClikListOfGrantedCreditOtherFacilities } from "../../../../../hooks/api/clikResult/clikContract/useClikListOfGrantedCreditOtherFacilities.ts";
import {
  ClikGrantedContract,
  ClikGrantedContractDetails,
} from "../../../../../types/clikContract.ts";
import ClikResultDetailsOfContract from "../ClikResultDetailsOfContract/ClikResultDetailsOfContract.tsx";
import ClikGrantedContracts from "../ClikResultGrantedContracts/ClikGrantedContracts.tsx";
import ClikResultNotGrantedContracts from "../ClikResultNotGrantedContracts/ClikResultNotGrantedContracts.tsx";
import { getContractDetailsOptions } from "../contants.ts";
import { getGrantedContractsApiSort } from "../utils.ts";

interface ClikResultOtherFacilitiesContractsProps {
  clikResultId: number;
}

const ClikResultOtherFacilitiesContracts = ({
  clikResultId,
}: ClikResultOtherFacilitiesContractsProps) => {
  const [page, setPage] = useState<number>(1);
  const [orderBy, setOrderBy] = useState<ClikGrantedCreditOtherFacilityOrderBy>(
    {
      id: OrderBy.Asc,
    }
  );
  const [selectedContractId, setSelectedContractId] = useState<number | null>();

  const [{ data: grantedCreditOFResponse, fetching: grantedCreditOFFetching }] =
    useClikGrantedCreditOtherFacilities({
      filter: { clikResultId: { equals: clikResultId } },
      orderBy,
      page,
    });

  const [
    {
      data: listOfGrantedCreditOtherFacilitiesResponse,
      fetching: grantedListOfCreditOFFetching,
    },
  ] = useClikListOfGrantedCreditOtherFacilities({ clikResultId });

  const [
    {
      data: grantedDetailsOfCreditOFResponse,
      fetching: grantedDetailsOfCreditOFFetching,
    },
  ] = useClikGrantedDetailsOfCreditOtherFacilities({ id: selectedContractId });

  const grantedCreditOFData = grantedCreditOFResponse
    ?.clikGrantedCreditOtherFacility?.data as ClikGrantedContract[];
  const grantedCreditOFCount =
    grantedCreditOFResponse?.clikGrantedCreditOtherFacility?.total ?? 0;

  const listOfGrantedCreditOtherFacilitiesData =
    listOfGrantedCreditOtherFacilitiesResponse?.clikGrantedCreditOtherFacility
      ?.data;

  const grantedDetailsOfCreditOFData =
    grantedDetailsOfCreditOFResponse?.clikGrantedCreditOtherFacilityDetails;

  const handleOnSortChange = useCallback(
    (sort: DataTableSortStatus) => {
      setOrderBy(getGrantedContractsApiSort(sort));
    },
    [setOrderBy]
  );

  return (
    <Flex gap={12} direction="column">
      <SectionTitle variant="bronze">
        Contract Details - Other Facilities
      </SectionTitle>
      <Flex gap={20} direction="column">
        <ClikResultNotGrantedContracts
          clikResultId={clikResultId}
          type={ContractTypeFilterOptions.OtherFacilities}
        />
        <ClikGrantedContracts
          contracts={grantedCreditOFData}
          total={grantedCreditOFCount}
          loading={grantedCreditOFFetching}
          type={ContractType.OtherFacilities}
          page={page}
          onSetPage={setPage}
          onSortChange={handleOnSortChange}
        />
        <ClikResultDetailsOfContract
          type={ContractType.OtherFacilities}
          data={grantedDetailsOfCreditOFData as ClikGrantedContractDetails}
          loading={
            grantedDetailsOfCreditOFFetching || grantedListOfCreditOFFetching
          }
          clikContractProfileFilter={
            selectedContractId
              ? {
                  grantedCreditOtherFacilityId: { equals: selectedContractId },
                }
              : undefined
          }
          detailsOptions={getContractDetailsOptions(
            listOfGrantedCreditOtherFacilitiesData,
            ContractType.OtherFacilities
          )}
          selectedContractId={selectedContractId}
          onSetSelectedContractId={setSelectedContractId}
        />
      </Flex>
    </Flex>
  );
};

export default ClikResultOtherFacilitiesContracts;
