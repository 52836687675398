import { createStyles, rem } from "@mantine/core";

export const useStyles = createStyles(() => ({
  grid: {
    "@media (max-width: 48em)": {
      display: "flex",
      flexFlow: "column",
      alignItems: "stretch",
      gap: rem(12),
    },
  },
}));
