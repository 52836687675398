import { Breadcrumbs, BreadcrumbsProps, Text } from "@mantine/core";

export interface RestructureBreadcrumbsProps extends Partial<BreadcrumbsProps> {
  onBreadcrumbsClick?: () => void;
}

export default function RestructureBreadcrumbs({
  onBreadcrumbsClick,
  ...breadcrumbsProps
}: RestructureBreadcrumbsProps) {
  const items = [
    { title: "Restructure Requests", onClick: onBreadcrumbsClick },
    { title: "Restructure Details" },
  ];

  return (
    <Breadcrumbs {...breadcrumbsProps}>
      {items?.map((item, index) => {
        return (
          <Text key={index} onClick={item.onClick}>
            {item.title}
          </Text>
        );
      })}
    </Breadcrumbs>
  );
}
