import { createStyles, rem } from "@mantine/core";

export const useStyles = createStyles(() => ({
  wrapper: {
    paddingTop: rem(20),
  },
  button: {
    width: "100%",
  },
}));
