import { Flex } from "@mantine/core";

import { useCurrentUserContext } from "../../../components/Auth/CurrentUserProvider.tsx";
import { RolePermission } from "../../../graphql/generated.ts";
import { Customer } from "../../../types/customer.ts";
import { hasPermission } from "../../../utils/user.ts";
import Page404 from "../../404/Page404.tsx";
import GiroManagementTable from "./GiroManagement/GiroManagementTable.tsx";
import LimitRequestsTable from "./LimitRequestsTable/LimitRequestsTable.tsx";
import PksTable from "./PksTable/PksTable.tsx";

interface Props {
  company: Pick<Customer, "id" | "partnerCode"> | null | undefined;
}

export default function LimitInfo({ company }: Props) {
  const { user: currentUser } = useCurrentUserContext();

  const hasAccess = hasPermission(
    currentUser,
    RolePermission.CanViewBuyerInfoLimitInfo
  );

  if (!hasAccess) {
    return <Page404 />;
  }

  return (
    <Flex direction="column" gap={{ base: 16, sm: 24 }}>
      <LimitRequestsTable companyId={company?.id} />
      <PksTable customerId={company?.id} />
      <GiroManagementTable company={company} />
    </Flex>
  );
}
