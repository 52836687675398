import { Button, Flex } from "@mantine/core";
import { isEmpty } from "lodash";
import { DataTableSortStatus } from "mantine-datatable";
import { useCallback, useState } from "react";

import Table from "../../../../../components/Table/Table.tsx";
import {
  SortDirection,
  TableRow,
} from "../../../../../components/Table/types.ts";
import Title from "../../../../../components/Title/Title.tsx";
import { ContractType } from "../../../../../graphql/generated.ts";
import { ClikGrantedContract } from "../../../../../types/clikContract.ts";
import { getPagesCount } from "../../../../../utils/pagination.ts";
import { clikResultTitle } from "../contants.ts";
import { useStyles } from "./ClikGrantedContracts.styles.ts";
import {
  getCRClikGrantedContractsProfileColumns,
  getCRCLikGrantedContractsProfileRows,
} from "./CRClikGrantedContractsProfile.tsx";
import { useCRGrantedContracts } from "./hooks/useCRClikGrantedContracts.tsx";

interface ClikGrantedContractsProps {
  loading?: boolean;
  type: ContractType;
  contracts: ClikGrantedContract[] | null | undefined;
  total: number;
  page: number;
  onSetPage: (value: number) => void;
  onSortChange: (tableSort: DataTableSortStatus) => void;
}

const ClikGrantedContracts = ({
  loading,
  type,
  contracts,
  total,
  page,
  onSetPage,
  onSortChange,
}: ClikGrantedContractsProps) => {
  const { classes } = useStyles();

  const [expandedContractIds, setExpandedContractIds] = useState<number[]>([]);

  const [expandAllRows, setExpandAllRows] = useState(false);

  const [columns, rows] = useCRGrantedContracts({
    data: contracts,
  });

  const haveNestedRows = contracts?.some(
    (contract) => !isEmpty(contract.contractProfiles)
  );

  const toggleExpandAllRows = useCallback(() => {
    const expand = !expandAllRows;
    const contractsIdsList = contracts?.map((item) => item.id) ?? [];
    setExpandAllRows(expand);
    setExpandedContractIds(expand ? contractsIdsList : []);
  }, [expandAllRows, contracts]);

  const getNestedRows = useCallback(
    (record: TableRow) => {
      const contract = contracts?.find((item) => item.id === record.id);
      return getCRCLikGrantedContractsProfileRows({
        data: contract?.contractProfiles,
        className: classes.yearWrapper,
      });
    },
    [contracts, classes]
  );

  const pageCount = getPagesCount(total);

  const handlePageChange = useCallback(
    (page: number) => {
      onSetPage(page);
    },
    [onSetPage]
  );

  return (
    <Flex gap={16} direction="column">
      <Flex justify="space-between">
        <Title size="h2">
          {`${clikResultTitle[type]} (Active, Closed, Closed in Advance)`}
        </Title>
        {haveNestedRows && (
          <Button
            variant="filledGolden"
            uppercase
            disabled={loading}
            onClick={toggleExpandAllRows}
          >
            {expandAllRows ? "hide nested rows" : "show nested rows"}
          </Button>
        )}
      </Flex>

      <Table
        columns={columns}
        rows={rows}
        loading={loading}
        pagination={{
          pageCount: pageCount,
          page: page,
          onPageChange: handlePageChange,
        }}
        defaultSort={{
          columnAccessor: "id",
          direction: SortDirection.asc,
        }}
        onSortChange={onSortChange}
        rowExpansion={
          haveNestedRows
            ? {
                allowMultiple: true,
                expanded: {
                  recordIds: expandedContractIds,
                  onRecordIdsChange: setExpandedContractIds,
                },
                content: (row) => (
                  <Flex className={classes.wrapper}>
                    <Table
                      withBorder
                      withColumnBorders
                      variant="nested"
                      mih={0}
                      columns={getCRClikGrantedContractsProfileColumns()}
                      rows={getNestedRows(row.record)}
                    />
                  </Flex>
                ),
              }
            : undefined
        }
      />
    </Flex>
  );
};

export default ClikGrantedContracts;
