import { useQuery } from "urql";

import {
  DashboardOutstandingPrincipalPerCollectionDocument,
  QueryDashboardOutstandingPrincipalPerCollectionArgs,
} from "../../../graphql/generated";

export function useDashboardOutstandingPrincipalPerCollection(
  args: QueryDashboardOutstandingPrincipalPerCollectionArgs
) {
  return useQuery({
    query: DashboardOutstandingPrincipalPerCollectionDocument,
    variables: args,
  });
}
