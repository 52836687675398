import { NavLink } from "@mantine/core";
import { ReactNode } from "react";
import { NavLink as Link, useLocation } from "react-router-dom";

import { useStyles } from "./SidebarItem.styles.ts";

interface SidebarItemProps {
  label: string;
  icon: ReactNode;
  isOpen?: boolean;
  url?: string;
  nestedLinks?: {
    label: string;
    url: string;
    show?: boolean;
  }[];
  onClick?: () => void;
}

const SidebarItem = ({
  label,
  icon,
  isOpen,
  url,
  nestedLinks,
  onClick,
}: SidebarItemProps) => {
  const location = useLocation();

  const isLinkActive = (url: string) => location.pathname.includes(url);
  const hasActiveSubLink = !!nestedLinks?.find((item) =>
    isLinkActive(item.url)
  );

  const { classes } = useStyles();

  return (
    <>
      {isOpen ? (
        <>
          {url ? (
            <Link to={url} className={classes.wrapper}>
              <NavLink
                icon={icon}
                active={isLinkActive(url)}
                label={label}
                onClick={onClick}
              />
            </Link>
          ) : (
            <NavLink label={label} icon={icon} defaultOpened={hasActiveSubLink}>
              {nestedLinks?.map(
                (item, index) =>
                  item.show && (
                    <Link key={index} to={item.url} className={classes.wrapper}>
                      <NavLink
                        label={item.label}
                        active={isLinkActive(item.url)}
                        onClick={onClick}
                        className={classes.nestedLink}
                      />
                    </Link>
                  )
              )}
            </NavLink>
          )}
        </>
      ) : (
        <NavLink icon={icon} active={hasActiveSubLink} />
      )}
    </>
  );
};

export default SidebarItem;
