import { useQuery } from "urql";

import {
  GetPksListDocument,
  PksFilter,
  PksOrderBy,
} from "../../../graphql/generated.ts";
import { getApiPaginationData } from "../../../utils/api.ts";
import { ApiHookProps } from "../types.ts";

export const usePksList = ({
  filter,
  orderBy,
  page,
}: ApiHookProps<PksFilter, PksOrderBy>) => {
  const pagination = getApiPaginationData(page);
  const variables = {
    filter,
    orderBy,
    skip: pagination?.skip,
    take: pagination?.take,
  };
  return useQuery({ query: GetPksListDocument, variables });
};
