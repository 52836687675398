import { Divider, Flex, MediaQuery, NavLink } from "@mantine/core";

import appConfig from "../../../config.ts";
import SvgLogout from "../../Icons/Logout.tsx";
import { navigationLinks } from "../constants.tsx";
import { useNavigationLinks } from "../hooks/useNavigationLinks.tsx";
import { useStyles } from "./MobileMenu.styles.ts";

interface MobileMenuProps {
  onClose: () => void;
}

const MobileMenu = ({ onClose }: MobileMenuProps) => {
  const { classes } = useStyles();

  const renderedLinks = useNavigationLinks({
    links: navigationLinks,
    isOpen: true,
    onClick: onClose,
  });

  return (
    <MediaQuery largerThan="52em" styles={{ display: "none" }}>
      <Flex
        direction="column"
        justify="space-between"
        className={classes.wrapper}
      >
        <Flex direction="column">{renderedLinks}</Flex>

        <Flex direction="column" gap={12}>
          <Divider className={classes.divider} />
          <a
            className={classes.logoutWrapper}
            href={`${appConfig.apiUrl}/logout`}
          >
            <NavLink icon={<SvgLogout />} label="Log out" />
          </a>
        </Flex>
      </Flex>
    </MediaQuery>
  );
};

export default MobileMenu;
