import { Flex, Select, SimpleGrid } from "@mantine/core";
import { useForm } from "@mantine/form";

import SectionTitle from "../../../components/Section/SectionTitle/SectionTitle";
import TableWaive from "../../../components/TableWaive/TableWaive";
import { WaiveFilters } from "../../../components/TableWaive/types";
import { getWaiveApiFilter } from "../../../components/TableWaive/utils";
import { approvalStatusOptions } from "./constants";
import { waiveFilter } from "./utils";
import { useStyles } from "./WaiveReview.styles";

export default function WaiveReview() {
  const { classes } = useStyles();

  const form = useForm<WaiveFilters>();

  const onFilter = waiveFilter(form);

  return (
    <Flex direction="column" gap={{ base: 16, sm: 24 }}>
      <SectionTitle variant="bronze">Waive Requests</SectionTitle>
      <SimpleGrid
        cols={3}
        spacing={20}
        verticalSpacing={8}
        className={classes.grid}
      >
        <Select
          size="m"
          searchable
          clearable
          label="Approval Status"
          placeholder="Select Approval Status"
          nothingFound="No results found"
          data={approvalStatusOptions}
          {...form.getInputProps("approvalStatus")}
          onChange={onFilter("approvalStatus")}
        />
      </SimpleGrid>

      <TableWaive filter={getWaiveApiFilter(form.values)} />
    </Flex>
  );
}
