import { Breadcrumbs, Text } from "@mantine/core";

import { Invoice } from "../../../../types/invoice.ts";
import { toHumanReadable } from "../../../../utils/string.ts";

interface InvoiceBreadcrumbsProps {
  invoice: Invoice;
  onBreadcrumbsClick?: () => void;
}

const InvoiceBreadcrumbs = ({
  invoice,
  onBreadcrumbsClick,
}: InvoiceBreadcrumbsProps) => {
  const items = [
    { title: "Invoices", onClick: onBreadcrumbsClick },
    {
      title: `${invoice.invoiceNumber} — ${toHumanReadable(
        invoice.paymentStatus
      )}`,
    },
  ];

  return (
    <Breadcrumbs>
      {items.map((item, index) => (
        <Text key={index} onClick={item.onClick}>
          {item.title}
        </Text>
      ))}
    </Breadcrumbs>
  );
};

export default InvoiceBreadcrumbs;
