import { MantineTheme } from "@mantine/core";

import { ThemeComponent } from "./helpers.ts";

const defaultScrollArea = (theme: MantineTheme) => ({
  thumb: {
    backgroundColor: theme.colors.grayIce[0],
  },
});

export default function scrollAreaTheme(): ThemeComponent {
  return {
    styles: (theme) => defaultScrollArea(theme),
  };
}
