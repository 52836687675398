import {
  Button,
  Flex,
  Modal,
  ScrollArea,
  Select,
  Textarea,
} from "@mantine/core";
import { useForm } from "@mantine/form";
import { useCallback } from "react";

import { ClikResult } from "../../../../types/clikResult.ts";
import { clikResultVerdictOptions } from "./constants.ts";

interface Props {
  opened: boolean;
  value: Pick<ClikResult, "internalNote" | "verdict"> | null;
  onClose: () => void;
  onSubmit: (value: Pick<ClikResult, "internalNote" | "verdict">) => void;
}

const EditClikResultModal = ({ opened, value, onClose, onSubmit }: Props) => {
  const form = useForm({
    initialValues: {
      internalNote: value?.internalNote,
      verdict: value?.verdict,
    },
  });

  const handleClose = useCallback(() => {
    onClose();
    form.reset();
  }, [form, onClose]);

  const handleSubmit = useCallback(() => {
    onSubmit(form.values);
    handleClose();
  }, [form.values, handleClose, onSubmit]);

  return (
    <Modal
      opened={opened}
      title="Edit CLIK Result"
      scrollAreaComponent={ScrollArea.Autosize}
      closeButtonProps={{
        size: 24,
        iconSize: 24,
      }}
      onClose={handleClose}
    >
      <Flex direction="column" gap={20}>
        <Flex direction="column" gap={12}>
          <Textarea
            autosize
            label="Internal Note"
            placeholder="Enter Internal Note"
            minRows={3}
            maxRows={3}
            size="m"
            {...form.getInputProps("internalNote")}
          />
          <Select
            size="m"
            placeholder="Select Verdict"
            label="Verdict"
            data={clikResultVerdictOptions}
            withinPortal
            {...form.getInputProps("verdict")}
          />
        </Flex>
        <Flex gap={20} justify="end">
          <Button
            variant="outlineBlue"
            uppercase
            type="reset"
            onClick={onClose}
          >
            cancel
          </Button>
          <Button
            variant="filledBlue"
            uppercase
            type="submit"
            disabled={!form.isValid()}
            onClick={handleSubmit}
          >
            save
          </Button>
        </Flex>
      </Flex>
    </Modal>
  );
};

export default EditClikResultModal;
