import { DataTableColumn } from "mantine-datatable";
import { useMemo } from "react";

import {
  TableData,
  TableRow,
} from "../../../../../../components/Table/types.ts";
import { ClikGrantedContract } from "../../../../../../types/clikContract.ts";
import { toLocalDate } from "../../../../../../utils/date.ts";
import { toHumanReadable } from "../../../../../../utils/string.ts";
import ClikResultContractLinkedSubjects from "../../ClikResultContractLinkedSubjects.tsx";

interface Props {
  data: ClikGrantedContract[] | null | undefined;
}

export function useCRGrantedContracts({ data }: Props): TableData {
  const columns: DataTableColumn<TableRow>[] = [
    {
      accessor: "id",
      hidden: true,
    },
    {
      accessor: "referenceNo",
      title: "No.",
      width: "100px",
    },
    {
      accessor: "cbContractCode",
      title: "CB Contract Code",
      sortable: true,
      width: "150px",
    },
    {
      accessor: "providerContractNumber",
      title: "Provider Contract Number",
      sortable: true,
      width: "150px",
    },
    {
      accessor: "contractType",
      title: "Contract Type",
      sortable: true,
      width: "150px",
    },
    {
      accessor: "contractPhase",
      title: "Contract Phase",
      sortable: true,
      width: "150px",
    },
    {
      accessor: "role",
      title: "Role",
      sortable: true,
      width: "150px",
    },
    {
      accessor: "startDate",
      title: "Start Date",
      sortable: true,
      width: "150px",
    },
    {
      accessor: "dueDate",
      title: "Due Date",
      sortable: true,
      width: "200px",
    },
    {
      accessor: "providerType",
      title: "Provider Type",
      sortable: true,
      width: "150px",
    },
    {
      accessor: "providerCode",
      title: "Provider",
      sortable: true,
      width: "150px",
    },
    {
      accessor: "referenceDate",
      title: "Last Update Date",
      sortable: true,
      width: "150px",
    },
    {
      accessor: "linkedSubject",
      title: "Linked Subjects List",
      sortable: false,
      width: "350px",
    },
    {
      accessor: "note",
      title: "Note",
      sortable: true,
      width: "150px",
    },
  ];
  const rows = useMemo(() => {
    return data?.map((item, index) => {
      return {
        id: item.id,
        referenceNo: index + 1,
        cbContractCode: item.commonData.cbContractCode,
        providerContractNumber: item.commonData.providerContractNumber,
        contractType:
          item.commonData.contractType &&
          toHumanReadable(item.commonData.contractType),
        contractPhase: item.commonData.contractPhase,
        role: item.commonData.role,
        startDate:
          item.commonData.startDate && toLocalDate(item.commonData.startDate),
        dueDate:
          item.commonData.dueDate && toLocalDate(item.commonData.dueDate),
        providerType: item.commonData.providerType,
        providerCode: item.commonData.providerCode,
        referenceDate:
          item.commonData.referenceDate &&
          toLocalDate(item.commonData.referenceDate),
        linkedSubject: (
          <ClikResultContractLinkedSubjects data={item.linkedSubjects} />
        ),
        note: item.note,
      };
    });
  }, [data]);

  return [columns, rows];
}
