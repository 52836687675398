import { useQuery } from "urql";

import {
  GetRestructuresDocument,
  RestructureFilter,
  RestructureOrderBy,
} from "../../../graphql/generated";
import { getApiPaginationData } from "../../../utils/api";
import { ApiHookProps } from "../types.ts";

export function useRestructure({
  filter,
  orderBy,
  pause,
  page,
}: ApiHookProps<RestructureFilter, RestructureOrderBy>) {
  const pagination = getApiPaginationData(page);
  const variables = {
    filter,
    orderBy,
    skip: pagination?.skip,
    take: pagination?.take,
  };
  return useQuery({
    query: GetRestructuresDocument,
    variables: variables,
    pause,
  });
}
