import { LimitUtilizationName } from "../../../graphql/generated.ts";

export const LimitUtilizationNameOptions = {
  [LimitUtilizationName.TotalLimitApproved]: "Total limit approved",
  [LimitUtilizationName.TotalActiveLimit]: "Total active limit",
  [LimitUtilizationName.PercentActiveLimit]: "% Active limit",
  [LimitUtilizationName.UtilizedLimit]: "Utilized limit",
  [LimitUtilizationName.PercentUtilizedLimit]: "% Utilized limit",
  [LimitUtilizationName.RemainingActiveLimit]: "Remaining active limit",
  [LimitUtilizationName.FreezedLimit]: "Freezed limit",
  [LimitUtilizationName.PercentFreezedLimit]: "% Freezed limit",
  [LimitUtilizationName.InactiveLimit]: "Inactive limit",
  [LimitUtilizationName.PercentInactiveLimit]: "% Inactive limit",
};
