import { useMemo } from "react";

import { MultiFieldCardItem } from "../../../../../../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import { ClikContractCollateral } from "../../../../../../../graphql/generated.ts";
import { toLocalDate } from "../../../../../../../utils/date.ts";
import {
  toCurrencyPrice,
  toLocalNumber,
} from "../../../../../../../utils/number.ts";

interface Props {
  data: ClikContractCollateral;
}

interface CRCollateral {
  grantedCollateral: MultiFieldCardItem[];
  mainCollateralValue: MultiFieldCardItem[];
}

export const useCRCollateral = ({ data }: Props): CRCollateral => {
  const grantedCollateral = useMemo(() => {
    return [
      {
        label: "Collateral Number",
        content: data?.collateralNumber,
      },
      {
        label: "Collateral Status",
        content: data?.collateralStatus,
      },
      {
        label: "Rating",
        content: data?.collateralRating,
      },
      {
        label: "Rating Institution",
        content: data?.ratingInstitution,
      },
      {
        label: "Bind Type",
        content: data?.bindType,
      },
      {
        label: "Bind Date",
        content: data?.bindDate && toLocalDate(data.bindDate),
      },
      {
        label: "Collateral Owner Name",
        content: data?.collateralOwnerName,
      },

      {
        label: "Proof of Ownership",
        content: data?.proofOfOwnership,
      },
      {
        label: "Common Collateral Status",
        content: data?.commonCollateralStatus,
      },
      {
        label: "Common Collateral Percentage",
        content:
          data?.commonCollateralPercentage &&
          `${toLocalNumber(data.commonCollateralPercentage)}%`,
      },
      {
        label: "Joint Account Credit Status",
        content: data?.jointAccountCreditStatus,
      },
      {
        label: "Insured",
        content: data?.insured,
      },
      {
        label: "Information",
        content: data?.information,
      },
    ];
  }, [data]);

  const mainCollateralValue = useMemo(() => {
    return [
      {
        label: "Collateral Value NJO",
        content:
          data?.collateralValueNJOP &&
          toCurrencyPrice(data.collateralValueNJOP),
      },
      {
        label: "Collateral Value Reporter",
        content:
          data?.collateralValueReporter &&
          toCurrencyPrice(data.collateralValueReporter),
      },
      {
        label: "Assessment Date Reporter",
        content:
          data?.collateralAssessmentDateReporter &&
          toLocalDate(data.collateralAssessmentDateReporter),
      },
      {
        label: "Collateral Value Indipendent Assersors",
        content: data?.collateralValueIndependentAssessors,
      },
      {
        label: "Independent Assessors Name",
        content: data?.independentAssersorsName,
      },
      {
        label: "Assessment Date Indipendent Assersors",
        content: data?.assessmentDateIndependentAssersors,
      },
      {
        label: "Provider Type",
        content: data?.providerTypeCode,
      },
      {
        label: "Provider",
        content: data?.providerCode,
      },
      {
        label: "Last Update Date",
        content: data?.referenceDate && toLocalDate(data.referenceDate),
      },
    ];
  }, [data]);

  return { grantedCollateral, mainCollateralValue };
};
