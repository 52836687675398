import { DataTableSortStatus } from "mantine-datatable";

import {
  CreditApprovalOrderBy,
  CreditUnderwritingOrderBy,
  CustomerOrderBy,
  LimitRequestOrderBy,
  RiskReviewOrderBy,
  UserOrderBy,
} from "../../graphql/generated.ts";
import { getApiOrderBy } from "../../utils/api.ts";

export const getRiskMonitoringApiSort = (
  tableSort: DataTableSortStatus
): LimitRequestOrderBy => {
  const {
    companyName,
    salesName,
    salesTeamName,
    limitRecommendation,
    tenorRecommendation,
    cuInsuranceLimit,
    cuAssignedName,
    rrAssignedName,
    rMonAssignedName,
    rrResultDate,
    cuLimitRecommendation,
    committeeDate,
    committeeResult,
    committeeNotes,
    ...apiSort
  } = getApiOrderBy(tableSort);
  let company: CustomerOrderBy | undefined = undefined;
  let riskReview: RiskReviewOrderBy | undefined = undefined;
  let creditUnderwriting: CreditUnderwritingOrderBy | undefined = undefined;
  let cuAssigned: UserOrderBy | undefined = undefined;
  let rrAssigned: UserOrderBy | undefined = undefined;
  let rMonAssigned: UserOrderBy | undefined = undefined;
  let creditApproval: CreditApprovalOrderBy | undefined = undefined;
  if (companyName) {
    company = { name: companyName };
  }
  if (salesName) {
    company = { salesName };
  }
  if (salesTeamName) {
    company = { salesTeamName };
  }
  if (limitRecommendation) {
    riskReview = { limitRecommendation: limitRecommendation };
  }
  if (tenorRecommendation) {
    riskReview = { tenorRecommendation: tenorRecommendation };
  }
  if (rrResultDate) {
    riskReview = { analysisResultDate: rrResultDate };
  }
  if (cuInsuranceLimit) {
    creditUnderwriting = { insuranceLimitApproved: cuInsuranceLimit };
  }
  if (cuLimitRecommendation) {
    creditUnderwriting = { limitRecommendation: cuLimitRecommendation };
  }
  if (cuAssignedName) {
    cuAssigned = { name: cuAssignedName };
  }
  if (rrAssignedName) {
    rrAssigned = { name: rrAssignedName };
  }
  if (rMonAssignedName) {
    rMonAssigned = { name: rMonAssignedName };
  }
  if (committeeDate) {
    creditApproval = { date: committeeDate };
  }
  if (committeeResult) {
    creditApproval = { result: committeeResult };
  }
  if (committeeNotes) {
    creditApproval = { notes: committeeNotes };
  }
  return {
    ...apiSort,
    company,
    riskReview,
    creditUnderwriting,
    cuAssigned,
    rrAssigned,
    rMonAssigned,
    creditApproval,
  };
};
