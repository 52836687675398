import { createStyles, MantineTheme, rem } from "@mantine/core";
import { CSSProperties } from "react";

const getTableVariantStyles = (
  theme: MantineTheme,
  variant: "default" | "nested"
) => {
  if (variant === "default") {
    return {
      paddingBottom: rem(20),
      "&>div>div>div>table>thead>tr>th": {
        fontWeight: 600,
        lineHeight: 1.5,
        color: `${theme.colors.midnightBlue[0]}`,
        paddingBottom: rem(12),
        paddingTop: rem(12),
        paddingLeft: 0,
        paddingRight: rem(20),
        "&:hover:not(:has(button:hover))": {
          background: theme.colors.grayIce[2],
        },
      },
      "&>div>div>div>table>thead>tr>th:first-of-type": {
        paddingLeft: rem(20),
      },
      "&>div>div>div>table>thead>tr>th:last-of-type": {
        paddingRight: rem(20),
      },
      "&>div>div>div>table>tbody>tr>td": {
        padding: `${rem(8)} ${rem(20)} ${rem(8)} 0`,
      },
      "&>div>div>div>table>tbody>tr>td:first-of-type": {
        padding: `${rem(8)} ${rem(20)} ${rem(8)} ${rem(20)}`,
      },
      "&>div>div>div>table>tbody>tr>td:last-of-type": {
        padding: `${rem(8)} ${rem(20)} ${rem(8)}  0`,
      },
    };
  }

  if (variant === "nested") {
    return {
      "&>div>div>div>table>thead>tr>th": {
        fontWeight: 500,
        lineHeight: 1.5,
        fontSize: rem(14),
        color: `${theme.colors.dusk[0]}`,
        padding: 0,
        "&:hover:not(:has(button:hover))": {
          background: theme.colors.grayIce[2],
        },
      },
      "&>div>div>div>table>thead>tr>th>div>div": {
        display: "flex",
        justifyContent: "center",
      },
      "&>div>div>div>table>tbody>tr>td": {
        padding: 0,
      },
    };
  }
};

export const useStyles = createStyles(
  (
    theme: MantineTheme,
    {
      minWidth,
      variant,
    }: { minWidth?: CSSProperties["minWidth"]; variant: "default" | "nested" }
  ) => ({
    empty: {
      minWidth: minWidth,
      maxWidth: "100%",
      width: "100%",
      minHeight: 200,
      padding: 20,
      borderRadius: rem(4),
      background: "rgba(176, 182, 189, 0.08)",
      color: theme.colors.grayIce[0],
      display: "flex",
      flexFlow: "column",
      alignItems: "center",
      justifyContent: "center",
      gap: rem(12),
      fontSize: rem(14),
    },
    tableWrapper: {
      display: "flex",
      flexFlow: "column",
      gap: rem(20),
      maxWidth: "100%",
      minWidth: minWidth,
      "@media (max-width: 48em)": {
        gap: rem(16),
      },
    },
    scrollArea: {
      maxWidth: "100%",
    },
    table: {
      width: "fit-content",
      minWidth: minWidth,
      ...getTableVariantStyles(theme, variant),
      "@media (max-width: 48em)": {
        paddingBottom: rem(16),
      },
    },
  })
);
