import { useMemo } from "react";

import { MultiFieldCardItem } from "../../../../../../components/Cards/MultiFieldCard/MultiFieldCard.tsx";
import {
  ClikContractMaximum,
  ContractType,
} from "../../../../../../graphql/generated.ts";
import { toLocalDate } from "../../../../../../utils/date.ts";
import { toCurrencyPrice } from "../../../../../../utils/number.ts";

interface Props {
  data: ClikContractMaximum | null | undefined;
  type: ContractType;
}

export const useCRMaximumDetailsOfContract = ({
  data,
  type,
}: Props): MultiFieldCardItem[] => {
  return useMemo(() => {
    if (type === ContractType.Credit) {
      return [
        {
          label: "Max Overdue Payments Amount",
          content:
            data?.maxOverduePaymentsAmount &&
            toCurrencyPrice(data.maxOverduePaymentsAmount),
        },
        {
          label: "Max Overdue Payments Number",
          content: data?.maxOverduePaymentsNumber,
        },
        {
          label: "Max Overdue Payments Number Date",
          content:
            data?.maxOverduePaymentsNumberDate &&
            toLocalDate(data?.maxOverduePaymentsNumberDate),
        },
        {
          label: "Max Days Past Due",
          content: data?.maxDaysPastDue,
        },
        {
          label: "Max Days Past Due Date",
          content:
            data?.maxDaysPastDueDate && toLocalDate(data?.maxDaysPastDueDate),
        },
        {
          label: "Worst Status",
          content: data?.worstStatus,
        },
        {
          label: "Worst Status Date",
          content: data?.worstStatusDate && toLocalDate(data.worstStatusDate),
        },
      ];
    }

    if (type === ContractType.Bond || type === ContractType.OtherFacilities) {
      return [
        {
          label: "Max Overdue Payments Amount",
          content:
            data?.maxOverduePaymentsAmount &&
            toCurrencyPrice(data.maxOverduePaymentsAmount),
        },
        {
          label: "Max Days Past Due",
          content: data?.maxDaysPastDue,
        },
        {
          label: "Max Days Past Due Date",
          content: null,
        },
        {
          label: "Worst Status",
          content: data?.worstStatus,
        },
        {
          label: "Worst Status Date",
          content: data?.worstStatusDate && toLocalDate(data.worstStatusDate),
        },
      ];
    }

    if (
      type === ContractType.Guarantee ||
      type === ContractType.LetterOfCredit
    ) {
      return [
        {
          label: "Worst Status",
          content: data?.worstStatus,
        },
        {
          label: "Worst Status Date",
          content: data?.worstStatusDate && toLocalDate(data.worstStatusDate),
        },
      ];
    }

    return [];
  }, [data, type]);
};
