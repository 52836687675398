import { Button, Flex, Modal } from "@mantine/core";
import { ReactNode } from "react";

import Title from "../Title/Title.tsx";
import { useStyles } from "./FilterMobile.styles.ts";

interface FilterModalProps {
  opened?: boolean;
  isActive?: boolean;
  children: ReactNode;
  onClose: () => void;
  onClean: () => void;
  onApply: () => void;
}
const FilterMobile = ({
  opened = false,
  isActive,
  children,
  onClose,
  onClean,
  onApply,
}: FilterModalProps) => {
  const { classes } = useStyles();

  return (
    <Modal
      opened={opened}
      fullScreen
      withCloseButton={false}
      size="xl"
      onClose={onClose}
    >
      <Flex direction="column" gap={20} h="100%" className={classes.wrapper}>
        <Flex justify="space-between" align="center">
          <Title size="h3">Filter</Title>
          <Button
            disabled={!isActive}
            variant="outlineBlue"
            uppercase
            onClick={onClean}
          >
            clear
          </Button>
        </Flex>
        <Flex direction="column" justify="space-between" h="100%">
          {children}
          <Flex gap={20}>
            <Button
              variant="outlineBlue"
              uppercase
              className={classes.button}
              onClick={onClose}
            >
              cancel
            </Button>
            <Button
              disabled={!isActive}
              variant="filledBlue"
              uppercase
              className={classes.button}
              onClick={onApply}
            >
              apply
            </Button>
          </Flex>
        </Flex>
      </Flex>
    </Modal>
  );
};

export default FilterMobile;
