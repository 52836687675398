import { Divider, Flex, Text } from "@mantine/core";
import { useCallback } from "react";

import { Note } from "../../types/note.ts";
import { toLocalDate } from "../../utils/date.ts";
import Spoiler from "../Spoiler/Spoiler.tsx";
import { useStyles } from "./NoteList.styles.ts";

interface Props {
  notes: Pick<Note, "text" | "createdAt">[] | undefined | null;
}

const NoteList = ({ notes }: Props) => {
  const { classes } = useStyles();

  const renderNote = useCallback(
    (note: Pick<Note, "text" | "createdAt">, index: number) => {
      return (
        <Flex key={index} justify="space-between">
          <Spoiler text={note.text} maxStringLength={8} width="50%" />
          <Text className={classes.notesDate}>
            {toLocalDate(note.createdAt)}
          </Text>
        </Flex>
      );
    },
    [classes]
  );

  if (!notes || notes.length === 0) {
    return;
  }

  return (
    <Flex w="100%" direction="column" className={classes.wrapper}>
      {notes.map((note, index) => {
        return (
          <>
            {renderNote(note, index)}
            {notes.length > index + 1 && <Divider />}
          </>
        );
      })}
    </Flex>
  );
};

export default NoteList;
