import { Flex } from "@mantine/core";

import { useCurrentUserContext } from "../../components/Auth/CurrentUserProvider.tsx";
import Layout from "../../components/Layout/Layout";
import Title from "../../components/Title/Title";
import { RolePermission } from "../../graphql/generated.ts";
import { hasPermission } from "../../utils/user.ts";
import Page404 from "../404/Page404.tsx";
import NewLimitRequestedTable from "./NewLimitRequested/NewLimitRequestedTable.tsx";
import OngoingCUTable from "./OngoingCU/OngoingCUTable.tsx";

const CreditUnderwritingPage = () => {
  const { user } = useCurrentUserContext();

  const hasAccess = hasPermission(
    user,
    RolePermission.CanViewCreditUnderwriting
  );

  if (!hasAccess) {
    return <Page404 />;
  }

  return (
    <Layout>
      <Flex direction="column" gap={{ base: 16, sm: 24 }}>
        <Title>Credit Underwriting</Title>
        <NewLimitRequestedTable />
        <OngoingCUTable />
      </Flex>
    </Layout>
  );
};

export default CreditUnderwritingPage;
