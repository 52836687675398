import { Flex } from "@mantine/core";

import { useCurrentUserContext } from "../../components/Auth/CurrentUserProvider.tsx";
import Layout from "../../components/Layout/Layout.tsx";
import Title from "../../components/Title/Title.tsx";
import { RolePermission } from "../../graphql/generated.ts";
import { hasPermission } from "../../utils/user.ts";
import Page404 from "../404/Page404.tsx";
import CreditLimitFunnelTable from "./CreditLimitFunnel/CreditLimitFunnelTable.tsx";
import LimitUtilizationTable from "./LimitUtilization/LimitUtilizationTable.tsx";

const RiskFunnelDashboardPage = () => {
  const { user } = useCurrentUserContext();

  const hasAccess = hasPermission(
    user,
    RolePermission.CanViewRiskFunnelDashboard
  );

  if (!hasAccess) {
    return <Page404 />;
  }

  return (
    <Layout>
      <Flex direction="column" gap={{ base: 16, sm: 24 }}>
        <Title>Risk Funnel Dashboard</Title>
        <CreditLimitFunnelTable />
        <LimitUtilizationTable />
      </Flex>
    </Layout>
  );
};

export default RiskFunnelDashboardPage;
