import { CSSProperties } from "react";

import ActionIcon from "../ActionIcon/ActionIcon";
import SvgInfo from "../Icons/Info";
import { useStyles } from "./NoDataMessage.styles";

interface Props {
  minWidth?: CSSProperties["minWidth"];
}

export default function NoDataMessage({ minWidth }: Props) {
  const { classes } = useStyles({ minWidth });
  return (
    <div className={classes.empty}>
      <ActionIcon variant="ghost" size={48}>
        <SvgInfo />
      </ActionIcon>
      <span>No data to display.</span>
    </div>
  );
}
